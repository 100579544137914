export default {
    welcome: 'स्वागत',
 
 // header
     SelectCamera :'कैमरा चुनें',
     Projects : 'परियोजना',
     AIData : 'PPE',
     AIDataAnalysis : 'PPE चार्ट',
     PpeReports : 'PPE रिपोर्ट',
     Aqi :'Aqi',
     PeopleDetection :'पीपल डिटेक्शन ',
     Archive : 'संग्रह',
     Live : 'लाइव',
     Timelapse :'टाइम लैप्स',
     WatchTimelapse : 'टाइम लैप्स देखें',
     CreateTimelapse :'टाइम लैप्स बनाएं',
     Compare :'तुलना',
     MediaUpload  :'मीडिया अपलोड',
     Planning :'योजना',
     EditData :'डेटा संपादित करें',
     CompareEvents :'घटनाओं की तुलना करें',
     EventChart : 'घटना चार्ट',
     EventTimelapse :'टाइम लैप्स आयोजन',
     Report :'रिपोर्ट',
     FavoriteList : 'पसंदीदा सूची',
     More : 'ज़्यादा',
     EventTimeline : 'घटना समयरेखा',
     SplitScreen : 'विभाजित स्क्रीन',
     Back :'पीछे',
     Menu : 'मेन्यू',
     Options : 'विकल्प',  
     CreateDetailedReport : 'विस्तृत रिपोर्ट बनाएं',
     InstantReport : 'तत्काल रिपोर्ट',
     CreateNow : 'अब बनाओ',
     Scheduleit : 'अनुसूची',
     Refresh : 'पुनः लोड करें',
     PleaseReloadPage : 'कृपया पृष्ठ पुनः लोड करें',
    // alerts

     ImageLoadError : 'क्षमा करें छवि लोड नहीं हुई',
     imagenotFound : 'छवियाँ चयनित तिथि पर नहीं मिली',
     ImageShareSuccessful :'छवि सफलतापूर्वक साझा की गई',
     SomethingWentWrong  :'कुछ गलत हो गया है। कृपया बाद में दोबारा प्रयास करें.',
     ImageShareProcoreSuccessful :'प्रोकोर के साथ छवि साझा करना सफल रहा।',
     LayoutSuccessfullyUploaded :'लेआउट सफलतापूर्वक अपलोड कर दिया गया है',
     LayoutSuccessfullyDeleted :'लेआउट सफलतापूर्वक हटा दिया गया है',

// settings 
     Logout : 'लॉगआउट',
     SuperAdmin : 'सुपर एडमिन',
     User : 'उपयोगकर्ता',
     Admin : 'व्यवस्थापक',
     SelectLanguage :'भाषा चुने',
     UpdatePassword : 'पासवर्ड अपडेट करें',
     AddUser : 'उपयोगकर्ता जोड़ें',
     Users : 'उपयोगकर्ताओं को प्रबंधित करें',
     UserLogs :'उपयोगकर्ता लॉग',
     EmailTrigger : 'ईमेल ट्रिगर',
     CameraOffalert : 'कैमरा-बंद अलर्ट',
     CameraStatus : 'कैमरा स्थिति',
     CameraNameAndTag : 'कैमरा का नाम और टैग',
     CreateCustomTimelapse : 'कस्टम टाइम लैप्स बनाएं',
     Billing : 'बिलिंग',
     ManageBIMRenderings :'BIM रेंडरिंग प्रबंधित करें',
     Integration : 'एकीकरण',
     TwoFactorAuthentication :'दो तरीकों से प्रमाणीकरण',
     DemoVideos :'डेमो वीडियो',
     Support : 'सहायता',
     CameraConfiguration :'कैमरा विन्यास',
     ProjectInfo : 'परियोजना की जानकारी',
     SearchProjects : 'प्रोजेक्ट खोजें',
     ContactSupport :'सहयोग टीम से संपर्क करें',
     SendEmail :'ईमेल भेजें',
   //  drawers 
     MapView: 'नक्शा देखें',
     SlideShow:'स्लाइड शो',
     ConstructionCamera : 'निर्माण कैमरा',
     InteriorMonitoring : 'आंतरिक निगरानी',
     MaterialTracking : 'सामग्री ट्रैकिंग',
     DroneMonitoring : 'ड्रोन निगरानी',
     LinearConstructionMonitoring : 'रैखिक निर्माण मॉनिटरिन',
     InteractiveDashboard : 'इंटरएक्टिव डैशबोर्ड',
  
  //Forms
     To:'को',
     OtherEmails:'यहां और ईमेल जोड़ें',
     Subject : 'विषय',
     Message : 'संदेश',
     SelectImage : 'छवि चुने',
     EmailRequired : 'ईमेल की जरूरत है',
     EmailValid : 'ईमेल मान्य होना चाहिए',
  // Buttons
     Send : 'भेजें',
     Cancel : 'रद्द करें',
     Close :'बंद करे',
     Done : 'सम्पन',
     AddNew : 'नया जोड़ो',
     Ok :'ठीक',
     Save : 'सहेजें',
  // DashboardPages: 
     LiveImage : 'लाइव छवि',
     ImageMarkup : 'छवि मार्कअप',
     ShareImage : 'चित्र साझा करें',
     AddEventTimeline :'इवेंट टाइमलाइन में जोड़ें',
     RemoveFavorite : 'पसंदीदा हटाएं',
     AddToFavorite :  'फेवरेट में जोड़ें',
     Weather : 'मौसम ',
     DownloadImage : 'छवि डाउनलोड करें',
     FullScreen : 'पूर्ण स्क्रीन',
     Pleasestandby : 'कृपया प्रतीक्षा करें',
     UpdateCameraTag : 'कैमरा टैग अपडेट करें',
     BIM : 'BIM',
     FullTimeline : 'पूर्ण समयरेखा',
     ShareToProcore :'प्रोकोर में साझा करें',
     WeatherInfo : 'मौसम की जानकारी',
     CreateNewBIM: 'नया BIM रेंडरिंग बनाएं',
     OpticVyuUpdates : 'OpticVyu अपडेट',
     InstallationChecklist : 'स्थापना चेकलिस्ट',
     InstallationDetails: 'स्थापना विवरण',
     SimCardAdded : 'सिम कार्ड जोड़ा गया',
     title :'शीर्षक',
     ItemRequired : 'आइटम की आवश्यकता है',
     Remoteit :'इसे रिमोट करें',
     InstallationSnap :'स्थापना स्नैप',
     CamConfigSnap :'कैमरा कॉन्फिग स्नैप',
     SpeedTest :'स्पीड टेस्ट',
     ActivateReport : 'रिपोर्ट सक्रिय',
     SiteLayout : 'साइट लेआउट',
     CamNameTag :  'कैमरा का नाम और टैग',
     DeliveryChallan :'डिलीवरी चालान',
     LocationWhatapps : 'Location Whatapps',
     ImageDeletion : 'छवि हटाना',
     NolayoutimageAvailable : 'कोई लेआउट छवि उपलब्ध नहीं है',
     Deletelayout : 'लेआउट हटाएं',
     GoToBilling : 'बिलिंग पर जाएं',
     NotifyTeam:  'टीम को सूचित करें',
     billingNote : 'नोट: इस अधिसूचना को हटाने के लिए सभी लंबित चालानों का भुगतान करें',
     mediaUploadTitle : 'एक लेआउट अपलोड करने के लिए क्लिक करें',
     DragDrop : 'खींचें और छोड़ें',
     EmailFaild : 'ईमेल वितरण विफल',
     Liveimagenotfound : 'लाइव छवि नहीं मिली',
     removedFavorites :'पसंदीदा से छवि हटाई गई',
     addedFavourites : 'चित्र पसंदीदा में जोड़ा गया',
     WhomtoNotify : 'किसे सूचित करें (ईमेल)?',
     ShareByEmail :'ईमेल द्वारा साझा करें',
     Structure : 'संरचना',
     RenederingName : 'रेन्डरिंगनाम',
     RenderingInfo : 'प्रतिपादन जानकारी',
     DiscribeTheEvent : 'घटना का वर्णन करें',
     Imagenotfound : 'छवि नहीं मिली',
     MainView : 'मुख्य पेज',
     EditView :'संपादित पेज',
     SurveyDate : 'सर्वेक्षण तिथि',
     SelectZone :'जोन चुनें',
     Inventories : 'सूची',
        Draft : 'प्रारूप',
        AddMaterial :'सामग्री जोड़ें',
        Suppliers :'आपूर्तिकर्ताओं',
        EditTask : 'कार्य संपादित करें',
        selectEvent: "घटना चुनें", 
        PlannedStartDate  : 'नियोजित प्रारंभ तिथि',
        seeActualStartDate:'वास्तविक प्रारंभ और समाप्ति तिथि छवियों को देखने के लिए यहां क्लिक करें',
        seeActualEndDate : 'नियोजित प्रारंभ और समाप्ति तिथि छवियों को देखने के लिए यहां क्लिक करें',
        SeePlannedDateImages : 'नियोजित तिथि छवि देखें',
        plannedEndDate:'नियोजित समाप्ति तिथि',
        ActualStartDate :'वास्तविक प्रारंभ तिथि',
        SaveActualStartDate:'वास्तविक प्रारंभ तिथि सहेजें',
        SaveActualEndDate : 'वास्तविक समाप्ति तिथि सहेजें',
        StartdateSave : 'वास्तविक प्रारंभ तिथि सफलतापूर्वक सहेजी गई है',
        EnddateSave : 'वास्तविक समाप्ति तिथि सहेजना सफल रहा',
        Search : 'खोजे',
        Import :'आयात',
        Export : 'निर्यात',
        TaskName : 'कार्य का नाम',
        startDateTime:"प्रारंभ दिनांक-समय",
        endDatetime:'समाप्ति तिथि-समय',
        SelectDatetime : 'दिनांक-समय चुनें',
        AddNewTask : 'नया कार्य जोड़ें',
        ImportExcelFile : 'एक्सेल फ़ाइल आयात करें',
        Steps : 'आनुक्रमिक',
        stepOne:'1. प्रिमावेरा / माइक्रोसॉफ्ट प्रोजेक्ट आदि से एक्सेल (.xls) निर्यात करें.',
        stepTwo: '2. डेटा को उसके स्वरूप या स्तंभ लेबल को बदले बिना नमूना.xls में कॉपी करें',
        stepThree : '3.sample.xls फ़ाइल अपलोड करें।',
        Downloadfile:'Sample.xls फ़ाइल डाउनलोड करें',
        RecordUpdatesuccessfully:'रिकॉर्ड सफलतापूर्वक अपडेट किया गया',
        NewEventsuccess:'नया ईवेंट कार्य सफलतापूर्वक सबमिट किया गया',
        taskUplaod : 'आपका कार्य सफलतापूर्वक अपलोड किया गया था।',
        submit : 'भेजें',
        StartDate : 'आरंभ करने की तिथि',
        EndDate : 'समाप्ति तिथि',
        ActualEndDate :'वास्तविक समाप्ति तिथि',
        Actions :'कार्रवाई',
        pleaseSelectanyOne :'कृपया किसी का चयन करें',
        eventtimenote:'घटना समय चूक: केवल दिन का समय (सुबह 8 बजे से शाम 5 बजे तक)',
        eventtime24 : 'घटना समय चूक: 24 घंटे ',
        videoConfirm : 'इस वीडियो को हटाने की पुष्टि करें?',
        removealert:'टाइमलैप्स वीडियो सफलतापूर्वक हटा दिया गया',
        videonotfound : 'टाइमलैप्स वीडियो नहीं मिला।',
        AddNewUser : 'नया उपयोगकर्ता पंजीकरण',
        Checkinfo : 'जानकारी जांचें',
        ManageUser :'उपयोगकर्ता प्रबंधित करें',
        FirstName : 'पहला नाम',
        LastName : 'अंतिम नाम',
        Email : 'ईमेल',
        ContactNumber :'संपर्क',
         selectproject: 'उपयोगकर्ता के लिए परियोजनाओं का चयन करें',
         selectProjectOpenAccess: 'परियोजनाओं का चयन करें',
         newUserOpenAccess: 'ओपन एक्सेस वाला नया उपयोगकर्ता सफलतापूर्वक बनाया गया',
         updateUserErrorOpenAccess: 'उपयोगकर्ता स्थिति अपडेट करने में असमर्थ',
         createOpenAccessHeading: 'ओपन एक्सेस लिंक बनाएं',
         createOpenAccessLinkButton: 'लिंक बनाएं',
         createOpenAccessLinkNavButton:'ओपन-एक्सेस लिंक',
        MakeNormalUser : 'सामान्य उपयोगकर्ता बनाएं',
        Privileges : 'विशेषाधिकार',
        SeeExistingUsers : 'मौजूदा उपयोगकर्ता देखें',
        MakeAdmin : 'एडमिन बनाओ',
        ChangePassword : 'पासवर्ड बदलें',
        EmailScheduler : 'ईमेल अनुसूचक',
        CameraTag : 'कैमरा टैग',
        CameraInfo : 'कैमरा जानकारी',
        CameraNotification : 'कैमरा अधिसूचना',
        CustomizeTimelapseVideo : 'टाइमलैप्स वीडियो को अनुकूलित करें',
        Userinfo : 'उपयोगकर्ता जानकारी',
        Name : 'नाम',
        CreatedDate :'रचना तिथि',
        Contact : 'संपर्क',
        Status : 'स्थिति',
        ProjectName :'परियोजना का नाम',
        Company :'कंपनी',
        City :'शहर',
        AddMorePorject :'और प्रोजेक्ट जोड़ें',
        usermsgone : 'कंस्ट्रक्शन कैमरे के लिए एक्सेस दिया गया है। आंतरिक निगरानी और सामग्री ट्रैकिंग के लिए एक्सेस प्रबंधित करने के लिए, उपयोगकर्ताओं को प्रबंधित करें पर जाएं',
        usermsgtwo: 'आंतरिक निगरानी मॉड्यूल के लिए एक्सेस दिया गया है। सामग्री ट्रैकिंग के लिए एक्सेस प्रबंधित करने के लिए, उपयोगकर्ताओं को प्रबंधित करें पर जाएं',
        usermsgthree :'उपयोगकर्ता सफलतापूर्वक जोड़ा गया। नए उपयोगकर्ता को लॉगिन निर्देश ईमेल कर दिए गए हैं.',
        usererrorp1 :'उपयोगकर्ता पहले से मौजूद है। विकल्प पर जाने के लिए अपने सुपरएडमिन से अनुरोध करें -> उपयोगकर्ता और एक्सेस दें',
        usererrorp2 : 'वर्तमान परियोजना के लिए',
        Default : 'डिफ़ॉल्ट',
        CreatenewBIMRenedering :'नया BIM प्रतिपादन बनाएं',
        createprocore:'Procore से जुड़ें',
        ConnectWithBim360 : 'Bim 360 से जुड़ें',
        requestURL:'एंबेडेड ऐप URL का अनुरोध करें',
        biminstruction : 'BIM360 इनसाइट डैशबोर्ड पर "एम्बेडेड ऐप URL" का उपयोग करने के लिए, नीचे दिए गए निर्देशों का पालन करें',
        instOne: '1. BIM 360 इनसाइट डैशबोर्ड पर जाएं',
        instTwo: ' 2. Customizee पर जाएं',
        instThree : '3. कार्ड लाइब्रेरी पर क्लिक करें',
        instfour :'4. "OpticVyu" या  "Partner Card" नाम का कार्ड खोजें और जोड़ें।',
        instFive :' 5. कार्ड को उपयुक्त शीर्षक और OpticVyu "एम्बेडेड ऐप URL . के साथ कॉन्फ़िगर करें" ',
        instSix:'6. कैमरा फ़ीड स्वचालित रूप से प्रदर्शित होनी चाहिए.',
        bimQue :'आप एम्बेडेड ऐप पर कौन से प्रोजेक्ट दिखाना चाहते हैं?',
        bimsuccessmsg:'अनुरोध पंजीकृत! आपको 2 दिनों के भीतर ईमेल के माध्यम से एम्बेडेड ऐप URL प्राप्त होगा।',
        Sim :'Sim',
        Notes: 'नोट्स ',
        CameraReboot :'कैमरा रिबूट',
        ImageInterval :'छवि अंतराल',
        CamType :'कैम प्रकार',
        BackImageTime : 'बैक इमेज टाइम',
        ViewAddNote:'नोट देखें/जोड़ें',
        SimManage:'सिम मैनेज',
        AddCameraNote :'कैमरा नोट जोड़ें',
        AddNote :'नोट जोड़े',
        AssignSimcard : 'सिम कार्ड असाइन करें',
        Addsimno :'सिम नंबर जोड़ें',
        simprovider : 'सिम प्रदाता',
        Simusagestatus :'सिम उपयोग की स्थिति',
        ifsimfree : 'फ्री हो तो किसके साथ',
        UpdatedBy : 'द्वारा अपडेट',
        camsetting: 'कैमरा सेटिंग को सफलतापूर्वक अपडेट किया गया',
        camfialdsetting :'कैमरा सेटिंग अपडेट करने में विफल। कृपया पुन: प्रयास करें!',
        EditCameraNameandTag:'कैमरा नाम और टैग संपादित करें',
        CameraName : 'कैमरा का नाम',
      
        Reset :'रीसेट',
        cameranotification:'कैमरा चालू/बंद अधिसूचना',
        NotificationActive : 'अधिसूचना सक्रिय',
        camranotiStatus:'कैमरा अधिसूचना स्थिति निष्क्रिय है, इसे सक्रिय करने के लिए कृपया चेकबॉक्स पर क्लिक करें',
        cameraActivemsg :'कैमरा अधिसूचना सक्रिय',
        Resetsuccesful:'रीसेट सफल',

        ChangeYourPassword :'अपना पासवर्ड बदलें',
        OldPassword :'पुराना पासवर्ड',
        NewPassword : 'नया पासवर्ड',
        ConfirmPassword:'पासवर्ड की पुष्टि कीजिये',
        passwordvalid:'कृपया कम से कम एक बड़ा, छोटा, संख्यात्मक और विशेष वर्ण दर्ज करें',
        passwordsame: 'पासवर्ड एक जैसा होना चाहिए',
        UploadSpeed : 'अपलोड स्पीड',
        OpticVyuPresentation : 'OpticVyu प्रेजेंटेशन',
        DateofInstallation :'स्थापना की तिथि',
        Designation : 'पद',
        Person1 : 'व्यक्ति 1',
        Person2 : 'व्यक्ति 2',
        Person3 : 'व्यक्ति 3',
        Person4 : 'व्यक्ति 4',
        AccountPerson :'खाता व्यक्ति',
        InstallationNote:'इंस्टालेशन नोट',
        OVEnginner :'OV इंजीनियर',
        OneTimeTimelapse : 'वन टाइम टाइमलैप्स',
        AdvanceTimelapse : 'एडवांस टाइमलैप्स',
        RecurringTimelapse : 'पुनरावर्ती टाइमलैप्स', 
        ProjectTimelapse : 'प्रोजेक्ट टाइमलैप्स',
        StartTime :'प्रारंभ समय',
        Endtime : 'अंतिम समय',
        TimelapseSpeed:'टाइमलैप्स स्पीड',
        TimelapseQuality :'टाइमलैप्स गुणवत्ता',
        TimelapseAdvancesettings : 'टाइमलैप्स एडवांस सेटिंग्स',
        Selectframeblending : 'फ्रेम सम्मिश्रण का चयन करें',
        SelectVideoSmoothing :'वीडियो स्मूदिंग का चयन करें ',
        SelectDeflicker : 'डिफ्लिकर का चयन करें',
        EnterOverlayText:'ओवरले टेक्स्ट दर्ज करें (वैकल्पिक)',
        timelapsenote1 : '1. उन्नत समय चूक में 8 घंटे तक लग सकते हैं। इसके तैयार होते ही आपको सूचित कर दिया जाएगा।',
        timelapsenote2 : '2. तेजी से अनुकूलन के लिए, कृपया वन-टाइम कस्टम टाइमलैप्स विकल्प का उपयोग करें।',
        projectnote1 : '1. प्रोजेक्ट टाइम-लैप्स में 12 घंटे तक लग सकते हैं। इसके तैयार होते ही आपको सूचित कर दिया जाएगा।',
        selectop1 :'उन्नत सम्मिश्रण विकल्पों का उपयोग केवल तभी करें जब कैमरा स्थिर हो। टावर क्रेन या अस्थिर समर्थन पर स्थापित होने पर, सम्मिश्रण विकल्प आउटपुट गुणवत्ता को खराब कर देगा।',
        selectop2 :'यदि एक कैमरा टावर क्रेन या पतले पोल आदि जैसे अस्थिर समर्थन पर स्थापित है, तो इस विकल्प का उपयोग अस्थिर समय-व्यतीत वीडियो को स्थिर करने के लिए करें।',
        Adalertmessage : 'प्रारंभ और समाप्ति दिनांक-समय आज के दिनांक-समय से अधिक नहीं होना चाहिए',
        adrequired :'प्रारंभ दिनांक-समय और समाप्ति दिनांक-समय अनिवार्य फ़ील्ड हैं',
        adrequired2 : 'प्रारंभ दिनांक-समय समाप्ति दिनांक-समय से पहले का होना चाहिए',
        adrequired3 : 'कृपया गति और गुणवत्ता का चयन करें',
        adrequired4 : 'कृपया उन्नत अनुकूलन विकल्प चुनें और यह उप-विकल्पों का सम्मान है।',
        adsuccess : 'अग्रिम समय चूक संसाधित किया जा रहा है। एक बार यह तैयार हो जाने पर आपको ईमेल के माध्यम से सूचित किया जाएगा',
        addnotschedule :'अग्रिम समय चूक निर्धारित नहीं है, कृपया पुन: प्रयास करें!',
        upto8 : '1. उन्नत समय चूक में 8 घंटे तक लग सकते हैं। इसके तैयार होते ही आपको सूचित कर दिया जाएगा।<br>2. तेजी से अनुकूलन के लिए, कृपया वन-टाइम कस्टम टाइमलैप्स विकल्प का उपयोग करें।',
        adarror : 'इस कैमरे के लिए कोई मौजूदा कस्टम टाइमलैप्स नहीं मिला।',
        resetsetting: 'आपकी सेटिंग रीसेट कर दी गई है।',
        adnotcreate :'अग्रिम समय चूक रीसेट नहीं, कृपया पुन: प्रयास करें!',

        info1 : 'एक वीडियो में एकाधिक कैमरों के समय-व्यतीत वीडियो देखने के लिए, इस विकल्प का उपयोग करें।',
      info2 :'प्रोजेक्ट टाइम-लैप्स वीडियो बनाने के लिए न्यूनतम 2 और अधिकतम 4 कैमरों का चयन किया जा सकता है।',
      ptnotifiy :'प्रोजेक्ट टाइम-लैप्स को प्रोसेस होने में 12 घंटे तक लग सकते हैं। इसके तैयार होते ही आपको सूचित कर दिया जाएगा।',
      proprocess :'परियोजना समय चूक संसाधित किया जा रहा है। एक बार यह तैयार हो जाने पर आपको ईमेल के माध्यम से सूचित किया जाएगा',
      projectTiError :'प्रोजेक्ट टाइम-लैप्स शेड्यूल नहीं किया गया है, कृपया पुन: प्रयास करें!',
      alertmessageminmax :"न्यूनतम 2 और अधिकतम 4 कैमरे चुनें",
      projectready :'प्रोजेक्ट टाइम-लैप्स को प्रोसेस होने में 12 घंटे तक लग सकते हैं। इसके तैयार होते ही आपको सूचित कर दिया जाएगा',
       reseterror:'एक बार कस्टम टाइम-लैप्स रीसेट नहीं, कृपया पुन: प्रयास करें!',
       Daily :'दैनिक',
       Weekly : 'साप्ताहिक',
       Monthly :'महीने के',
       info_rec : 'सिस्टम सबमिट की गई सेटिंग्स के अनुसार दैनिक आधार पर एक टाइम-लैप्स वीडियो बनाएगा। .',
       Infovidoss :'कस्टम प्रारंभ/समाप्ति तिथि, प्रारंभ/समाप्ति समय, वीडियो गति और गुणवत्ता की आवश्यकता के लिए एक समय चूक वीडियो बनाएं।',
      createTimelapseInfo : 'प्रोजेक्ट टाइम-लैप्स: एक वीडियो में कई कैमरों के टाइम-लैप्स वीडियो देखने के लिए, इस विकल्प का उपयोग करें। <br />प्रोजेक्ट टाइम-लैप्स वीडियो बनाने के लिए न्यूनतम 2 और अधिकतम 4 कैमरों का चयन किया जा सकता है।',
        reccuringtimelapsecheck :'कृपया पुनरावर्ती टाइमलैप्स प्रकार की जांच करें',
       alerrecurring : 'कृपया पुनरावर्ती टाइमलैप्स प्रकार की जांच करें',
       recurringsave :'आवर्ती समय चूक सेटिंग्स सफलतापूर्वक सहेजी गईं',
       timelapsenotsave:'टाइमलैप्स सबमिट नहीं किया गया, कृपया पुनः प्रयास करें!',
       timelapsebingpressed :'वन टाइम कस्टम टाइम-लैप्स संसाधित किया जा रहा है। एक बार यह तैयार हो जाने पर आपको ईमेल के माध्यम से सूचित किया जाएगा',
       timelapsenotexit:'इस कैमरे के लिए कोई मौजूदा कस्टम टाइमलैप्स नहीं मिला',
       custometimelapseresrt :'कस्टम टाइम-लैप्स रीसेट नहीं हुआ, कृपया पुनः प्रयास करें',
       recurringsettingsave :'आवर्ती टाइमलैप्स सेटिंग्स सफलतापूर्वक सहेजी जाती हैं',
       onetimesetting:'एक बार की टाइमलैप्स सेटिंग सफल सहेजी गई',
       ConnectwithProcore : 'Procore से जुड़ें',
       UserName : 'यूजर का नाम',
       Module : 'मॉड्यूल',
       Page : 'पेज',
       Date :'दिनांक',
       UserType :'यूजर का प्रकार',
       selectRange:'एक रेंज चुनें',
       ManageUsers : 'उपयोगकर्ताओं को प्रबंधित करें',
       UserProfile : 'उपयोगकर्ता प्रोफ़ाइल',
       emailhint : 'ईमेल को अपडेट नहीं किया जा सकता',
        selectuserHint:'यूजर के लिए प्रोजेक्ट का चयन करें',
        Active : 'सक्रिय',
        conscamaccess:'Contruction Camera Access',
        MaterialAccess : 'Material Access',
        InteriorviewAccess : 'Interior view Access',
        InteriorEditAccess:'Interior Edit Access',
        DroneEditAccess : 'Drone Edit Access',
        LinearEditAccess : 'Linear Edit Access',
        UserAccess : 'User Access',
        PPEAIDataScheduleReport: "PPE AI डेटा शेड्यूल रिपोर्ट",
        Reporttype : 'रिपोर्ट प्रकार',
        DaiDay:'दैनिक रिपोर्ट हर दिन भेजी जाएगी',
        DaiDay1:'साप्ताहिक रिपोर्ट प्रत्येक सोमवार को भेजी जाएगी.',
        Dailyreport: 'दैनिक रिपोर्ट',
        Weeklyreport : 'साप्ताहिक विवरण',
        ppeReport : 'पीपीई रिपोर्ट की स्थिति निष्क्रिय है, इसे सक्रिय करने के लिए कृपया चेकबॉक्स पर क्लिक करें',
         ppeActive : 'पीपीई रेपोट सक्रिय',
         procoreconnection : 'आप अपने Procore खाते को OpticVyu से जोड़ सकते हैं। यह आपको आसानी से उपयोगकर्ताओं को Opticvyu में शामिल होने के लिए आमंत्रित करने और छवियों को वापस Procore में साझा करने की अनुमति देगा',
         opticvyuProject:'OpticVyu प्रोजेक्ट्स',
         linkproject : 'Procore प्रोजेक्ट को जोड़ने के लिए',
         selectprocoreproject : 'प्रोकोर प्रोजेक्ट का चयन करें',
          configprocore :'प्रोकोर के साथ प्रोजेक्ट कॉन्फ़िगर करें',
          procoreConnectionSuccess : 'प्रोजेक्ट सफलतापूर्वक प्रोकोर से जुड़ा है',
          requestembadedcode : 'एंबेडेड ऐप कोड का अनुरोध करें',
         SendImagery : 'इमेजरी भेजें',
         procoreissue : "प्रोकोर कनेक्शन मुद्दा?",
         procorealreadyconnected  : "यह प्रोसीड प्रोजेक्ट पहले से OpticVyu प्रोजेक्ट से जुड़ा हुआ है, कृपया अपने प्रोकोर प्रोजेक्ट पर जाएं और मौजूदा एल्बमों का नाम बदलें या निकालें और फिर से प्रयास करें।",
          whichpro : 'आप एम्बेडेड ऐप पर कौन से प्रोजेक्ट दिखाना चाहते हैं ?',
          procoreconfirm  : 'क्या आप वाकई इस प्रोजेक्ट को Opticvyu से जोड़ते हैं?',
          procoreDisconnect: 'चित्र भेजने के लिए प्रोजेक्ट सफलतापूर्वक डिस्कनेक्ट किया गया',
          procorediconfigure : 'क्या आप Procore के साथ सभी कनेक्टेड प्रोजेक्ट्स को डिकॉन्फ़िगर करना चाहते हैं?',
          deconfigure : 'सफलतापूर्वक डीकॉन्फ़िगर करें',
          embadedRequest : 'एंबेडेड ऐप कोड के लिए अनुरोध सफलतापूर्वक पंजीकृत किया गया है। आपको 2 कार्य दिवसों के भीतर ईमेल के माध्यम से कोड प्राप्त होगा।',
          procoremsg :' आपने Opticvyu को Procore के साथ संवाद करने के लिए अधिकृत किया है। आप इसे पूर्ववत कर सकते हैं और Procore से OpticVyu को क्लिक करके अधिकृत कर सकते हैं',
          here : "here",
          proopticvy : 'OpticVyu के बारे में अधिक जानकारी के लिए - प्रोकोर इंटीग्रेशन',
          EmailSchedule:'ईमेल शेड्यूल',
          Selectdays:'दिन चुनें',
          Selecttime:'समय चुनें (IST)*',
          resetsuccessfull :'रीसेट सफलतापूर्वक हुआ',
          twofactoreAuthentication : 'जब आप लॉगिन करने का प्रयास करेंगे तो आपके ईमेल-आईडी पर एक प्रमाणीकरण कोड भेजा जाएगा',
          ClicktoInActive :'सक्रिय करने के लिए क्लिक करें',
          ClicktoActive :'सक्रिय करने के लिए क्लिक करें',
          Device : 'उपकरण',
          Emailstatement : 'ईमेल द्वारा विवरण भेजें',
          Reload : 'पुनः लोड करें',
          Statementsentsuccessfully : 'विवरण सफलतापूर्वक भेजा गया',
          InvoiceDate : 'चालान की तारीख',
          Invoice : 'चालान',
          WorkOrder : 'कार्य आदेश',
          Amount : 'राशि',
          BalanceDue : 'शेष राशि',
          DueDate : 'नियत तारीख',
          OverdueBy : 'बाकी', 
          DefaultTimelapse : 'डिफ़ॉल्ट टाइमलैप्स',
          CustomTimelapse :'कस्टम टाइमलैप्स',
          ArchiveTimelapse : 'टाइमलैप्स संग्रह',
          Advance : 'एडवांस',
          Project : 'प्रोजैक्ट',
          Type : 'प्रकार',
          Advanceediting :'एडवांस संपादन',
          Deflicker : 'डिफ्लिकर',
          Copyurl :'URL कॉपी करें',
          MoveTimelapseToArchive:'टाइमलैप्स को संग्रह में ले जाएं',
          Addcaptionforarchivetimelapse:'टाइमलैप्स संग्रह के लिए कैप्शन जोड़ें',

          Graphs :'ग्राफ ',
          max7 : 'अधिकतम 7 दिनों का चयन किया जा सकता है',
          last7days:'नोट: पिछले 7 दिनों में कोई डेटा उपलब्ध नहीं है। पिछले दिनांकित डेटा को देखने के लिए कस्टम दिनांक सीमा का चयन करें।',
         
          clicktogetrelated : 'संबंधित तस्वीरें प्राप्त करने के लिए चार्ट बार पर क्लिक करें',
          PersonsWithRequiredEquipment :'आवश्यक उपकरण वाले व्यक्ति',
          PersonsWithoutRequiredEquipment : 'आवश्यक उपकरण के बिना व्यक्ति',
          PersonUnrecognizable :'अपरिचित व्यक्ति ',
          PersonsIdentified : 'व्यक्ति की पहचान होती है',

          Remark : 'रिमार्क',
          PossibleHealthImpacts :'संभावित स्वास्थ्य प्रभाव',
          ColorCode : 'रंग कोड',
          Good : 'अच्छा',
          poor :"खराब",
          VeryPoor :"बहुत खराब",
          
          Satisfactory : 'संतोषजनक',
          Moderate : 'उदारवादी',
          Severe  :'गंभीर',

          Minimalimpact :'न्यूनतम प्रभाव',
          Minorbreathing:'संवेदनशील लोगों को सांस लेने में मामूली तकलीफ',
          breathing1:'फेफड़े, दमा और हृदय रोग से पीड़ित लोगों को सांस लेने में तकलीफ',
          breathing2 : 'लंबे समय तक एक्सपोजर पर ज्यादातर लोगों को सांस लेने में तकलीफ',
          breathing3 : 'लंबे समय तक एक्सपोजर पर सांस की बीमारी',
          breathing4 :'स्वस्थ लोगों को प्रभावित करते हैं और मौजूदा बीमारियों वाले लोगों को गंभीरता से प्रभावित करते हैं',

          AddedOn : 'पर जोड़ा',
          AdvanceCustomization : 'अतिरिक्त बदलाव ',
          Speed : 'स्पीड',
          FrameBlending : 'फ्रेम सम्मिश्रण',
          TimelapseSmoothing : 'टाइमलैप्स स्मूथिंग',
          notification :'कोई संग्रह समय चूक नहीं मिला। कस्टम टाइम-लैप्स बनाएं और भविष्य के संदर्भ के लिए संग्रह में महत्वपूर्ण वीडियो जोड़ें',
          notificationtitle : 'सूचना',
          archiveDelete: 'संग्रह टाइमलैप्स सफलतापूर्वक हटाया गया.',
          archiveError : 'संग्रह टाइमलैप्स हटाया नहीं गया। कृपया पुन: प्रयास करें।',
          copymsg : 'URL कॉपी करें! लिंक केवल 7 दिनों के लिए वैध है',
          timelapsesharesuccess:'टाइमलैप्स सफलतापूर्वक साझा किया गया। कृपया अपना मेल चेक करें',
          Quality :'गुणवत्ता',
          Delete : 'हटाएं',
          Share : 'साझा करना',
          Editor :'एडीटर',
          SavetoCart : 'कार्ट में सहेजें',
          CartImages : 'कार्ड में छवियां',
          Deleteallcartimages : 'सभी कार्ट छवियां हटाएं',
          selectImagescard :'कार्ट में जोड़ने के लिए संपादक से एक छवि चुनें',
          Info :"जानकारी",
          GenerateReport : 'रिपोर्ट बनाओ',
          PreviousReports :'पिछली रिपोर्ट',
          CartImageInfo : "कार्ट के इमेजेज की जानकारी",
          Addedby :'द्वारा जोड़ा',
          Addedat : 'पर जोड़ा गया',
          SwitchMode : 'स्विच मोड',
          CheckDifference : 'अंतर की जाँच करें',
          OnetimeRecurring :'एक बार/आवर्ती',
          ShowAll : 'सब देखे',
          Favourites : 'पसंदीदा',
          ImageCapturedTime: 'इमेज कैप्चर किया गया समय',
          ImageAddedTime : 'छवि जोड़ा गया समय',
          favouriteremove : 'पसंदीदा से छवि सफलतापूर्वक हटाई गई.',
          favoriterror :'पसंदीदा से छवि निकालते समय त्रुटि आ रही है',
          ReportDemo : 'रिपोर्ट डेमो',
          ScheduleReport : 'शेड्यूल रिपोर्ट',
          GeneratePdfReport : 'PDF रिपोर्ट जेनरेट करें',
          MailPdfReport : ' मेल PDF रिपोर्ट',
          emailsuccessMsg : 'सफलतापूर्वक रिपोर्ट साझा की गई. कृपया अपना मेल चेक करें',
          emailFailsMSG: 'रिपोर्ट साझा करने में विफल. कृपया पुनः लोड करें और पुनः प्रयास करें!',
          SelectDate :'तारीख़ चुनें',
          Allprojects :'सभी परियोजनाएं',
          Runningprojects : 'चल रही परियोजनाएं',
          Completedprojects : 'पूर्ण प्रोजेक्ट',
          Albums : 'एलबम',
          ImagesVideos :'छवियाँ / वीडियो',
         mobilessupload:'Playstore से OpticVyu ऐप डाउनलोड करें और मोबाइल के माध्यम से कैप्चर की गई प्रोजेक्ट तस्वीरें अपलोड करें',
         nodirectry : 'इस परियोजना के तहत कोई निर्देशिका नहीं जोड़ी गई',
         noImages : 'इस निर्देशिका के अंतर्गत कोई छवि या वीडियो अपलोड नहीं किया गया है',
         nodaavailble :'नोट: इस तिथि में कोई डेटा उपलब्ध नहीं है कृपया पिछले दिनांकित डेटा देखें',
         Smoothing : 'स्मूथिंग',
         archivetimelapsedone:'टाइमलैप्स को सफलतापूर्वक संग्रह टाइमलैप्स में ले जाया गया',

         biweekly : 'द्वि-साप्ताहिक रिपोर्ट महीने की पहली और 16 तारीख को भेजी जाएगी',
         monthly : "मासिक रिपोर्ट महीने की पहली तारीख को भेजी जाएगी",
         Weeklyprogressreport :'साप्ताहिक प्रगति रिपोर्ट',
         Biweeklyprogressreport :'द्वि-साप्ताहिक प्रगति रिपोर्ट',
         Monthlyprogressreport : 'मासिक प्रगति रिपोर्ट',
          nosechedulereport: "इस प्रोजेक्ट के लिए कोई मौजूदा सक्रिय शेड्यूल रिपोर्ट नहीं मिली।",
          checkreport :'कृपया रिपोर्ट प्रकार जांचें',
          EnterEmail: 'कृपया ईमेल-आईडी दर्ज करें',
          selectCamera : 'कृपया कैमरा चुनें',

          settingsave :'शेड्यूल-रिपोर्ट सेटिंग सफलतापूर्वक सहेजी गई',
           schedulenotsubmit: 'अनुसूची-रिपोर्ट सबमिट नहीं की गई, कृपया पुनः प्रयास करें',
           reportReset : 'शेड्यूल-रिपोर्ट सफलतापूर्वक रीसेट किया गया',
         reportnotseset : 'शेड्यूल-रिपोर्ट रीसेट नहीं, कृपया पुन: प्रयास करें',
         copysuccess : 'Copied successful to archive timelapse',

        //  Interior 

        AddCoordinate : 'कोआर्डिनेट जोड़ें',
        WorkStatus : 'काम की स्थिति',
        VisitingDate : 'विज़िटिंग दिनांक',
        Category : 'कैटेगरी',
        Gallery : 'गैलरी',
        UploadFiles:'फाइल अपलोड करे',
        NormalImage : 'सामान्य छवि',
        Image : 'छवि',
        Video : 'वीडियो',
        RemoveSelected : 'चुना हुआ हटाओ',
        click360video : '360 वीडियो अपलोड करने के लिए क्लिक करें',
        UpdateCoordinate : 'कोआर्डिनेट अपडेट करें',
        cordinatConfirm : 'क्या आप वाकई इस कोआर्डिनेट को जोड़ना चाहते हैं.',
        removeCoordinate: 'क्या आप निश्चित रूप से कोआर्डिनेट हटाना चाहते हैं? हां, आपकी सभी अपलोड की गई छवि और वीडियो को इस कोआर्डिनेट से स्थायी रूप से हटा दिया जाएगा',
        cordianteUpdate :'कोआर्डिनेट सफलतापूर्वक अपडेट किया गया ',
        videoupload : 'वीडियो सफलतापूर्वक अपलोड किया गया',
        deleteConfirm : 'इस फ़ाइल को हटाने की पुष्टि करें',
        deletefilesuccess : 'चयनित फ़ाइलें सफलतापूर्वक हटाई गईं',
        Edit : 'एडिट करे ',
        UploadDrawing : 'ड्राइंग अपलोड करें',
        AddZone : 'जोन जोड़ें',
        DrawingName : 'ड्राइंग नाम',
        Floor : 'Floor',
        Area: 'Area',
        zoneName : 'जोन का नाम',
        NewZone : 'नया जोन',
        drawingupload : 'ड्रॉइंग अपलोड सफल',
        drawingconfirm : 'इस ड्रॉइंग को हटाने की पुष्टि करें?',
        drawingremove : 'ड्रॉइंग सफलता पूर्वक हटाया गया ',
        addezone : 'नया जोन सफलतापूर्वक जोड़ा गया',

        Images360 : '360 इमेज', 
        NormalImages : 'सामान्य छवियां',
         videos360 : '360 वीडियो',
         Comments : 'कमैंट्स ',
         Task : 'टास्क ',
         AddComment : 'कमैंट्स जोड़ें',
         Lock : 'लॉक',
         ImportTasks : 'इम्पोर्ट टास्क ',
         StandardTaskLists:'स्टैंडर्ड कार्य सूचियाँ',
         
         FilterByDate : 'तिथि के अनुसार फ़िल्टर करें',
         AddNewComment : 'नई कमैंट्स जोड़ें',
         Comment : 'कमैंट्स',
         Progress : 'प्रोग्रेस %',
         Tag : 'टैग',
         UpdateTask : 'कार्य अपडेट करें',

         DownloadExcel : 'Download Excel',
         OR : 'OR',
         DownloadPDF : ' Download PDF',

         start : 'आरंभ',
         end : 'अंत ',
         addzone :'नया क्षेत्र सफलतापूर्वक जोड़ा गया',
         addcommnetssucess :'टिप्पणी सफलतापूर्वक जोड़ी गई',
         listconfirmation : 'क्या आप निश्चित रूप से कार्य सूची आयात करना चाहते हैं?',
         standdrlistloaded: 'स्टैंडर टास्क लिस्ट उपलोड करे ',

         newmsg :'नया टास्क जोड़ा गया .',

         tasksuccess : 'टास्क सफलतापूर्वक अपडेट  किया गया ',
         sureremove : 'क्या आप वाकई इस टास्क को हटा रहे हैं',
         taskDelete : 'टास्क सफलतापूर्वक हटाया गया',

         removetask :"क्या आप वाकई चयनित टास्क को हटा रहे हैं?",

         PlannedEnd : 'योजना समाप्ति ',
         PlannedStart : 'योजना प्रारंभ',
         DroneImages : 'ड्रोन छवियां',
         DroneVideo : 'ड्रोन वीडियो',
         UploadMedia : 'डिया अपलोड करें',
         VisitDate : 'विजिट दिनांक ',
         AddNewVisitingDate : 'नई विज़िटिंग दिनांक जोड़ें',
         Upload : 'अपलोड',
         OpenGallery : 'गैलरी खोलें',
         srtfile:'.srt फाइल अपलोड करें',
         AddVideoInfo : 'वीडियो जानकारी जोड़ें',
         MetaList : 'मेटा सूची',
         latlongnotavailble :'छवि अक्षांश और देशांतर उपलब्ध नहीं है',
         imagesnotavailble : 'छवियां उपलब्ध नहीं हैं',
         recordadded : 'रिकॉर्ड सफलतापूर्वक जोड़ा गया',
         visitdateadded :'विज़िट की तिथि सफलतापूर्वक जोड़ी गई',
         selecteddelte :'चयनित फ़ाइलें सफलतापूर्वक हटाई गईं',
         taskconfirmation : 'क्या आप वाकई इस जानकारी को हटा रहे हैं?',
         recordDeleted : 'रिकॉर्ड सफलतापूर्वक हटाया गया',
         BulkUpload : 'बल्क  अपलोड',
         DroneVideos : 'ड्रोन वीडियो',
         ReviewDate :'पुनरीक्षण दिनांक',
         ReviewImages : 'इसे चेक करें',
          reviewImagesandSubmit : 'छवियां अपलोड की गईं। सबमिट करने के लिए कृपया छवियों की समीक्षा करें पर क्लिक करें',

          Delayed : 'विलंबित',
          Critical : 'गंभीर',
          Complete : 'पूर्ण',
          Running : 'प्रगति पर ',
          LayoutInsights : 'लेआउट इनसाइट्स',
          SelectDrawing : 'ड्राइंग चुने',

          AddNewMaterialHeading : 'नई सामग्री शीर्षक जोड़ें',
          AddNewSubHeading : 'नया उपशीर्षक जोड़ें',
          AddNewMaterial: 'नई सामग्री जोड़ें',
          MaterialName : 'सामग्री नाम',
          Unit : 'यूनिट',
          AssetType : 'संपदा प्रकार',
          Estimateqty : 'अनुमान मात्रा',
          MaterialHeading :'सामग्री शीर्षक',
          Enable : 'सक्रिय',
          MaterialSubHeading : 'सामग्री उप शीर्षक',
          integetrequre :'यह फ़ील्ड केवल पूर्णांकों की अनुमति दें',
           HeadingUpdateSuccessfully:'शीर्षक अद्यतन सफलतापूर्वक',
           confirmdelteheading:'इस शीर्षक को मिटाने की पुष्टि करें?',
           HeadingdeletedSuccessfully: 'शीर्षक सफलतापूर्वक हटाया गया',
           HeadingAddedSuccessfully  :'शीर्षक सफलतापूर्वक जोड़ा गया', 
           SubHeadingUpdateSuccessfully: 'उप शीर्षक अद्यतन सफलतापूर्वक',
           SubheadingdeleteSuccessfully:'उप-शीर्षक सफलतापूर्वक हटाएं',
           SubheadingAddedSuccessfully:'उप-शीर्षक सफलतापूर्वक जोड़ा गया',
           MaterialUpdateSuccessfully : 'सामग्री अद्यतन सफलतापूर्वक',
           MaterialDeleteSuccessfully: 'सामग्री सफलतापूर्वक हटाएं',
           MaterialAddedSuccessfully : 'सामग्री सफलतापूर्वक जोड़ी गई',
           Yourtaskuploadedsuccesfully: 'हमारा कार्य सफलतापूर्वक अपलोड किया गया',
           DraftsInventories : 'ड्राफ्ट इन्वेंटरी',

           Confirmremovethisitem:'इस आइटम को हटाने की पुष्टि करें ?',
           EnteryDeletedSuccessfully : 'प्रविष्टि सफलतापूर्वक हटाई गई',
           goback: 'पीछे', 
           Discription : 'विवरण',
           GoToInventory : 'इन्वेंटरी पर जाएं',
           UpdateInventory : 'अपडेट इनवेंटरी',
           DCNo : 'DC No. / वजन पर्ची',
           PONo : 'PO No',
           VehicleNo : 'वावाहन क्र.',
           SupplierName : 'आपूर्तिकर्ता का नाम',
           Rate : 'Rate',
           Quantity : 'मात्रा',
           Update : 'अपडेट',
           NewInventory : 'नई सूची',
           InventoryUpdateSuccessfully : 'इन्वेंटरी अपडेट सफलतापूर्वक',
           InventoryDeletedSuccessfully : 'इन्वेंट्री सफलतापूर्वक हटाई गई',
           InventoryAddedSuccessfully : 'इन्वेंट्री सफलतापूर्वक जोड़ी गई',
           mobileapp : 'सूची जमा करने के लिए कृपया मोबाइल ऐप से चित्र अपलोड करें.',
           InventorySubmitSuccessfull : 'इन्वेंटरी सबमिट सफल',

           EditRequestNote :'अनुरोध नोट संपादित करें',
           EditRequest : 'अनुरोध संपादित करें',
           InventoryEditRequest:'सूची संपादन अनुरोध',
           Commentsforinventoryupdate:'इन्वेंट्री अपडेट के लिए टिप्पणियाँ',
           RequestSuperadmin:'सुपर-एडमिन का अनुरोध करें',
           
           OverallReport:'Overall Report',
           CumulativeReport : 'Cumulative Report',
           MaterialwiseReport : 'Material-wise Report',
           Requestsuccessfullysent:'अनुरोध सफलतापूर्वक भेजा गया',
           Images : 'इमेजिस',
           VehicleEntry : 'वाहन प्रवेश',
           offtheproject:'परियोजना स्थल से बाहर',
         
           VehicleNumberPlate : 'वाहन नंबर प्लेट',
           other : 'अन्य',
           reflected : 'यदि तालिका डेटा प्रतिबिंबित नहीं होता है, तो कृपया पृष्ठ को पुनः लोड करने या हार्ड रीफ़्रेश करने का प्रयास करें',
           ImportSuppliers : 'आयात आपूर्तिकर्ता',
           SupplieraddedSuccessfull : 'आपूर्तिकर्ता सफलतापूर्वक जोड़ा गया',
           Suppliersfileimportsuccessfully : 'आपूर्तिकर्ता फ़ाइल आयात सफलतापूर्वक',
           deleteconfirm:'प्रदायक हटाएं की पुष्टि करें',
            removesucces:'आपूर्तिकर्ता सफलतापूर्वक निकालें',
            clear : 'मिटाएँ',
            Profile : 'परियोजना की जानकारी',
            SaveandShare : 'सेव एंड शेयर',
            CRM:'सीआरएम',
            DailyReport:'दैनिक रिपोर्ट',
            EveryMonthprogressreport:'मासिक प्रगति रिपोर्ट',
            BiMonthlyprogressreport:'द्विमासिक प्रगति रिपोर्ट',
            Quarterlyprogressreport:'तिमाही प्रगति रिपोर्ट',
            HalfYearlyprogressreport:'अर्धवार्षिक प्रगति रिपोर्ट',
}