<template>
  <section class="section-container">
    <!-- <v-app-bar app dense class="headercolor loginheader"  v-if="chanel == 'secure.opticvyu.com'">
     
      <v-toolbar-title class="white--text logolink">
        <span class="font-weight-bold shado"
          ><img class="loginlogo" :src="logo" @click="setrouter('https://www.opticvyu.com/')"
        /></span>
      </v-toolbar-title>
      <v-tooltip bottom> </v-tooltip>

      <v-spacer></v-spacer>
      <v-btn text color="rgba(255, 255, 255, 0.7)" class="navmenu_items" small @click="setrouter('https://www.opticvyu.com/')">
        <span> Home </span>
      </v-btn>

      <v-menu offset-y open-on-hover :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="navmenu_items"
            text
            color="rgba(255, 255, 255, 0.7)"
            small
            v-bind="attrs" 
            v-on="on"
          >
            Solution
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-group no-action color="#f8bb03">
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Construction Camera</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
            <v-list-item @click="setrouter('https://www.opticvyu.com/products/timelapse-camera')">
              <v-list-tile-content>
                <v-list-tile-title>Opticvyu Make</v-list-tile-title>
              </v-list-tile-content>
            </v-list-item>

            <v-list-item @click="setrouter('https://www.opticvyu.com/products/cctv-timelapse')">
              <v-list-tile-content>
                <v-list-tile-title
                  >Integrate Existing CCTV With OpticVyu
                  Platform</v-list-tile-title
                >
              </v-list-tile-content>
            </v-list-item>
          </v-list-group>
          <v-list-group no-action color="#f8bb03">
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Interior Monitoring</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
            <v-list-item @click="setrouter('https://www.opticvyu.com/products/360-image-based-monitoring')">
              <v-list-tile-content>
                <v-list-tile-title>360 Image Based Monitoring</v-list-tile-title>
              </v-list-tile-content>
            </v-list-item>
            <v-list-item @click="setrouter('https://www.opticvyu.com/products/timelapse-cameras-for-interiors')">
              <v-list-tile-content>
                <v-list-tile-title>Time-Lapse Cameras for Interior</v-list-tile-title>
              </v-list-tile-content>
            </v-list-item>
        </v-list-group>

        
          <v-list-item @click="setrouter('https://www.opticvyu.com/products/material-tracking')">
            <v-list-item-title> Material Tracking</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="setrouter('https://www.opticvyu.com/products/microscopic-timelapse')"
          >
            <v-list-item-title> Microscropic Time-lapse</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="setrouter('https://www.opticvyu.com/products/drone-photography-service')"
          >
            <v-list-item-title> Drone Service</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text color="rgba(255, 255, 255, 0.7)" class="navmenu_items" small @click="setrouter('https://www.opticvyu.com/dashboard-features')">
        <span> Features </span>
      </v-btn>
      <v-btn text color="rgba(255, 255, 255, 0.7)" class="navmenu_items" small @click="setrouter('https://www.opticvyu.com/opticvyu-demo')">
        <span> Gallery </span>
      </v-btn>
      <v-menu offset-y open-on-hover :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="navmenu_items"
            text
            color="rgba(255, 255, 255, 0.7)"
            small
            v-bind="attrs"
            v-on="on"
          >
            About-us <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="setrouter('https://www.opticvyu.com/faq')">
            <v-list-item-title> FAQs</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="setrouter('https://www.opticvyu.com/applications')"
          >
            <v-list-item-title> Applications</v-list-item-title>
          </v-list-item>
          <v-list-item @click="setrouter('https://www.opticvyu.com/#clients')">
            <v-list-item-title> Our Clients</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>-->
     <!--  <v-btn text color="white" class="navmenu_items" small>
        <span> Become Partner </span>
      </v-btn> -->
      <!-- <v-btn text color="rgba(255, 255, 255, 0.7)" class="navmenu_items" small @click="setrouter('https://blog.opticvyu.com/')">
        <span> Blog </span>
      </v-btn>
      <v-btn text :color="'/login' == activelink ? activecolor: 'rgba(255, 255, 255, 0.7)'" class="navmenu_items" small>
        <span> Login </span>
      </v-btn>
      <v-btn rounded color="#f8bb03" class="navmenu_items" average dark @click="setrouter('https://www.opticvyu.com/contact-us')">
        <span> Contact Us </span>
      </v-btn>
    </v-app-bar> -->
    <v-row class="signin">
      <!-- <v-col cols="9" class="left backgroundimage">
       
      </v-col> -->

      <v-col cols="12" class="right backgroundimage" >
        <div v-if="!tpl">
        <div
          v-if="loginerror"
          style="
            max-width: 100%;
            width: 450px;
            margin: auto;
            position: relative;
            top: 8%;
            background: #000000a3;
            padding: 23px;
            border-radius: 8px;
            box-shadow: rgb(120 120 120) 0px 4px 20px 1px;
          "
        >
        <img
            :src="logo"
            :class="lgclaslogclass"
            style="max-width: 188px; margin: auto; display: block"
          />

          <h2>Two-Factor Authentication</h2>
          <validation-observer ref="observer">
            <v-form @submit.prevent="submit">
              <validation-provider
                v-slot="{ errors }"
                name="otp"
                rules="required"
              >
                <v-text-field
                  v-model="otp"
                  :error-messages="errors"
                  label="Enter OTP"
                  required
                  outlined
                  dark
                  filled
                  rounded
                  dense
                  clearable
                  prepend-inner-icon="mdi-security"
                ></v-text-field>
              </validation-provider>
              
              <div class="text-center">
                <v-btn class="signin-btn singbuttons" type="submit" rounded>
                  VERIFY
                </v-btn>

                <div class="mt-5" style="cursor: pointer;">
                  <p>Send code again</p>
                  <p style="font-size:13px"  @click="setrouter('/login')"><v-icon  dark left>mdi-keyboard-backspace</v-icon> Back</p>
                </div>
                <!-- <div class="mt-5">
                 <a href="https://login-sandbox.procore.com/oauth/authorize?response_type=token&client_id=
5b291e76b22b638a03363b6324e41fa524422723cf8498c1657e3953714308df&
redirect_uri=http://localhost:8080/dashboard/settings/procore_connection"
                  >Procore Login ?</a>
                 </div> -->
              </div>
            </v-form>
          </validation-observer>
          <div class="text-center">
            <v-alert dense outlined :type="type" class="mt-5" v-if="error">
              {{ errorMessage }}
            </v-alert>
          </div>
        </div>
      </div>

       <!-- tata page design start -->

       <div v-if="tpl">
        <div
          v-if="loginerror"
          class="loginbox"
        >
        <img :src="Trinetra_Logo" style="max-width: 188px;max-height: 70px; margin: auto; display: block" />

       
          <p class="poppins-bold logintitle"> Two-Factor Authentication </p>
          <validation-observer ref="observer">
            <v-form @submit.prevent="submit">
              <p class="labletata poppins-semibold ">Enter Security Code</p>
              <validation-provider
                v-slot="{ errors }"
                name="otp"
                rules="required"
              >
                <v-text-field
                  v-model="otp"
                  :error-messages="errors"
                  required
                  class="tatatextfield" 
                 single-line
                 solo 
                 dense
                 color = "#193155"
                  prepend-inner-icon="mdi-security"
                ></v-text-field>
              </validation-provider>
              
              <div class="text-center">
                <!-- <v-btn class="signin-btn singbuttons" type="submit" rounded>
                  VERIFY
                </v-btn> -->
                <button class="tatasingbuttons poppins-semibold" type="submit" >
                  VERIFY
                </button>

                <div class="mt-5" style="cursor: pointer;">
                  <p  class="poppins-semibold forgotlink">Send code again</p>
                  <p  class="poppins-semibold forgotlink"  @click="setrouter('/login')"> <v-icon color="#193155">
                mdi-arrow-left
              </v-icon> Back</p>
                </div>
                <!-- <div class="mt-5">
                 <a href="https://login-sandbox.procore.com/oauth/authorize?response_type=token&client_id=
5b291e76b22b638a03363b6324e41fa524422723cf8498c1657e3953714308df&
redirect_uri=http://localhost:8080/dashboard/settings/procore_connection"
                  >Procore Login ?</a>
                 </div> -->
              </div>
            </v-form>
          </validation-observer>
          <div class="text-center">
            <v-alert dense outlined :type="type" class="mt-5" v-if="error">
              {{ errorMessage }}
            </v-alert>
          </div>
        </div>
      </div>

       <!-- tata page design end -->
     </v-col>    
    </v-row>
    <v-footer
      height="auto"
      color="#CDCDCD"
      class="font-weight-medium footer_style"
      v-if="chanel == 'secure.opticvyu.com'"
    >
    <v-row>
      <v-col class="text-center white--text" cols="12"> 
          <v-row gutters>
      <v-col
        cols="12"
        sm="4"
        style="text-align:left;"
      >
        <v-card
         class="pa-2 aboutov"
          outlined
          tile
        >
        <h3 class="small_heading whitet" style="font-size: 18pt;
    line-height: 35px;
    font-weight: initial;"> About Optic<span class="txtyellow">Vyu</span></h3>
        <p class="aboutpara">
          OpticVyu provides construction monitoring services through construction camera, 360° image based construction monitoring & material tracking. With OpticVyu engineer deployed at your site, we do Exterior Time-lapsing, 360° Based Interior Monitoring, Document Management, Material Tracking, Project Planning Integration, Reporting & Image/Videos documentation of every important activity.
          <br/><br/>
          <ul class="ablinksList padding" style="font-size: 13px;">
                            <li><a href="https://www.opticvyu.com/privacy-policy">Privacy Policy</a></li>
                            <li><a href="https://www.opticvyu.com/terms-of-use">Terms of Use</a></li>
                           </ul>
        </p>
        <br/><br/>
        <div class="footer_bottom">
          <span class="copyright"> © Copyright 2016-21 OpticVyu ®. All rights are reserved.</span>
        </div>
        </v-card>
      </v-col>
       <v-col
        cols="12"
        sm="5"
        style="text-align:left;"
      >
        <v-card
          class="pa-2 aboutov"
          outlined
          tile
        >
        <h3 class="small_heading whitet" style="font-size:22px;">Links</h3>
       
      <v-row gutters>
        <v-col
        cols="12"
        sm="6"
        style="text-align:left;"
      >
       <v-card
            class="pa-2 aboutov"
            outlined
            tile
          >
       <p class="aboutpara">
         <ul class="linksList list padding1" style="font-size: 15px;margin-left: -22%;">
                      <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://www.opticvyu.com/" class="left">Home </a></li>
                      <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://www.opticvyu.com/products/timelapse-camera" class="left">Construction Camera </a></li>
                      <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://www.opticvyu.com/products/360-image-based-monitoring" class="left">Interior Monitoring </a></li>
					            <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://www.opticvyu.com/products/material-tracking" class="left">Material Tracking </a></li>
                      <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://www.opticvyu.com/dashboard-features" class="left">Features </a></li>
                      <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://www.opticvyu.com/opticvyu-demo" class="left">Demonstration Videos </a></li>
                      <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://www.opticvyu.com/sample-time-lapse" class="left">Sample Time-Lapse Videos</a></li>
                      <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://www.opticvyu.com/sample-images" class="left">Sample Images </a></li>
                      <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://www.opticvyu.com/applications" class="left">Applications</a></li>
                     </ul>
               </p>  
               </v-card>    
            </v-col>

      <v-col
        cols="12"
        sm="6"
        style="text-align:left;"
      >
      <v-card
            class="pa-2 aboutov"
            outlined
            tile
          >
      <p class="aboutpara">
          <ul class="linksList list padding1" style="font-size: 15px;margin-left: -22%;">
             <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://blog.opticvyu.com/opticvyu-procore-integration-steps/" class="left">Integration With Procore</a></li>
                          <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://blog.opticvyu.com/opticvyu-construction-camera-integration-with-bim-360/" class="left">Integration With BIM360</a></li>
                          <li><v-icon dark left>mdi-arrow-right</v-icon><a href="https://blog.opticvyu.com/opticvyu-integration-with-bim-3d-renderings/" class="left">Integration With BIM-Revit 3D Models</a></li>
						   <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://blog.opticvyu.com/project-planning-integration/" class="left">Project Planning Integration</a></li>
                          <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://www.opticvyu.com/faq" class="left">FAQs </a></li>
                          <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://www.opticvyu.com/contact-us" class="left">Contact us/Get a Quote </a></li>
                          <li><v-icon  dark left>mdi-arrow-right</v-icon><a href="https://www.opticvyu.com/login" class="left">Login To Dashboard </a></li>
          </ul>
      </p>
         </v-card>
      </v-col>
      </v-row>
        </v-card>
      </v-col>
       <v-col
        cols="12"
        sm="3"
        style="text-align:left;"
      >
       <v-card
          class="pa-2 aboutov"
          outlined
          tile
        >
        <h3 class="small_heading whitet" style="font-size:22px;">Have a Question?</h3>
         <p class="aboutpara">
          <ul class="linksList padding" style="font-size: 13px;">
                            <li><v-icon  dark left>mdi-map-marker</v-icon>Hyderabad, Telangana
India</li><br>
                            <li><v-icon  dark left>mdi-email</v-icon>admin@opticvyu.com</li>
                           </ul>
          </p>
            <br><br>
          <div class="padding">
                    <ul class="social_item">
                        <li><a href="https://www.linkedin.com/company/opticvyu-construction-camera" target="_blank" title="linkedin"><v-icon  dark left>mdi-linkedin</v-icon></a></li>
                        <li><a href="https://www.facebook.com/OpticVyu-1806456722922150/?ref=aymt_homepage_panel" target="_blank" title="Facebook"><v-icon  dark left>mdi-facebook</v-icon></a></li>
						
                    </ul>
               </div>
        <div class="padding" style="margin-top: -20%;">
                    <ul class="social_item">
                        <li><a href="https://play.google.com/store/apps/details?id=com.main.opticvyu" target="_blank" title="playstore"><v-icon  dark left>mdi-android-debug-bridge</v-icon></a></li>
                        <li><a href="https://apps.apple.com/in/app/opticvyu-construction-camera/id1555185085" target="_blank" title="apple store"><v-icon  dark left>mdi-apple</v-icon></a></li>
						
                    </ul>
               </div>

        </v-card>
      </v-col>
    </v-row>
</v-col>
</v-row>
    </v-footer>
  </section>
</template>

        
<script>
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  setInteractionMode,
  ValidationObserver,
} from "vee-validate";
import axios from "axios";
import logo from "../assets/OpticVyu_logo_HD.png";

import rawadhalogo from "../assets/rawadh.png";
import lisuallogo from "../assets/lisual.png";
import smartvyu from "../assets/smartvyulogo.png";
import tatalogo from "../assets/tatafull.png";
import lntlogo from "../assets/lnt.png";
import equalbaselogo from "../assets/equalbase.png";
import globaltimelapselogo from "../assets/globaltimelapse.png";
import smlogo from "../assets/sm.png";
import Trinetra_Logo from "../assets/tata/Trinetra_Logo_final@2x.png";
import tboxsystemslogo from "../assets/protboxforloginscreen.png";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    email: "",
    password: null,
    showPass: false,
    error: false,
    errorMessage: "",
    type: "",
    loginerror: true,

    activelink: "/login",
    activecolor: "amber accent-3",
    otp: "",
    logop: logo,
    rawadhalogo: rawadhalogo,
    lisuallogo: lisuallogo,
    smartvyu: smartvyu,
    tatalogo: tatalogo,
    lntlogo: lntlogo,
    globaltimelapselogo: globaltimelapselogo,
    smlogo: smlogo,
    equalbaselogo: equalbaselogo,
    logo: '',
    chanel: '',
    Trinetra_Logo:Trinetra_Logo,
    tpl : false,
     lgclaslogclass : '',
     tboxsystemslogo : tboxsystemslogo
  }),
  computed: {
    params() {
      return {
        emailadd: this.email,
        otp: this.otp,
      };
    },
  },
  created() {
    var chenal_partner = window.location.hostname;
    this.chanel = chenal_partner;
      
    if(chenal_partner == "tpl.opticvyu.com"){
     this.tpl = true;
    }
    if (chenal_partner == "secure.rawadh.com") {
      this.logo = this.rawadhalogo;
      this.lgclas = "rawadh";
    } else if (chenal_partner == "secure.lisual.com") {
      this.logo = this.lisuallogo;
      this.lgclas = "lisual";
    } else if (chenal_partner == "secure.opticvyu.com") {
      this.logo = this.logop;
      this.lgclas = "opticvyu";
    } else if (chenal_partner == "image.smartvyu.com") {
      this.logo = this.smartvyu;
      this.lgclas = "smartvyu";
    } else if (chenal_partner == "secure.tata.com") {
      this.logo = this.tatalogo;
      this.lgclas = "tata";
    } else if (chenal_partner == "secure.lnt.com") {
      this.logo = this.lntlogo;
      this.lgclas = "lnt";
    } else if (chenal_partner == "secure.spacematrix.com") {
      this.logo = this.smlogo;
      this.lgclas = "spacematrix";
    } else if (chenal_partner == "login.globaltimelapse.com") {
      this.logo = this.globaltimelapselogo;
      this.lgclas = "globaltimelapse";
    } else if (chenal_partner == "equalbase.tboxsystems.com") {
      this.logo = this.equalbaselogo;
      this.lgclas = "equalbase";
      this.lgclaslogclass = "equalbaselogo";
    } else if (chenal_partner == "pro.tboxsystems.com") {
      this.logo = this.tboxsystemslogo;
      this.lgclas = "tboxsystems";
      this.lgclaslogclass = "tboxsystemslogo";
    }  else {
      this.logo = this.logop;
    }
    this.checklogin();
  },
  methods: {
    checklogin() {
      /* this.userdata = JSON.parse(localStorage.getItem("userdata")); */
       this.email = localStorage.getItem("authemail");
      console.log(this.email);
     
    },
    setrouter: function (url) {
      window.location.href = url;
    },
    async submit() {
      const valid = await this.$refs.observer.validate();
      if (valid) {
        //console.log("Hello i am here.");
        this.verifylogin(this.params); // action to login
      }
    },

    verifylogin(data) {
      axios({
        url:
          "/user/verify_otppwd",
        data: data,
        method: "post",
      })
        .then((resp) => {
          console.log("current datat", resp.data.success);
          localStorage.setItem('userdata', JSON.stringify(resp.data.data[0]));
          var redirect;
          var userdata = resp.data.data[0];
          var self = this;
          setTimeout(() => {
            var chenal_partner = window.location.hostname;
             this.chanel = chenal_partner;

            if(chenal_partner == 'tpl.opticvyu.com'){
              window.location.href = "/tpls";
              //redirect = "/dashboard/user-interior-view";
            }else{
            if (userdata.cont_camera_aceess == 1) {
              redirect = "/dashboard";
            } else if (userdata.interior_access == 1) {
              redirect = "/dashboard/user-interior-view";
            } else if (userdata.material_access == 1) {
              redirect = "/dashboard/material/inventories";
            } else if (userdata.drone_access == 1) {
              redirect = "/dashboard/user-drone-view";
            }else if (userdata.linear_monitoring_access == 1) {
              redirect = "/dashboard/gps-map-view";
            }else if (userdata.virtual_tour_access == 1) {
              redirect = "/dashboard/virtual-tour";
            }
            localStorage.setItem('luser_code', userdata.user_code);
            this.$router.push(redirect);
          }
            console.log("check redirect", redirect);
          self.$router.push(redirect);
           }, 1000);
        })
        
        .catch((err) => {
          console.log(err);
          this.error = true;
          this.errorMessage =
            "You are not authorized to access account. Please contact to admin";
          this.type = "error";
        });
    },
    async forgotPassword() {
      console.log(this.email);

      var email = {
        email: this.email,
      };
      axios({ url: "/password/email", data: email, method: "POST" })
        .then((response) => {
          console.log(response, "response Link send Sccessful");
          if (response.data.success) {
            this.error = true;
            this.type = "success";
            this.errorMessage = "Reset password link send successfull.";
            this.loginerror = !this.loginerror;
          } else {
            this.error = true;
            this.type = "error";
            this.errorMessage = "Something went wrong please check your email.";
          }
        })
        .catch((err) => {
          console.log(err);
          this.error = true;
          this.errorMessage = "Something went please check your email.";
          this.type = "error";
        });
    },
  },
};
</script>
<style>
.loginerror {
  color: brown;
}
.loginlogo {
  width: 170px !important;
  position: relative;
  top: 14px !important;
  left: 2% !important;
}

.logolink {
  cursor: pointer;
}
.footer_style {
  background-color: rgb(12 12 12) !important;
  border-color: rgb(18 19 20) !important;
  bottom: 15%;
  /* margin-bottom: 21%; */
  position: relative !important;
  height: auto;
  width: 100%;
}
.loginheader {
  height: 70px !important;
}
.navmenu_items {
  font-size: 13px !important;
  /* padding: 25px 3px !important; */
  margin-top: 1%;
}
.aboutov {
  background-color: #0c0c0c !important;
  color: #ffff !important;
  font-weight: 200;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-left: 6%;
}
.small_heading {
  font-weight: 400;
}
.txtyellow {
  color: #f8bb03;
}
.aboutpara {
  margin-top: 10%;
  font-size: 16px;
  font-family: emoji;
  text-align: justify;
  color: #a6a6a6 !important;
}
.ablinksList li a {
  color: #fed136 !important;
  font-weight: 400;
}
.linksList li a {
  color: #a6a6a6 !important;
  font-weight: 400;
  text-decoration: none;
}
.linksList {
  list-style: none;
}
.ablinksList {
  list-style: none;
}
.footer_bottom {
  padding: 34px 0px 0;
  overflow: auto;
  font-size: 11px;
  color: #f8bb03;
}
ul.linksList.list.padding1 li {
  padding-left: 30px;
  margin-top: 9px;
  position: relative;
  text-align: initial;
}
ul.social_item {
  padding: 0;
  list-style: none;
  margin-top: 50px;
}
ul.social_item li {
  height: 56px;
  width: 56px;
  line-height: 43px;
  /* background-color: #333333; */
  border-radius: 45%;
  font-size: 44px;
  color: #fff;
  display: inline-block;
  text-align: center;
  margin-right: 16px;
}
.v-application a {
  text-decoration: none;
}
.icona {
  font-size: 15px;
  color: #a6a6a6;
  position: absolute;
  left: 8px;
  top: 3px;
}

</style>