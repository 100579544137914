<template>
<div>
    <Preloader v-if="preloader" />

    <v-row>
        <v-col cols="12" md="12" class="tohed">

        </v-col>

        <div class="megaMenus" :class="drawingToggle?'opendrawer':'closedrawer'">
            <v-row>
                <v-btn fab x-small color="#fff" class="closeDrower" @click="closeMegamenu">
                    <v-icon dark>
                        mdi-close
                    </v-icon>
                </v-btn>

                <v-col v-for="n in drawings" :key="n.drawingid" :class="`d-flex child-flex drawingitem mega ${n.drawingid == activeDrawing.drawingid?'selected':''}`" cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
                    <v-img :src="n.thumbnail" aspect-ratio="1.5" class="black lighten-2" @click="goToDrawing(n)">
                        <div class="drawingname">
                            {{n.name}}

                            <!-- <v-btn
            class="mx-2 removedrawing"
            fab
            x-small
            color="#ffc400"
            @click="removeDrawing(n.drawingid)"
            >
            <v-icon dark>
               mdi-delete
            </v-icon>
            </v-btn> -->

                        </div>
                        <div class="drawingname dfooter">
                            <v-btn class="mx-2" fab x-small color="#fff">
                                <v-icon dark>
                                    mdi-axis-z-rotate-clockwise
                                </v-icon>
                            </v-btn>
                            {{n.numberofrotet}}
                            <v-btn class="mx-2" fab x-small color="#fff">
                                <v-icon dark>
                                    mdi-camera
                                </v-icon>
                            </v-btn>
                            {{n.numberofimages}}
                            <v-btn class="mx-2" fab x-small color="#fff">
                                <v-icon dark>
                                    mdi-play
                                </v-icon>
                            </v-btn>
                            {{n.numberofvideos}}

                        </div>

                    </v-img>
                </v-col>
            </v-row>
        </div>

        <v-col cols="6"  ref="draggableContainer"  :class="`drawingxx ${paddingActive?'paddingadd':''}`">
            
                        <v-icon x-large color="#f00" class="arrowaaa">
                            mdi-triangle-small-up
                                        </v-icon>
             <v-btn :class="`mx-2  drag-buttons ${zoom < 0.6 ? 'zoomd': ''}`" fab x-small color="#f8bb03" @mousedown="dragMouseDown" >
                <v-icon dark>
                    mdi-arrow-all
                </v-icon>
            </v-btn>
              <v-btn :class="`mx-2 drag-buttons mobileonly tabonly ${zoom < 0.6 ? 'zoomd': ''}`" fab x-small color="#f8bb03"  @touchmove="tuchdragMouseDown" >
                <v-icon dark>
                    mdi-arrow-all
                </v-icon>
            </v-btn>
          
          
          
            <!-- <v-btn class="mx-2 magnify-buttons" fab x-small color="#f8bb03" @click="magnifytogle" v-if="!sliderActive">
                <v-icon dark>
                    {{magnify ? 'mdi-magnify-minus':'mdi-magnify-plus'}}
                </v-icon>
            </v-btn> -->

              <v-btn class="mx-2 drawing-edit-buttons" title="Refresh Drawing and Connection" fab x-small color="#f8bb03" @click="refresh">
                <v-icon dark>
                   mdi-reload 
                </v-icon>
            </v-btn>

            <vue-photo-zoom-pro :high-url="drawing.drawingpath" :width="200" :selector="magnify" type="circle" :scale="2">
                <img :src="drawing.drawingpath" alt="Workplace" id="ble" class="map half" usemap="#workmap" height="600">
            </vue-photo-zoom-pro>

            <map name="workmap" id="mymap">
                <area shape="circle" v-for="(d, index) in cordinates" href="#" :data-maphilight='d.fillcolor' :key="d.coordinateid" @click="openTooltip(d, index)" :coords="`${d.x},${d.y},${radius}`" alt="Corridor"> </map>

            <img :src="casmangle" id="ang" v-if="activet == 'panaroma' && colum" :class="angle ?'seeangle':'hideangle'" :style="{left: xf +'px', top: yf +'px',  transform: 'rotate('+anglez+'deg)' }">
            <div id="moon" :class="[cordinatInfo ?'openTool': 'closetool', angle && activet == 'panaroma' && colum ?'low':'hiegth']" :style="{left: x +'px', top: y +'px'}">

                <span class="closepop" id="closecordinate"> <i class="fa fa-times" aria-hidden="true"></i> </span>
                <p class="circledata"> {{ openCoordinate.title }} </p>
                 
                   <span class="citemcloser" :title="lang.Close" @click="cordinatInfo = false">
                        <v-btn fab x-small color="#fff">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </span>

            </div>

         

        </v-col>
         <v-col cols="12" class="playerplase autodeskcc">
            <div class="autobox">
                <h4> Auto Connect <v-icon style="cursor: pointer;" light title="Sync one image & auto-connect rest all images automatically with BIM model. Follow step1, 2 & 3.">
                 mdi-information
               </v-icon></h4>
                <v-btn class="ma-2 add flotings" x-small v-if="toottipimageopen" @click="setAgnleDiff" title="Step 1. Align the 360° image towards the arrow shown on the layout.">
                1. Align 360° image
               </v-btn>

                <v-btn class="ma-2 add flotings" x-small @click="getviwerid" title="Step 2. Align BIM model with 360° image & set position">
                2. Align BIM model
               </v-btn>

               <v-btn class="ma-2 add flotings" v-if="singlePositionsSet" x-small @click="autoconnect" title="Step 3. Auto-connect all the points on layout.">
                3. Auto-connect
               </v-btn>
               <v-btn class="ma-2 add flotings" v-if="!singlePositionsSet" x-small @click="autoconnectnotication" title="Step 3. Please connect at least one point manually...">
               Auto-connect
               </v-btn>

               <v-btn class="ma-2 add flotings" v-if="userdata.email == 'anshul.jain@opticvyu.com' || userdata.email == 'nitesh.patel@opticvyu.com'"  small @click="removeallPositions">
                 4. Remove All Connections
               </v-btn>
           </div>
            <div class="autobox2">
                <h4> Manual Connect <v-icon style="cursor: pointer;" light title="To fine-tune the 360° image synchronization with BIM model, use this option. Set any/all images manually">
                 mdi-information
               </v-icon></h4>
                <v-btn  class="ma-2 add flotings" v-if="singlePositionsSet" x-small @click="getviwerid">
               Set Position
               </v-btn>
            </div>

               
              
            <div id="forgeViewert" v-if="docloaded"></div>
            
        </v-col>
     </v-row>

     <div ref="draggableContainer33"  class="imgesssxc" v-if="activesliderImage">
            <v-btn class="mx-2  drag-buttons" fab x-small color="#f8bb03" @mousedown="dragMouseDownimage" >
               <v-icon dark>
                   mdi-arrow-all
               </v-icon>
           </v-btn>
     <v-pannellum ref="panaromascreen" :showFullscreen="true" :showZoom="true" :compass="true" :doubleClickZoom="true" :mouseZoom="true" :draggable="true" :src="activesliderImage" :dynamic="true"></v-pannellum>
        </div>
        <v-snackbar v-model="alert" top right timeout="3000">
        {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>
   
</div>
</template>

<script>
import axios from 'axios';

import Preloader from '../../components/PreLoader'
import image1 from '../../assets/e.png'

import casmangle from '../../assets/casmangle.png'
import $ from 'jquery'
import {
    locals
} from "../../utils/locals";
import vuePhotoZoomPro from 'vue-photo-zoom-pro'
import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css'
import VuePannellum from 'vue-pannellum';
import * as THREE from 'three'; 
//import { forEach } from 'lodash';
// import {
//     Carousel3d,
//     Slide
// } from 'vue-carousel-3d';

export default {
    components: {
        Preloader,
       vuePhotoZoomPro,
       'VPannellum': VuePannellum,
        
    },
    name: "interiorViewEdit",
    data() {
        return {
            zoneMessage: false,
            preloader: false,
            casmangle: casmangle,
            projectid: 0,
           
            zoneid: 0,
            image1: image1,
            loafin: false,
            drawings: {},
            uploadDrawignaForm: false,
            drawing: {
                name: 'Select Drawing',
                drawingpath: image1
            },
            proj_dir: '',
            drawingname: '',
            floor: '',
            area: '',
            thumbnail: '',
            send: false,
            valid: true,
            alertmessage: '',
            alert: false,
            addZoneform: false,
            validzone: true,
            newzonename: '',
            currentDrawingName: 'Select Drawing',
            drawingToggle: false,
            activeDrawing: {},
            cordinates: [],
            cordinatInfo: false,
            openCoordinate: {},
            x: 0,
            y: 0,
            xc: 0,
            yc: 0,
            xf: 0,
            yf: 0,
            colum: false,
            showadd : false,
            magnify: false,
            currentProject : '',
            docloaded : false,
            viewer : {},
             positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0
            },
            positionsxxc: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0
            },
            zoom : 1,
            currentZoneDate: '',
            angle : false,
            paddingActive :  false,
            activet : '',
            userdata : '',
            activesliderImage:'',
            radius: 8,
            drawingheight : 0,
            drawingwidth : 0,
            imaglossp : 0,
            yh:0,
            xh:0,
            maxx : 0,
            maxy : 0,
            scalefactore_x : 0,
            scalefactore_y : 0,
            singlePositionsSet : false,
            activeposition:'',
            toottipimageopen : false
           

        };
    },
    mounted(){
        this.userdata = JSON.parse(localStorage.getItem('userdata'));
        if (this.userdata.open_access == 1) {
            this.$router.push('/dashboard')
        }
    },
    created() {
       
        var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng];
        this.currentZoneName = localStorage.getItem('currentZoneName');
        this.currentZoneDate = JSON.parse(localStorage.getItem("currentZoneData"));
        var _self = this;
        setTimeout(() => {
            _self.userdata = JSON.parse(localStorage.getItem('userdata'));
            if (_self.userdata.user_code == 2 || _self.userdata.user_code == 0) {
                _self.useraccess = true
            }
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            //console.log("Current data get",this.$store.state.currentCamera)
            if (currentProject === null) {
                _self.$router.go();
            }
            console.log("projectdata==>", currentProject === null);
            _self.cam_prefix = _self.$store.state.currentCamera.camera_prefix;
            _self.projectid = currentProject.id;
            _self.proj_dir = currentProject.pro_dir;
            _self.project_name = currentProject.project_name;
            _self.zoneid = _self.$store.state.currentZone;
              _self.autodesk_viewer = currentProject.autodesk_viewer;
            _self.initViewer();
            _self.getDrawings();
                _self.getToken();
            document.body.addEventListener('keyup', e => {
                if (e.keyCode === 27) {
                    _self.cordinatInfo = false;
                }
            }, 500)

             this.$refs.draggableContainer.addEventListener('wheel', this.handleScroll)


               _self.$refs.draggableContainer.style.transform = 'scale(0.5)';
                 _self.$refs.draggableContainer.style.left = '0px';
                _self.$refs.draggableContainer.style.top = '70px';
           
        }, 2000)

              setTimeout(() => {
            if(_self.width < 750 ){
                  this.zoom = 0.3;
                  console.log("evet 750 loade");
                 _self.$refs.draggableContainer.style.transform = 'scale(0.3)';
                 _self.$refs.draggableContainer.style.left = '0px';
                _self.$refs.draggableContainer.style.top = '70px';
               }
           },10000)
    },
    watch: {
        '$route': function (to) {
            console.log("Router change", to)
        },
        '$store.state.currentZone': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            this.zoneid = this.$store.state.currentZone;
            console.log("current zone", this.zoneid);
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            this.projectid = currentProject.id;
            this.proj_dir = currentProject.pro_dir;
            this.project_name = currentProject.project_name;
              this.autodesk_viewer = currentProject.autodesk_viewer;
            this.getDrawings();
            this.currentZoneName = localStorage.getItem('currentZoneName');
            this.currentZoneDate = JSON.parse(localStorage.getItem("currentZoneData"));
            this.org = currentProject.company;
            this.city = currentProject.city;
            this.getUsersByProject();
            
        },
        '$store.state.reportToggle': function () {

            this.reportToggle = this.$store.state.reportToggle;
            console.log(this.reportToggle);

            this.dataExcel();

        },
        '$store.state.drawingToggle': function () {

            this.drawingToggle = this.$store.state.drawingToggle;
            console.log(this.drawingToggle);

        },
        hfov(val) {
            console.log("hfov", val);
        },

        pitch(val) {
            console.log("pitch", val);
        },
        options: function (nval) {
            this.tui_re_render++;
            console.log(" ttui => ", nval);
        },
    },
    computed: {
        timeline() {
            return this.events.slice().reverse()
        },
        // drag: function (v){

        //     console.log("compute scrooling",v);

        //       //return a;

        // }
         param_add() {
            return {
                cmt_id:this.cmtt_id,
                co_id: this.cmt_coordinate,
                dis_note: this.add_disnote,
                
      };
    },
     filteredList() {
         if(this.searchtext){
             console.log("search cmt", this.searchtext);
            return this.commentsdata.filter(comment => {
                var result = comment.cmt_title.toLowerCase().includes(this.searchtext.toLowerCase()) || comment.comment_txt.toLowerCase().includes(this.searchtext.toLowerCase())
                return result;
            })
         }  
         else if(this.filter_status){
             console.log("cmt status", this.filter_status);
             //this.filter_status == 1 ? 'Completed':'Pending';
            return this.commentsdata.filter(comment => {
              var result = comment.comment_status == this.filter_status && comment.cmt_type.toLowerCase().includes(this.filter_type.toLowerCase());
                //console.log("filtered data", this.commentsdata);
                return result;
            })
         } 
         else if(this.filter_type){
             console.log("cmt type", this.filter_type);
            return this.commentsdata.filter(comment => {
                var result = comment.cmt_type.toLowerCase().includes(this.filter_type.toLowerCase())
                return result;
            })
         }else if(this.filter_duedate){
             console.log("duedate filter", this.filter_duedate);
                return this.commentsdata.filter(comment => {
                    var admission = this.$moment(comment.due_date, "YYYY-MM-DD"); 
                    var discharge = this.$moment(new Date(), "YYYY-MM-DD");
                    console.log("check date", admission, discharge);
                    var difference = discharge.diff(admission, 'days');
                    console.log("date difference", difference);
                    if(difference >= 1){
                    var result = comment.comment_status == this.filter_duedate
                return result;
                }
            })
         }else{
            return this.commentsdata.filter(comment => {
                var result = comment.cmt_title.toLowerCase().includes(this.searchtext.toLowerCase()) || comment.comment_txt.toLowerCase().includes(this.searchtext.toLowerCase())
                return result;
            })
         }
         
    }
    },
    methods: {
            refresh(){
                this.getDrawings();
            },
           getToken() {

             const Autodesk = window.Autodesk;

            this.currentProject = JSON.parse(localStorage.getItem('currentProject'));


            //  var self = this;
            if(this.currentProject.autodesk_viewer == 1){
             

             this.showadd = false;

           var _self = this;
           _self.preloader = true;

            axios({
                    url: '/interior/autodesk-token',
                    method: 'post',
                })
                .then(resp => {
                    this.myToken = resp.data.access_token;
                    console.log("token -", this.myToken);

                    if (this.currentProject.autodesk_urn || this.currentZoneDate.autodesk_urn) {
                            this.docloaded = true;
                        }

                     
                  //  var viewer;
                        var options = {
                            env: 'AutodeskProduction2',
                            api: 'streamingV2',  // for models uploaded to EMEA change this option to 'streamingV2_EU'
                            getAccessToken: function(onTokenReady) {
                                var token = resp.data.access_token;
                                var timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
                                onTokenReady(token, timeInSeconds);
                            }
                        };

                    console.log("options",options);
                   
                    Autodesk.Viewing.Initializer(options, function() {

                        _self.preloader = true;

                        var htmlDiv = document.getElementById('forgeViewert');
                        _self.viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
                        var startedCode = _self.viewer.start();
                        if (startedCode > 0) {
                            console.error('Failed to create a Viewer: WebGL not supported.');
                            return;
                        }

                        console.log('Initialization complete, loading a model next...');

                        var documentId = 'urn:'; 
                            if(_self.currentZoneDate.autodesk_urn){
                               documentId = 'urn:' + _self.currentZoneDate.autodesk_urn;
                             }
                         
                         Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);
                          
                            function onDocumentLoadSuccess(viewerDocument) {
                                _self.preloader = true;
                            var defaultModel = viewerDocument.getRoot().getDefaultGeometry();

                               console.log("defaultModel",defaultModel);
                              
                            _self.viewer.loadDocumentNode(viewerDocument, defaultModel);
                           
                            setTimeout(() => {
                                    _self.onDocumentLoadS();
                                    _self.preloader = false;
                                 }, 10000);
                            //_self.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, selectionChanged);
                            
                            //    function selectionChanged(dd) {
                                  
                               
                            //      setTimeout(() => {
                            //            if(_self.openCoordinate.coordinateid){

                            //              if(_self.openCoordinate.bim_dbid == dd.dbIdArray[0]){
                                           
                            //                 console.log("Item already selected");
                            //              }else{
                            //                 var a = confirm("Do you wish to connect "+_self.openCoordinate.title+" point against the selected item?");
                            //          if(a){

                            //           console.log("connect items",dd.dbIdArray[0],_self.openCoordinate.coordinateid);
                                      
                            //           // _self.connectidss(dd.dbIdArray[0], _self.openCoordinate.coordinateid);
                            //         }
                            //          }
                            //         }else{
                            //             alert("Please Select a coordinate firt");
                            //           }
                                    
                            //      }, 1000);
                            //     }
                               

                //          setTimeout(function(){

                               
                //                 //viewer.isolateById(dbId);
                   
                //                 // const tree = viewer.model.getInstanceTree();
                //                 // console.log("tree",tree);
                //                 // let nextAvailableID = tree.objectCount + 1; 
                //                 // console.log("nextAvailableID",nextAvailableID);

                //                 var it = _self.viewer.model.getData().instanceTree;
                //   var eidIndex = it.nodeAccess.nameSuffixes.indexOf(592214);
                //    console.log("eidIndex",eidIndex);
                //   for( let key in it.nodeAccess.dbIdToIndex ) { 
                //     if( it.nodeAccess.dbIdToIndex[key] === eidIndex) {

                //          var dbId = parseInt(key);
                //                 _self.viewer.impl.selector.setSelection([dbId], _self.viewer.model);
                //                _self.viewer.fitToView([dbId]);
                //                 _self.viewer.select([dbId])
                //               // viewer.isolate([dbId]);
            
                //                  console.log( key ) 
                //                     }
                //                   }   
                //              }, 10000); 
                              
                            }
                           function onDocumentLoadFailure() {
                              console.error('Failed fetching Forge manifest');
                            }

                            

                    });
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
         }else{
            this.showadd = true;
         }
        },
            tuchdragMouseDown(e){
         var touchLocation = e.targetTouches[0];
          console.log(touchLocation);
          this.$refs.draggableContainer.style.left = touchLocation.pageX + 'px';
         this.$refs.draggableContainer.style.top = touchLocation.pageY + 'px';
    },

    dragMouseDownimage: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:

       this.positionsxxc.clientX = event.clientX
       this.positionsxxc.clientY = event.clientY
       document.onmousemove = this.elementDragxxc
       document.onmouseup = this.closeDragElementxxc
      },

      elementDragxxc: function (event) {
      event.preventDefault()
      this.positionsxxc.movementX = this.positionsxxc.clientX - event.clientX
      this.positionsxxc.movementY = this.positionsxxc.clientY - event.clientY
      this.positionsxxc.clientX = event.clientX
      this.positionsxxc.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer33.style.top = (this.$refs.draggableContainer33.offsetTop - this.positionsxxc.movementY) + 'px'
      this.$refs.draggableContainer33.style.left = (this.$refs.draggableContainer33.offsetLeft - this.positionsxxc.movementX) + 'px'
    },

    closeDragElementxxc () {
      document.onmouseup = null
      document.onmousemove = null
    },

    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:

       this.positions.clientX = event.clientX
       this.positions.clientY = event.clientY
       document.onmousemove = this.elementDrag
       document.onmouseup = this.closeDragElement
      },
        elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement () {
      document.onmouseup = null
      document.onmousemove = null
    },
        handleScroll(event){

             console.log("event triger.");
        
                   var delta = Math.sign(event.deltaY);
                    var p = this.zoom;
                     console.log("var delta", delta);
                    if(delta == -1){
                     
                        if( p < 1){
                         p = this.zoom + 0.02;
                         console.log("Plus",p);
                         this.zoom = p;
                         this.$refs.draggableContainer.style.transform = 'scale('+p+')';
                        }
                    }else{
                       // console.log(p);
                        if( p > 0.4){ 
                        p = this.zoom - 0.02;
                        this.zoom = p;
                        console.log("minus",p);
                         this.$refs.draggableContainer.style.transform = 'scale('+p+')';
                        }
                            
                    }
        },

        connectidss(dbid, coorid){

        var did =  this.drawing.drawingid;

         axios({ url: '/interior/conn-dbid-coordinate', method: 'post',data : { "coorid" : coorid , "did": did, "dbid" : dbid }})
          .then(resp => {
            console.log("User data getting", resp);

                    this.alert = true;
                    this.alertmessage = "Coordinate connected successfully.";
                    this.alertType = "error"
                    this.cordinates = [];
                    this.getDrawings();
                    

          })
          .catch(err => {
                   console.log(err);
                   this.alert = true;
                    this.alertmessage = "Something went wrong please try again.";
                    this.alertType = "error"
          })
      },

      autoconnectidss(dbid, coorid,index){

        var did =  this.drawing.drawingid;

         axios({ url: '/interior/conn-dbid-coordinate', method: 'post',data : { "coorid" : coorid , "did": did, "dbid" : dbid }})
          .then(resp => {
            console.log("User data getting", resp);

                    this.alert = true;
                    this.alertmessage = "Coordinate connected successfully.";
                    this.alertType = "error"
                   // this.cordinates = [];
                   // this.getDrawings();

                   if((this.cordinatesOriginal.length - 1) == index){
                     this.cordinates = [];
                    this.getDrawings();
                   }
                
          })
          .catch(err => {
                   console.log(err);
                   this.alert = true;
                    this.alertmessage = "Something went wrong please try again.";
                    this.alertType = "error"
          })
       
      },

     
         openautodeskview(){
                 this.autodeskcompare =  !this.autodeskcompare;
         },
        validate() {
            this.$refs.form.validate()
        },
        initViewer() {
            // console.log("init Viewer");
            this.preloader = false;
            this. getOrg()
            this.getUsersByProject();
            // this.getcoordinates();
        },
        
        getOrg() {
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            var org_id = currentProject.organization_id;
            var proj_id = currentProject.id;

            var data = {
                org_id: org_id
            }

            var url = "/organization/" + proj_id;
            axios({
                    url: url,
                    method: "GET",
                    params: data
                })
                .then((resp) => {

                    console.log("resp...", resp.data.success);
                    this.logourl = resp.data.success.org_logo_base;

                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getUsersByProject(){

         console.log("activeProjectData", this.projectid);
        
        axios({ url: '/project/'+this.projectid+'/users', method: 'GET' })
          .then(resp => {
            console.log("User data getting", resp.data.success);
            this.getusers = resp.data.success;
              /// this.preloader = false;
          })
          .catch(err => {
              this.preloader = false;
            console.log(err);
          })
      },
        onchanges: function (v) {

            console.log("watch scrooling", v);

            var a = this.$refs.panaromascreen;
            // var b = this.$refs.panaromascreen2;

            var lefrr = a.viewer.getYaw();
            this.anglez = lefrr;
            console.log("lefrr", lefrr);

        },
        getDrawings() {
            this.$store.dispatch('drawingname', 'Loading...');

            // if(this.zoneid == 0){
            //     this.zoneMessage = true;
            // }

            axios({
                    url: '/interior/project/' + this.projectid + '/get-drawings',
                    method: 'get',
                    params: {
                        zone: this.zoneid
                    } 
                })
                .then(resp => {
                    // console.log("headings", resp);
                    this.drawings = resp.data.success;
                    // console.log("dfasfasdf", resp.data)

                    if (resp.data.success.length > 0) {
                        console.log("is In");
                        this.zoneMessage = false;

                       var aa =  localStorage.getItem("openDrawing");
                        if(aa){

                            this.getDrawing(aa);
                        }else{
                            this.getDrawing(resp.data.success[0].drawingid);
                        }

                    } else {
                        this.zoneMessage = false;
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        getRatio() {
            var drawingwidth  = this.drawingwidth.toFixed(2);
            var drawingheight = this.drawingheight.toFixed(2);

            var bbox = this.viewer.model.getBoundingBox();

            console.log("drawingwidth",drawingwidth,"drawingheight",drawingheight);

            // Access the min and max x and y values
            var minX = bbox.min.x - 25;
            var minY = bbox.min.y - 35;
            var maxX = bbox.max.x + 25;
            var maxY = bbox.max.y + 35;

            this.maxx = maxX;
            this.maxy = maxY;

            var bimWidth = maxX - minX;
            var bimheight = maxY - minY;


            console.log("bimWidth",bimWidth,bimheight);


            var scalefactore_x = drawingwidth/bimWidth;
            var scalefactore_y = drawingheight/bimheight;

            this.scalefactore_x = scalefactore_x.toFixed(2);
            this.scalefactore_y = scalefactore_y.toFixed(2);

            console.log("scalefactore_x",this.scalefactore_x, this.scalefactore_y);

            

            console.log('Min X:', minX);
            console.log('Min Y:', minY);
            console.log('Max X:', maxX);
            console.log('Max Y:', maxY);
        },
        getDrawing(id) {
            this.preloader = true;
            this.latesttrue = false;
            this.cordinatInfo = false;
            this.commentbox = false;
            this.colum = false;
            localStorage.setItem("openDrawing", id);
            axios({
                    url: '/interior/get-drawingbyid',
                    method: 'get',
                    params: {
                        did: id
                    }
                })
                .then(resp => {
                    this.refresh3++;
                    //console.log("Drawing DTA", resp);
                    this.drawing = resp.data.success[0];

                    this.radius = this.drawing.point_size;

                    var i = new Image();

                    var _self = this;

                    i.onload = function () {
                        // alert( i.width+", "+i.height );

                        _self.drawingheight = i.height;
                        _self.drawingwidth = i.width;
                        

                        console.log("i.height", i.height);
                        if (i.height > 650) {

                            var loss = i.height - 600;

                            //    Loss percentage = (Loss × 100) / C.P

                            //     Loss % = (5 × 100) / 50

                            //     Loss % = 500 / 50

                            //     Loss % = 10

                            var Losspercentage = (loss * 100) / i.height;

                            this.imaglossp = Losspercentage;

                            console.log("Loss percentage", this.imaglossp);
                            _self.getcoordinates(resp.data.success[0].drawingid, Losspercentage);
                            _self.getcoordinatesOrg(resp.data.success[0].drawingid);

                        } else {
                            _self.getcoordinates(resp.data.success[0].drawingid, 0);
                            _self.getcoordinatesOrg(resp.data.success[0].drawingid);
                        }
                    };
                    i.src = this.drawing.drawingpath;

                    // this.getcoordinates(resp.data.success[0].drawingid,Losspercentage);
                    this.$store.dispatch('drawingname', resp.data.success[0].name);

                }).catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
         goToDrawingdirect() {

             var openDrawing  =  localStorage.getItem("openDrawing");

            this.$router.push({
                name: 'interior_viewedit',
                params: {
                    id: openDrawing
                }
            });
        },
        getNormalImages(t) {
            this.preloader = true;
            this.down = false;
            this.commentbox = false;
            //  console.log("Normal Images");
            this.refresh3++;
            this.slides = [];
            this.selected = [];
            axios({
                    url: '/interior/get-media',
                    method: 'get',
                    params: {
                        coordi: this.openCoordinate.coordinateid,
                        type: t
                    }
                })
                .then(resp => {
                     console.log("Normal Images", resp.data.success);

                    this.activet = t;

                    this.slides = resp.data.success

                    this.saved = resp.data.success

                    this.filterdate(resp.data.success)
                    this.insids = false;
                    this.preloader = false;

                    // console.log("this.slides", this.slides);

                    // console.log("this.latestPanaromas", this.latestPanaromas);

                }).catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        getcommentImages(url) {
            this.colum = true;
            this.imagepanaroma = url;
            this.activet = 'panaroma';
        },
        getlatestImages() {
            this.preloader = true;
            //this.down = false;
            //this.commentbox = false;
            //  console.log("Normal Images");
            this.refresh3++;
            //this.slides = [];
            //this.selected = [];
            axios({
                    url: '/interior/get-media-latest_cmt',
                    method: 'get',
                    params: {
                        coordi: this.openCoordinate.coordinateid,
                        type: "panaroma",
                    }
                })
                .then(resp => {
                     console.log("Normal Images", resp.data.success[0].mediapath);
                    this.colum = true;
                    this.imagepanaroma = resp.data.success[0].mediapath;
                    this.activet = "panaroma";

                   // this.slides = resp.data.success

                    //this.saved = resp.data.success

                   // this.filterdate(resp.data.success)
                   // this.insids = false;
                    this.preloader = false;

                    // console.log("this.slides", this.slides);

                    // console.log("this.latestPanaromas", this.latestPanaromas);

                }).catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        goToDrawing(n) {
            this.currentDrawingName = n.name;

            this.activeDrawing = n;

            this.getDrawing(n.drawingid);

            this.drawingToggle = false;
            //this.latesttrue = true;
            this.refresh++;
            this.refresh3++;

            this.$store.dispatch('openDrawing', false);
            this.$store.dispatch('drawingname', n.name);

        },
        submitZoneform() {
            axios({
                    url: '/interior/project/' + this.projectid + '/add-zone',
                    method: 'get',
                    params: {
                        zonename: this.newzonename
                    }
                })
                .then(() => {
                    this.alert = true;
                    this.alertmessage = this.lang.addzone;
                    this.addZoneform = false;
                    this.$router.go();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        drawingMenuToggle() {

            this.drawingToggle = !this.drawingToggle;
        },
        downUp() {
            this.down = !this.down;

        },

        openTooltip(n, index) {

            console.log("index", index);
            this.commentsdataa = false;
            if (n.cateone == "360 Videos Walk-through") {

                this.openCoordinate = n;
                this.cordinatInfo = false;
                this.commentbox = false;
                this.getNormalImages('video');

            } else {

                //console.log("n",n);

                this.cordinatInfo = false;
                var _self = this;
                setTimeout(() => {
                    _self.cordinatInfo = true;
                    _self.angle = true;

                }, 150);

                setTimeout(() => {

                    _self.x = n.x;
                    _self.y = n.y;

                    _self.xc = n.x + 100;
                    _self.yc = n.y;

                    _self.xf = n.x - 137 + 80;
                    _self.yf = n.y - 138 + 41;

                    if (n.x < 151) {
                        console.log("left side active");

                        this.paddingActive = true;
                        _self.x = n.x + 138;
                         _self.xf = n.x - 137 + 80 + 138;
                    } else {
                        this.paddingActive = false;
                    }

                     if(n.bim_dbid !== null){

                        //  console.log("viwer position change")

                        // var dbId = n.bim_dbid;
                       
                        //      _self.viewer.impl.selector.setSelection([dbId], _self.viewer.model);
                        //        _self.viewer.fitToView([dbId]);

                        //     //    _self.viewer.loadExtension('Autodesk.BimWalk').activate();
                        //     //    _self.viewer.loadExtension('Autodesk.ViewerSettings').activate();
                        //      //  _self.viewer.loadExtension('Autodesk.BimWalk');
                          
                        this.setPosition(n.bim_dbid);

                        this.accorddinateLatestImage(n);

                        //  var xRatio = this.scalefactore_x; 
                        //  var yRatio = this.scalefactore_y;  
                         
                        //  var doo = this.cordinatesOriginal[index];

                        //  var pos =  this.calculateX2Y2(doo.x, doo.y, xRatio, yRatio);

                        //  this.createposition(pos,pos);

                        // var target =  this.calculateX2Y2(p2.x, p2.y, xRatio, yRatio);

                     }

                }, 100);

                //  console.log(n);
                this.openCoordinate = n;
                this.openCoordinateindex = index;
            }
        },
        autoconnect(){

            var cc = confirm("All previous data will be overwritten.");

        if(cc){


                var xRatio = this.scalefactore_x; 
                var yRatio = this.scalefactore_y; 
                
                var p,t,u,prs,position;

                var rpt = this.activeposition.split(",");

            this.cordinatesOriginal.forEach((c, index) => {

              
                         console.log(index);
                          position =  this.calculateX2Y2(c.x, c.y, xRatio, yRatio);

                            var x3 = position.x2+10;
                            var y3 = position.y2+100;
                            //var z3 = z;

                                p = position.x2+","+position.y2+","+rpt[2];
                                t = x3+","+y3+","+rpt[5];
                                u = rpt[6]+","+rpt[7]+","+rpt[8];
                      prs = p+","+t+","+u;
                     console.log("prs",prs);
                     this.autoconnectidss(prs,c.coordinateid,index);
                         
            });
          }
        },
       calculateX2Y2(x1, y1, xRatio, yRatio) {

    console.log("the value",(x1 / xRatio), this.maxx, (y1 / yRatio), this.maxy);

    //var offset = 25;
    // scaleFactor = Math.min(xRatio, yRatio);

    let x2 = (x1 / xRatio) - this.maxx;
    let y2 = this.maxy - (y1 / yRatio);

    return { x2, y2 };
    },
        createposition(autodesk_position,target){

//console.log("create autodesk_position", newangle);

//  console.log("scalefactore_x",this.scalefactore_x,this.scalefactore_y); 

if (autodesk_position) {

    var ap = autodesk_position; 
    var z = -7;
    var x3 = target.x2+2;
    var y3 = target.y2+5;
    var z3 = z;
    //console.log("creat possition",xx,yy);
    var position  = new THREE.Vector3(ap.x2,ap.y2,z);
    var targetc = new THREE.Vector3(x3,y3,z3);
    var up = new THREE.Vector3(0.000981706401707367,0.11897028436517434,0.992897329883916);

     //console.log("creat possition",position,target,up);
    

       // _self.alert = true;
      // _self.alertmessage = 'Click "First Person" view for model alignment if needed.';
    //  _self.viewerbim.navigation.setView(position, target, up);
     this.viewer.navigation.setView(position, targetc, up);
     this.viewer.impl.camera.fov = 100;
   
 }

},
        fullscreen() {
            this.full = true;

            //console.log("hfghf", this.full);
        },
        fullscreenexit() {
            this.full = false;
            this.fulllImage = false;
            this.colum = false;
            this.autodeskcompare = false;
            //console.log("hfghf", this.full);
        },
        closeAutodesk(){
             this.autodeskcompare = false;
        },
        getcoordinatesOrg(id) {
            axios({
                    url: '/interior/getcoordinates',
                    method: 'get',
                    params: {
                        did: id
                    }
                })
                .then(resp => {
                    //  console.log("headings", resp);

                    this.cordinatesOriginal = resp.data;

                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        getcoordinates(id, pr) {
            axios({
                    url: '/interior/getcoordinates',
                    method: 'get',
                    params: {
                        did: id
                    }
                })
                .then(resp => {
                      console.log("headings", resp.data);

                    this.imaglossp = pr;

                    var _self = this;

                    this.cordinates = resp.data.filter(function (item) {

                        var lox = item.x - (item.x * pr) / 100;
                        item.x = lox;

                        // item.xoo = item.x;

                        var loy = item.y - (item.y * pr) / 100;
                        item.y = loy;

                        // item.yoo = item.y;

                        if (item.bim_dbid) {

                            _self.singlePositionsSet = true;
                            _self.activeposition = item.bim_dbid;
                            var ap = item.bim_dbid.split(",");

// Create a new camera object with the saved information

 var p = parseFloat(ap[0])+","+parseFloat(ap[1])+","+parseFloat(ap[2]);
 var t = parseFloat(ap[3])+","+parseFloat(ap[4])+","+parseFloat(ap[5]);
 var u = parseFloat(ap[6])+","+parseFloat(ap[7])+","+parseFloat(ap[8]);

 var prs = p+","+t+","+u;
 _self.activeposition = prs;

                            item.fillcolor = '{"fillColor":"ff0000"}';

                        } else {
                            item.fillcolor = '{"fillColor":"fdc004"}';
                        }

                        return 1 == 1;

                    });

                    //  console.log("cordinates data", this.cordinates);

                    this.coorddinates();

                   // var _self = this;
                    setTimeout(() => {
                        _self.coorddinates();
                        //_self.accorddinateLatestImage();
                        $('.map').maphilight({
                            strokeColor: "000000",
                            strokeWidth: 2,
                            stroke: true,
                            fillOpacity: 0.9,
                            alwaysOn: true
                        });
                        _self.preloader = false;
                    }, 1000);

                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        accorddinateLatestImage(c) {

            // this.latestPanaromas = [];
            // this.latesttrue = false
            // this.sliderloader = true
            // // console.log("this.cordinates", this.cordinates);
            // var _self = this;
            // this.cordinates.forEach((c, index) => {

            //     //  console.log(this.cordinatesOriginal[index].coordinateid, c.coordinateid);
            //     //  console.log(this.cordinatesOriginal[index].x, c.x);
            //     //   console.log(this.cordinatesOriginal[index].y, c.y);

                console.log("c.coordinateid", c.x, c.y);
                axios({
                        url: '/interior/get-media-latest',
                        method: 'get',
                        params: {
                            coordi: c.coordinateid,
                            type: 'panaroma'
                        }
                    })
                    .then(resp => {
                        console.log("Laterst Images Images", resp.data.success);
                        this.activesliderImage = resp.data.success[0].mediapath;

                        this.toottipimageopen = true;

                       // console.log(this.activesliderImage);

                       // console.log("ddd = >", this.latestPanaromas);
                    })
          

        },
        onSelectslider(index) {

            this.index = index;

            this.sliderActive = true;
            // console.log("index",index);
            // console.log("dd",this.latestPanaromas[index][0]);
            // this.latestPanaromas[index]; 
            this.activesliderImage = this.latestPanaromas[index][0].mediapath;

            this.titile = this.latestPanaromas[index][0].titile;
            this.uploadat = this.latestPanaromas[index][0].uploadat;

            var self = this;
            setTimeout(() => {
                var b = self.$refs.slider;
                // console.log(" b.viewer", b.viewer);
                b.viewer.setHfov(b.viewer.getHfov() + 50);
                //b.viewer.stopAutoRotate(50); 
                //this.drag = this.$refs.panaromascreen.viewer.getPitch();

            }, 2000);

        },
        minus() {

            this.index = this.index - 1;
            var index = this.index;
            this.activesliderImage = this.latestPanaromas[index][0].mediapath;
            this.titile = this.latestPanaromas[index][0].titile;
            this.uploadat = this.latestPanaromas[index][0].uploadat;

            var self = this;
            setTimeout(() => {
                var b = self.$refs.slider;
                // console.log(" b.viewer", b.viewer);
                b.viewer.setHfov(b.viewer.getHfov() + 50);
                //b.viewer.stopAutoRotate(50); 
                //this.drag = this.$refs.panaromascreen.viewer.getPitch();

            }, 2000);

        },
        plus() {
            this.index = this.index + 1;
            var index = this.index;
            this.activesliderImage = this.latestPanaromas[index][0].mediapath;
            this.titile = this.latestPanaromas[index][0].titile;
            this.uploadat = this.latestPanaromas[index][0].uploadat;

            var self = this;
            setTimeout(() => {
                var b = self.$refs.slider;
                // console.log(" b.viewer", b.viewer);
                b.viewer.setHfov(b.viewer.getHfov() + 50);
                //b.viewer.stopAutoRotate(50); 
                //this.drag = this.$refs.panaromascreen.viewer.getPitch();

            }, 2000);

        },
        openComment_image(url) {
            //alert("hello");
            this.secondimagenormal = url;
            this.commentopenImage = true;
        },
        onSelect(a) {
            // var a = this.$refs.carousel.currentIndex;
            //  this.image = this.slides[a].mediapath;
            // console.log(this.image);

            this.imagenormalcompare = false;
            this.selected = [];
            this.colum = true;
            //  this.down = !this.down;
            if (this.activet == 'normal') {
                this.imagenormal = this.slides[a].mediapath;

                this.compatedatefirst = this.slides[a].uploadat;

            }
            if (this.activet == 'panaroma') {
                this.imagepanaroma = this.slides[a].mediapath;
                this.compatedatefirst = this.slides[a].uploadat;
            }
            if (this.activet == 'video') {

                console.log("this.slides[a]", this.slides[a]);
                this.compatedatefirst = this.slides[a].uploadat;

                this.pvideo = this.slides[a].mediapath;

            }

            let content = document.querySelector(".drawingxx");
            var _self = this;
            setTimeout(() => {
                // console.log("content", content);
                content.scrollLeft = _self.x - 100;
            }, 2000);
        },
        oncompare(event, a) {

            // var a = this.$refs.carousel.currentIndex;
            //  this.image = this.slides[a].mediapath;
            // console.log(this.image);
            if (event.target.checked) {

                //  this.selected.push(a);
                // var _this = this;
                setTimeout(() => {

                    //   console.log(_this.selected);

                    // console.log(this.selected.length);
                    if (this.selected.length > 2) {
                        this.selected.shift();
                    }

                    if (this.selected.length > 1) {

                        this.colum = true;
                        this.imagenormalcompare = true;
                        if (this.activet == 'normal') {

                            this.imagenormal = this.slides[this.selected[this.selected.length - 2]].mediapath;

                            this.compatedatefirst = this.slides[this.selected[this.selected.length - 2]].uploadat;

                            this.secondimagenormal = this.slides[this.selected[this.selected.length - 1]].mediapath;
                            this.compatedate = this.slides[this.selected[this.selected.length - 1]].uploadat;

                        }
                    }

                    if (this.selected.length > 1) {
                        this.colum = true;
                        if (this.activet == 'panaroma') {

                            this.imagepanaroma = this.slides[this.selected[this.selected.length - 2]].mediapath;
                            this.compatedatefirst = this.slides[this.selected[this.selected.length - 2]].uploadat;

                            //  console.log(this.selected.length);

                            this.secondimagepanaroma = this.slides[this.selected[this.selected.length - 1]].mediapath;
                            this.compatedate = this.slides[this.selected[this.selected.length - 1]].uploadat;
                            this.imagenormalcompare = true;
                        }
                    }
                    if (this.activet == 'video') {
                        this.colum = true;
                        // console.log("this.slides[a]", this.slides[a]);
                        this.compatedatefirst = this.slides[a].uploadat;

                        this.pvideo = this.slides[a].mediapath;

                    }
                }, 500)
            }
        },
        closeCompare() {
            this.selected = [];
            this.imagenormalcompare = false;
        },
        filterdate(m) {

            var a = ['All']

            m.forEach(i => {
                a.push(i.uploadat);
            });

            let unique = a.filter((item, i, ar) => ar.indexOf(item) === i);
            //  console.log(unique);

            this.uniquedates = unique.sort(function (a, b) {
                var dateA = new Date(a),
                    dateB = new Date(b)
                return dateB - dateA //sort by date ascending
            })
        },
        selectDate(d) {

            this.preloader = true;

            // console.log(d);
            this.refresh++;
            var _self = this;

            if (d == 'All') {
                // console.log(d);
                _self.slides = [];
                setTimeout(() => {
                    _self.slides = _self.saved;
                    _self.preloader = false;
                }, 500)

            } else {
                _self.slides = [];
                setTimeout(() => {
                    _self.slides = _self.saved.filter(function (item) {
                        return item.uploadat == d;
                    }, 500)
                    _self.preloader = false;
                });
            }
        },
        panUp() {
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

            a.viewer.setPitch(a.viewer.getPitch() + 20);
            b.viewer.setPitch(b.viewer.getPitch() + 20);
        },

        panDown() {
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

            a.viewer.setPitch(a.viewer.getPitch() - 20);
            b.viewer.setPitch(b.viewer.getPitch() - 20);
        },
        panLeft() {
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

            a.viewer.setYaw(a.viewer.getYaw() - 20);
            b.viewer.setYaw(b.viewer.getYaw() - 20);
        },
        panRight() {
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

            a.viewer.setYaw(a.viewer.getYaw() + 20);
            b.viewer.setYaw(b.viewer.getYaw() + 20);
        },
        panIn() {
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

            a.viewer.setHfov(a.viewer.getHfov() - 20);
            b.viewer.setHfov(b.viewer.getHfov() - 20);
        },
        panOut() {
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

            a.viewer.setHfov(a.viewer.getHfov() + 20);
            b.viewer.setHfov(b.viewer.getHfov() + 20);
        },
        addComment() {

            var a = this.$refs.panaromascreen;

            // console.log("refrence", a);

            var img = a.viewer.getRenderer().render(a.viewer.getPitch() / 180 * Math.PI,
                a.viewer.getYaw() / 180 * Math.PI,
                a.viewer.getHfov() / 180 * Math.PI, {
                    'returnImage': true
                });
            //  console.log(img);

            this.commentImage = img;

            this.commmentForm = true;
        },
        meckReaded(comment) {

            // console.log(comment);

            var status;
            if (comment.comment_status == 1) {
                status = 0;
            } else {
                status = 1;
            }

            // console.log(status);

            axios({
                    url: '/interior/commnent_update',
                    method: 'get',
                    params: {
                        comment_id: comment.cmt_id,
                        status: status
                    }
                })
                .then(resp => {
                    console.log("comments", resp);
                    // this.commentsdata = resp.data.success;
                    // this.commentbox = true;
                    this.getCommnets();
                    //this.getCommnets_bs64();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        getCommnets() {
            this.preloader = true;
            axios({
                    url: '/interior/get-commnent',
                    method: 'get',
                    params: {
                        coordinatid: this.openCoordinate.coordinateid
                    }
                })
                .then(resp => {
                    this.preloader = false
                    console.log("comments data", resp);
                    this.commentsdata = resp.data.success;
                    this.commentbox = true;
                    this.cmtSearchdata = resp.data.success.filter(function (item) {
                        return item.cmt_id;
                    });
                   // this.getCommnets_bs64();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        getCommnets_bs64() {
            this.preloader = false;
            this.progree = '20' + '/100%';
            this.reportStart = true;
            this.msg = 'Please wait while the report is being generated....'

            console.log("get comments bs64 started");
            axios({
                    url: '/interior/get-commnent_bs64',
                    method: 'get',
                    params: {
                        coordinatid: this.openCoordinate.coordinateid
                    }
                })
                .then(resp => {
                    this.reportStart = true;
                    this.progree = '40' + '/100%';
                    this.msg = 'Processing the report...'
                    console.log("comments dataa bs64", resp);
                    this.commentsdataa = resp.data.success;
                    if (this.commentsdataa) {
                        this.generate_snaglistreport();
                    }
                    //this.commentbox = true;
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        sendComment() {
            this.preloader = true;
            const url1 = this.imagepanaroma.split("?");
            console.log("image data", url1);
            if(this.commetnsemail_extra.split(",")){
                var ex_email = this.commetnsemail_extra.split(",");
                var cmtemail = this.commetnsemail;
                ex_email.forEach(function(ex_email) {
                    console.log("cmt_email extra", ex_email);
                    cmtemail.push(ex_email);
                console.log("cmt receiver email if con", cmtemail);
                });
                
                
            } else if(!this.commetnsemail_extra.split(",")){
               this.commetnsemail.push(this.commetnsemail_extra);
                console.log("cmt receiver email if con", this.commetnsemail);
            }else{
                console.log("cmt receiver email else con", this.commetnsemail);
            }
            if(this.cmt_rootcause){
                    console.log("root cause of comment", this.cmt_rootcause);
            }else{
                this.cmt_rootcause = 'Not available'
            }
            
            //console.log("cmt receiver email", this.commetnsemail);
            axios({
                    url: '/interior/send-commnent',
                    method: 'post',
                    data: {
                        proj_dir: this.proj_dir,
                        coordinatename: this.openCoordinate.title,
                        projectname: this.project_name,
                        drawingname: this.drawing.name,
                        project_id: this.projectid,
                        userid: this.userdata.id,
                        zoneid: this.zoneid,
                        commnettext: this.comment,
                        commentimg: this.commentImage,
                        coordinatid: this.openCoordinate.coordinateid,
                        realimage: url1[0],
                        sender_name: this.userdata.firstname + ' ' + this.userdata.lastname,
                        receiveremails: this.commetnsemail,
                        title:this.cmt_title,
                        type:this.cmt_type.cmt_type_op,
                        status:this.cmt_status_val.cmt_status,
                        assigned:this.cmt_assigned_to,
                        duedate:this.date1,
                        rootcause:this.cmt_rootcause,
                    }
                })
                .then(() => {
                    this.commmentForm = false;
                    // console.log("comments", resp);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.addcommnetssucess;
                    this.alertType = "success";
                    this.cmt_title='';
                    this.commetnsemail= [];
                    this.commetnsemail_extra='';
                    this.cmt_rootcause='';
                    this.cmt_status_val=null;
                    this.cmt_type=null;
                    this.date1= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    this.comment='';
                    

                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        taskboxopen() {
            this.taskbox = true;
            this.getListItemss();
        },
        getStanderType() {
            axios({
                    url: '/interior/get-stander-type',
                    method: 'get'
                })
                .then(resp => {

                    // console.log(resp);

                    this.tasktype = resp.data.success;

                    this.showstanderoption = true;

                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        addnewItem() {
            console.log('ok');
        },
        selectTlistItem(d) {
            axios({
                    url: '/interior/get-stander-list',
                    method: 'get',
                    params: {
                        type: d,
                        coordinatid: this.openCoordinate.coordinateid,
                        zoneid: this.zoneid,
                        drawingid: this.activeDrawing.drawingid,
                        userid: this.userdata.id,
                    }
                })
                .then(resp => {
                    console.log(resp);
                    this.getListItemss();

                    var _self = this;
                    setTimeout(() => {
                        var c = confirm(this.lang.listconfirmation)

                        if (c) {
                            _self.alert = true;
                            _self.alertmessage = this.lang.standdrlistloaded;
                            _self.alertType = "error"
                            _self.showstanderoption = false
                        } else {
                            _self.removeTaskLoaded();
                        }

                    }, 30000);

                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        getColor(datae, status) {

            // console.log(datae, status);

            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const firstDate = new Date(datae);
            const secondDate = new Date();

            //  console.log(firstDate, secondDate);

            const diffDays = Math.round((firstDate - secondDate) / oneDay);

            console.log("d -- diffe", status, diffDays);

            if (status == 'In progress' && diffDays >= 0 && diffDays <= 7) return '#bf9bff'
            else if (status == 'In progress' && diffDays > 7) return 'orange'
            else if (status == 'In progress' && diffDays <= 0) return '#f37171'
            else if (status == 'Completed') return '#87e38b'
            else if (status == 'Not started') return 'white'
            else return 'white'
        },
        days(datae, status) {

            //   console.log(datae, status);

            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const firstDate = new Date(datae);
            const secondDate = new Date();

            //  console.log(firstDate, secondDate);

            const diffDays = Math.round((firstDate - secondDate) / oneDay);

            //  console.log("d -- diffe", status ,diffDays);

            if (status == 'In progress' && diffDays >= 0 && diffDays <= 7) return diffDays
            else if (status == 'In progress' && diffDays <= 0) return diffDays
            else if (status == 'Completed') return diffDays
            else if (status == 'Not started') return diffDays
            else return diffDays
        },
        verificationEnventory(item) {
            this.preloader = true;

            var varify = 1;

            var m = "Task marked as verified."

            // console.log("item",item);

            if (item.verification == 1) {
                varify = 0;
                m = "Task status not verified."
            }

            axios({
                    url: '/interior/verification-task',
                    method: 'get',
                    params: {
                        verification: varify,
                        taskid: item.taskid
                    }
                })
                .then(() => {
                    //console.log("Resp", resp);
                    this.alert = true;
                    this.alertmessage = m;
                    this.alertType = "success";
                    var _self = this;

                    setTimeout(() => {
                        _self.getListItemss();
                        _self.preloader = false;

                    }, 2000);
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        getListItemss() {
            this.preloader = true;
            axios({
                    url: '/interior/get-list',
                    method: 'get',
                    params: {
                        coordinatid: this.openCoordinate.coordinateid,
                    }
                })
                .then(resp => {
                    //  console.log("table data", resp.data.success);

                    this.tasks = resp.data.success;
                    this.preloader = false;
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        removeTaskLoaded() {
            axios({
                    url: '/interior/remove-task',
                    method: 'get',
                    params: {
                        coordinatid: this.openCoordinate.coordinateid,
                    }
                })
                .then(resp => {
                    console.log("Remove data", resp.data.success);

                    this.getListItemss()
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        sendTask() {
            this.preloader = false
            //console.log("this.newTask");
            axios({
                    url: '/interior/add-task',
                    method: 'get',
                    params: {
                        coordinatid: this.openCoordinate.coordinateid,
                        taskname: this.newTask.taskname,
                        taskStatus: this.newTask.taskstatus,
                        startdate: this.newTask.taskstartdate,
                        enddate: this.newTask.taskenddate,
                        prograss: this.newTask.taskprogress,
                        tag: this.newTask.tasktag,
                        taskchecked: 0,
                        userid: this.userdata.id,
                        zoneid: this.zoneid,
                        drawingid: this.activeDrawing.drawingid,
                        headingtype: this.newTask.headingtype ? 1 : 0

                    }
                })
                .then(resp => {
                    console.log("Remove data", resp.data.success);
                    this.newTaskForm = false;
                    this.getListItemss()
                    this.alert = true;
                    this.alertmessage = this.lang.newmsg;
                    this.alertType = "success"
                    this.preloader = false
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        editTask(d) {

            this.updateTaskForm = true
            this.updateTask = {
                taskname: d.taskname,
                taskstatus: d.status,
                taskstartdate: d.start_date,
                taskenddate: d.end_date,
                taskprogress: d.progress,
                tasktag: d.tag,
                taskid: d.taskid,
                headingtype: d.headingtype
            }
        },
        sendupdateTask() {
            this.preloader = true
            //console.log("this.newTask");
            axios({
                    url: '/interior/update-task',
                    method: 'get',
                    params: {

                        taskname: this.updateTask.taskname,
                        taskStatus: this.updateTask.taskstatus,
                        startdate: this.updateTask.taskstartdate,
                        enddate: this.updateTask.taskenddate,
                        prograss: this.updateTask.taskprogress,
                        tag: this.updateTask.tasktag,
                        taskchecked: 0,
                        userid: this.userdata.id,
                        taskid: this.updateTask.taskid,
                        headingtype: this.updateTask.headingtype ? 1 : 0

                    }
                })
                .then(resp => {
                    console.log("Remove data", resp.data.success);
                    this.updateTaskForm = false;
                    this.getListItemss()
                    this.alert = true;
                    this.alertmessage = this.lang.tasksuccess;
                    this.alertType = "success"
                    this.preloader = false
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        setrouter: function (url) {

            if (this.$route.path !== url) {
                this.$router.push(url)
                //console.log("current router ", this.$route.path);

            }

            //console.log(url);
        },
         magnifytogle() {
            this.magnify = !this.magnify;
        },
        deleteTask(id) {

            var s = confirm(this.lang.sureremove);

            if (s) {
                this.preloader = true
                //console.log("this.newTask");
                axios({
                        url: '/interior/delete-task',
                        method: 'get',
                        params: {
                            taskid: id

                        }
                    })
                    .then(resp => {
                        console.log("Remove data", resp.data.success);
                        this.updateTaskForm = false;
                        this.getListItemss()
                        this.alert = true;
                        this.alertmessage = this.lang.taskDelete;
                        this.alertType = "success"
                        this.preloader = false
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            }
        },
       
        
        coorddinates() {
            var has_VML, has_canvas, create_canvas_for, add_shape_to, clear_canvas, shape_from_area,
                canvas_style, hex_to_decimal, css3color, is_image_loaded, options_from_area;

            has_canvas = !!document.createElement('canvas').getContext;

            // VML: more complex
            has_VML = (function () {
                var a = document.createElement('div');
                a.innerHTML = '<v:shape id="vml_flag1" adj="1" />';
                var b = a.firstChild;
                b.style.behavior = "url(#default#VML)";
                return b ? typeof b.adj == "object" : true;
            })();

            if (!(has_canvas || has_VML)) {
                $.fn.maphilight = function () {
                    return this;
                };
                return;
            }

            if (has_canvas) {
                hex_to_decimal = function (hex) {
                    return Math.max(0, Math.min(parseInt(hex, 16), 255));
                };
                css3color = function (color, opacity) {
                    return 'rgba(' + hex_to_decimal(color.substr(0, 2)) + ',' + hex_to_decimal(color.substr(2, 2)) + ',' + hex_to_decimal(color.substr(4, 2)) + ',' + opacity + ')';
                };
                create_canvas_for = function (img) {
                    var c = $('<canvas style="width:' + $(img).width() + 'px;height:' + $(img).height() + 'px;"></canvas>').get(0);
                    c.getContext("2d").clearRect(0, 0, $(img).width(), $(img).height());
                    return c;
                };
                var draw_shape = function (context, shape, coords, x_shift, y_shift) {
                    x_shift = x_shift || 0;
                    y_shift = y_shift || 0;

                    context.beginPath();
                    if (shape == 'rect') {
                        // x, y, width, height
                        context.rect(coords[0] + x_shift, coords[1] + y_shift, coords[2] - coords[0], coords[3] - coords[1]);
                    } else if (shape == 'poly') {
                        context.moveTo(coords[0] + x_shift, coords[1] + y_shift);
                        for (var i = 2; i < coords.length; i += 2) {
                            context.lineTo(coords[i] + x_shift, coords[i + 1] + y_shift);
                        }
                    } else if (shape == 'circ') {
                        // x, y, radius, startAngle, endAngle, anticlockwise
                        context.arc(coords[0] + x_shift, coords[1] + y_shift, coords[2], 0, Math.PI * 2, false);
                    }
                    context.closePath();
                };
                add_shape_to = function (canvas, shape, coords, options, name) {
                    var i, context = canvas.getContext('2d');

                    console.log(name, i);

                    // Because I don't want to worry about setting things back to a base state

                    // Shadow has to happen first, since it's on the bottom, and it does some clip /
                    // fill operations which would interfere with what comes next.
                    if (options.shadow) {
                        context.save();
                        if (options.shadowPosition == "inside") {
                            // Cause the following stroke to only apply to the inside of the path
                            draw_shape(context, shape, coords);
                            context.clip();
                        }

                        // Redraw the shape shifted off the canvas massively so we can cast a shadow
                        // onto the canvas without having to worry about the stroke or fill (which
                        // cannot have 0 opacity or width, since they're what cast the shadow).
                        var x_shift = canvas.width * 100;
                        var y_shift = canvas.height * 100;
                        draw_shape(context, shape, coords, x_shift, y_shift);

                        context.shadowOffsetX = options.shadowX - x_shift;
                        context.shadowOffsetY = options.shadowY - y_shift;
                        context.shadowBlur = options.shadowRadius;
                        context.shadowColor = css3color(options.shadowColor, options.shadowOpacity);

                        // Now, work out where to cast the shadow from! It looks better if it's cast
                        // from a fill when it's an outside shadow or a stroke when it's an interior
                        // shadow. Allow the user to override this if they need to.
                        var shadowFrom = options.shadowFrom;
                        if (!shadowFrom) {
                            if (options.shadowPosition == 'outside') {
                                shadowFrom = 'fill';
                            } else {
                                shadowFrom = 'stroke';
                            }
                        }
                        if (shadowFrom == 'stroke') {
                            context.strokeStyle = "rgba(0,0,0,1)";
                            context.stroke();
                        } else if (shadowFrom == 'fill') {
                            context.fillStyle = "rgba(0,0,0,1)";
                            context.fill();
                        }
                        context.restore();

                        // and now we clean up
                        if (options.shadowPosition == "outside") {
                            context.save();
                            // Clear out the center
                            draw_shape(context, shape, coords);
                            context.globalCompositeOperation = "destination-out";
                            context.fillStyle = "rgba(0,0,0,1);";
                            context.fill();
                            context.restore();
                        }
                    }

                    context.save();

                    draw_shape(context, shape, coords);

                    // fill has to come after shadow, otherwise the shadow will be drawn over the fill,
                    // which mostly looks weird when the shadow has a high opacity
                    if (options.fill) {
                        context.fillStyle = css3color(options.fillColor, options.fillOpacity);
                        context.fill();
                    }
                    // Likewise, stroke has to come at the very end, or it'll wind up under bits of the
                    // shadow or the shadow-background if it's present.
                    if (options.stroke) {
                        context.strokeStyle = css3color(options.strokeColor, options.strokeOpacity);
                        context.lineWidth = options.strokeWidth;
                        context.stroke();
                    }

                    context.restore();

                    if (options.fade) {
                        $(canvas).css('opacity', 0).animate({
                            opacity: 1
                        }, 100);
                    }
                };
                clear_canvas = function (canvas) {
                    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
                };
            } else { // ie executes this code
                create_canvas_for = function (img) {
                    return $('<var style="zoom:1;overflow:hidden;display:block;width:' + img.width + 'px;height:' + img.height + 'px;"></var>').get(0);
                };
                add_shape_to = function (canvas, shape, coords, options, name) {
                    var fill, stroke, opacity, e;
                    for (var i in coords) {
                        coords[i] = parseInt(coords[i], 10);
                    }
                    fill = '<v:fill color="#' + options.fillColor + '" opacity="' + (options.fill ? options.fillOpacity : 0) + '" />';
                    stroke = (options.stroke ? 'strokeweight="' + options.strokeWidth + '" stroked="t" strokecolor="#' + options.strokeColor + '"' : 'stroked="f"');
                    opacity = '<v:stroke opacity="' + options.strokeOpacity + '"/>';
                    if (shape == 'rect') {
                        e = $('<v:rect name="' + name + '" filled="t" ' + stroke + ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' + coords[0] + 'px;top:' + coords[1] + 'px;width:' + (coords[2] - coords[0]) + 'px;height:' + (coords[3] - coords[1]) + 'px;"></v:rect>');
                    } else if (shape == 'poly') {
                        e = $('<v:shape name="' + name + '" filled="t" ' + stroke + ' coordorigin="0,0" coordsize="' + canvas.width + ',' + canvas.height + '" path="m ' + coords[0] + ',' + coords[1] + ' l ' + coords.join(',') + ' x e" style="zoom:1;margin:0;padding:0;display:block;position:absolute;top:0px;left:0px;width:' + canvas.width + 'px;height:' + canvas.height + 'px;"></v:shape>');
                    } else if (shape == 'circ') {
                        e = $('<v:oval name="' + name + '" filled="t" ' + stroke + ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' + (coords[0] - coords[2]) + 'px;top:' + (coords[1] - coords[2]) + 'px;width:' + (coords[2] * 2) + 'px;height:' + (coords[2] * 2) + 'px;"></v:oval>');
                    }
                    e.get(0).innerHTML = fill + opacity;
                    $(canvas).append(e);
                };
                clear_canvas = function (canvas) {
                    // jquery1.8 + ie7
                    var $html = $("<div>" + canvas.innerHTML + "</div>");
                    $html.children('[name=highlighted]').remove();
                    $(canvas).html($html.html());
                };
            }

            shape_from_area = function (area) {
                var i, coords,
                    shape = (area.getAttribute('shape') || 'rect').toLowerCase().substr(0, 4);
                if (shape === 'defa') {
                    // 'default' doesn't really apply to what we're doing; it's the background state
                    return;
                }
                coords = (area.getAttribute('coords') || '').split(',');
                for (i = 0; i < coords.length; i++) {
                    coords[i] = parseFloat(coords[i]);
                }
                return [shape, coords];
            };

            options_from_area = function (area, options) {
                var $area = $(area);
                return $.extend({}, options, $.metadata ? $area.metadata() : false, $area.data('maphilight'));
            };

            is_image_loaded = function (img) {
                if (!img.complete) {
                    return false;
                } // IE
                if (typeof img.naturalWidth != "undefined" && img.naturalWidth === 0) {
                    return false;
                } // Others
                return true;
            };

            canvas_style = {
                position: 'absolute',
                left: 0,
                top: 0,
                padding: 0,
                border: 0
            };

            var ie_hax_done = false;
            $.fn.maphilight = function (opts) {
                opts = $.extend({}, $.fn.maphilight.defaults, opts);

                if (!has_canvas && !ie_hax_done) {
                    $(window).ready(function () {
                        document.namespaces.add("v", "urn:schemas-microsoft-com:vml");
                        var style = document.createStyleSheet();
                        var shapes = ['shape', 'rect', 'oval', 'circ', 'fill', 'stroke', 'imagedata', 'group', 'textbox'];
                        $.each(shapes,
                            function () {
                                style.addRule('v\\:' + this, "behavior: url(#default#VML); antialias:true");
                            }
                        );
                    });
                    ie_hax_done = true;
                }

                return this.each(function () {
                    var img, wrap, options, map, canvas, canvas_always, usemap;
                    img = $(this);

                    //  console.log(highlighted_shape,imgSrc);

                    if (!is_image_loaded(this)) {
                        // If the image isn't fully loaded, this won't work right.  Try again later.
                        return window.setTimeout(function () {
                            img.maphilight(opts);
                        }, 200);
                    }

                    options = $.extend({}, opts, $.metadata ? img.metadata() : false, img.data('maphilight'));

                    // jQuery bug with Opera, results in full-url#usemap being returned from jQuery's attr.
                    // So use raw getAttribute instead.
                    usemap = img.get(0).getAttribute('usemap');

                    if (!usemap) {
                        return;
                    }

                    map = $('map[name="' + usemap.substr(1) + '"]');

                    if (!(img.is('img,input[type="image"]') && usemap && map.length > 0)) {
                        return;
                    }

                    if (img.hasClass('maphilighted')) {
                        // We're redrawing an old map, probably to pick up changes to the options.
                        // Just clear out all the old stuff.
                        var wrapper = img.parent();
                        img.insertBefore(wrapper);
                        wrapper.remove();
                        $(map).unbind('.maphilight');
                    }

                    //Formating the image source. IE > 9 has issue with new line characters
                    // var imgSrc = this.src.replace(/[\n\r]/g, '');
                    //  console.log(imgSrc);
                    wrap = $('<div></div>').css({
                        display: 'block',
                        background: 'url("' + this.src + '")',
                        "background-size": 'contain',
                        "background-repeat": 'no-repeat',
                        position: 'relative',
                        padding: 0,
                        width: this.width,
                        height: this.height
                    });
                    if (options.wrapClass) {
                        if (options.wrapClass === true) {
                            wrap.addClass($(this).attr('class'));
                        } else {
                            wrap.addClass(options.wrapClass);
                        }
                    }
                    // Firefox has a bug that prevents tabbing into the image map if
                    // we set opacity of the image to 0, but very nearly 0 works!
                    img.before(wrap).css('opacity', 0.0000000001).css(canvas_style).remove();
                    if (has_VML) {
                        img.css('filter', 'Alpha(opacity=0)');
                    }
                    wrap.append(img);

                    canvas = create_canvas_for(this);
                    $(canvas).css(canvas_style);
                    canvas.height = this.height;
                    canvas.width = this.width;

                    $(map).bind('alwaysOn.maphilight', function () {
                            // Check for areas with alwaysOn set. These are added to a *second* canvas,
                            // which will get around flickering during fading.
                            if (canvas_always) {
                                clear_canvas(canvas_always);
                            }
                            if (!has_canvas) {
                                $(canvas).empty();
                            }
                            $(map).find('area[coords]').each(function () {
                                var shape, area_options;
                                area_options = options_from_area(this, options);
                                if (area_options.alwaysOn) {
                                    if (!canvas_always && has_canvas) {
                                        canvas_always = create_canvas_for(img[0]);
                                        $(canvas_always).css(canvas_style);
                                        canvas_always.width = img[0].width;
                                        canvas_always.height = img[0].height;
                                        img.before(canvas_always);
                                    }
                                    area_options.fade = area_options.alwaysOnFade; // alwaysOn shouldn't fade in initially
                                    shape = shape_from_area(this);
                                    if (!shape) {
                                        return;
                                    }
                                    if (has_canvas) {
                                        add_shape_to(canvas_always, shape[0], shape[1], area_options, "");
                                    } else {
                                        add_shape_to(canvas, shape[0], shape[1], area_options, "");
                                    }
                                }
                            });
                        }).trigger('alwaysOn.maphilight')
                        .bind('mouseover.maphilight focusin.maphilight', function (e) {
                            
                            var shape, area_options, area = e.target;
                            area_options = options_from_area(area, options);
                            if (!area_options.neverOn && !area_options.alwaysOn) {
                                shape = shape_from_area(area);
                                if (!shape) {
                                    return;
                                }
                                add_shape_to(canvas, shape[0], shape[1], area_options, "highlighted");
                                if (area_options.groupBy) {
                                    if (typeof area_options.groupBy == 'string') {
                                        area_options.groupBy = [area_options.groupBy];
                                    }
                                    var el = $(this);
                                    $.each(area_options.groupBy, function (index, groupitem) {
                                        var areas;
                                        // two ways groupBy might work; attribute and selector
                                        if (/^[a-zA-Z][a-zA-Z]+$/.test(groupitem)) {
                                            areas = map.find('area[' + groupitem + '="' + el.attr(groupitem) + '"]');
                                        } else {
                                            areas = map.find(groupitem);
                                        }
                                        var first = this;
                                        areas.each(function () {
                                            if (this != first) {
                                                var subarea_options = options_from_area(this, options);
                                                if (!subarea_options.neverOn && !subarea_options.alwaysOn) {
                                                    var shape = shape_from_area(this);
                                                    add_shape_to(canvas, shape[0], shape[1], subarea_options, "highlighted");
                                                }
                                            }
                                        });
                                    });
                                }
                                // workaround for IE7, IE8 not rendering the final rectangle in a group
                                if (!has_canvas) {
                                    $(canvas).append('<v:rect></v:rect>');
                                }
                            }
                        }).bind('mouseout.maphilight focusout.maphilight', function () {
                            //console.log(e);
                            clear_canvas(canvas);
                        });

                    img.before(canvas); // if we put this after, the mouseover events wouldn't fire.

                    img.addClass('maphilighted');
                });
            };
            $.fn.maphilight.defaults = {
                fill: true,
                fillColor: 'fdc004',
                fillOpacity: 0.2,
                stroke: true,
                strokeColor: '000000',
                strokeOpacity: 1,
                strokeWidth: 1,
                fade: true,
                alwaysOn: true,
                neverOn: false,
                groupBy: false,
                wrapClass: true,
                // plenty of shadow:
                shadow: true,
                shadowX: 0,
                shadowY: 0,
                shadowRadius: 6,
                shadowColor: '000000',
                shadowOpacity: 0.8,
                shadowPosition: 'outside',
                shadowFrom: false
            }
        },
        opendialogbox() {
            //  console.log(value);
            this.reportdialogbox = true
        },
     getviwerid(){
                      console.log("viewableId ==",this.viewer.getCamera());
                      
                         
                      var cam = this.viewer.navigation.getCamera();
       
                         const position =  cam.position;
                         const target =  cam.target;
                         const upVector  =  cam.up;
       
                     console.log(position, target, upVector);
       
                   // var proid = this.currentProject.id;
       
                     var  p = position.x+","+position.y+","+position.z;
                     var  t = target.x+","+target.y+","+target.z;
                     var  u = upVector.x+","+upVector.y+","+upVector.z;
                     var prs = p+","+t+","+u;
                     this.activeposition = prs;
                     console.log("prs",prs);
                     this.connectidss(prs, this.openCoordinate.coordinateid);

                    //this.getRatio();
                    //this.getNewRatio();

                    // let pointsSmall = [{x: 67.70522246237984, y: 51.878183101333896}, {x: 85.5560400540403, y: -38.88354448795559}, {x: 145.78969316917974, y: 54.07704968802474},{x: 148.85696125445133, y: -37.84512892718664} ];
                    // let pointsLarge = [{x: 576.9066286528868, y: 163.3642195295795}, {x: 613.2573057733429, y: 391.304347826087}, {x: 756.9493941553814, y: 170.20669992872416}, {x: 761.2259444048468, y: 397.2915181753385}];

                    // let scaleFactors = this.calculateScaleFactor(pointsSmall, pointsLarge);
                    // console.log("Scale Factor X:", scaleFactors.scaleX);
                    // console.log("Scale Factor Y:", scaleFactors.scaleY);
               },
               setPosition(autodesk_position){
          /// var autodesk_position =  this.current_cam.o_position;
           //var autodesk_target =  this.current_cam.o_target;
               
           var _self = this;
            console.log("autodesk_position", autodesk_position);
           
           if(autodesk_position){

            var ap = autodesk_position.split(",");

// Create a new camera object with the saved information

 var position = new THREE.Vector3(parseFloat(ap[0]),parseFloat(ap[1]),parseFloat(ap[2]));
 var target = new THREE.Vector3(parseFloat(ap[3]),parseFloat(ap[4]),parseFloat(ap[5]));
 var up = new THREE.Vector3(parseFloat(ap[6]),parseFloat(ap[7]),parseFloat(ap[8]));
               
               setTimeout(function () {
                _self.alert = true;
                _self.alertmessage = 'Click "First Person" view for model alignment if needed.';
                _self.viewer.navigation.setView(position, target,up);

             }, 100);
              
              }
         },
         closeMegamenu() {
            this.$store.dispatch('openDrawing', false)
        },
        removeallPositions(){
           
             var conf = confirm("Do you want to disconnect the connection of all the points with the BIM model?");
             
             if(conf){
             var drawingid = this.drawing.drawingid;
             var zoneid = this.zoneid;
             var projectid = this.projectid;
             axios({ url: '/interior/disconnect-coordinates', method: 'post',data : { "drawingid" : drawingid , "projectid" : projectid, "zoneid" : zoneid }})
          .then(resp => {
            console.log("User data getting", resp);

                    this.alert = true;
                    this.alertmessage = "Coordinate disconnected successfully. Refresh page to see the changes.";
                    this.alertType = "error"

          })
          .catch(err => {
                   console.log(err);
                   this.alert = true;
                    this.alertmessage = "Something went wrong please try again.";
                    this.alertType = "error"
          })
        }
        },
        onDocumentLoadS() {
            var df =  this.viewer.setActiveNavigationTool('bimwalk');
                                 console.log("df",df);
         this.viewer.impl.camera.fov = 100;
            this.getRatio();
        },
        autoconnectnotication(){

                    this.alert = true;
                    this.alertmessage = "Please connect at least one point manually.";
                    this.alertType = "error"

        },
        setAgnleDiff(){
            if (this.$refs.panaromascreen) {
               var yaw = this.$refs.panaromascreen.viewer.getYaw(); // Get current yaw
               //var pitch = this.$refs.panaromascreen.viewer.getPitch(); // Get current pitch
               console.log("yaw",yaw);

             var did =  this.drawing.drawingid;

             console.log("did",did);
            

         axios({ url: '/interior/update-drawing-anglediff', method: 'post',data : { "did" : did, "angleDiff" : yaw }})
          .then(resp => {
            console.log("User data getting", resp);

                    this.alert = true;
                    this.alertmessage = "Done";
                    this.alertType = "error"
                    

          })
          .catch(err => {
                   console.log(err);
                   this.alert = true;
                    this.alertmessage = "Something went wrong please try again.";
                    this.alertType = "error"
          })
          }

        }
    }
};
</script>

<style>

.child_div1{
    position: relative;
}
#forgeViewert .right {
    right : 0;
}
.autodeskcc {
    height: calc(100vh - 45px) !important;
    padding: 0 !important;
}
.blank{
 display:block;
 width: 20px;
 height: 20px;
 border-radius:50%;
 position: absolute;
 top: -39px;
}
.redd {
    border:1px solid #d30c0cfa;
    background : #d30c0cfa;
}
.greend {
    border:1px solid #67ee00;
    background : #67ee00;
        box-shadow: 0px 3px 20px 5px #fff;
        border:1px solid #fff;
}
.citemcloser{
  top:50px;
  position:absolute;
}
.positiontt{
  position: absolute;
  top: 50px;
  right:160px;
  z-index:9;

}
.positionttremove{
  position: absolute;
  top: 87px;
  right: 160px;
  z-index:9;
}
.positiontsauto{
   position: absolute;
  top: 50px;
  right: 43px;
  z-index:9;
}
.setlangle{
  position: absolute;
  top: 10px;
  right: 100px;
  z-index:9;
}
.imgesssxc {
    width: 300px;
    height: 200px;
    position: fixed;
    z-index: 99;
    bottom:30px;
    right:10px;
}
.arrowaaa {
    position: relative !important;
    left: 58%;
    top: 67px;
    /* right: 1px; */
    z-index: 9999999999 !important;
    font-size: 90px !important;
}
.autobox {
    position: absolute;
    right: 20px;
    width: 151px;
    height: 134px;
    display: block;
    top: 81px;
    z-index: 5;
    /* background: #ccc; */
}
.autobox2 {
    position: absolute;
    right: 200px;
    width: 150px;
    height: 200px;
    display: block;
    top: 83px;
    z-index: 5;
}
.flotings{
    cursor: pointer;
}
</style>
