<template>
  <div>
    <Preloader v-if="preloader" />
    <div class="mobilerotedouter">
      <img :src="rotate" class="mobileroted detect">
    </div>
    <v-row>
      <div cols="12" md="6">
  
    <div v-if="isSmallScreen">
        <div class="mainViewMedia item mobile">
        <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#f8ba04"
          small
          v-bind="attrs"
          v-on="on"
        >
          {{ selectedMedia }} <v-icon dark right> mdi-arrow-down-drop-circle </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item style="background-color: transparent;">
          <v-list-item-title><v-btn small :color="`${selectedMedia == 'Drone Video' ? '#ffc400' : '#FFF'}`" :class="`ma-2 Block--text mediatypees ${selectedMedia == 'Drone Video' ? 'active' : ''
      }`
      " @click="mediatypeset('Drone Video')" :disabled="visitdata.drone_videos_count == 0">{{ lang.DroneVideo }}
            <strong>{{ visitdata.drone_videos_count }}</strong></v-btn></v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title><v-btn small :color="`${selectedMedia == 'Drone Images' ? '#ffc400' : '#FFF'}`" :class="`ma-2 Block--text mediatypees ${selectedMedia == 'Drone Images' ? 'active' : ''
      }`
      " @click="mediatypeset('Drone Images')" :disabled="visitdata.drone_images_count == 0">{{ lang.DroneImages }}
            <strong>{{ visitdata.drone_images_count }}</strong></v-btn></v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title><v-btn small :color="`${selectedMedia == 'Normal Images' ? '#ffc400' : '#FFF'}`" :class="`ma-2 Block--text mediatypees ${selectedMedia == 'Normal Images' ? 'active' : ''
      }`
      " @click="mediatypeset('Normal Images')" :disabled="visitdata.normal_images_count == 0">
            {{ lang.NormalImages }}
            <strong>{{ visitdata.normal_images_count }}</strong></v-btn></v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title><v-btn small :color="`${selectedMedia == '360 Images' ? '#ffc400' : '#FFF'}`" :class="`ma-2 Block--text mediatypees ${selectedMedia == '360 Images' ? 'active' : ''
      }`
      " @click="mediatypeset('360 Images')" :disabled="visitdata.panaroma_count == 0">
            {{ lang.Images360 }} <strong>{{ visitdata.panaroma_count }}</strong></v-btn></v-list-item-title>
        </v-list-item>
     

      <v-list-item>
        <v-list-item-title>
          <v-btn
          small
            :color="`${selectedMedia == 'Route' ? '#ffc400' : '#FFF'}`"
            :class="
              `ma-2 Block--text mediatypees ${
                selectedMedia == 'Route' ? 'active' : ''
              }`
            "
            @click="getTaskData()"
          >
          Route
          </v-btn>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>
           <v-btn
           small 
            :color="`${selectedMedia == 'Route' ? '#ffc400' : '#FFF'}`"
            :class="
              `ma-2 Block--text mediatypees ${
                selectedMedia == 'Route' ? 'active' : ''
              }`
            "
            @click="gotoOrthophoto()"
            v-if="visitdata.orthophoto_count > 0"
          >
          Ortho Photo <strong>{{ visitdata.orthophoto_count }}</strong>
          </v-btn> 
        </v-list-item-title>
      </v-list-item>

          <v-list-item>
            <v-list-item-title>
          <v-btn 
          small
            :color="`${selectedMedia == 'Route' ? '#ffc400' : '#FFF'}`"
            :class="
              `ma-2 Block--text mediatypees ${
                selectedMedia == 'Route' ? 'active' : ''
              }`
            "
            @click="goto3dmodle()"
            v-if="visitdata.orthophoto_count > 0"
          >
          3D Model
          </v-btn> 
        </v-list-item-title>
      </v-list-item>
      </v-list>

    </v-menu>
  </div>

    </div>
    <div v-else>
      <div class="mainViewMedia item">
          <v-btn :color="`${selectedMedia == 'Drone Video' ? '#ffc400' : '#FFF'}`" small :class="`ma-2 Block--text mediatypees ${selectedMedia == 'Drone Video' ? 'active' : ''
      }`
      " @click="mediatypeset('Drone Video')" :disabled="visitdata.drone_videos_count == 0">
            {{ lang.DroneVideo }}
            <strong>{{ visitdata.drone_videos_count }}</strong>
          </v-btn>
          <v-btn small :color="`${selectedMedia == 'Drone Images' ? '#ffc400' : '#FFF'}`" :class="`ma-2 Block--text mediatypees ${selectedMedia == 'Drone Images' ? 'active' : ''
      }`
      " @click="mediatypeset('Drone Images')" :disabled="visitdata.drone_images_count == 0">
            {{ lang.DroneImages }}
            <strong>{{ visitdata.drone_images_count }}</strong>
          </v-btn>
          <v-btn small :color="`${selectedMedia == 'Normal Images' ? '#ffc400' : '#FFF'}`" :class="`ma-2 Block--text mediatypees ${selectedMedia == 'Normal Images' ? 'active' : ''
      }`
      " @click="mediatypeset('Normal Images')" :disabled="visitdata.normal_images_count == 0">
            {{ lang.NormalImages }}
            <strong>{{ visitdata.normal_images_count }}</strong>
          </v-btn>
          <v-btn small :color="`${selectedMedia == '360 Images' ? '#ffc400' : '#FFF'}`" :class="`ma-2 Block--text mediatypees ${selectedMedia == '360 Images' ? 'active' : ''
      }`
      " @click="mediatypeset('360 Images')" :disabled="visitdata.panaroma_count == 0">
            {{ lang.Images360 }} <strong>{{ visitdata.panaroma_count }}</strong>
          </v-btn>

          <!-- //Route button disable we will enble when its assign project. -->
          <v-btn
          small
            :color="`${selectedMedia == 'Route' ? '#ffc400' : '#FFF'}`"
            :class="
              `ma-2 Block--text mediatypees ${
                selectedMedia == 'Route' ? 'active' : ''
              }`
            "
            @click="getTaskData()"
          >
          Route
          </v-btn>
           <v-btn
           small
            :color="`${selectedMedia == 'Route' ? '#ffc400' : '#FFF'}`"
            :class="
              `ma-2 Block--text mediatypees ${
                selectedMedia == 'Route' ? 'active' : ''
              }`
            "
            @click="gotoOrthophoto()"
            v-if="visitdata.orthophoto_count > 0"
          >
          Ortho Photo <strong>{{ visitdata.orthophoto_count }}</strong>
          </v-btn> 
          <v-btn
            small
            :color="`${selectedMedia == 'Route' ? '#ffc400' : '#FFF'}`"
            :class="
              `ma-2 Block--text mediatypees ${
                selectedMedia == 'Route' ? 'active' : ''
              }`
            "
            @click="goto3dmodle()"
            v-if="visitdata.orthophoto_count > 0"
          >
          3D Model
          </v-btn> 

          <v-btn
            small
            :color="`${selectedMedia == 'Route' ? '#ffc400' : '#FFF'}`"
            :class="
              `ma-2 Block--text mediatypees ${
                selectedMedia == 'Route' ? 'active' : ''
              }`
            "
            @click="goto3dgsmodle()"
            v-if="visitdata.threeD_count > 0"
          >
          3DGS Model
          </v-btn> 
        </div>
    </div>
  


        <!-- <v-col cols="12" md="12" v-if="uploadzone">
        <v-card class="formcard">
                <div>
                    <vue-dropzone ref="myVueDropzone" id="drop1" v-on:vdropzone-thumbnail="thumbnail" v-on:vdropzone-sending="sendingEvent" :options="dropOptions"></vue-dropzone>
                </div>

        </v-card>
      </v-col> -->

        <!-- <v-col cols="12" md="12" v-if="galleryview">
             <v-row>
                        <v-col v-for="(n, index) in images" :key="n.id" class="d-flex child-flex gitem" cols="4" >
                          <v-img :src="n.thumbnail_path"  aspect-ratio="1.5" class="grey lighten-2" @click="fullpanaromaImage(n, index)">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
</v-img>
</v-col>
</v-row>
</v-col> -->
        <v-col cols="12" md="12" class="maps mapsMainView" v-if="loopcout && galleryview">
          <div :class="{ 'hide-map-controls': !showMapControls }">
          <gmap-map map-type-id="satellite" :center="center" :zoom="zoom" :class="`mapite ${mapToggle ? 'min' : 'max'} ${selectedMedia == 'Drone Video' ? 'fifty' : 'full'
      }`
      " ref="mapRef">
            <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
              @closeclick="infoWinOpen = false">

            </gmap-info-window>

            <!-- <gmap-marker :key="i" v-for="(m,i) in markers"  :position="m.position" :clickable="true" :icon="cimi == i ? mapMarkerActive : mapMarker" @click="videocontrollers(m,i)"></gmap-marker> -->

            <span v-if='selectedMedia == "Route"'> <gmap-marker :key="i" v-for="(m, i) in markers"
                :position="m.position" :clickable="true" :icon="markerr(m, i)"
                @click="toggleInfoWindow(m, i)"></gmap-marker> </span>

            <span v-if='selectedMedia !== "Route"'> <gmap-marker :key="i" v-for="(m, i) in markers"
                :position="m.position" :clickable="true" :icon="markerr(m, i)"
                @click="videocontrollers(m, i)"></gmap-marker> </span>

          </gmap-map>
        </div>
        <span class="mapCheckIcon" v-if="!showMapControls"><v-icon style="font-size: 40px;" color="#ffc403">mdi-map-marker-circle</v-icon></span>
        <v-checkbox
  v-model="showMapControls"
  class="my-4 buttonClass white-border-checkbox"
  color="#ffc403"
>
</v-checkbox>
        </v-col>

        <div :class="`fullviewbox ${selectedMedia == 'Drone Video' ? 'fifty' : 'full'}`
      " v-if="fulllImage" @keydown.esc="fulllImage = false" tabindex="0">
      <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="infobox"
      class="infoboxclass"
    >
      <template>
        <v-card>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="info_title"
                  :label="lang.DroneHeading"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  solo
                  v-model="info_description"
                  :label="lang.DroneDescription"
                  required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-btn
                  class="mr-4"
                  @click="infoboxsubmit"
                  color="mylightbutton"
                >
                  {{ lang.DroneInfoAdd }}
                </v-btn>
                <v-btn @click="infobox = false" color="mydarkbutton">
                  {{ lang.Cancel }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="updateinfobox"
      class="infoboxclass"
    >
      <template>
        <v-card>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="info_title"
                  :label="lang.DroneHeading"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  solo
                  v-model="info_description"
                  :label="lang.DroneDescription"
                  required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-btn
                  class="mr-4"
                  @click="updateinfoboxsubmit"
                  color="mylightbutton"
                >
                  {{ lang.Update }}
                </v-btn>
                <v-btn @click="updateinfobox = false" color="mydarkbutton">
                  {{ lang.Cancel }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </v-dialog>
    <v-col cols="12" md="12" class="infobox metadata infoboxclass" v-if="taskbox">
      <v-icon dark color="#000" @click="taskbox = false" class="exittask">
        mdi-close
      </v-icon>
      <div class="mt-8">
        <v-data-table
          :headers="tableheaders"
          item-key="id"
          :items="infolist"
          :items-per-page="-1" 
          :footer-props="footerpro"
          class="elevation-1"
        >
          <template v-slot:item.status="{ item }">
            <v-btn
              fab
              dark
              icon
              small
              v-if="userdata.user_code == 0 || userdata.user_code == 2"
              color="black"
              @click="editTask(item)"
            >
              <v-icon dark>
                mdi-pencil-box-multiple
              </v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              icon
              small
              v-if="userdata.user_code == 0 || userdata.user_code == 2"
              color="black"
              @click="deleteTask(item.id)"
            >
              <v-icon dark>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-col>
          <v-pannellum v-if="selectedMedia == '360 Images'" class="fullscreen" :src="liveimage"></v-pannellum>
          <v-zoomer style="width: 100%; height:100vh;" v-if="selectedMedia == 'Drone Images' ||
      selectedMedia == 'Normal Images'
      " class="zoomercustome">
            <img :src="liveimage" style="width: auto; height:100vh" />
          </v-zoomer>

          <div class="info-items-user-view" v-if="selectedMedia == 'Drone Video'">
            <span>
              <v-btn v-if="hasDroneVideoURL" :href="hasDroneVideoURL" target="_blank" class="downloadButton" outlined
                color="white" download>
                Download HD Video
              </v-btn>
            </span>
            <p v-for="info in infolist" :key="info.id">
              <span class="info_title_user_view" v-if="activePosition >= Math.round(info.time) &&
      activePosition - 6 <= Math.round(info.time)
      ">
                {{ info.title }}
              </span>
              <span class="info_description_user_view" v-if="activePosition >= Math.round(info.time) &&
      activePosition - 6 <= Math.round(info.time)
      ">
                {{ info.description }}
              </span>
            </p>
          </div>

          <v-btn
            class="mx-2  btnView checboxbutton"  fab dark small :color="infoAddMode?'#9a864d':'#f8bb03'"
            v-if="userdata.user_code == 2 && selectedMedia == 'Drone Video'" @click="addinfoactivatore()" title="Add Video Info" >
            <v-icon dark color="#000">
              mdi-map-marker-plus
          </v-icon>
          </v-btn>

          <v-btn
            class="mx-2 btnView listbutton"  fab dark small color="#f8bb03"
            v-if="userdata.user_code == 2 && selectedMedia == 'Drone Video'" @click="taskboxopen()" title="Text Overlay Info" >
            <v-icon dark color="#000">
              mdi-format-list-bulleted-triangle
          </v-icon>
          </v-btn>

          <v-btn
            v-if="selectedMedia == 'Normal Images' || selectedMedia == 'Drone Images' || selectedMedia == 'Drone Video'"
            class="mx-2 buttonPosition shareVideo btnView" fab dark small color="#ff5566"
            @click="openDroneDataShareDialog"><v-icon dark> mdi-share-variant </v-icon>
          </v-btn>
        

          <video v-if="selectedMedia == 'Drone Video'" class="videostylez" id="cspd_video" controls="true"
            ref="custome_timelapsvideo"></video>
          <v-icon dark color="#000" @click="fullscreenexit()" class="exitbuttons" large>
            mdi-fullscreen-exit
          </v-icon>
        
          <v-btn icon @click="preImage" class="left arrow" v-if="cimi > 0">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <v-btn icon @click="nextImage" class="right-next-image arrow" v-if="cimi < images.length - 1">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-row>
    <div class="timeline3d up" v-if="sliderloader">
      <v-progress-linear color="#f8bb03" indeterminate reverse></v-progress-linear>
    </div>
    <div :class="`timeline3d ${down ? 'down' : 'up'}`" v-if="latesttrue">
      <v-btn class="mx-2 arrowr leftal" fab x-small color="#f8bb03" @click="downUp">
        <v-icon dark>
          mdi-chevron-up-circle
        </v-icon>
      </v-btn>
      <carousel :key="refresh3" class="timelinec" :autoplay="false" :items="8" :slideBy="7" :dots="false" :margin="8"
        :nav="true" :navText="['', '']">
        <div v-for="(slide, indx) in images" :key="indx + 100"
          :class="`timelaineimagebox ${cimi == indx ? 'active' : ''}`" :title="slide.id">
          <span class="addUrlIconSpanMainView">
            <v-btn v-if="slide.drone_video_url != null && selectedMedia == 'Drone Video'" icon
              :href="slide.drone_video_url" target="_blank">
              <v-icon class="addUrlIconSpanMainView">mdi-high-definition-box</v-icon>
            </v-btn>
          </span>
          <img :src="slide.thumbnail_path == 'null' ? vthumbnail : slide.thumbnail_path
      " @click="fullpanaromaImage(slide, indx)" />
        </div>
      </carousel>
    </div>

    <v-snackbar v-model="alert" top right timeout="3000" type="success">
      {{ alertmessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="alert = false">
          {{ lang.Close }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="droneDataShare"
      style="z-index: 99999;">
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark>
            {{ lang.ShareByEmail }}
            <span class="btn btn-info text-white copy-btn ml-auto" @click.stop.prevent="copyTestingCode">
              <v-btn depressed color="primary"> {{ lang.Copyurl }} </v-btn>
              <input type="hidden" id="copyUrl" :value="timelapseUrl" />
            </span>
          </v-toolbar>
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submitShare">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="email" :label="lang.Email" required :rules="emailrules"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="subject" :label="lang.Subject" required></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                    {{ lang.Send }}
                    <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                  </v-btn>
                  <v-btn @click="droneDataShare = false" color="mydarkbutton">
                    {{ lang.Cancel }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </template>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";
//import vueDropzone from "vue2-dropzone";
import exifr from "exifr";
import VuePannellum from "vue-pannellum";
import carousel from "vue-owl-carousel";
import vthumbnail from "../../assets/video_thumbnail.png";
import icon from "../../assets/Dot-Icon-Blue.png";
import iconactive from "../../assets/Dot-Icon-BlueActive.png";
import red from "../../assets/red.png";
import green from "../../assets/green.png";
import yellow from "../../assets/yellow.png";
import grey from "../../assets/grey.png";
import black from "../../assets/black.png"
import pink from "../../assets/pink.png";
import { locals } from "../../utils/locals";
import $ from "jquery";
import rotate from '../../assets/rotedmobilepsd.jpg';
export default {
  components: {
    Preloader,
    VPannellum: VuePannellum,
    carousel
  },
  name: "CompareEvent",
  data() {
    return {
      rotate: rotate,
      preloader: false,
      files: [],
      dropOptions: {
        url: "https://api.opticvyu.com/api/project/gps/image-upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        paramName: "file",
        maxFilesize: 500, // MB
        acceptedFiles: ".jpeg,.jpg,.png",
        maxFiles: 4,
        chunkSize: 500, // Bytes
        thumbnailMethod: "contain",
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        autoProcessQueue: false,
        dictDefaultMessage:
          "Click to upload Images  <br />----------------- <br/>Drag & Drop"
      },
      thumbnailimage: "",
      pro_dir: "",
      projectid: "",
      userdata: {},
      imagesExtract: "",
      currentvisitId: "",
      visitdata: "",
      galleryview: true,
      uploadzone: false,
      visitDatepopup: false,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      images: [],
      loopcout: false,
      // infoOptions: {
      //   content: "",
      //   backgroundColor: '#2ffff3',
      //   //optional: offset infowindow so it visually sits nicely on top of our marker
      //   pixelOffset: {
      //     width: 0,
      //     height: -35,
      //     zoomControl: true,
      //     mapTypeControl: true,
      //     scaleControl: true
      //   }
      // },
      infoOptions: {},
      infoWindowPos: null,
      infoWinOpen: false,
      dats: [],
      center: {
        lat: 20.5937,
        lng: 78.9629
      },
      zoom: 5,
      markers: [],
      currentPlace: null,
      coi: 0,
      valid: true,
      currentMidx: null,
      fulllImage: false,
      liveimage: "",
      cimi: 0,
      alertmessage: "",
      alert: false,
      refresh3: 0,
      sliderloader: true,
      latesttrue: false,
      down: false,
      mapToggle: false,
      selectedMedia: "Drone Video",
      mapMarkerActive: iconactive, //"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyMyAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yMyAxMS41QzIzIDIxLjUgMTEuNSAyOC41IDExLjUgMjguNUMxMS41IDI4LjUgMCAyMS41IDAgMTEuNUMwIDUuMTQ4NzMgNS4xNDg3MyAwIDExLjUgMEMxNy44NTEzIDAgMjMgNS4xNDg3MyAyMyAxMS41WiIgZmlsbD0iI0M3MDYyOSIvPg0KPGNpcmNsZSBjeD0iMTEuNSIgY3k9IjExLjUiIHI9IjUuNSIgZmlsbD0iIzgxMDAxNyIvPg0KPC9zdmc+DQo=",
      mapMarker: icon, //"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyMyAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yMyAxMS41QzIzIDIxLjUgMTEuNSAyOC41IDExLjUgMjguNUMxMS41IDI4LjUgMCAyMS41IDAgMTEuNUMwIDUuMTQ4NzMgNS4xNDg3MyAwIDExLjUgMEMxNy44NTEzIDAgMjMgNS4xNDg3MyAyMyAxMS41WiIgZmlsbD0iIzMzMzMzMyIvPg0KPGNpcmNsZSBjeD0iMTEuNSIgY3k9IjExLjUiIHI9IjUuNSIgZmlsbD0iYmxhY2siLz4NCjwvc3ZnPg0K",
      selectedKey: null,
      selectedMarker: null,
      activePosition: 0,
      vthumbnail: vthumbnail,
      infolist: [],
      Local: locals,
      lang: {},
      dialog: false,
      zonesFirstRow: {},
      zonesSecondRow: {},
      firstRowzoneid: 0,
      secondRowzoneid: 0,
      projid: "",
      zonename: "",
      items: [],
      comparisonOne: false,
      comparisonTwo: false,
      compareOneActivePosition: 0,
      comparisonOneMarkers: 0,
      compareTwoActivePosition: 0,
      comparisonTwoMarkers: 0,
      compareDataFirstRow: [],
      compareDataSecondRow: [],
      firstRowMarkers: [],
      secondRowMarkers: [],
      firstRowCoi: 0,
      secondRowCoi: 0,
      firstRowDats: [],
      secondRowDats: [],
      centerFirstRow: {
        lat: 20.5937,
        lng: 78.9629
      },
      centerSecondRow: {
        lat: 20.5937,
        lng: 78.9629
      },
      zoomFirstRow: 5,
      zoomSecondRow: 5,
      hasDroneVideoURL: "",
      droneVideoURLMainView: "",
      droneVideoIDMainView: "",
      rules: {
        required: (value) => !!value || "This field is required.",
        url: (value) => {
          // A simple regex to validate URLs
          const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
          return regex.test(value) || "Invalid URL.";
        },
      },
      isFormValid: false,
      droneDataShare: false,
      emailrules: [
        (v) => !!v || this.lang.required,
        (v) => /.+@.+\..+/.test(v) || this.lang.EmailValid,
      ],
      send: false,
      timelapseUrl: "",
      shareImageLat: "",
      shareImageLong: "",
      routedata: {},
      subject: '',
      email: '',
      red: red,
      yellow: yellow,
      pink: pink,
      grey: grey,
      green: green,
      black: black,
      isSmallScreen: false,
      dropdownOpen: false,
      infoAddMode: false,
      infobox: false,
      info_description: "",
      info_title: "",
      markdata: "",
      markindex: "",
      infoxitem: "",
      tableheaders: [
        {
          text: "Heading",
          value: "title"
          // width: 150,
        },
        {
          text: "Description",
          value: "description",
          width: 150
        },
        {
          text: "Time",
          value: "time"
          // width: 60,
        },
        {
          text: "Height",
          value: "height"
          // width: 70,
        },
        {
          text: "Distance",
          value: "distance"
          // width: 70,
        },
        {
          text: "Action",
          value: "status"
          // width: 100,
        }
      ],
      taskbox: false,
      updateinfobox: false,
      footerpro: {
                itemsPerPageOptions: [100, 250, 500, -1],
            },
      editedVideosAccess: false,
      mapOptions: null, // Start as null, set it later
      showMapControls: false,

    };
  },
   mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    // if (this.userdata.user_code == 1) {
    //   this.editedVideosAccess = true;
    // }

    let currentProject = JSON.parse(localStorage.getItem("currentProject"));
    //console.log("Current data get",this.$store.state.currentCamera)
    this.cam_prefix = this.$store.state.currentCamera.camera_prefix;
    this.projectid = currentProject.id;
    this.pro_dir = currentProject.pro_dir;
    this.initViewer();
    var _self = this;
    document.body.addEventListener("keyup", e => {
      if (e.keyCode === 27) {
        _self.fullscreenexit();
        this.mediatypeset("Drone Video");
      }
    });
    this.project = currentProject.project_name;
    // Check screen size on mount and whenever window is resized
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  destroyed() {
    // Clean up event listener on component destroy
    window.removeEventListener('resize', this.checkScreenSize);
  },
  created() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }

    let currentProject = JSON.parse(localStorage.getItem("currentProject"));
    //console.log("Current data get",this.$store.state.currentCamera)
    this.cam_prefix = this.$store.state.currentCamera.camera_prefix;
    this.projectid = currentProject.id;
    this.pro_dir = currentProject.pro_dir;
    this.initViewer();
    var _self = this;
    document.body.addEventListener("keyup", e => {
      if (e.keyCode === 27) {
        _self.fullscreenexit();
        this.mediatypeset("Drone Video");
      }
    });
    this.project = currentProject.project_name;

    this.$root.$refs.openDroneCompareVideoDialog = this;
  },
  watch: {
    "$store.state.currentvisitId": function () {
      this.preloader = true;
      this.latesttrue = false;
      var _self = this;
      setTimeout(() => {
        _self.currentvisitId = _self.$store.state.currentvisitId;
        console.log(_self.currentvisitId, "_self.currentvisitId");
        // this.$refs.myVueDropzone.removeAllFiles();
        _self.initViewer();
        _self.sliderloader = false;

        _self.preloader = false;
      }, 5000);
    },
    $route: function () {
      //console.log(to);
      var hash = window.location.hash;
      console.log(hash);
      var result = hash.substring(1);
      this.openbyDrop(result);
    },
  },

  computed: {
    paramsShareDialog() {
      return {
        to: this.email,
        message: "",
        subject: this.subject,
        attachment: this.timelapseUrl,
        project: this.project,
        visit_date: this.visitdata.visit_date,
      };
    },
  },
  methods: {
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth <= 950; // Adjust breakpoint as needed
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    handleButtonClick(option) {
      console.log(`Clicked ${option}`);
      // Handle button click logic here
    },
    initViewer() {
      //   this.preloader = true;
      console.log("Page Load", this.currentvisitId);
      this.visitdata = JSON.parse(localStorage.getItem("currentvisitD"));

      console.log("this.visitdata", this.visitdata);
      this.getImages();
    },
    sendingEvent(file, xhr, formData) {
      console.log("d", file);
      // formData.append("project_id", "2");
      this.visitdata = JSON.parse(localStorage.getItem("currentvisitD"));

      console.log("xhr", xhr);

      formData.append("latitude", this.imagesExtract.latitude);

      formData.append("longitude", this.imagesExtract.longitude);

      formData.append("gps_date_stamp", this.imagesExtract.GPSDateStamp);

      formData.append("gps_time_stamp", this.imagesExtract.GPSTimeStamp);

      formData.append("project_id", this.projectid);
      formData.append("proj_dir", this.pro_dir);
      formData.append("thumbnail", this.thumbnailimage);
      formData.append("user_id", this.userdata.id);
      formData.append("visit_date", this.visitdata.visit_date);
      formData.append("visit_id", this.visitdata.id);

      console.log("formData", formData);
    },
    async thumbnail(file, dataUrl) {
      this.thumbnailimage = dataUrl;
      var s = await exifr.parse(file);
      this.imagesExtract = s;
      console.log(
        s.latitude,
        s.longitude,
        s.GPSDateStamp,
        s.GPSTimeStamp,
        s.Make,
        s.Model,
        s.Software,
        file.name
      );

      // console.log("this.thumbnailimage g", this.thumbnailimage);

      this.$refs.myVueDropzone.processQueue();
    },
    uploadzoneAction() {
      this.galleryview = false;
      this.uploadzone = true;
    },
    galleryviewAction() {
      this.getImages();
      this.galleryview = true;
      this.uploadzone = false;
    },
    addvisitDate() {
      this.visitDatepopup = !this.visitDatepopup;
    },
    getImages() {
      this.markers = [];

      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      this.cam_prefix = this.$store.state.currentCamera.camera_prefix;
      this.projectid = currentProject.id;
      this.pro_dir = currentProject.pro_dir;

      this.refresh3++;

      this.marker = [];
      this.loopcout = false;
      axios({
        url:
          "/project/" + this.projectid + "/gps/get_images/" + this.visitdata.id,
        method: "GET"
      })
        .then(resp => {
          if (resp.data.success.length > 0) {

            var _self = this;

            this.dats = resp.data.success.filter(function (item) {
              return item.mediatype == _self.selectedMedia;
            });

            if (this.selectedMedia == "Drone Video") {
              this.maplocationsviwer(this.dats[0]);
            }

            this.images = this.dats;
            this.coi = this.dats.length;

            var n = 0;
            /// var sa = '';
            this.dats.forEach((i, index) => {

              if (i.latitude && i.longitude) {
                this.markers[n] = {
                  position: {
                    lat: parseFloat(i.latitude),
                    lng: parseFloat(i.longitude)
                  },
                  infoText:
                    '<a style="text-align:center" href="#' +
                    index +
                    '" > Open Image </a>',
                   
                };
              }

              n++;
              var _self = this;
              if (this.coi == n) {
                setTimeout(() => {
                  _self.loopcout = true;
                  var lt = parseFloat(this.dats[0].latitude);
                  var lng = parseFloat(this.dats[0].longitude);
                  console.log("this.markers", _self.markers);
                  _self.center = {
                    lat: lt,
                    lng: lng
                  };
                  _self.zoom = 15;
                  _self.latesttrue = true;
                  _self.sliderloader = false;
                }, 2000);
              }
            });
          } else {
            console.log("elsem", resp);
            this.sliderloader = false;
            this.preloader = false;
            this.alert = true;
            this.alertmessage = "Images not available.";
          }
        })
        .catch(err => {
          console.log("Getting image Error ", err);
          this.getImages();
        });
        if (!window.google || !window.google.maps) {
        console.error("Google Maps API is not loaded.");
        return;
      }
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    fullscreenexit() {
      this.fulllImage = false;
      this.mapToggle = false;
      //console.log("hfghf", this.full);
    },
    fullpanaromaImage(d, index) {
      this.fulllImage = true;
      this.mapToggle = true;
      this.cimi = index;
      this.timelapseUrl = d.image_path;
      this.shareImageLat = d.latitude;
      this.shareImageLong = d.longitude;
      this.infoxitem = d;
      console.log(this.timelapseUrl, "this.timelapseUrl");
      if (this.selectedMedia == "Drone Video") {
        this.maplocationsviwer(d); 
        this.maplocationsInfo(d);
        var _self = this;
        setTimeout(() => {
          _self.$refs.custome_timelapsvideo.src = d.image_path;
        }, 500);
      } else {
        console.log("fullpanaromaImage", index);

        this.liveimage = d.image_path;
      }
    },
    openbyDrop(index) {
      this.fulllImage = true;
      this.mapToggle = true;
      this.cimi = index;

      this.liveimage = this.images[index].image_path;
    },
    preImage() {
      var dataindex = this.cimi - 1;

      this.cimi = dataindex;

      console.log("fullpanaromaImage preImage", dataindex);

      var data = this.images[dataindex];

      this.liveimage = data.image_path;
    },
    nextImage() {
      var dataindex = this.cimi + 1;

      this.cimi = dataindex;

      console.log("fullpanaromaImage nextImage", dataindex);

      var data = this.images[dataindex];
      this.liveimage = data.image_path;
    },
    addVisitDate() {
      console.log("this  dater", this.picker);

      axios({
        url: "/project/" + this.projectid + "/gps/new_visit_date",
        method: "post",
        data: {
          project_id: this.projectid,
          visit_date: this.picker,
          createdby: this.userdata.id
        }
      })
        .then(resp => {
          console.log(resp);
          this.alert = true;
          if (resp.data.error) {
            this.alertmessage = resp.data.error.message;
          } else {
            this.alertmessage = "Visit date added Successfully";
            this.visitDatepopup = false;
            this.$router.go();
          }
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = "Visit date adding error";
          this.alertType = "error";
          this.preloader = false;
          this.showprogrss = false;
        });
    },
    downUp() {
      this.down = !this.down;
    },
    mediatypeset(c) {
      this.preloader = true;
      this.latesttrue = false;
      this.selectedMedia = c;
      var _self = this;
      setTimeout(() => {
        _self.getImages();
        _self.preloader = false;
      }, 1000);
      
    },
    infoboxsubmit() {

       console.log("markdata",this.markdata);

      var c = this.markdata;
     
      var data = {
        description: this.info_description,
        title: this.info_title,
        time: c.time,
        distance: c.distance,
        height: c.height,
        pointindex: this.markindex,
        mediaid: this.infoxitem.id,
        project_id: this.projectid
      };

      console.log("data", data);

      axios({
        url: "/project/gps/drone-video-info",
        method: "post",
        data: data
      })
        .then(resp => {
          console.log(resp);
          this.alert = true;
          this.alertmessage = this.lang.recordadded;
          this.preloader = false;
          this.infobox = false;
          this.info_description = '';
          this.info_title = '',
          this.maplocationsviwer(this.infoxitem);
          this.maplocationsInfo(this.infoxitem);
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
          this.showprogrss = false;
        });
    },
    addinfoactivatore(){
      this.infoAddMode = !this.infoAddMode; 
    },
    videocontrollers(m, index) {
      if (this.selectedMedia == "Drone Video") {
        var theVideo = document.getElementById("cspd_video");
        // var vid_currentTime = theVideo.currentTime;
        if (this.infoAddMode) {
          console.log(this.infoAddMode);
          this.infobox = true;
          this.markdata = m;
          this.markindex = index;
          this.info_description = '';
          this.info_title = '';

          var ac = this.infolist.filter(function(item) {
            //  console.log("item.mediatype", item.mediatype, _self.selectedMedia);

            return item.pointindex == index;
          });

          if (ac.length > 0) {
            this.info_description = ac[0].description;
            this.info_title = ac[0].title;
            this.info_id = ac[0].id;

            this.infobox = false;
            this.updateinfobox = true;
          }
           
          console.log("this.infobox",this.infobox);

        } else {
          theVideo.currentTime = m.time;
        }
       
      } else {
        this.fulllImage = true;
        this.cimi = index;
        this.mapToggle = true;

        this.liveimage = this.images[index].image_path;
      }
    },
    maplocationsInfo(d) {
      console.log("d", d);
      axios({
        url: "/project/gps/drone-info",
        method: "get",
        params: {
          mediaid: d.id,
          project_id: this.projectid
        }
      })
        .then(resp => {
          console.log(resp.data.success);
          this.infolist = resp.data.success;
          this.hasDroneVideoURL = d.drone_video_url;
        })
        .catch(err => {
          console.log(err);
        });
    },
    maplocationsviwer(d) {
      this.loopcout = false;
      var _self = this;
      $.getJSON(d.gpsfile, function (mydata) {
        _self.coi = mydata.length;

        var n = 0;
        _self.markers = [];
        mydata.forEach((i, index) => {

          if (i.lat && i.long) {
            _self.markers[n] = {
              position: {
                lat: parseFloat(i.long),
                lng: parseFloat(i.lat)
              },
              infoText:
                '<a style="text-align:center" href="#' +
                index +
                '" > ' +
                i.id +
                " </a>",
              time: i.start,
              distance: i.distance,
              height: i.height
            };
          }
          //console.log("n", n);
          n++;

          //  console.log("this.coi == n+1", _self.coi, n);
          if (_self.coi == n) {
            setTimeout(() => {
              _self.loopcout = true;
              var lt = parseFloat(mydata[0].long);
              var lng = parseFloat(mydata[0].lat);
              //  console.log("this.markers in", _self.markers);
              _self.center = {
                lat: lt,
                lng: lng
              };
              _self.zoom = 17;
            }, 1000);
          }
        });
      });

      _self.checkvideoloop();
    },
    checkvideoloop() {
      //
      // theVideo.currentTime = vid_currentTime - 5;

      //  this.getMarkers()
      var _self = this;
      //  var n = 1;
      setInterval(() => {
        var theVideo = document.getElementById("cspd_video");
        if (theVideo !== null) var vid_currentTime = theVideo.currentTime;
        // console.log("vid_currentTime", vid_currentTime)
        _self.activePosition = Math.round(vid_currentTime);
        //    n++;
      }, 1000);
    },
    markerr(m, i) {
      if (this.selectedMedia == "Drone Video") {
        if (
          this.activePosition >= Math.round(m.time) &&
          this.activePosition - 2 <= Math.round(m.time)
        ) {
          console.log("---", m.time);
          return this.mapMarkerActive;
        } else {
          return this.mapMarker;
        }
      } else if (this.selectedMedia == "Route") {

        //console.log(this.routedata[i] === undefined);
        var result = this.grey;
        if (this.routedata[i] !== undefined) {


          var status = this.routedata[i].pointstatus;

          if (status == 'not startde') {
            result = this.grey;
          } else if (status == 'pier_cage') {
            result = this.black;
          } else if (status == 'pier') {
            result = this.green;
          } else if (status == 'pier_cap') {
            result = this.pink;
          } else if (status == 'deck') {
            result = this.red;
          }

        }
        return result;

      } else {
        if (this.cimi == i) {
          return this.mapMarkerActive;
        } else {
          return this.mapMarker;
        }
      }
    },
    getVisitDates() {
      // Moduletype variable store value of marial, interior or constructuion cameras.
      axios({
        url: "/project/" + this.projectid + "/gps/get_visit_date/",
        method: "GET"
      })
        .then(resp => {
          this.preloader = false;
          this.zones = resp.data.success;
          if (this.zones.length == 0) {
            localStorage.setItem("currentvisitDate", "");
          } else {
            localStorage.setItem(
              "currentvisitDate",
              JSON.stringify(resp.data.success[0])
            );
            this.zonename = resp.data.success[0].visit_date;
          }
        })
        .catch(err => {
          console.log(err);
          this.preloader = false;
        });
    },
    storeVisitData(id) {
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      axios({
        url: "/project/" + currentProject.id + "/gps/get_visit/" + id,
        method: "GET"
      })
        .then(resp => {
          if (resp.data.success) {
            localStorage.setItem(
              "currentvisitDate",
              JSON.stringify(resp.data.success)
            );
          } else {
            localStorage.setItem("currentvisitDate", JSON.stringify({}));
          }
        })
        .catch(err => {
          console.log("Error in current visit data set", err);
        });
    },
    openDroneDataShareDialog() {
      this.droneDataShare = true;
    },
    async submitShare() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        if (this.selectedMedia == "Drone Images" || this.selectedMedia == "Normal Images") {
          this.paramsShareDialog.location = "https://www.google.com/maps/?q=" + this.shareImageLat + "," + this.shareImageLong;
          this.shareDroneOrNormalImageData(this.paramsShareDialog);
          console.log(this.paramsShareDialog, "this.paramsShareDialog");
        } else {
          this.shareDroneVideoData(this.paramsShareDialog);
        }
      }
    },
    shareDroneVideoData(sharedata) {
      this.send = true;
      axios({
        url: "https://secure.opticvyu.com/api/send-with-drone-video-link",
        data: sharedata,
        method: "post",
      })
        .then((resp) => {
          console.log(resp);
          this.droneDataShare = false;
          this.send = false;
        })
        .catch((err) => {
          console.log(err)
          this.send = false;
          this.snackbar = true;
          this.text = this.lang.EmailFaild;
        });
    },
    shareDroneOrNormalImageData(sharedata) {
      this.send = true;
      axios({
        url: "https://secure.opticvyu.com/api/send-with-drone-normal-image-link",
        data: sharedata,
        method: "post",
      })
        .then((resp) => {
          console.log(resp);
          this.droneDataShare = false;
          this.send = false;
        })
        .catch((err) => {
          console.log(err)
          this.send = false;
          this.snackbar = true;
          this.text = this.lang.EmailFaild;
        });
    },
    copyTestingCode() {
      let testingCodeToCopy = document.querySelector("#copyUrl");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("url copied " + msg);
        this.snackbar = true;
        this.text = this.lang.copymsg;
      } catch (err) {
        //alert("Oops, unable to copy");
        this.snackbar = true;
        this.text = this.lang.SomethingWentWrong;
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    setrouter: function (url) {
      this.showmobilemenu = false;
      if (this.$route.path !== url) {
        this.$router.push(url)
        //console.log("current router ", this.$route.path);
        this.activelink = this.$route.path;
      }

      //console.log(url);
    },
    getTaskData() {
      this.preloader = true;
      var proid = this.projectid;
      axios({
        url: 'get/' + proid + '/drone/routes',
        method: 'GET'
      })
        .then(resp => {
          this.markers = [];
          this.selectedMedia = 'Route'
          this.preloader = false;
          console.log("Resp", resp);
          this.routedata = resp.data.success;
          var n = 0;
          this.routedata.forEach((i, index) => {
            //  console.log("==>", i.latitude, i.longitude);

            if (i.latitude && i.longitude) {
              this.markers[n] = {
                position: {
                  lat: parseFloat(i.latitude),
                  lng: parseFloat(i.longitude)
                },
                infoText:
                  '<a style="text-align: center; background: #2ffff3;padding: 4px 8px;display: block; height: 25px;" href="#' +
                  index + '" > ' + i.piermark + ' </a>'
              };
            }

            n++;
            var _self = this;
            if (this.coi == n) {
              setTimeout(() => {
                _self.loopcout = true;
                var lt = parseFloat(this.routedata[1].latitude);
                var lng = parseFloat(this.routedata[1].longitude);
                //console.log("this.markers", _self.markers);
                _self.center = {
                  lat: lt,
                  lng: lng
                };
                _self.zoom = 15;
              }, 2000);
            }
          });
        })
        .catch(err => {
          console.log(err);
          this.preloader = false;
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error"
        })
    },
    taskboxopen() {
      console.log("taskboxopen", this.taskbox);
      this.taskbox = true;
    },
    editTask(data) {
      this.info_description = data.description;
      this.info_title = data.title;
      this.info_id = data.id;

      this.updateinfobox = true;
    },
    updateinfoboxsubmit() {
      this.preloader = true;
      var data = {
        description: this.info_description,
        title: this.info_title,
        info_id: this.info_id,
        project_id: this.projectid
      };

      console.log("data", data);

      axios({
        url: "/project/gps/update-drone-video-info",
        method: "post",
        data: data
      })
        .then(resp => {
          console.log(resp);
          this.alert = true;
          this.alertmessage = this.lang.RecordUpdatesuccessfully;
          this.preloader = false;
          this.updateinfobox = false;

          this.maplocationsInfo(this.infoxitem);
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
          this.showprogrss = false;
        });
    },
    deleteTask(id) {
      var s = confirm(this.lang.taskconfirmation);

      if (s) {
        this.preloader = true;
        //console.log("this.newTask");
        axios({
          url: "/project/gps/remove-drone-info",
          method: "post",
          data: {
            info_id: id,
            project_id: this.projectid
          }
        })
          .then(resp => {
            console.log("Remove data", resp.data.success);
            this.maplocationsInfo(this.infoxitem);
            this.alert = true;
            this.alertmessage = this.lang.recordDeleted;
            this.alertType = "success";
            this.preloader = false;
          })
          .catch(err => {
            console.log(err);
            this.alert = true;
            this.alertmessage = this.lang.SomethingWentWrong;
            this.alertType = "error";
            this.preloader = false;
          });
      }
    },
    goto3dmodle(){
     var token = localStorage.getItem("token");
     let currentProject = JSON.parse(localStorage.getItem("currentProject"));
    //console.log("Current data get",this.$store.state.currentCamera)
     var projectid = currentProject.id; 
      window.open("https://3da.opticvyu.com/index.html?token="+token+"&pid="+projectid);
    },
    goto3dgsmodle(){

     var token = localStorage.getItem("token");
     let currentProject = JSON.parse(localStorage.getItem("currentProject"));
     //console.log("Current data get",this.$store.state.currentCamera)
     var projectid = currentProject.id; 
     var date = this.visitdata.visit_date;

     // const inputDate = "2025-01-24";
      const outputDate = this.convertDateFormat(date);
      console.log(outputDate); // Output: "24-01-2025"

      window.open("https://3da.opticvyu.com/index.html?token="+token+"&pid="+projectid+"&type=3dgs&date="+outputDate);

    },
    gotoOrthophoto(){
      this.$router.push('/dashboard/drone-map');
    },
     convertDateFormat(dateString) {
    // Split the input date string
    const [year, month, day] = dateString.split("-");

    // Return the date in dd-mm-yyyy format
    return `${day}-${month}-${year}`;
  },
  }
};
</script>

<style>
.ttile {
  position: relative;
  top: 5px;
  font-size: 18px;
  font-weight: bold;
  padding-left: 15px;
}

.mapite.min {
  position: fixed !important;
  width: 150px;
  z-index: 200;
  height: 150px;
  top: 10px;
  left: 10px;
  transition: 0.5s;
}

.mapite.min:hover {
  top: 0px;
  left: 0px;
  opacity: 1;
  width: 700px;
  z-index: 200;
  height: 100vh;
  transition: 0.5s;
}

.mainViewMedia.item {
  position: fixed;
  z-index: 1;
  top: 52px;
  left: 3px;
}

.mainViewMedia.item.mobile {
  position: fixed;
  z-index: 1;
  top: 60px;
  left: 17px;
}

.mediatypees.active {
  /* background: #000 !important;
    color: #fff;
    border: 2px solid #fff;
    box-sizing: border-box; */
  font-weight: 900;
}

.fullviewbox.fifty {
  width: 50%;
  right: 0;
  left: 50%;
  background: #ffffff;
}

.mapite.min.fifty {
  width: 50%;
  left: 0;
  height: 100%;
  top: 0;
}

.mediatypees strong {
  position: relative;
  right: -10px;
  border: 1px solid;
  display: inline;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 19px;
}

.info-items-user-view {
  position: absolute;
  left: 10px;
  top: 50px;
  z-index: 1;
}

span.info_title_user_view {
  color: #ffc30e;
  background: #5f5f5fb3;
  font-weight: 600;
  font-size: 31px;
  padding: 3px 6px;
}

span.info_description_user_view {
  color: #fff;
  background: #5f5f5fb3;
  font-size: 14px;
  display: block;
  padding: 3px 6px;
}

.downloadButton {
  background: #444444b0;

}

.videostylez {
  margin: auto;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.right-next-image {
  right: 16px;
}

.vue-map-container {
  position: absolute !important;
}

.mapite.min {
  position: fixed !important;
  width: 150px;
  z-index: 200;
  height: 150px;
  top: 10px;
  left: 10px;
  transition: 0.5s;
}

.addUrlIconSpanMainView i {
  font-size: 16px !important;
  color: #fec306 !important;
}

.addUrlIconSpanMainView {
  position: absolute;
  z-index: 1;
  top: -2px;
  left: 1px;
}

vue-zoomer.zoomercustome {
  background-color: #141414;
}

.buttonPosition {
  position: absolute;
}

.btnView {
  z-index: 999;
  display: block;
}

.shareVideo {
  top: 24px;
  right: 86px;
}
.infoboxclass {
  z-index: 9999999
}

.checboxbutton {
    z-index: 999;
    float: right;
    top: 26px;
    right: 180px;
    position: fixed !important;
    background: #ffc107;
    border-radius: 50%;
}

.listbutton {
    z-index: 999;
    float: right;
    top: 26px;
    right: 133px;
    position: fixed !important;
    background: #ffc107;
    border-radius: 50%;
}
/* 
  When the parent div has class 'hide-map-controls',
  hide all the default Google Maps UI controls.
*/

.hide-map-controls .gm-fullscreen-control,
.hide-map-controls .gm-style-mtc{
  display: none !important;
}

.buttonClass {
  position: absolute;
  right: 1px;
}


.mapsMainView .gmnoprint.gm-style-mtc-bbw {
  right: 65px !important;  
  left: auto !important;  
  top: 0px !important;  
  position: absolute !important;
}

.mapsMainView .gm-control-active.gm-fullscreen-control{
  position: absolute !important;
  right: 24px !important;
  top: 0px !important;
}
.mapsMainView gmp-internal-camera-control{
  display: none !important;
}

.mapsMainView .gm-svpc{
  display: none !important;
}

/* .white-border-checkbox .v-input--selection-controls__ripple::before {
  border-color: #ffc403 !important;
} */

.white-border-checkbox .v-icon {
  color: #ffc403 !important;
}

/* .white-border-checkbox .v-input--selection-controls__input {
  border: 2px solid #ffc403 !important;
} */

.mapCheckIcon{
  position: absolute;
    top: 12px;
    right: 32px;
}
</style>
