<template>
    <nav>
            <Notificaton/>
        <v-app-bar app dense class="headercolor">
            <v-app-bar-nav-icon v-if="nofreeaccess" class="whitercolor" @click="drawer = !drawer" :title="lang.Projects"></v-app-bar-nav-icon>
            <v-toolbar-title class="white--text logolink">
                <span class="font-weight-bold shado"><img class="logo" @click="setrouter('/dashboard/virtual-tour')" :src="logo" /></span>
            </v-toolbar-title>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="projectname white--text">{{ pname }}</span>
                </template>
                <span>{{ currentProject.company }}</span>
            </v-tooltip>
                <v-spacer></v-spacer>
    
             <!-- <div :class="`mobilemenu ${showmobilemenu ? 'showmenu' : 'hidemenu'}`">

            </div> -->
              <!-- <v-btn class="secondcolor black-text mobileview" @click="mobilemenus" title="Menu" dark v-bind="size" ><span>{{lang.Menu}}</span><v-icon dark right> mdi-arrow-down-drop-circle </v-icon>
              </v-btn> -->
            <v-menu :disabled="manutoggle" v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="secondcolor black-text" dark v-bind="attrs" v-on="on">
                        <span>{{lang.Options}}</span>
                         <v-icon dark right>
                            mdi-cog-outline
                        </v-icon>
                    </v-btn>
                </template>
    
                <v-card class="mysettings">
                    <v-list>
                        <v-list-item>
                            <v-list-item-avatar color="amber accent-3">
                                <span class="white--text headline">{{ userdata.firstname[0] }}{{ userdata.lastname[0] }}</span>
                            </v-list-item-avatar>
    
                            <v-list-item-content>
                                <v-list-item-title>{{ userdata.firstname }} {{ userdata.lastname }}</v-list-item-title>
                                <v-list-item-subtitle>{{ userdata.user_code == 2 ?  lang.SuperAdmin : userdata.user_code == 1 ? lang.User: lang.Admin }}</v-list-item-subtitle>
                            </v-list-item-content>
    
                            <v-list-item-action v-if = "!openAccessFeature">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="#000" dark v-bind="attrs" v-on="on" @click="logout()">
                                            mdi-logout
                                        </v-icon>
                                    </template>
                                    <span>{{lang.Logout}} </span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>
    
                    </v-list>
    
                    <v-divider></v-divider>
                     
                    <!-- <v-list dense>
                  
                        <v-list-item-group color="amber accent-3">
                            <v-list-item :color="'/dashboard/geo/settings/change-password' == activelink ? activecolor: 'grey darken-3'" @click="setrouter('/dashboard/geo/settings/change-password')"  v-if = "!openAccessFeature">
                                <v-list-item-icon>
                                    <v-icon>mdi-lock-reset</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{lang.UpdatePassword }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                    v-if="useraccesssuperadmin && !openAccessFeature"
                    :color="
                      '/dashboard/geo/settings/profile' == activelink
                        ? activecolor
                        : 'grey darken-3'
                    "
                    @click="setrouter('/dashboard/geo/settings/profile')"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-account-box</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ lang.Profile }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                            <v-list-item v-if="useraccess" :color="'/dashboard/geo/settings/adduser' == activelink ? activecolor: 'grey darken-3'" @click="setrouter('/dashboard/geo/settings/adduser')">
                                <v-list-item-icon>
                                    <v-icon>mdi-account-plus</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{lang.AddUser}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="nofreeaccess && !openAccessFeature" :color="'/dashboard/geo/settings/manage-user' == activelink ? activecolor: 'grey darken-3'" @click="setrouter('/dashboard/geo/settings/manage-user')">
                                <v-list-item-icon>
                                    <v-icon>mdi-account-group</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{lang.Users}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
    
                                 <v-list-item
                    v-if="useraccesssuperadmin"
                    :color="
                      '/dashboard/geo/settings/user-logs' == activelink
                        ? activecolor
                        : 'grey darken-3'
                    "
                    @click="setrouter('/dashboard/geo/settings/user-logs')"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-target-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title> {{lang.UserLogs}} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="setrouter('/dashboard/geo/settings/security/two-factor-auth-setup')" v-if="!openAccessFeature">
                        <v-list-item-icon>
                        <v-icon>mdi-security</v-icon>
                        </v-list-item-icon>
                            <v-list-item-content>
                            <v-list-item-title> {{lang.TwoFactorAuthentication}}</v-list-item-title>
                            </v-list-item-content>
                  </v-list-item>
    
                            
    
                            <v-list-item @click="support">
                                <v-list-item-icon>
                                    <v-icon>mdi-face-agent</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title> {{lang.Support}} </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list> -->
                    <Languages/>
                </v-card>
            </v-menu>
        </v-app-bar>
    
         <div :class="`popupnavebar ${fullpopup ? 'fullpopup' : '' }`"  v-if="activelink == '/dashboard/virtual-tour' && drawer">
            <v-row>
                  <v-col cols="12" class="pt-0 pb-0"> 
                    <span class="font-weight-bold shado logopopup"
                    ><img class="logo" :src="logo"
                    /></span> 
                   <v-text-field small v-model="searchtext" hide-details="auto" class="searchboxfull" autocomplete="off"  :label="lang.SearchProjects"  color="success" :loading='searchloader' @keyup="filterObjects" @keydown="filterObjects" height="25">
                   <v-icon class="clier" @click="clearSearch()">mdi-window-close</v-icon>
                    
                   </v-text-field>
    
                     <v-btn
                      class="mr-4"
                      color="mylightbutton"
                      @click="setrouter('/dashboard/geo/settings/global-dashboard')"
                    >
                      <span class="desktop">{{ lang.MapView }}</span>
                      <v-icon right>mdi-map-marker-radius</v-icon>
                      
                    </v-btn>
                 
                    </v-col> 
                    <v-btn fab x-small color="#fff" class="closeDrower" @click="closeDrower">
                        <v-icon dark>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <v-col v-for="item in items" :key="item.id" :class="`projectitem ${item.id == currentProject.id ? 'selected':''}`"  cols="12"
               xs="12"
               sm="6"
               md="6"
               lg="3"
               xl="3">
                        <div :class="`proimagebox ${item.const_camera == 1 && user_cont_camera_aceess ? 'enable' : 'disable'}`" @click="selectConstruction(item)">
                        <img :src="item.thumbnail_name == 'null' ? project_image : item.thumbnail_name" class="project_thumbnail lighten-2"/>
                            <div class="menuprojectname">
                                {{item.project_name}}
    
                            </div>
                        </div>
                            <div class="menufooter">
                                 <span  title="Construction Camera">
                                <v-btn class="mx-2" @click="selectConstruction(item)" fab x-small color="#fff" :title="lang.ConstructionCamera" :disabled="item.const_camera == 1 && user_cont_camera_aceess ? false : true">
                                    <v-icon dark>
                                        mdi-cctv
                                    </v-icon>
                                </v-btn>
                                  </span>
    
                                <span  title="Interior Monitoring">
                                <v-btn class="mx-2" @click="selectInterior(item)" fab x-small color="#fff" :title="lang.InteriorMonitoring" :disabled="item.interior == 1 && user_interior_access ? false : true">
                                    <v-icon dark>
                                    
                                         mdi-axis-z-rotate-clockwise
                                    </v-icon>
                                </v-btn>
                                </span>
                                <span  title="Material Tracking">
                                <v-btn class="mx-2" @click="selectMaterial(item)" fab x-small color="#fff" :title="lang.MaterialTracking" :disabled="item.material == 1 && user_material_access ? false : true">
                                    <v-icon dark>
                                            mdi-dump-truck
                                    </v-icon>
                                </v-btn>
                                 </span>
    
                                <!-- <span  title="Drone Monitoring">
                                <v-btn class="mx-2" @click="selectDrone(item)" fab x-small color="#fff" :title="lang.DroneMonitoring" :disabled="item.drone == 1 && user_drone_access ? false : true">
                                    <v-icon dark>
                                        mdi-quadcopter
                                    </v-icon>
                                </v-btn>
                                </span> -->
    
                                 <span  title="Construction Drone">
                                <v-btn class="mx-2" @click="selectLinear(item)" fab x-small color="#fff" title="Construction Drone" :disabled="item.linear_monitoring == 1 && user_linear_access ? false : true">
                                    <v-icon dark>
                                        mdi-quadcopter
                                    </v-icon>
                                </v-btn>
                                </span>
                                <span title="Virtual Tour">
                                <v-btn class="mx-2" @click="selectVirtualTour(item)" fab x-small color="#fff" title="Virtual Tour" :disabled="item.virtual_tour == 1 && user_virtual_tour_access ? false : true">
                                    <v-icon dark>
                                        mdi-camera-control
                                    </v-icon>
                                </v-btn>
                                </span> 
    
                            </div>
    
                        
                    </v-col>
                </v-row>
      </div>
    
         <v-navigation-drawer v-model="drawer" app temporary width:300  v-if="activelink !== '/dashboard/virtual-tour'">
               <v-list-item @change="setrouter('/dashboard/geo/settings/global-dashboard')">
                <template>
                    <v-list-item-content>
                        <v-list-item-title> {{lang.InteractiveDashboard}} </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                        <v-icon>mdi-earth</v-icon>
                    </v-list-item-icon>
                </template>
            </v-list-item>
            <v-text-field v-model="searchtext"  class="searchProject searchbox" autocomplete="off"  :label="lang.SearchProjects" outlined  color="success" :loading='searchloader' @keyup="filterObjects"  @keydown="filterObjects"></v-text-field>
             <!-- <v-icon class="clier" @click="clearSearch()">mdi-window-close</v-icon> -->
            <v-list  v-for="item in items"
            :key="item.project_name"
           
             >
          <v-list-group v-if="item.interior == 1 || item.material == 1 || item.drone == 1 || item.linear_monitoring == 1" no-action  color="#f8bb03">
            <template v-slot:activator >
              <v-list-item-content>
                <v-list-item-title v-text="item.project_name"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item  v-bind:class="[isActive ? activeClass : '']"  @change="selectConstruction(item)"
              v-if="item.const_camera == 1 && user_cont_camera_aceess"
            >
              <v-list-item-content >
                <v-list-item-title text="Construction"> {{lang.ConstructionCamera}} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item  active-class="actiaveitem"  v-if="item.interior == 1 && user_interior_access" @change="selectInterior(item)">
              <v-list-item-content>
                <v-list-item-title text="Interiors"> {{lang.InteriorMonitoring}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
               <!-- <v-list-item  active-class="actiaveitem" v-if="item.drone == 1 && user_drone_access"  @change="selectDrone(item)">
              <v-list-item-content>
                <v-list-item-title text="Interiors"> {{lang.DroneMonitoring }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item  active-class="actiaveitem"  v-if="item.material == 1 && user_material_access" @change="selectMaterial(item)">
              <v-list-item-content>
                <v-list-item-title text="Material Tracking"> {{lang.MaterialTracking }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
    
            <v-list-item  active-class="actiaveitem"  v-if="item.linear_monitoring == 1 && user_linear_access" @change="selectLinear(item)">
              <v-list-item-content>
                <v-list-item-title text="Construction Drone"> Construction Drone </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item  active-class="actiaveitem"  v-if="item.virtual_tour == 1 && user_virtual_tour_access" @click="selectVirtualTour(item)">
              <v-list-item-content>
                <v-list-item-title text="Virtual Tour"> Virtual Tour </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
    
          </v-list-group>
          <v-list-item  active-class="actiaveitem"  v-if="item.interior == 0 && item.material == 0 && item.drone == 0 && item.const_camera == 1 && item.linear_monitoring == 0 && user_cont_camera_aceess"  @change="selectConstruction(item)"> 
           <template >
              <v-list-item-content>
                <v-list-item-title v-text="item.project_name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>  
        </v-list>
        </v-navigation-drawer>
    </nav>
    </template>
    
    <script>
    import axios from 'axios'
    import logo from "../../assets/ov.png";
    import rawadhalogo from "../../assets/rawadh.png";
    import lisuallogo from "../../assets/lisual.png";
    
    import headerNotificaton from './headerNotification';
    import Languages from "./LanguageSelecter"
    import { locals } from "../../utils/locals";
    import tatalogo from "../../assets/tatasmall.png";
    import lntlogo from "../../assets/lnt.png";
    import smlogo from "../../assets/sm.png";
    import lodhalogo from "../../assets/lodha.png";
    import adanilogo from "../../assets/Adani_logo.png";
    import globaltimelapselogo from "../../assets/globaltimelapse.png"; 
    import tbox from "../../assets/Tbox-logo-white-nowords.jpg"; 
    import protbox from "../../assets/Tbox-logo-white-nowords.jpg"; 
    import equalbaselogo from "../../assets/equalbasedev_logo.jpeg";
    export default {
            components: {
               Notificaton : headerNotificaton,
               Languages,
            },
        data: () => ({
            drawer: null,
            links: [{
                    icon: 'home',
                    text: 'Homes',
                    route: '/'
                },
                {
                    icon: 'contacts',
                    text: 'About',
                    route: '/dashboard/about'
                },
            ],
            userdata: {},
            items: [],
            zones: {},
            currentProject: '',
            currentCamere: '',
            fav: true,
            menu: false,
            message: false,
            hints: true,
            activelink: '/dashboard',
            activecolor: 'amber accent-3',
            dialogm1: '',
            current_cam: '',
            manutoggle: false,
            useraccess: false,
            useraccesssuperadmin: false,
            nofreeaccess:false,
            camid: 0,
            selectedItem: 0,
            logop: logo,
            rawadhalogo: rawadhalogo,
            lisuallogo: lisuallogo,
            tatalogo:tatalogo,
        lntlogo:lntlogo,
        smlogo : smlogo,
        lodhalogo: lodhalogo,
        globaltimelapselogo:globaltimelapselogo,
        equalbaselogo: equalbaselogo,
        tbox : tbox,
        protbox:protbox,
        adanilogo:adanilogo,
            logo : '',
            searchtext: '',
            projectSearchdata: {},
            searchloader: false,
            apdata: '',
            acdata: '',
            ap: '',
            ac: '',
            i: 0,
            pname: '',
            billingaccess: false,
            procoreshare: true,
            showurls: true,
            procer_project: '',
            procore_url: '/dashboard/settings/integration/procore_connection',
            isActive : false,
            zoneid : 0,
            showmenus : true,
            currentDrawing : 'Select Drawing',
            useraccsess : false,
            interior_editor_access : false,
            user_material_access:false,
            user_interior_access:false,
            user_linear_access : false,
            linear_edit_access : false,
            showmobilemenu : false,
            projid : '',
            zonename : '',
            activelinkname : '',
             Local :locals,  
            lang : {}, 
            fullpopup : false,
            openAccessFeature : false 
        }),
        watch: {
            '$store.state.currentvisitId': function () {
    
               // this.current_cam = this.$store.state.currentCamera
                this.ProjectName();
            },
            '$route': function (to) {
                 this.activelink = this.$route.path;
                  this.activelinkname = to.name;
                // console.log("Router change", to)
                if (to.name == 'changepassword' || to.name == 'adduser' || to.name == 'schedulemail' || to.name == 'cameranotification' || to.name == 'camerainfo' || to.name == 'customizetimelapse' || to.name == 'bim_rendering') {
                    this.manutoggle = true;
                    this.menu = false;
    
                } else {
                    this.manutoggle = false;
                }
    
                if (to.name == 'changepassword' || to.name == 'adduser' || to.name == 'schedulemail' || to.name == 'cameranotification' || to.name == 'camerainfo' || to.name == 'customizetimelapse' || to.name == 'bim_rendering' || to.name == 'procore_connection' || to.name == 'invoices' || to.name == 'global-dashboard' || to.name == 'geo_global-dashboard' || to.name == 'bim_rendering' || to.name == 'manageuser') {
                    this.showurls = false;
                } else {
                    this.showurls = true;
                }
    
                 if (to.name == 'interior_viewedit') {
                    this.showmenus = false;
                } else {
                    this.showmenus = true;
                }
                if(to.name == 'interior_userinteriorview'){
                    this.useraccsess = true;
                }else{
                    this.useraccsess = false;
                }
    
                 if(this.user_linear_access == false){
                   //console.log("checking in ==>",this.user_interior_access);
                //   console.log(" user_cont_camera_aceess checking in redirect --->",this.user_cont_camera_aceess);
                this.$router.push('/')
                  
            }
              this.userlogs(); 
            },
            '$store.state.currentDrawing': function () {
                this.currentDrawing = this.$store.state.currentDrawing;
                console.log(this.currentDrawing);
                this.userlogs();
            }
        },
        computed: {
            //isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
        shouldBeVisible() {
          return this.$route.name === 'geo_360_image_compare' || this.$route.name === 'geo_drone_image_compare' || this.$route.name === 'geo_drone_video_compare';
        },
        },
    
        created() {
              
    
                 var fullpopup =  localStorage.getItem('ppopup');
    
           console.log("fullpopup", fullpopup);
    
            if(fullpopup  == 'true'){
              this.drawer = true;
              this.fullpopup = true;
              localStorage.setItem('ppopup', false);
            }
    
            var chenal_partner = window.location.hostname;
            if(chenal_partner == "tpl.opticvyu.com"){
              this.drawer = false;
              console.log("checking");
              this.fullpopup = false;
            }else{
    
            if(fullpopup == 'true'){
              this.drawer = true;
              this.fullpopup = true;
            }else{
                 this.drawer = false;
            }
           }
    
                 var cl = this.$cookies.get('currentLanguage');
                console.log("currentLanguage nav", cl)
                if (cl === null) {
                    this.$cookies.set("currentLanguage", 'English', -1);
                       
                }else{
                    this.currentLang = cl;
                }
                
             //this.lang = locals['English'];
             
             var selectleng = this.$cookies.get('currentLanguage');
    
           this.lang = locals[selectleng];
    
               if(this.$route.name == 'interior_userinteriorview'){
                    this.useraccsess = true;
                }else{
                    this.useraccsess = false;
                }
    
             if (this.$route.name == 'interior_viewedit' || this.$route.name == 'interior_userinteriorview') {
                    this.showmenus = false;
                } else {
                    this.showmenus = true;
                }
                
            var p = new URL(location.href).searchParams.get('p');
    
            this.ap = p;
    
            var c = new URL(location.href).searchParams.get('c');
    
            this.ac = c;
    
            var indexOf = new URL(location.href).searchParams.get('i');
    
            this.i = indexOf;
    
            //  console.log(">>>>>>>>",this.ap);
            //  console.log(">>>>>>>>",this.i);
    
            this.userdata = JSON.parse(localStorage.getItem('userdata'));
    
            //console.log(this.userdata);
    
            if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
                this.useraccess = true
            }
            if (this.userdata.user_code == 2) {
                this.useraccesssuperadmin = true
            }
    
            if (this.userdata.billing_access == 1) {
                this.billingaccess = true
            }
            if (this.userdata.free_access == 0) {
                this.nofreeaccess = true
            }
             if (this.userdata.interior_editor_access == 1) {
                this.interior_editor_access = true
            }
             if (this.userdata.interior_access == 1) {
                this.user_interior_access = true
            }
            if (this.userdata.material_access == 1) {
                this.user_material_access = true
            }
              if (this.userdata.drone_access == 1) {
                this.user_drone_access = true
            }
            if (this.userdata.cont_camera_aceess == 1) {
                this.user_cont_camera_aceess = true
            }
             if (this.userdata.linear_monitoring_access == 1) {
                this.user_linear_access = true
            }
             if (this.userdata.linear_monitoring_edit == 1) {
                this.linear_edit_access = true
            }
            if (this.userdata.open_access == 1) {
                this.openAccessFeature = true;
            }
            if(this.userdata.virtual_tour_access == 1){
          this.user_virtual_tour_access = true;
          }
              console.log("checking",this.user_material_access);
    
            if(this.user_linear_access !== true){
                
                  this.$router.push('/')
                   console.log("checking in linear_edit_access",this.user_linear_access);
            }
    
            this.getPorjects();
            this.currentRouteName();
    
            var pop = localStorage.getItem('procer_project');
    
            //console.log("pop", pop);
            if (pop > 0) {
                this.procore_url = "/dashboard/settings/integration/procore_update";
            }
              document.body.addEventListener('keyup', e => {
                if (e.keyCode === 27) {
                    this.drawer = false;
                }
            })
        },
        methods: {
            userlogs(){
                 this.userdata = JSON.parse(localStorage.getItem('userdata'));
                var logdata = {
                    "user_name" : this.userdata.firstname+' '+this.userdata.lastname,
                    "user_id" : this.userdata.id,
                    "user_type" : this.userdata.user_code,
                     "opticvyu_user" : this.userdata.opticvyu_user,
                    "visit_page" : this.$route.meta.title,
                    "project_id" : this.projid,
                    "project_type" : "Geo Monitoring",
                    "cam_id" : '',
                    "zone_name" : '',
                    "drawing_name" : '',
                    "project_name" : this.pname
                 }
                   axios({
                        url: "/userlog/"+this.projid+"/add_record",
                        data: logdata,
                        method: "post",
                    })
                    .then((resp) => {
                        console.log("user logs added--------------------->", resp);
                    })
                    .catch((err) => {
                        console.log("user log error",err);
                      
                    });
              },
    
            ProjectName() {
                this.pname = this.currentProject.project_name.substr(0, 15);
                
                this.projid = this.currentProject.id;
                //localStorage.setItem('pc_token', this.currentProject.procore_token);
    
                var chenal_partner = this.currentProject.channel_partner;
                if(chenal_partner == "rawadh"){
               this.logo =  this.rawadhalogo;
           }else if(chenal_partner == "lisual"){
            this.logo =  this.lisuallogo;
           }else if(chenal_partner == "tata"){
            this.logo =  this.tatalogo;
           }else if(chenal_partner == "lnt"){
            this.logo =  this.lntlogo;
           }else if(chenal_partner == "spacematrix"){
            this.logo =  this.smlogo;
           }else if(chenal_partner == "lodha"){
            this.logo =  this.lodhalogo;
           }else if(chenal_partner == "opticvyu"){
            this.logo =  this.logop;
           }else if(chenal_partner == "adani"){
            this.logo =  this.adanilogo;
            }else if(chenal_partner == "globaltimelapse"){
            this.logo =  this.globaltimelapselogo;
           }else if (chenal_partner == "equalbase") {
            this.logo = this.equalbaselogo;
            this.lgclas = "equalbase";
           } else if(chenal_partner == "tbox_shot_singapore"){
            this.logo =  this.tbox;
           } else if(chenal_partner == "tbox"){
            this.logo =  this.protbox;
           }
            else{
            this.logo =  this.logop;
           }
    
                if (this.currentProject.procore_proj_id) {
                    this.procoreshare = false;
                }
            },
            setrouter: function (url) {
               this.showmobilemenu = false; 
                if (this.$route.path !== url) {
                    this.$router.push(url)
                    //console.log("current router ", this.$route.path);
                    this.activelink = this.$route.path;
                }
    
                //console.log(url);
            },
            logout: function () {
                //console.log("logout butten pressed");
                this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push('/login')
                    })
            },
            getPorjects: function () {
                axios({
                        url: '/project',
                        method: 'GET'
                    })
                    .then(resp => {
                        //console.log("Projects", resp.data.success);
                        localStorage.setItem('procer_project', 0);
                        this.items = resp.data.success.filter(function (item) {
    
                            if (item.procore_status == 1) {
    
                                localStorage.setItem('procer_project', item.id);
    
                            }
    
                            return item.project_active == 1;
                        });
    
                        this.projectSearchdata = resp.data.success.filter(function (item) {
                            return item.project_active == 1;
                        });
    
                        if (this.ap) {
                            var v = this;
                            this.apdata = resp.data.success.filter(function (item) {
                                return item.id == v.ap;
    
                            });
    
                            // console.log(" this.apdata ",  this.apdata );
    
                            this.initialSetup(this.apdata[0]);
    
                            this.ProjectName();
    
                        } else {
    
                            if (this.items.length > 0) {
                                let currentProject = JSON.parse(localStorage.getItem('currentProject'));
                                //console.log("currentProject",currentProject);
                                if (currentProject == null) {
                                    this.initialSetup(this.items[0]);
    
                                    this.ProjectName();
    
                                } else {
                                    // console.log("Project Already Set")
                                    this.initialSetup(currentProject);
                                    this.ProjectName();
                                }
                            }
                        }
                if(this.items.length == 1){
                this.drawer = false;
                }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
    
            filterObjects() {
                var filter = this.searchtext.toLowerCase();
                var filtered = {};
                this.searchloader = true;
                /// console.log(projectSearchdata);
                var keys = Object.keys(this.projectSearchdata);
                for (var i = 0; i < keys.length; i++) {
                    var key = keys[i];
    
                    var object = this.projectSearchdata[key];
    
                    var objectAsString = JSON.stringify(object).toLowerCase();
                    //   console.log(objectAsString);
                    if (key.toLowerCase().indexOf(filter) > -1 || objectAsString.indexOf(filter) > -1) {
                        filtered[key] = object;
                    }
    
                }
    
                // console.log(filtered); 
                this.items = filtered;
                this.searchloader = false;
                if (filter == '') {
                    this.items = this.projectSearchdata;
                }
            },
            clearSearch(){
                this.searchtext = ''
                this.items = this.projectSearchdata;
            },
            selectConstruction: function (projectdata) {
    
                if (this.ap) {
                    window.location.href = "/dashboard"
                }
    
                
                
                if(this.$store.state.currentModule == "material" || this.$store.state.currentModule == "interior" || this.$store.state.currentModule == "drone" || this.$store.state.currentModule == "geo" || this.$store.state.currentModule == "vtour") {
                  this.$store.dispatch('curentModule','construction_camera')
                  window.location.href = "/dashboard"
                }
                
    
               console.log('curentModule',this.$store.state.currentModule);
    
                localStorage.setItem('currentCamIndex', 0)
                this.camid = 0;
                localStorage.setItem('currentProject', JSON.stringify(projectdata))
                
                this.getCurrentProjectData('construction_camera')
            },
            selectMaterial: function (projectdata) {
    
                localStorage.setItem('currentProject', JSON.stringify(projectdata))
    
    
                  if(this.$store.state.currentModule == "construction_camera" || this.$store.state.currentModule == "interior" || this.$store.state.currentModule == "drone" || this.$store.state.currentModule == "geo" || this.$store.state.currentModule == "vtour") {
                    this.$store.dispatch('curentModule','material')
                    window.location.href = "/dashboard/material/inventories"
                }
                 console.log('curentModule',this.$store.state.currentModule);
    
                this.getCurrentProjectData('material');
                this.setrouter('/dashboard/material/inventories'); 
            },
            selectDrone: function (projectdata) {
    
                localStorage.setItem('currentProject', JSON.stringify(projectdata))
                 if(this.$store.state.currentModule == "construction_camera" || this.$store.state.currentModule == "material" || this.$store.state.currentModule == "interior" || this.$store.state.currentModule == "geo" || this.$store.state.currentModule == "vtour") {
                      this.$store.dispatch('curentModule','drone')
                    window.location.href = "/dashboard/user-drone-view"
                }
                console.log('curentModule',this.$store.state.currentModule);
                 this.$store.dispatch('curentModule','drone')
                this.getCurrentProjectData('drone')
                this.setrouter('/dashboard/user-drone-view');
     
            },
    
            selectInterior: function (projectdata) {
    
                localStorage.setItem('currentProject', JSON.stringify(projectdata))
                 if(this.$store.state.currentModule == "construction_camera" || this.$store.state.currentModule == "material" || this.$store.state.currentModule == "drone" || this.$store.state.currentModule == "geo" || this.$store.state.currentModule == "vtour") {
                      this.$store.dispatch('curentModule','interior')
                    // window.location.href = "/dashboard/user-interior-view"
                    if (this.userdata.open_access == 1) {
                    window.location.href = "/dashboard/user-interior-view-open-access";
                    }else{
                    window.location.href = "/dashboard/user-interior-view";
                    }
                }
                console.log('curentModule',this.$store.state.currentModule);
                 this.$store.dispatch('curentModule','interior')
                this.getCurrentProjectData('interior')
                // this.setrouter('/dashboard/user-interior-view');
                if (this.userdata.open_access == 1) {
                this.setrouter("/dashboard/user-interior-view-open-access");
                }else{
                this.setrouter("/dashboard/user-interior-view");
                }
      
            },
           selectLinear : function (projectdata) {
    
                localStorage.setItem('currentProject', JSON.stringify(projectdata))
                 if(this.$store.state.currentModule == "construction_camera" || this.$store.state.currentModule == "material" || this.$store.state.currentModule == "drone") {
                      this.$store.dispatch('curentModule','geo')
                    window.location.href = "/dashboard/gps-map-view"
                }
                console.log('curentModule',this.$store.state.currentModule);
                 this.$store.dispatch('curentModule','geo')
                this.getCurrentProjectData('geo')
                this.setrouter('/dashboard/gps-map-view');
     
            },     
            selectVirtualTour : function (projectdata) {

        localStorage.setItem('currentProject', JSON.stringify(projectdata))
        if(this.$store.state.currentModule == "construction_camera" || this.$store.state.currentModule == "material" || this.$store.state.currentModule == "drone" || this.$store.state.currentModule == "interior") {
              this.$store.dispatch('curentModule','vtour')
            window.location.href = "/dashboard/virtual-tour";
        }
        // console.log('curentModule',this.$store.state.currentModule);
        this.$store.dispatch('curentModule','vtour')
        this.getCurrentProjectData('vtour')
        this.setrouter('/dashboard/virtual-tour');

        },      
            getCurrentProjectData(init) { // Moduletype variable store value of marial, interior or constructuion cameras.
                let currentProject = JSON.parse(localStorage.getItem('currentProject'))
                this.currentProject = currentProject;
                // console.log(this.currentProject);
                  var fullpopup =  localStorage.getItem('ppopup');
                 
                  var chenal_partner = window.location.hostname;
            if(chenal_partner == "tpl.opticvyu.com"){
              this.drawer = false;
              console.log("checking");
              this.fullpopup = false;
            }else{
    
                 if(init == 'init' && fullpopup == 'true'){
               this.drawer = true;
           }else{
             this.drawer = false;
             this.fullpopup = false;
             localStorage.setItem('ppopup', false);
             }
           }
           
                this.zones = {}
                axios({
                        url: '/project/'+currentProject.id+'/gps/get_visit_date/',
                        
                        method: 'GET'
                    })
                    .then(resp => {
    
                        this.preloader = false;
                        this.zones = resp.data.success;
    
                       if(this.zones.length == 0){
                               this.selectZone(0);
    
                         localStorage.setItem('currentvisitD','');  
                        }else{
                            this.zones.forEach(element => {
                                // console.log("element",element.orthophoto_count);
                                const parts = element.visit_date.split('-');
                                const year = parts[0];
                                const month = parseInt(parts[1], 10);
                                // Function to add leading zero for single digits
                                const padWithZero = (number) => number.toString().padStart(2, '0');
    
                                const day = padWithZero(parseInt(parts[2], 10));
    
                                const months = [
                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                ];
    
                                const monthName = months[month - 1];
                                var formattedDate = '';
                                if(element.orthophoto_count > 0){
                                     formattedDate = `${day}-${monthName}-${year}  - 3D`;
                                }else {
                                    formattedDate = `${day}-${monthName}-${year}`;
                                }
                               
                                element.visit_date = formattedDate;
                            });
                            console.log(this.zones, "zones");
                         //console.log("sss", resp.data.success[0]);
                         this.selectZone(resp.data.success[0].id);
    
                         localStorage.setItem('currentvisitD', JSON.stringify(resp.data.success[0]))         
    
                         this.zoneid = resp.data.success[0].id;
                         this.zonename = resp.data.success[0].visit_date;
                           
                        }
                    
                    })
                    .catch(err => {
                        console.log(err);
                        this.preloader = false;
                    })
            },
            selectZone(event) {
                    this.$store.dispatch('setVistId', event)
                    .then(() =>
                         
                         this.storeVisitData(event)
                    )
                    .catch(err => console.log(err))
            },
            storeVisitData(id){
                axios({
                        url: '/project/'+ this.currentProject.id+'/gps/get_visit/'+id,
                        method: 'GET'
                    })
                    .then(resp => {
                       //  console.log("visit data resp",resp);
                         if(resp.data.success){
                          localStorage.setItem('currentvisitD', JSON.stringify(resp.data.success));         
                         }else{
                           localStorage.setItem('currentvisitD', JSON.stringify({}));         
                             
                         }
                    })
                    .catch(err => {
                        console.log("Error in current visit data set", err);
                    })
            },
            initialSetup(initialidata) {
                //  console.log("initialidata", initialidata);
                localStorage.setItem('currentProject', JSON.stringify(initialidata))
                this.getCurrentProjectData('init')
            },
            setCamera(data) {
                this.$store.dispatch('setCamera', data)
                    .then(() =>
                        console.log("camera set data")
                    )
                    .catch(err => console.log(err))
            },
            currentRouteName() {
                // console.log("current router ", this.$route.path);
                this.activelink = this.$route.path;
    
            },
            support: function () {
                window.open(
                    'https://www.opticvyu.com/contact-us',
                    '_blank'
                );
    
            },
             dateFormate(d) {
    
                var n = new Date(d).toLocaleString('en-GB', {
                    timeZone: 'Asia/Kolkata'
                });
    
                return n;
            },
            downloadReport(){
                 this.$store.dispatch('openReport', true)
            },
            openSelectDrawing(){
                 this.$store.dispatch('openDrawing', true)
            },
             closeDrower(){
                this.drawer = false;
               this.fullpopup = false;
               localStorage.setItem('ppopup', false);
            },
              mobilemenus(){
           this.showmobilemenu = !this.showmobilemenu;
            },
            // callDialogEnableFunction(){
            //     this.$root.$refs.openDroneCompareVideoDialog.enableCompareDialog(); 
                 
            // },
    
        }
    }
    </script>
    
    <style>
    .v-application--is-ltr .v-list-group--no-action>.v-list-group__items>.v-list-item {
        padding-left: 42px !important;
    }
    
    .v-list.v-sheet.theme--light {
        padding: 0;
    }
    
    .projectname {
        font-size: 18px !important;
        padding-left: 16px;
        top: 2px;
        position: relative;
    }
    
    .cameraselect {
        max-width: 150px;
        top: 1px;
        left: 21px;
        display: inline-block;
        padding: 0px 8px;
        position: relative;
        color: #929292;
        font-weight: 300;
    }
    
    .theme--light.v-sheet {
        background: #fff;
    }
    
    .zonedropdown {
        top: 15px;
        left: 15px;
        max-width: 156px;
    }
    
    .zonedropdown .v-input__slot {
        min-height: 30px !important;
    }
    
    span.font-weight-bold.shado {
        text-shadow: -2px 4px 4px black;
    }
    
    .logo {
        width: 35px;
        position: relative;
        top: 3px;
    }
    
    .logolink {
        cursor: pointer;
    }
    
    .navmenu_item .v-icon--left {
        margin-left: 0px;
        margin-right: 1px;
    }
    
    .navmenu_item {
        padding: 0 3px !important;
    }
    .searchbox .v-text-field__details{
      display: none;
    }
    .proimagebox.disable {
        pointer-events: none;
    }
    @media screen and (max-width: 1200px) {
        .v-toolbar__content .v-size--small {
            padding: 0 5px !important;
        }
    
        .v-toolbar__content .v-btn.v-size--small {
            font-size: 0.70rem;
        }
    }
    /* SCROLL STYLE FOR V-SELECT */
    .selectScroll::-webkit-scrollbar {
            width: 10px;
        }
    
        .selectScroll::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
        }
    
        .selectScroll::-webkit-scrollbar-thumb {
            background-color: #ffc403;
        }
    
        .selectScroll::-webkit-scrollbar-thumb:hover {
            background-color: #ffc403;
        }
    </style>
    