<template>
  <div>
    <Preloader v-if="preloader" />
    <v-row>
      <v-col cols="12" md="12" v-if="!showadd">
        <div class="topbr">
          <v-btn
            class="ma-2 add newmodel"
            small
            v-if="userdata.user_code == 2"
            @click="addNewModel"
            color="mylightbutton"
          >
            Add New Model
          </v-btn>
          <!-- <v-btn
            class="ma-2 add newmodelzone"
            small
            v-if="userdata.user_code == 2"
            @click="connectWithZone"
            color="mylightbutton"
          >
            Add New Model in {{ currentZoneName }}
          </v-btn>
          <v-btn
            class="ma-2 add connectWithCordinate"
            small
            v-if="userdata.user_code == 2 && docloaded"
            @click="connectWithCordinate"
            color="mylightbutton"
          >
            Connect With Coordinates
          </v-btn> -->
        </div>
        <v-form
          ref="uploadDrawing"
          v-model="valid"
          lazy-validation
          @submit.prevent="submitDrawingform"
        >
          <div class="fileuploadZone" v-if="NoHaveDocId">
            <h3>Upload Source File to OSS</h3>
            <div class="uploadx">
              <v-file-input
                truncate-length="41"
                :rules="requerdRules"
                v-model="files"
                placeholder="Select zip file to upload"
                @change="handleFileUpload()"
                accept=".zip,.rvt,.nwd"
              ></v-file-input>

              <p>
                Note: Only .rvt, .nwd & .zip file (.zip with iam file) are
                supported.
              </p>
              <v-btn
                class="mr-4"
                type="submit"
                :disabled="uploadOn"
                color="mylightbutton"
              >
                Upload
              </v-btn>
            </div>
          </div>
        </v-form>
        <!-- <forge-vuer @modelLoaded="handleProgressUpdated" v-if="docloaded" @documentLoadError="documentLoadError" :getAccessToken="myGetTokenMethodAsync" :urn="myObjectUrn" /> -->
        <div id="forgeViewer" v-if="docloaded"></div>
        <div class="loaderInfo" v-if="uploadingStart">
          <v-progress-circular
            :size="50"
            color="amber"
            indeterminate
          ></v-progress-circular>
          <h4>{{ progree }}</h4>
          <p class="mgss">{{ msg }}</p>
        </div>
      </v-col>
      <v-col cols="12" md="12" v-if="showadd">
        <div class="msgbox">
          <p>
            You can integrate BIM model for comparing site images with BIM 3D
            model. Contact
            <a href="mailto:admin@opticvyu.com">admin@opticvyu.com </a> for more
            information
          </p>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="alert" top right timeout="3000">
      {{ alertmessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="alert = false">
          {{ lang.Close }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
//import ForgeVuer from 'forge-vuer';

import axios from "axios";
import Preloader from "../../components/PreLoader";
//import $ from 'jquery'
 import * as THREE from 'three';
import { locals } from "../../utils/locals";

export default {
  components: {
    Preloader
    // ForgeVuer
  },
  name: "autodesk",

  data() {
    return {
      preloader: false,
      myToken: "",
      myObjectUrn: "",
      docloaded: false,
      NoHaveDocId: true,
      uploadingStart: false,
      files: [],
      valid: false,
      Local: locals,
      lang: {},
      progree: 0,
      msg: "File Uploading...",
      projectid: 0,
      requerdRules: [v => !!v || this.lang.required],
      uploadOn: true,
      currentProject: {},
      userdata: {},
      alertmessage: "",
      alert: false,
      autodesk_viewer: 0,
      showadd: true,
      viewer: "",
      currentZoneName: "Zone Name"
    };
  },
  mounted() {
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.open_access == 1) {
      this.$router.push("/dashboard");
    }
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.autodesk_viewer = this.currentProject.autodesk_viewer;
    this.projectid = this.currentProject.id;
    // if(this.currentProject.autodesk_urn){
    //            console.log("mounted test");
    // }
  },
  created() {
    console.log("df");
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.autodesk_viewer = this.currentProject.autodesk_viewer;
    this.projectid = this.currentProject.id;
    this.currentZoneName = localStorage.getItem("currentZoneName");
    this.getToken();
  },
  watch: {
    "$store.state.currentCamera": function() {
      this.getToken();
    },
    "$store.state.currentZone": function() {
      // console.log("Current data get",this.$store.state.currentCamera)
      this.zoneid = this.$store.state.currentZone;
      this.currentZoneName = localStorage.getItem("currentZoneName");
      console.log("current zone", this.zoneid);
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.autodesk_viewer = this.currentProject.autodesk_viewer;
      this.projectid = this.currentProject.id;
      if (this.currentProject.autodesk_urn) {
        console.log("mounted test");
      }
    }
  },
  methods: {
    validate() {
      this.$refs.uploadDrawing.validate();
    },
    documentLoadError(eventData) {
      console.log(`Progress: ${eventData}`);
      this.docloaded = false;
      this.NoHaveDocId = true;
    },
    handleProgressUpdated: function() {
      console.log("Progress");
    },
    getToken() {
      console.log("first", this.autodesk_viewer);
      //  var self = this;

      if (this.autodesk_viewer == 1) {
        this.showadd = false;

        var _self = this;

        axios({
          url: "/interior/autodesk-token",
          method: "post"
        })
          .then(resp => {
            this.myToken = resp.data.access_token;
            console.log("token", this.myToken);

            if (this.currentProject.autodesk_urn) {
              this.docloaded = true;
              this.NoHaveDocId = false;
              this.myObjectUrn = this.currentProject.autodesk_urn;
            }

            const Autodesk = window.Autodesk;
            // var viewer;
            var options = {
              env: "AutodeskProduction2",
              api: "streamingV2", // for models uploaded to EMEA change this option to 'streamingV2_EU'
              getAccessToken: function(onTokenReady) {
                var token = resp.data.access_token;
                var timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
                onTokenReady(token, timeInSeconds);
              }
            };

            console.log("options", options);

            Autodesk.Viewing.Initializer(options, function() {
              var htmlDiv = document.getElementById("forgeViewer");
              _self.viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
              var startedCode = _self.viewer.start();
              if (startedCode > 0) {
                console.error(
                  "Failed to create a Viewer: WebGL not supported."
                );
                return;
              }

              console.log("Initialization complete, loading a model next...");

              var documentId = "urn:" + _self.currentProject.autodesk_urn;
              console.log("documentId", documentId);
              Autodesk.Viewing.Document.load(
                documentId,
                onDocumentLoadSuccess,
                onDocumentLoadFailure
              );

              function onDocumentLoadSuccess(viewerDocument) {
                var defaultModel = viewerDocument
                  .getRoot()
                  .getDefaultGeometry(); //viewerDocument.getRoot().getDefaultGeometry();
                //(viewableId ? doc.getRoot().findByGuid(viewableId) : doc.getRoot().getDefaultGeometry())
                console.log("defaultModel", defaultModel);

                _self.viewer.loadDocumentNode(viewerDocument, defaultModel);
                _self.viewer.addEventListener(
                  Autodesk.Viewing.SELECTION_CHANGED_EVENT
                );
                setTimeout(() => {
                                  _self.setPosition();
                                 }, 2000);
                setTimeout(() => {
                                    _self.onDocumentLoadS();
                                 }, 10000);
              }

              function onDocumentLoadFailure() {
                console.error("Failed fetching Forge manifest");
              }
            });
          })
          .catch(err => {
            console.log(err);
            this.alert = true;
            this.alertmessage = this.lang.SomethingWentWrong;
            this.alertType = "error";
            this.preloader = false;
          });
      } else {
        this.showadd = true;
      }
    },
    onDocumentLoadS() {
            var df =  this.viewer.setActiveNavigationTool('bimwalk');
                                 console.log("df",df);
        },
        setPosition(){
           var autodesk_position =  this.current_cam.o_position;
           //var autodesk_target =  this.current_cam.o_target;
               
           var _self = this;
            console.log("autodesk_position", autodesk_position);
           
           if(autodesk_position){

            var ap = autodesk_position.split(",");

// Create a new camera object with the saved information

 var position = new THREE.Vector3(parseFloat(ap[0]),parseFloat(ap[1]),parseFloat(ap[2]));
 var target = new THREE.Vector3(parseFloat(ap[3]),parseFloat(ap[4]),parseFloat(ap[5]));
 var up = new THREE.Vector3(parseFloat(ap[6]),parseFloat(ap[7]),parseFloat(ap[8]));
//up: new THREE.Vector3(parseInt(ap[6]),parseInt(ap[7]),parseInt(ap[8]))
// console.log("savedCamera", savedCamera);
// Set the camera view to the saved camera information
 //this.viewer.navigation.setView(savedCamera);
 //this.viewer.navigation.setCamera(savedCamera);
            // this.viewer.utilities.goHome();
               
               setTimeout(function () {
                  
                _self.alert = true;
                _self.alertmessage = 'Click "First Person" view for model alignment if needed.';

                _self.viewer.navigation.setView(position, target,up);

                 console.log("s--");
            //  var ap = autodesk_position.split(",");
            //  var at = autodesk_target.split(",");

            //   console.log(ap, at);

                  // var position = new THREE.Vector3(ap[0],ap[1],ap[2]); // Example camera position
                  //              var target = new THREE.Vector3(at[0],at[1],at[2]); // Example camera target
                  //              _self.viewer.navigation.setView(position, target);  
           
                }, 2000);
              
              }
         },
    // getviwerid(){

    //          console.log("viewableId ==",this.viewer.navigation.getCamera());

    //        var cam = this.viewer.navigation.getCamera();

    //           const position =  cam.position;
    //           const target =  cam.target;

    //       console.log(position, target);

    //      var proid = this.currentProject.id;

    //       var  p = position.x+","+position.y+","+position.z;
    //       var  t = target.x+","+target.y+","+target.z;

    //       var data = {
    //           position : p,
    //           target : t
    //       };

    //       var url = "/update-autodesk-position/"+proid;
    //     axios({ url: url , data: data, method: "POST" })
    // .then((resp) => {
    //        console.log("Project autodesk position update",resp);
    //           this.preloader =  false;
    //         this.alert = true;
    //         this.alertmessage = "Auto desk position set successfully";
    //         this.getCurrentProject();
    //         // location.reload(true);
    //  }) .catch((err) => {
    //     console.log(err);
    //      this.preloader =  false;
    //  });

    // },
    myGetTokenMethodAsync: async function(onSuccess) {
      // console.log("second");
      // An API call to retrieve a valid token should be
      // done here. A backend service might need to be implemented.

      // For testing purposes, a valid token can be hardcoded but will
      // last a maximum of 1 hour (3600 seconds.)
      console.log("i am working..");
      let token = this.myToken;
      let expireTimeSeconds = 3599;
      onSuccess(token, expireTimeSeconds);
    },
    onDocumentLoadSuccess(viewerDocument) {
      console.log(
        "viewerDocument is an instance of Autodesk.Viewing.Document",
        viewerDocument
      );
    },
    onDocumentLoadFailure() {
      console.error("Failed fetching Forge manifest");
    },
    handleFileUpload() {
      // this.file = this.files[0];
      console.log("file", this.files);
      this.uploadOn = false;

      this.createBucket();
    },
    createBucket() {
      let bucketname = this.currentProject.pro_dir.toLowerCase() + "bucker";
      var data = {
        bucketKey: bucketname,
        access: "full",
        policyKey: "transient"
      };

      const config = {
        headers: {
          Authorization: "Bearer " + this.myToken
        }
      };

      axios
        .post("https://developer.api.autodesk.com/oss/v2/buckets", data, config)
        .then(resp => {
          console.log(resp);
        })
        .catch(err => {
          console.log(err);
          // this.alert = true;
          //this.alertmessage = this.lang.SomethingWentWrong;
          // this.alertType = "error"
          //this.preloader = false
          //this.uploadingStart = false
        });
    },
    submitDrawingform() {
      this.uploadingStart = true;

      var _self = this;

      const config = {
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(percentCompleted);

          _self.progree = percentCompleted + "%";
        },
        headers: {
          Authorization: "Bearer " + _self.myToken
        }
      };
      // let bucketname = this.currentProject.pro_dir.toLowerCase();

      let bucketname = this.currentProject.pro_dir.toLowerCase() + "buckercv";
      axios
        .put(
          "https://developer.api.autodesk.com/oss/v2/buckets/" +
            bucketname +
            "/objects/" +
            this.files.name,
          this.files,
          config
        )
        .then(resp => {
          console.log("upload response", resp);
          this.fileTranslation(resp);
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
          this.uploadingStart = false;
        });
    },
    fileTranslation(resp) {
      var encodedStr = btoa(resp.data.objectId);
      console.log("encodedStr", encodedStr);
      var p = resp.data.objectKey;

      console.log("file type", p);

      var rootFilename = p;

      var data = {};

      if (p.includes(".zip")) {
        rootFilename = p.replace(".zip", ".iam");

        console.log("rootFilename 1", rootFilename);

        data = {
          input: {
            urn: encodedStr,
            rootFilename: rootFilename,
            compressedUrn: true
          },
          output: {
            destination: {
              region: "us"
            },
            formats: [
              {
                type: "svf2",
                views: ["2d", "3d"]
              }
            ]
          }
        };
      } else if (p.includes(".rvt")) {
        // rootFilename =  p.replace('.rvt', '.iam');

        data = {
          input: {
            urn: encodedStr
          },
          output: {
            destination: {
              region: "us"
            },
            formats: [
              {
                type: "svf",
                views: ["2d"]
              }
            ]
          }
        };

        console.log("rootFilename 2", rootFilename);
      } else if (p.includes(".nwd")) {
        // rootFilename =  p.replace('.rvt', '.iam');

        data = {
          input: {
            urn: encodedStr
          },
          output: {
            destination: {
              region: "us"
            },
            formats: [
              {
                type: "svf",
                views: ["2d"]
              }
            ]
          }
        };

        console.log("rootFilename 2", rootFilename);
      }

      // console.log("rootFilename 3",rootFilename);

      console.log("tvdata", data);
      axios
        .post(
          "https://developer.api.autodesk.com/modelderivative/v2/designdata/job",
          data,
          {
            headers: {
              Authorization: "Bearer " + this.myToken,
              "Content-Type": "application/json"
            }
          }
        )
        .then(resp => {
          console.log("Tranlation response", resp);

          console.log(resp.data.urn);
          this.myObjectUrn = resp.data.urn;
          this.checkProgressStatus(resp.data.urn);
          this.storeUrnTokenInProject(resp.data.urn);
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
          this.uploadingStart = false;
        });
    },
    storeUrnTokenInProject(urn) {
      console.log("Store token in db is pandding.");

      var data = {
        urn: urn,
        pid: this.projectid
      };

      axios
        .post("/interior/autodesk-token-update/" + this.projectid, data)
        .then(resp => {
          console.log("urn Token update in DB", resp);
          this.getCurrentProject();
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
          this.uploadingStart = false;
        });
    },
    checkProgressStatus(token) {
      var _self = this;
      //console.log("Store token in db is pandding.");

      axios
        .get(
          "https://developer.api.autodesk.com/modelderivative/v2/designdata/" +
            token +
            "/manifest",
          {
            headers: {
              Authorization: "Bearer " + this.myToken
            }
          }
        )
        .then(resp => {
          console.log("Translation Status", resp);

          if (resp.data.progress == "complete") {
            this.progree = resp.data.progress;
            this.msg = "File Translation is " + resp.data.status;

            setTimeout(() => {
              _self.docloaded = true;
              _self.NoHaveDocId = false;
              _self.uploadingStart = false;
            }, 5000);
          } else {
            this.progree = resp.data.progress;
            (this.msg = "File Translation is " + resp.data.status),
              setTimeout(() => {
                _self.checkProgressStatus(token);
              }, 5000);
          }
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
          this.uploadingStart = false;
        });
    },
    loadModel() {
      console.log("Model loading start.");
    },
    addNewModel() {
      var a = confirm(
        "Are you sure to upload the new model? The existing model will be replaced by the new model after successful uploading."
      );

      if (a) {
        this.docloaded = false;
        this.NoHaveDocId = true;
        this.deleteBucket();
      }
    },
    deleteBucket() {
      let bucketname = this.currentProject.pro_dir.toLowerCase() + "bucker";
      axios
        .delete(
          "https://developer.api.autodesk.com/oss/v2/buckets/" + bucketname,
          {
            headers: {
              Authorization: "Bearer " + this.myToken
            }
          }
        )
        .then(resp => {
          console.log("Bucket Status", resp);
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
        });
    },
    getCurrentProject() {
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      var projid = currentProject.id;

      axios({
        url: "/projec/" + projid,
        method: "get"
      })
        .then(resp => {
          console.log("project update date", resp.data);
          // this.initialSetup(resp.data);
          localStorage.setItem("currentProject", JSON.stringify(resp.data));

          this.$router.go();
        })
        .catch(err => {
          console.log("project update date", err);
        });
    },
    viewerInitialized(cc) {
      console.log("viewerInitialized:", cc);
    },
    connectWithCordinate() {
      console.log("Add Coordinate");

      this.$router.push({ name: "interior_biminteriorview" });
    },
    connectWithZone() {
      this.$router.push({ name: "interior_autodeskZone" });
    }
  }
};
</script>

<style>
.forge-vuer-container {
  width: 100%;
  height: 100%;
  position: absolute !important;
  /* display: block; */
  /* box-sizing: border-box; */
}

.fileuploadZone {
  width: 50%;
  height: 73vh;
  background: #fff;
  box-shadow: 0 0 20px 2px #ccc;
  text-align: center;
  margin: auto;
  position: relative;
  top: 5%;
  border-radius: 5px;
  padding: 15px;
}

.uploadx {
  display: block;
  position: relative;
  top: 33%;
}

.newmodel {
  position: relative;
  /* left: 5px;
    top: 10px; */
  z-index: 9;
}
.newmodelzone {
  position: relative;
  /* left: 170px;
    top: 10px; */
  z-index: 9;
}
.connectWithCordinate {
  position: relative;
  /* left: px;
    top: 10px; */
  z-index: 9;
}
.msgbox {
  width: 600px;
  text-align: center;
  position: relative;
  margin: auto;
  background: #ccc;
  padding: 50px;
  top: 100px;
  border-radius: 5px;
  font-size: 24px;
}
.topbr {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
