<template>
  <div>
    <Preloader v-if="preloader" />
    <div class="megaMenus" :class="drawingToggle ? 'opendrawer' : 'closedrawer'">
      <v-row>
        <v-btn fab x-small color="#fff" class="closeDrower" @click="closeMegamenu">
          <v-icon dark>
            mdi-close
          </v-icon>
        </v-btn>
        <v-col v-for="n in drawings" :key="n.drawingid"
          :class="`d-flex child-flex drawingitem mega ${n.drawingid == activeDrawing.drawingid ? 'selected' : ''}`"
          cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
          <v-img :src="n.thumbnail" aspect-ratio="1.5" class="black lighten-2" @click="goToDrawing(n)">
            <div class="drawingname">
              {{ n.name }}
              <span class="progrssss">( {{ n.progress }}%)</span>
            </div>
            <div class="drawingname dfooter">
              <v-btn class="mx-2" fab x-small color="#fff">
                <v-icon dark>
                  mdi-axis-z-rotate-clockwise
                </v-icon>
              </v-btn>
              {{ n.numberofrotet }}
              <v-btn class="mx-2" fab x-small color="#fff">
                <v-icon dark>
                  mdi-camera
                </v-icon>
              </v-btn>
              {{ n.numberofimages }}
              <v-btn class="mx-2" fab x-small color="#fff">
                <v-icon dark>
                  mdi-play
                </v-icon>
              </v-btn>
              {{ n.numberofvideos }}
            </div>
          </v-img>
        </v-col>
      </v-row>
    </div>
    <v-row>
    <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2"  class="datepicker rangePicker">
    <!-- <ejs-daterangepicker
            :change="onValueChange"
            placeholder="Select Range"
          >
          </ejs-daterangepicker> -->

          <date-range-picker
          @update="onValueChange"
          :ranges = "false"
          opens="left"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
    v-model="chartdate"
    :date-format="dateFormat"
  ></date-range-picker>

        </v-col>
      </v-row>
    <v-row class="topChartsMargin">
      <v-col cols="12" md="6">
        <v-card>
          <apexchart type="donut" v-if="showcharts" height="380" :options="snagStatusChartOptions"
            :series="snagStatusSeries"></apexchart>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <apexchart type="bar" v-if="showcharts" height="370" :options="barchartOptions" :series="barseries"></apexchart>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="bottomChartsMargin">
      <v-col cols="12" md="6">
        <v-card>
          <apexchart type="donut" v-if="showcharts" height="380" :options="pendingSnagStatusChartOptions"
            :series="pendingSnagStatusSeries"></apexchart>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <apexchart type="donut" v-if="showcharts" height="380" :options="completedSnagStatusChartOptions"
            :series="completedSnagStatusSeries"></apexchart>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <apexchart type="bar" v-if="showcharts" height="370" :options="criticalityBarChartOptions" :series="criticalitybarseries"></apexchart>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" md="6">
        <v-card class="snagChartComulative" v-if="showcharts">
          <v-card-title class="cumulativeTitle">CUMULATIVE</v-card-title>
          <v-data-table :headers="headers" :items="snagsTableData" class="elevation-1" :search="search" height="600px">
            <template slot="body.append">
              <tr>
                <td></td>
                <td style="font-weight:bold" class="text-center">Total</td>
                <td style="font-weight:bold" class="text-center">{{  snagsTableData.reduce((acc, item) => acc + item.total_snaglist_count, 0) }}</td>
                <td style="font-weight:bold" class="text-center">{{  snagsTableData.reduce((acc, item) => acc + item.completed_snaglist_count, 0) }}</td>
                <td style="font-weight:bold" class="text-center">{{  snagsTableData.reduce((acc, item) => acc + item.pending_snaglist_count, 0) }}</td>
              </tr>
            </template>
          </v-data-table>
          
        </v-card>
      </v-col>
      <v-col cols="6" md="6">
        <v-card class="snagChartByDate" v-if="showcharts">
          <v-card-title>DATEWISE</v-card-title>
          <v-card-subtitle>Select date range to filter the data below</v-card-subtitle>
          <v-card-subtitle v-if="startDate&&endDate" class="selectDateRange">Selected Date Range</v-card-subtitle>
          <v-card-subtitle v-if="startDate&&endDate" class="selectedDateRange">{{ startDate }} - {{ endDate }}</v-card-subtitle>
          <v-data-table :headers="headers" :items="snagsTableDataByDate" class="elevation-1" :search="search" height="600px">
            <template slot="body.append">
              <tr>
                <td></td>
                <td style="font-weight:bold" class="text-center">Total</td>
                <td style="font-weight:bold" class="text-center">{{  snagsTableDataByDate.reduce((acc, item) => acc + item.total_snaglist_count, 0) }}</td>
                <td style="font-weight:bold" class="text-center">{{  snagsTableDataByDate.reduce((acc, item) => acc + item.completed_snaglist_count, 0) }}</td>
                <td style="font-weight:bold" class="text-center">{{  snagsTableDataByDate.reduce((acc, item) => acc + item.pending_snaglist_count, 0) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import axios from "axios";
import Preloader from "../../components/PreLoader";
import { locals } from "../../utils/locals";
import VueApexCharts from 'vue-apexcharts';
import image1 from '../../assets/e.png';
import $ from 'jquery';
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
  components: {
    Preloader,
    apexchart: VueApexCharts,
    DateRangePicker
  },
  name: "UserLogs",
  data() {
    return {
      preloader: false,
      items: {},
      currentProject: "",
      currentcam: "",
      logsdesserts: [],
      logitem: {},
      Local: locals,
      lang: {},
      snagStatusSeries: [],
      completedSnagStatusSeries: [],
      pendingSnagStatusSeries: [],
      snagStatusChartOptions: {
        chart: {
          width: 380,
          type: 'donut',
        },
        labels: ['Completed Snag', 'Pending Snags'],
        colors: ["#63b598", "#ce7d78"],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        title: {
          text: 'Complete/Pending Snaglist Chart',
          align: 'center',
          offsetX: -50,
          style: {
            color: '#444'
          }
        }
      },
      completedSnagStatusChartOptions: {
        chart: {
          width: 380,
          type: 'donut',
        },
        labels: [],
        colors: ["#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177", "#0d5ac1", "#f205e6", "#1c0365", "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0", "#d2737d", "#c0a43c", "#f2510e", "#651be6", "#79806e", "#61da5e", "#cd2f00", "#9348af", "#01ac53", "#c5a4fb", "#996635", "#b11573", "#4bb473", "#75d89e", "#2f3f94", "#2f7b99", "#da967d", "#34891f", "#b0d87b", "#ca4751", "#7e50a8", "#c4d647", "#e0eeb8", "#11dec1", "#289812", "#566ca0", "#ffdbe1", "#2f1179", "#935b6d", "#916988", "#513d98", "#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d", "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977", "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b", "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf", "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234", "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158", "#fb21a3", "#51aed9", "#5bb32d", "#807fb"],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        title: {
          text: 'Completed Snaglist Chart Displine Wise',
          align: 'center',
          offsetX: -70,
          style: {
            color: '#444'
          }
        }
      },
      pendingSnagStatusChartOptions: {
        chart: {
          width: 380,
          type: 'donut',
        },
        labels: [],
        colors: ["#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177", "#0d5ac1", "#f205e6", "#1c0365", "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0", "#d2737d", "#c0a43c", "#f2510e", "#651be6", "#79806e", "#61da5e", "#cd2f00", "#9348af", "#01ac53", "#c5a4fb", "#996635", "#b11573", "#4bb473", "#75d89e", "#2f3f94", "#2f7b99", "#da967d", "#34891f", "#b0d87b", "#ca4751", "#7e50a8", "#c4d647", "#e0eeb8", "#11dec1", "#289812", "#566ca0", "#ffdbe1", "#2f1179", "#935b6d", "#916988", "#513d98", "#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d", "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977", "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b", "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf", "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234", "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158", "#fb21a3", "#51aed9", "#5bb32d", "#807fb"],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        title: {
          text: 'Pending Snaglist Chart Displine Wise',
          offsetX: -60,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      },
      barseries: [{
        name: "Completed Task",
        data: []
      },
        {name: "Pending Task",
        data: []
      }
    ],
      barchartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
            columnWidth: '50%',
          }
        },
        dataLabels: {
          enabled: true
        },
        colors: ["#63b598", "#ce7d78"],
        xaxis: {
          title: {
            text: 'Weeks'
          },
          categories: ['<1 Week', '1-2 Week', '2-3 Weeks', '3-4 Weeks', '>4 weeks'],
        },
        yaxis: {
          title: {
            text: 'Number Of Tasks'
          }
        },
        title: {
          text: 'Snag Age Analysis',
          floating: true,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      },
      criticalitybarseries: [{
        name: "High",
        data: []
      },
        {name: "Moderate",
        data: []
      },
      {name: "Low",
        data: []
      },
    ],
    criticalityBarChartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
            columnWidth: '50%',
          }
        },
        dataLabels: {
          enabled: true
        },
        xaxis: {
          title: {
            text: 'Severity'
          },
          categories: ['Closed', 'Open'],
        },
        yaxis: {
          title: {
            text: 'Number Of Tasks'
          }
        },
        title: {
          text: 'Criticality Wise Status',
          floating: true,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      },
      completedSnagListCount: "",
      pendingSnagListCount: "",
      showcharts: false,
      getdrawingid: '',
      currentDrawingName: 'Select Drawing', 
      drawingToggle: false, 
      activeDrawing: {},
      cordinates: [], 
      cordinatInfo: false, 
      openCoordinate: {}, x: 0,
      y: 0, 
      xc: 0, 
      yc: 0, 
      xf: 0, 
      yf: 0, 
      colum: false, 
      slides: [], 
      activet: '', 
      fulllImage: true, 
      full: false, 
      imagenormal: '', 
      imagepanaroma: '', 
      pvideo: '',
      radius: 8,
      refresh: 0,
      refresh3: 100,
      latesttrue: false,
      commentbox: false, 
      comment: '', 
      userdata: {}, 
      taskbox: false, 
      search: '', 
      tasks: [], 
      tasktype: {},
      zoom: 1,
      image1: image1,
      drawing: {
        name: 'Select Drawing',
        drawingpath: image1
      },
      reportemails: '',
      screenH: 768,
      ImgHeight: 600,
      imaglossp: 50,
      reportdates: {},
      projectid: 0,
      alertmessage: '',
      alert: false,
      hshow: false,
      allcordinatedata: [],
      coordsh: [],
      allavarage: 0,
      cordinatesOriginal: [],
      noti30: 30,
      noti45: 45,
      noti60: 60,
      notemessage60: "",
      sixtydaysnotificationxx: false,
      sendmail60: "",
      sendmail45: "",
      sendmail30: "",
      thirtydayNotification: false,
      foutyfivednotification: false,
      width: 0,
      timer: true,
      drawings:[],
      zoneid:0,
      picker: new Date().toISOString().substr(0, 10),
      drawingLoadedData : [],
      snagList:[],
      headers: [
        { 
          text: 'S.No', 
          value: 'serialNumber' ,
          align:"center",
          width:"1px",
        },
        {
          text: "Package",
          value: "discipline",
          align:"center",
          width:"1px",
        },
        {
          text: "Raised",
          value: "total_snaglist_count",
          align: "center",
          width:"1px",
        },
        {
          text: "Closed",
          value: "completed_snaglist_count",
          align:"center",
          width:"1px",
        },
        {
          text: "Open",
          value: "pending_snaglist_count",
          align:"center",
          width:"1px",
        }
      ],
      pending_snaglist_count:[],
      completed_snaglist_count:[],
      snagListByDate:[],
      pending_snaglist_count_by_date:[],
      completed_snaglist_count_by_date:[],
      closedHighSnagListCount:"",
      openHighSnagListCount:"",
      closedModerateSnagListCount:"",
      openModerateSnagListCount:"",
      closedLowSnagListCount :"",
      openLowSnagListCount:"",
      startDate:"",
      endDate:"",
      chartdate : {}

    };
  },
  mounted() {
    this.CurrentDateFunction();
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];

    (this.currentProject = JSON.parse(
      localStorage.getItem("currentProject")
    ));
    
    this.userdata = JSON.parse(localStorage.getItem("userdata"));

   
    if (this.userdata.open_access == 1) {
      this.$router.push("/dashboard");
    }
    
    this.getDrawings();
    //this.getCommnets_bydrawing();
  
  },
  watch: {
    '$store.state.currentZone': function () {
      this.zoneid = this.$store.state.currentZone;
      this.sliderActive = false;
      let currentProject = JSON.parse(localStorage.getItem('currentProject'));
      this.projectid = currentProject.id;
      this.proj_dir = currentProject.pro_dir;
      this.project_name = currentProject.project_name;
      this.autodesk_viewer = currentProject.autodesk_viewer;
      this.getDrawings();
      
      this.currentZoneName = localStorage.getItem('currentZoneName');
      this.currentZoneDate = JSON.parse(localStorage.getItem("currentZoneData"));
      this.org = currentProject.company;
      this.city = currentProject.city;
      this.interior = currentProject.interior;

    },
    "$store.state.currentCamera": function () {
      this.currentcam = this.$store.state.currentCamera;
      console.log("Current data get");
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
    },
    '$store.state.drawingToggle': function () {

      this.drawingToggle = this.$store.state.drawingToggle;

    },
  },
  created() {
        this.CurrentDateFunction();
        this.$root.$refs.interiorViewEdit = this;
        console.log("window.innerHeight", window.innerHeight);

        console.log("window.innerWidth", window.innerWidth);

        this.width = window.innerWidth;

        // this.$refs.draggableContainer.addEventListener("wheel",this.handleScroll)
        this.screenH = window.innerHeight;

        //  this.getToken();
        var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng];
        this.currentZoneName = localStorage.getItem('currentZoneName');
        this.currentZoneDate = JSON.parse(localStorage.getItem("currentZoneData"));

        var _self = this;
        setTimeout(() => {
            _self.userdata = JSON.parse(localStorage.getItem('userdata'));
            if (_self.userdata.user_code == 2 || _self.userdata.user_code == 0) {
                _self.useraccess = true;
            }
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            if (currentProject === null) {
                _self.$router.go();
            }
            _self.cam_prefix = _self.$store.state.currentCamera.camera_prefix;
            _self.projectid = currentProject.id;
            _self.proj_dir = currentProject.pro_dir;
            _self.project_name = currentProject.project_name;
            _self.zoneid = _self.$store.state.currentZone;
            _self.autodesk_viewer = currentProject.autodesk_viewer;
            _self.interior = currentProject.interior;
            // _self.initViewer();
            _self.getDrawings();

        }, 2000)

        setTimeout(() => {
            if (_self.width < 750) {
                this.zoom = 0.3;
                _self.$refs.draggableContainer.style.transform = 'scale(0.3)';
                _self.$refs.draggableContainer.style.left = '0px';
                _self.$refs.draggableContainer.style.top = '70px';
            }
        }, 10000)

    },
  computed: {
    snagsTableData() {
      return this.snagList.map((discipline, index) => {
        const completedCount = this.completed_snaglist_count[index] || 0;
        const pendingCount = this.pending_snaglist_count[index] || 0;
        const totalCount = completedCount + pendingCount;
        return {
          serialNumber: index + 1,
          discipline: discipline,
          completed_snaglist_count: completedCount,
          pending_snaglist_count: pendingCount,
          total_snaglist_count: totalCount
        };
      });
    },
    snagsTableDataByDate() {
      return this.snagListByDate.map((discipline, index) => {
        const completedCount = this.completed_snaglist_count_by_date[index] || 0;
        const pendingCount = this.pending_snaglist_count_by_date[index] || 0;
        const totalCount = completedCount + pendingCount;
        return {
          serialNumber: index + 1,
          discipline: discipline,
          completed_snaglist_count: completedCount,
          pending_snaglist_count: pendingCount,
          total_snaglist_count: totalCount
        };
      });
    }
  },
  methods: {
    dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() >= new Date()
        }
        return classes
      },
     
      CurrentDateFunction(){
          var today = new Date();
          today.setDate(today.getDate() - 7);
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();
         var start = mm+'-' +dd+'-'+yyyy;

              var date = new Date();
             
              var edd = String(date.getDate()).padStart(2, '0');
          var emm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
          var eyyyy = date.getFullYear();
          var end = emm+'-'+edd+'-'+eyyyy;

           console.log("startdate,",start, end);

   
            this.chartdate = {
              startDate : start,
               endDate : end
            }

      },
    getCommnets_bydrawing(drawingId) {
      this.preloader = true;
     // var drawingId = localStorage.getItem("openDrawing");
      axios({
        url: '/interior/get-commnent_bydrawingid',
        method: 'get',
        params: {
          drawingid: drawingId
        }
      })
        .then(resp => {
          // console.log("changedData",resp.data.success);
          this.drawing_commentsdata = resp.data.success;
          this.drawingLoadedData = resp.data.success;

          //pie chart data for completed and pending snags
          this.completedSnagListCount = this.drawing_commentsdata.reduce((total, obj) => {
            return obj.comment_status === 1 ? total + 1 : total;
          }, 0);
          this.pendingSnagListCount = this.drawing_commentsdata.reduce((total, obj) => {
            return obj.comment_status === 0 ? total + 1 : total;
          }, 0);
          
          this.snagStatusSeries.length = 0;
          this.snagStatusSeries.push(this.completedSnagListCount);
          this.snagStatusSeries.push(this.pendingSnagListCount);
          const data = resp.data.success;

          const discipline = data.reduce((acc, item) => {
            acc[item.cmt_type] = (acc[item.cmt_type] || 0) + 1;
            return acc;
          }, {});
          const disciplineWiseKeys = Object.keys(discipline);
          this.snagList = disciplineWiseKeys;
          this.snagListByDate = disciplineWiseKeys;

          //pie chart data for pending snags by type
          const pendingSnagFilteredData = data.filter(item => item.comment_status === 0);
          const pendingCountByType = pendingSnagFilteredData.reduce((acc, item) => {
            acc[item.cmt_type] = (acc[item.cmt_type] || 0) + 1;
            return acc;
          }, {});

          const pendingSnagKeys = Object.keys(pendingCountByType);
          const pendingSnagValues = Object.values(pendingCountByType);
          // this.pendingSnagStatusSeries.length = 0;
          this.pendingSnagStatusChartOptions['labels'] = pendingSnagKeys;
          this.pendingSnagStatusSeries = pendingSnagValues;
          this.pending_snaglist_count = pendingSnagValues;
          this.pending_snaglist_count_by_date = pendingSnagValues;
          


          //pie chart data for completed snags by type
          const completedSnagFilteredData = data.filter(item => item.comment_status === 1);
          const completedCountByType = completedSnagFilteredData.reduce((acc, item) => {
            acc[item.cmt_type] = (acc[item.cmt_type] || 0) + 1;
            return acc;
          }, {});

          const completedSnagKeys = Object.keys(completedCountByType);
          const completedSnagvalues = Object.values(completedCountByType);
          this.completedSnagStatusChartOptions['labels'] = completedSnagKeys;
          this.completedSnagStatusSeries = completedSnagvalues;
          this.completed_snaglist_count = completedSnagvalues;
          this.completed_snaglist_count_by_date = completedSnagvalues;
          
          //bar chart data for completed snags by duration
          const counts = { '1': 0, '2': 0, '3': 0, '4': 0, '>4': 0 };
          const completedSnagFilteredDataForAgeAnalysis = data.filter(item => item.comment_status === 1);
            
          completedSnagFilteredDataForAgeAnalysis.forEach(function(item) {
          const extractedUpdateDate = item.update_at.split(' ')[0];
          const extractedCreateDate = item.creat_at.split(' ')[0];
          const startDate = new Date(extractedUpdateDate);
          const endDate = new Date(extractedCreateDate);
          const timeDifference = startDate.getTime() - endDate.getTime();
          const weeksDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));
                console.log(weeksDifference);
            if (weeksDifference <= 1) {
              counts['1'] += 1;
            } else if (weeksDifference > 1 && weeksDifference <= 2) {
              counts['2'] += 1;
            } else if (weeksDifference > 2 && weeksDifference <= 3) {
              counts['3'] += 1;
            } else if (weeksDifference > 3 && weeksDifference <= 4) {
              counts['4'] += 1;
            } else {
              counts['>4'] += 1;
            }
          });

          const weeksData = Object.values(counts);
          this.barseries[0].data = weeksData;

          //bar chart data for pending snags by duration
          const pendingSnagsCount = { '1':0,'2':0,'3':0,'4':0,'>4': 0 };

          const pendingSnagFilteredDataForAgeAnalysis = data.filter(item => item.comment_status === 0);

          pendingSnagFilteredDataForAgeAnalysis.forEach(function(item) {
          const extractedUpdateDate = item.update_at.split(' ')[0];
          const extractedCreateDate = item.creat_at.split(' ')[0];
          const startDate = new Date(extractedUpdateDate);
          const endDate = new Date(extractedCreateDate);
          const timeDifference = startDate.getTime() - endDate.getTime();
          const weeksDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));
                
            if (weeksDifference <= 1) {
                pendingSnagsCount['1'] += 1;
            } else if (weeksDifference > 1 && weeksDifference <= 2) {
              pendingSnagsCount['2'] += 1;
            } else if (weeksDifference > 2 && weeksDifference <= 3) {
              pendingSnagsCount['3'] += 1;
            } else if (weeksDifference > 3 && weeksDifference <= 4) {
              pendingSnagsCount['4'] += 1;
            } else {
              pendingSnagsCount['>4'] += 1;
            }
          });

          const weeksPendingSnagsData = Object.values(pendingSnagsCount);
          this.barseries[1].data = weeksPendingSnagsData;

          //bar chart data for open and closed snags by criticality
          this.closedHighSnagListCount = this.drawing_commentsdata.reduce((total, obj) => {
            return obj.priority === "High" &&  obj.comment_status === 1? total + 1 : total;
          }, 0);
          this.openHighSnagListCount = this.drawing_commentsdata.reduce((total, obj) => {
            return obj.priority === "High" &&  obj.comment_status === 0? total + 1 : total;
          }, 0);

          this.closedModerateSnagListCount = this.drawing_commentsdata.reduce((total, obj) => {
            return obj.priority === "Moderate" &&  obj.comment_status === 1 ? total + 1 : total;
          }, 0);
          this.openModerateSnagListCount = this.drawing_commentsdata.reduce((total, obj) => {
            return obj.priority === "Moderate" &&  obj.comment_status === 0 ? total + 1 : total;
          }, 0);

          this.closedLowSnagListCount = this.drawing_commentsdata.reduce((total, obj) => {
            return obj.priority === "Low" &&  obj.comment_status === 1? total + 1 : total;
          }, 0);
          this.openLowSnagListCount = this.drawing_commentsdata.reduce((total, obj) => {
            return obj.priority === "Low" &&  obj.comment_status === 0? total + 1 : total;
          }, 0);

          this.criticalitybarseries[0].data.length = 0;
          this.criticalitybarseries[1].data.length = 0;
          this.criticalitybarseries[2].data.length = 0;

          this.criticalitybarseries[0].data.push(this.closedHighSnagListCount);
          this.criticalitybarseries[0].data.push(this.openHighSnagListCount);
          this.criticalitybarseries[1].data.push(this.closedModerateSnagListCount);
          this.criticalitybarseries[1].data.push(this.openModerateSnagListCount);
          this.criticalitybarseries[2].data.push(this.closedLowSnagListCount);
          this.criticalitybarseries[2].data.push(this.openLowSnagListCount);

          
          setTimeout(() => {
            this.showcharts = true;
           }, 1000);

           this.preloader = false;

        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = "something went wrong";
          this.alertType = "error"
          this.preloader = false
        })
    },
    goToDrawing(n) {
      this.showcharts = false;
      this.getdrawingid = n.drawingid;
      this.currentDrawingName = n.name;
      this.radius = n.point_size;

      this.activeDrawing = n;

      this.getDrawing(n.drawingid);
      this.getCommnets_bydrawing(n.drawingid);
      this.drawingToggle = false;
      //this.latesttrue = true;
      this.refresh++;
      this.refresh3++;

      this.$store.dispatch('openDrawing', false);
      this.$store.dispatch('drawingname', n.name);

    },
    getDrawing(id) {
      this.preloader = true;
      this.latesttrue = false;
      this.cordinatInfo = false;
      this.commentbox = false;
      this.colum = false;
      this.zoom = 1;
      // this.goPositon(1);
      this.getDrawingReport(id);

      localStorage.setItem("openDrawing", id);
      axios({
        url: '/interior/get-drawingbyid',
        method: 'get',
        params: {
          did: id
        }
      })
        .then(resp => {
          this.refresh3++;
          this.drawing = resp.data.success[0];
          this.reportemails = this.drawing.emails;
          this.radius = this.drawing.point_size;

          var i = new Image();
          var _self = this;
          i.src = '';
          i.onload = function () {
            _self.ImgHeight = i.height;

            if (i.height > _self.screenH) {
              var hh = _self.screenH - 50;
              _self.ImgHeight = hh;
              var loss = i.height - hh;
              var Losspercentage = (loss * 100) / i.height;

              this.imaglossp = Losspercentage;

              _self.getcoordinates(resp.data.success[0].drawingid, Losspercentage);
              _self.getcoordinatesOrg(resp.data.success[0].drawingid);
            } else {
              _self.getcoordinates(resp.data.success[0].drawingid, 0);
              _self.getcoordinatesOrg(resp.data.success[0].drawingid);
            }
          };
          i.src = this.drawing.drawingpath;

          // if (this.userdata.billing_notif_access == 1) {
          //   let currentProject = JSON.parse(localStorage.getItem('currentProject'));
          //   this.zohoInvoices(currentProject.id);
          // }

          this.$store.dispatch('drawingname', resp.data.success[0].name);
          setTimeout(() => {
            if (_self.width < 750) {
              this.zoom = 0.3;
              _self.$refs.draggableContainer.style.transform = 'scale(0.3)';
              _self.$refs.draggableContainer.style.left = '0px';
              _self.$refs.draggableContainer.style.top = '70px';
            }

            if (_self.width >= 700) {
              //_self.radius = 5;
            }
            if (_self.width > 1000) {
              // _self.radius = 3;
            }
          }, 2000)

          setTimeout(() => {

            _self.getDrawingBase64(id);

          }, 10000)

        }).catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error"
          this.preloader = false
        })
    },
    getDrawingReport(id) {
      this.reportdates = [];
      axios({
        url: '/interior/report-date/' + this.projectid,
        method: 'get',
        params: {
          drawingid: id
        }
      })
        .then(resp => {
          this.preloader = false;
          this.reportdates = resp.data.success;
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error"
          this.preloader = false
        })

    },
    getcoordinates(id, pr) {
      this.cordinates = [];
      this.hshow = false;
      axios({
        url: '/interior/getcoordinates',
        method: 'get',
        params: {
          did: id
        }
      })
        .then(resp => {

          this.imaglossp = pr;
          this.allcordinatedata = resp.data;

          var numberofdata = 0;
          var tatal = 0;
          var _self = this;

          this.cordinates = resp.data.filter(function (item) {

            tatal += parseInt(item.progress);
            numberofdata++;

            var lox = item.x - (item.x * pr) / 100;
            item.x = lox;

            // item.xoo = item.x;

            var loy = item.y - (item.y * pr) / 100;
            item.y = loy;

            // item.yoo = item.y;

            if (item.cateone == "360 Videos Walk-through") {

              item.fillcolor = '{"fillColor":"ff3131","strokeColor": "000000", "strokeWidth": 2}';

            } else {
              item.fillcolor = '{"fillColor":"fdc004","strokeColor": "000000", "strokeWidth": 2}';
            }

            if (_self.coordsh.includes(item.coordinateid)) {
              item.fillcolor = '{"fillColor":"fdc004", "strokeColor": "ff3131","strokeWidth": 2}';
            }



            return 1 == 1;

          });

          var avarage = tatal / numberofdata;

          this.allavarage = avarage;

          // console.log("cordinates data", tatal, numberofdata);

          this.updatedrawingProgresss(avarage);

          // this.coorddinates();
          _self.hshow = true;


          setTimeout(() => {
            _self.coorddinates();
            // _self.accorddinateLatestImage();
            $('.map').maphilight({

              strokeWidth: 2,
              stroke: true,
              fillOpacity: 0.9,
              alwaysOn: true
            });
            // _self.preloader = false;

          }, 1000);

        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error"
          this.preloader = false
        })
    },
    getcoordinatesOrg(id) {
      axios({
        url: '/interior/getcoordinates',
        method: 'get',
        params: {
          did: id
        }
      })
        .then(resp => {
          //  console.log("headings", resp);

          this.cordinatesOriginal = resp.data;

        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error"
          this.preloader = false
        })
    },
    getDrawingBase64(id) {
      axios({
        url: '/interior/get-drawingbyidbase64',
        method: 'get',
        params: {
          did: id
        }
      })
        .then(resp => {
          // this.refresh3++;
          // console.log("/get-drawingbyidbase64", resp);
          this.drawing = resp.data.success[0];
        }).catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error"
          this.preloader = false
        })

    },
    updatedrawingProgresss(progress) {

      axios({
        url: '/interior/update-progress-drawing',
        method: 'get',
        params: {
          did: this.getdrawingid,
          progress: progress
        }
      })
        .then(resp => {
          console.log("sssssssssss", resp);
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error"
          this.preloader = false
        })

    },
    coorddinates() {
      var has_VML, has_canvas, create_canvas_for, add_shape_to, clear_canvas, shape_from_area,
        canvas_style, hex_to_decimal, css3color, is_image_loaded, options_from_area;

      has_canvas = !!document.createElement('canvas').getContext;

      // VML: more complex
      has_VML = (function () {
        var a = document.createElement('div');
        a.innerHTML = '<v:shape id="vml_flag1" adj="1" />';
        var b = a.firstChild;
        b.style.behavior = "url(#default#VML)";
        return b ? typeof b.adj == "object" : true;
      })();

      if (!(has_canvas || has_VML)) {
        $.fn.maphilight = function () {
          return this;
        };
        return;
      }

      if (has_canvas) {
        hex_to_decimal = function (hex) {
          return Math.max(0, Math.min(parseInt(hex, 16), 255));
        };
        css3color = function (color, opacity) {
          return 'rgba(' + hex_to_decimal(color.substr(0, 2)) + ',' + hex_to_decimal(color.substr(2, 2)) + ',' + hex_to_decimal(color.substr(4, 2)) + ',' + opacity + ')';
        };
        create_canvas_for = function (img) {
          var c = $('<canvas style="width:' + $(img).width() + 'px;height:' + $(img).height() + 'px;"></canvas>').get(0);
          c.getContext("2d").clearRect(0, 0, $(img).width(), $(img).height());
          return c;
        };
        var draw_shape = function (context, shape, coords, x_shift, y_shift) {
          x_shift = x_shift || 0;
          y_shift = y_shift || 0;

          context.beginPath();
          if (shape == 'rect') {
            // x, y, width, height
            context.rect(coords[0] + x_shift, coords[1] + y_shift, coords[2] - coords[0], coords[3] - coords[1]);
          } else if (shape == 'poly') {
            context.moveTo(coords[0] + x_shift, coords[1] + y_shift);
            for (var i = 2; i < coords.length; i += 2) {
              context.lineTo(coords[i] + x_shift, coords[i + 1] + y_shift);
            }
          } else if (shape == 'circ') {
            // x, y, radius, startAngle, endAngle, anticlockwise
            context.arc(coords[0] + x_shift, coords[1] + y_shift, coords[2], 0, Math.PI * 2, false);
          }
          context.closePath();
        };
        add_shape_to = function (canvas, shape, coords, options) {
          var context = canvas.getContext('2d');

          ///console.log(name,);

          // Because I don't want to worry about setting things back to a base state

          // Shadow has to happen first, since it's on the bottom, and it does some clip /
          // fill operations which would interfere with what comes next.
          if (options.shadow) {
            context.save();
            if (options.shadowPosition == "inside") {
              // Cause the following stroke to only apply to the inside of the path
              draw_shape(context, shape, coords);
              context.clip();
            }

            // Redraw the shape shifted off the canvas massively so we can cast a shadow
            // onto the canvas without having to worry about the stroke or fill (which
            // cannot have 0 opacity or width, since they're what cast the shadow).
            var x_shift = canvas.width * 100;
            var y_shift = canvas.height * 100;
            draw_shape(context, shape, coords, x_shift, y_shift);

            context.shadowOffsetX = options.shadowX - x_shift;
            context.shadowOffsetY = options.shadowY - y_shift;
            context.shadowBlur = options.shadowRadius;
            context.shadowColor = css3color(options.shadowColor, options.shadowOpacity);

            // Now, work out where to cast the shadow from! It looks better if it's cast
            // from a fill when it's an outside shadow or a stroke when it's an interior
            // shadow. Allow the user to override this if they need to.
            var shadowFrom = options.shadowFrom;
            if (!shadowFrom) {
              if (options.shadowPosition == 'outside') {
                shadowFrom = 'fill';
              } else {
                shadowFrom = 'stroke';
              }
            }
            if (shadowFrom == 'stroke') {
              context.strokeStyle = "rgba(0,0,0,1)";
              context.stroke();
            } else if (shadowFrom == 'fill') {
              context.fillStyle = "rgba(0,0,0,1)";
              context.fill();
            }
            context.restore();

            // and now we clean up
            if (options.shadowPosition == "outside") {
              context.save();
              // Clear out the center
              draw_shape(context, shape, coords);
              context.globalCompositeOperation = "destination-out";
              context.fillStyle = "rgba(0,0,0,1);";
              context.fill();
              context.restore();
            }
          }

          context.save();

          draw_shape(context, shape, coords);

          // fill has to come after shadow, otherwise the shadow will be drawn over the fill,
          // which mostly looks weird when the shadow has a high opacity
          if (options.fill) {
            context.fillStyle = css3color(options.fillColor, options.fillOpacity);
            context.fill();
          }
          // Likewise, stroke has to come at the very end, or it'll wind up under bits of the
          // shadow or the shadow-background if it's present.
          if (options.stroke) {
            context.strokeStyle = css3color(options.strokeColor, options.strokeOpacity);
            context.lineWidth = options.strokeWidth;
            context.stroke();
          }

          context.restore();

          if (options.fade) {
            $(canvas).css('opacity', 0).animate({
              opacity: 1
            }, 100);
          }
        };
        clear_canvas = function (canvas) {
          canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
        };
      } else { // ie executes this code
        create_canvas_for = function (img) {
          return $('<var style="zoom:1;overflow:hidden;display:block;width:' + img.width + 'px;height:' + img.height + 'px;"></var>').get(0);
        };
        add_shape_to = function (canvas, shape, coords, options, name) {
          var fill, stroke, opacity, e;
          for (var i in coords) {
            coords[i] = parseInt(coords[i], 10);
          }
          fill = '<v:fill color="#' + options.fillColor + '" opacity="' + (options.fill ? options.fillOpacity : 0) + '" />';
          stroke = (options.stroke ? 'strokeweight="' + options.strokeWidth + '" stroked="t" strokecolor="#' + options.strokeColor + '"' : 'stroked="f"');
          opacity = '<v:stroke opacity="' + options.strokeOpacity + '"/>';
          if (shape == 'rect') {
            e = $('<v:rect name="' + name + '" filled="t" ' + stroke + ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' + coords[0] + 'px;top:' + coords[1] + 'px;width:' + (coords[2] - coords[0]) + 'px;height:' + (coords[3] - coords[1]) + 'px;"></v:rect>');
          } else if (shape == 'poly') {
            e = $('<v:shape name="' + name + '" filled="t" ' + stroke + ' coordorigin="0,0" coordsize="' + canvas.width + ',' + canvas.height + '" path="m ' + coords[0] + ',' + coords[1] + ' l ' + coords.join(',') + ' x e" style="zoom:1;margin:0;padding:0;display:block;position:absolute;top:0px;left:0px;width:' + canvas.width + 'px;height:' + canvas.height + 'px;"></v:shape>');
          } else if (shape == 'circ') {
            e = $('<v:oval name="' + name + '" filled="t" ' + stroke + ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' + (coords[0] - coords[2]) + 'px;top:' + (coords[1] - coords[2]) + 'px;width:' + (coords[2] * 2) + 'px;height:' + (coords[2] * 2) + 'px;"></v:oval>');
          }
          e.get(0).innerHTML = fill + opacity;
          $(canvas).append(e);
        };
        clear_canvas = function (canvas) {
          // jquery1.8 + ie7
          var $html = $("<div>" + canvas.innerHTML + "</div>");
          $html.children('[name=highlighted]').remove();
          $(canvas).html($html.html());
        };
      }

      shape_from_area = function (area) {
        var i, coords,
          shape = (area.getAttribute('shape') || 'rect').toLowerCase().substr(0, 4);
        if (shape === 'defa') {
          // 'default' doesn't really apply to what we're doing; it's the background state
          return;
        }
        coords = (area.getAttribute('coords') || '').split(',');
        for (i = 0; i < coords.length; i++) {
          coords[i] = parseFloat(coords[i]);
        }
        return [shape, coords];
      };

      options_from_area = function (area, options) {
        var $area = $(area);
        return $.extend({}, options, $.metadata ? $area.metadata() : false, $area.data('maphilight'));
      };

      is_image_loaded = function (img) {
        if (!img.complete) {
          return false;
        } // IE
        if (typeof img.naturalWidth != "undefined" && img.naturalWidth === 0) {
          return false;
        } // Others
        return true;
      };

      canvas_style = {
        position: 'absolute',
        left: 0,
        top: 0,
        padding: 0,
        border: 0
      };

      var ie_hax_done = false;
      $.fn.maphilight = function (opts) {
        opts = $.extend({}, $.fn.maphilight.defaults, opts);

        if (!has_canvas && !ie_hax_done) {
          $(window).ready(function () {
            document.namespaces.add("v", "urn:schemas-microsoft-com:vml");
            var style = document.createStyleSheet();
            var shapes = ['shape', 'rect', 'oval', 'circ', 'fill', 'stroke', 'imagedata', 'group', 'textbox'];
            $.each(shapes,
              function () {
                style.addRule('v\\:' + this, "behavior: url(#default#VML); antialias:true");
              }
            );
          });
          ie_hax_done = true;
        }

        return this.each(function () {
          var img, wrap, options, map, canvas, canvas_always, usemap;
          img = $(this);

          //  console.log(highlighted_shape,imgSrc);

          if (!is_image_loaded(this)) {
            // If the image isn't fully loaded, this won't work right.  Try again later.
            return window.setTimeout(function () {
              img.maphilight(opts);
            }, 200);
          }

          options = $.extend({}, opts, $.metadata ? img.metadata() : false, img.data('maphilight'));

          // jQuery bug with Opera, results in full-url#usemap being returned from jQuery's attr.
          // So use raw getAttribute instead.
          usemap = img.get(0).getAttribute('usemap');

          if (!usemap) {
            return;
          }

          map = $('map[name="' + usemap.substr(1) + '"]');

          if (!(img.is('img,input[type="image"]') && usemap && map.length > 0)) {
            return;
          }

          if (img.hasClass('maphilighted')) {
            // We're redrawing an old map, probably to pick up changes to the options.
            // Just clear out all the old stuff.
            var wrapper = img.parent();
            img.insertBefore(wrapper);
            wrapper.remove();
            $(map).unbind('.maphilight');
          }

          //Formating the image source. IE > 9 has issue with new line characters
          // var imgSrc = this.src.replace(/[\n\r]/g, '');
          //  console.log(imgSrc);
          wrap = $('<div></div>').css({
            display: 'block',
            background: 'url("' + this.src + '")',
            "background-size": 'contain',
            "background-repeat": 'no-repeat',
            position: 'relative',
            padding: 0,
            width: this.width,
            height: this.height
          });
          if (options.wrapClass) {
            if (options.wrapClass === true) {
              wrap.addClass($(this).attr('class'));
            } else {
              wrap.addClass(options.wrapClass);
            }
          }
          // Firefox has a bug that prevents tabbing into the image map if
          // we set opacity of the image to 0, but very nearly 0 works!
          img.before(wrap).css('opacity', 0.0000000001).css(canvas_style).remove();
          if (has_VML) {
            img.css('filter', 'Alpha(opacity=0)');
          }
          wrap.append(img);

          canvas = create_canvas_for(this);
          $(canvas).css(canvas_style);
          canvas.height = this.height;
          canvas.width = this.width;

          $(map).bind('alwaysOn.maphilight', function () {
            // Check for areas with alwaysOn set. These are added to a *second* canvas,
            // which will get around flickering during fading.
            if (canvas_always) {
              clear_canvas(canvas_always);
            }
            if (!has_canvas) {
              $(canvas).empty();
            }
            $(map).find('area[coords]').each(function () {
              var shape, area_options;
              area_options = options_from_area(this, options);
              if (area_options.alwaysOn) {
                if (!canvas_always && has_canvas) {
                  canvas_always = create_canvas_for(img[0]);
                  $(canvas_always).css(canvas_style);
                  canvas_always.width = img[0].width;
                  canvas_always.height = img[0].height;
                  img.before(canvas_always);
                }
                area_options.fade = area_options.alwaysOnFade; // alwaysOn shouldn't fade in initially
                shape = shape_from_area(this);
                if (!shape) {
                  return;
                }
                if (has_canvas) {
                  add_shape_to(canvas_always, shape[0], shape[1], area_options, "");
                } else {
                  add_shape_to(canvas, shape[0], shape[1], area_options, "");
                }
              }
            });
          }).trigger('alwaysOn.maphilight')
            .bind('mouseover.maphilight focusin.maphilight', function (e) {
              // console.log(e);
              var shape, area_options, area = e.target;
              area_options = options_from_area(area, options);
              if (!area_options.neverOn && !area_options.alwaysOn) {
                shape = shape_from_area(area);
                if (!shape) {
                  return;
                }
                add_shape_to(canvas, shape[0], shape[1], area_options, "highlighted");
                if (area_options.groupBy) {
                  if (typeof area_options.groupBy == 'string') {
                    area_options.groupBy = [area_options.groupBy];
                  }
                  var el = $(this);
                  $.each(area_options.groupBy, function (index, groupitem) {
                    var areas;
                    // two ways groupBy might work; attribute and selector
                    if (/^[a-zA-Z][a-zA-Z]+$/.test(groupitem)) {
                      areas = map.find('area[' + groupitem + '="' + el.attr(groupitem) + '"]');
                    } else {
                      areas = map.find(groupitem);
                    }
                    var first = this;
                    areas.each(function () {
                      if (this != first) {
                        var subarea_options = options_from_area(this, options);
                        if (!subarea_options.neverOn && !subarea_options.alwaysOn) {
                          var shape = shape_from_area(this);
                          add_shape_to(canvas, shape[0], shape[1], subarea_options, "highlighted");
                        }
                      }
                    });
                  });
                }
                // workaround for IE7, IE8 not rendering the final rectangle in a group
                if (!has_canvas) {
                  $(canvas).append('<v:rect></v:rect>');
                }
              }
            }).bind('mouseout.maphilight focusout.maphilight', function () {
              //console.log(e);
              clear_canvas(canvas);
            });

          img.addClass('maphilighted');
          img.before(canvas); // if we put this after, the mouseover events wouldn't fire.
        });
      };
      $.fn.maphilight.defaults = {
        fill: true,
        fillColor: 'fdc004',
        fillOpacity: 0.2,
        stroke: true,
        strokeColor: '000000',
        strokeOpacity: 1,
        strokeWidth: 1,
        fade: true,
        alwaysOn: true,
        neverOn: false,
        groupBy: false,
        wrapClass: true,
        // plenty of shadow:
        shadow: true,
        shadowX: 0,
        shadowY: 0,
        shadowRadius: 6,
        shadowColor: '000000',
        shadowOpacity: 0.8,
        shadowPosition: 'outside',
        shadowFrom: false
      }
    },
    counter() {
      var self = this;
      // console.log(this.countDown);
      if (this.countDown > 0 && this.timer) {
        setTimeout(() => {
          this.countDown -= 1;
          if (this.countDown == 0) {
            self.sixtydaysnotification = false;
          }
          this.counter();
        }, 1000);
      }
    },
    closeMegamenu() {
      this.$store.dispatch('openDrawing', false)
    },
    getDrawings() {
      this.$store.dispatch('drawingname', 'Loading...');
      var currentProjectDetails = JSON.parse(localStorage.getItem('currentProject'));
      this.projectid = currentProjectDetails.id;

      var zoneData = JSON.parse(localStorage.getItem("currentZoneData"));
      this.zoneid = zoneData.zoneid;
      axios({
        url: '/interior/project/' + this.projectid + '/get-drawings',
        method: 'get',
        params: {
          zone: this.zoneid
        }
      })
        .then(resp => {
          this.drawings = resp.data.success;

          if (resp.data.success.length > 0) {
            // console.log("is In", resp.data.success);
            this.zoneMessage = false;
            // this.getCommnets_bydrawing();
            this.getDrawing(resp.data.success[0].drawingid);
            this.getCommnets_bydrawing(resp.data.success[0].drawingid);
            this.getdrawingid = resp.data.success[0].drawingid;
            // console.log("check drawing id", this.getdrawingid);
          } else {
            this.zoneMessage = false;
          }
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error"
          this.preloader = false
        })
    },
    onValueChange: function(args) {
      // var ss =  args.value.toLocaleDateString();
      if (args.startDate) {
        this.showChart = false;
        // this.preloader = true;

        this.getSelectedDate(args);
      }
    },
    getSelectedDate(args) {

       console.log(args);
         

          var start = new Date(this.chartdate.startDate).toISOString().slice(0, 10);
          var end = new Date(this.chartdate.endDate).toISOString().slice(0, 10);

          this.startDate =start;
          this.endDate = end;
          this.snagStatusSeries.length = 0;
          this.pendingSnagStatusSeries.length = 0;
          this.completedSnagStatusSeries.length = 0;

          const formattedStartDate = start.split('-').map((d) => d.padStart(2, '0')).join('-');
          const formattedEndDate = end.split('-').map((d) => d.padStart(2, '0')).join('-');

          //pie chart data for completed and pending snags by date
          this.completedSnagListCount = this.drawingLoadedData.reduce((total, obj) => {
            var date = this.dateFromatter(obj.completed_time);
            return obj.comment_status === 1 && (date >= formattedStartDate && date <= formattedEndDate) ? total + 1 : total;
          }, 0);

          this.pendingSnagListCount = this.drawingLoadedData.reduce((total, obj) => {
            var date = this.dateFromatter(obj.update_at);
            return obj.comment_status === 0 && (date >= formattedStartDate && date <= formattedEndDate)  ? total + 1 : total;
          }, 0);

          this.snagStatusSeries.push(this.completedSnagListCount);
          this.snagStatusSeries.push(this.pendingSnagListCount);
          const data = this.drawingLoadedData;
          const discipline = data.reduce((acc, item) => {
            acc[item.cmt_type] = (acc[item.cmt_type] || 0) + 1;
            return acc;
          }, {});
          const disciplineWiseKeys = Object.keys(discipline);
          this.snagListByDate = disciplineWiseKeys;
          //pie chart data for pending snags by type and date
          const pendingSnagFilteredData = data.filter(item => item.comment_status === 0);
          console.log(pendingSnagFilteredData);

          const pendingCountByType = pendingSnagFilteredData.reduce((acc, item) => {
            var date = this.dateFromatter(item.update_at);
            acc[item.cmt_type] = (acc[item.cmt_type] || 0) + (date >= formattedStartDate && date <= formattedEndDate ? 1 : 0);
            return acc;
          }, {});
          console.log(pendingCountByType,"pending");
          const pendingSnagKeys = Object.keys(pendingCountByType);
          const pendingSnagValues = Object.values(pendingCountByType);
          this.pendingSnagStatusChartOptions['labels'] = pendingSnagKeys;

          this.pendingSnagStatusSeries = pendingSnagValues;
          this.pending_snaglist_count_by_date = pendingSnagValues;


          //pie chart data for completed snags by type and date
          const completedSnagFilteredData = data.filter(item => item.comment_status === 1);
          console.log(completedSnagFilteredData);
          const completedCountByType = completedSnagFilteredData.reduce((acc, item) => {
            var date = this.dateFromatter(item.completed_time);
            acc[item.cmt_type] = (acc[item.cmt_type] || 0) + (date >= formattedStartDate && date <= formattedEndDate ? 1 : 0);
            return acc;
          }, {});

          const completedSnagKeys = Object.keys(completedCountByType);
          const completedSnagvalues = Object.values(completedCountByType);
          this.completedSnagStatusChartOptions['labels'] = completedSnagKeys;

          this.completedSnagStatusSeries = completedSnagvalues;
          this.completed_snaglist_count_by_date = completedSnagvalues;

          this.closedHighSnagListCount.length = 0,
          this.openHighSnagListCount.length = 0,
          this.closedModerateSnagListCount.length = 0,
          this.openModerateSnagListCount.length = 0,
          this.closedLowSnagListCount.length = 0,
          this.openLowSnagListCount.length = 0,
          //bar chart data for open and closed snags by criticality
          this.closedHighSnagListCount = this.drawingLoadedData.reduce((total, obj) => {
            var date = this.dateFromatter(obj.update_at);
            return obj.priority === "High" &&  obj.comment_status === 1 && (date >= formattedStartDate && date <= formattedEndDate)? total + 1 : total;
          }, 0);
          this.openHighSnagListCount = this.drawingLoadedData.reduce((total, obj) => {
            var date = this.dateFromatter(obj.creat_at);
            return obj.priority === "High" &&  obj.comment_status === 0 && (date >= formattedStartDate && date <= formattedEndDate)? total + 1 : total;
          }, 0);

          this.closedModerateSnagListCount = this.drawingLoadedData.reduce((total, obj) => {
            var date = this.dateFromatter(obj.update_at);
            return obj.priority === "Moderate" &&  obj.comment_status === 1 && (date >= formattedStartDate && date <= formattedEndDate) ? total + 1 : total;
          }, 0);
          this.openModerateSnagListCount = this.drawingLoadedData.reduce((total, obj) => {
            var date = this.dateFromatter(obj.creat_at);
            return obj.priority === "Moderate" &&  obj.comment_status === 0 && (date >= formattedStartDate && date <= formattedEndDate) ? total + 1 : total;
          }, 0);

          this.closedLowSnagListCount = this.drawingLoadedData.reduce((total, obj) => {
            var date = this.dateFromatter(obj.update_at);
            return obj.priority === "Low" &&  obj.comment_status === 1 && (date >= formattedStartDate && date <= formattedEndDate)? total + 1 : total;
          }, 0);
          this.openLowSnagListCount = this.drawingLoadedData.reduce((total, obj) => {
            var date = this.dateFromatter(obj.creat_at);
            return obj.priority === "Low" &&  obj.comment_status === 0 && (date >= formattedStartDate && date <= formattedEndDate)? total + 1 : total;
          }, 0);
          console.log("ondatachabge");
          this.criticalitybarseries[0].data.length = 0;
          this.criticalitybarseries[1].data.length = 0;
          this.criticalitybarseries[2].data.length = 0;

          this.criticalitybarseries[0].data.push(this.closedHighSnagListCount);
          this.criticalitybarseries[0].data.push(this.openHighSnagListCount);
          this.criticalitybarseries[1].data.push(this.closedModerateSnagListCount);
          this.criticalitybarseries[1].data.push(this.openModerateSnagListCount);
          this.criticalitybarseries[2].data.push(this.closedLowSnagListCount);
          this.criticalitybarseries[2].data.push(this.openLowSnagListCount);
          console.log(this.criticalitybarseries,"ondatachabge");
        },
        dateFromatter(updateDate){
          const dateString = updateDate;
          const date = new Date(dateString);
          return date.toISOString().split('T')[0];
        },
        
  }
};
</script>
<style>
.topChartsMargin{
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}
.bottomChartsMargin{
  margin-right: 10px;
  margin-left: 10px;
}
.snagChartComulative{
  margin-top: 20px;
  margin-left: 21px;
  max-height: 700px
}
.snagChartComulative .v-data-footer{
  width:100%;
  position: relative;
}
.snagChartByDate{
  margin-top: 20px;
  margin-right: 21px;
  max-height: 700px
}
.snagChartByDate .v-data-footer{
  width:100%;
  position: relative;
}
.rangePicker{
  margin-left: 81%;
  padding-top: 24px;
}
.selectDateRange{
  position: absolute;
  top: -1px;
  right: 1px;
}
.selectedDateRange{
  position: absolute;
  top: 30px;
  right: 1px;
}
.cumulativeTitle{
  height: 85px;
}
</style>
  