<template>
    <div>
        <Preloader v-if="preloader" />
    
                <div class="dropzone">
                    <div>
                        <vue-dropzone ref="myVueDropzone1" id="drop2" v-on:vdropzone-thumbnail="thumbnail" v-on:vdropzone-sending="sendingEvent" :options="dropOptionspanaroma"></vue-dropzone>
                     </div>
                </div>
    
        <v-snackbar v-model="alert" top right timeout="3000">
            {{ alertmessage }}
    
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="alert = false">
                    {{lang.Close}}
                </v-btn>
            </template>
        </v-snackbar>
        <div class="progress" v-if="showprogrss">
            <v-progress-circular :rotate="-90" :size="100" :width="15" :value="uploadPercentage" color="primary">
                {{ uploadPercentage }}
            </v-progress-circular>
            <v-btn color="red" @click="reloadpage">
                {{lang.Cancel}}
            </v-btn>
        </div>
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import Preloader from '../../components/PreLoader'
    import image1 from '../../assets/e.png'
    import $ from 'jquery'
    import vueDropzone from "vue2-dropzone";
    import exifr from 'exifr';
    import { locals } from "../../utils/locals";
    
    export default {
        components: {
            Preloader,
            vueDropzone
        },
        name: "bulkuploadFile",
        data() {
            return {
                preloader: false,
                requerdRules: [
                    v => !!v || this.lang.required,
                ],
                projectid: 0,
                zoneid: 0,
                image1: image1,
                image: '',
                loafin: false,
                drawings: [],
                cordinates: [],
                xx: 0,
                yy: 0,
                drawing: {
                    name: '',
                    drawingpath: image1
                },
                coordinateForm: false,
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                menu2: false,
                title: '',
                workStatus: '',
                category: '',
                uploadMedia: false,
               
                dropOptionspanaroma: {
                    url: "https://api.opticvyu.com/api/interior/upload-media",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('token')
                    },
                    paramName: "file",
                    maxFilesize: 100, // MB
                    acceptedFiles: ".jpeg,.jpg,.png",
                    maxFiles: 25,
                    chunkSize: 500, // Bytes
                    thumbnailMethod: "contain",
                    thumbnailWidth: 200,
                    thumbnailHeight: 150,
                    autoProcessQueue: false,
                    parallelUploads: 1,
                    addRemoveLinks: true,
                    dictDefaultMessage: "Click to upload Images <br/> ----------------- <br/>Drag & Drop",
                },

                openMedia: {},
                thumbnailimage: [],
                activet: 'normal',
                valid: true,
                send: false,
                coordinateUpdateForm: false,
                updatedate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                menu3: false,
                updatetitle: '',
                updatex: '',
                updatey : '',
                updateworkStatus: 'In Progress',
                updatecategory: 'Normal',
                videoOnly: false,
                alertmessage: '',
                alert: false,
                alertType: '',
                galleryOpen: false,
                normalImages: {},
                fulllImage: false,
                panaromaImages: {},
                videos: {},
                value: 0,
                showprogrss: false,
                interval: 0,
                vidoesss: '',
                uploadPercentage: 0,
                selected: [],
                showdeletebuttons: false,
                bulkupload: false,
                imagesExtract: [],
                picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                changess: [],
                indd: 0,
                review : false,
                updateItems : [],
                Local :locals,  
                lang : {},
            }; 
        },
        mounted() {
    
             var selectleng = this.$cookies.get('currentLanguage');
             this.lang = locals[selectleng]; 
    
            this.userdata = JSON.parse(localStorage.getItem('userdata'));
            if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
                this.useraccess = true
            }
            if (this.userdata.interior_editor_access == 0) {
                this.$router.push('/dashboard/user-interior-view')
            }
            if (this.userdata.open_access == 1) {
                this.$router.push('/dashboard')
            }
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            //console.log("Current data get",this.$store.state.currentCamera)
            this.cam_prefix = this.$store.state.currentCamera.camera_prefix;
            this.projectid = currentProject.id;
            this.prefix = currentProject.pro_dir;
            this.initViewer();
            var _self = this;
            document.body.addEventListener('keyup', e => {
                if (e.keyCode === 27) {
                    _self.fullscreenexit();
                }
            })
        },
        watch: {
            '$store.state.currentZone': function () {
                // console.log("Current data get",this.$store.state.currentCamera)
                this.zoneid = this.$store.state.currentZone;
                let currentProject = JSON.parse(localStorage.getItem('currentProject'));
                this.projectid = currentProject.id;
                this.prefix = currentProject.pro_dir;
                  this.userdata = JSON.parse(localStorage.getItem('userdata'));
                this.getDrawings();
            },
            
    
        },
        computed: {
    
        },
        methods: {
            allowedDates: date => date <= new Date().toISOString().substr(0, 10),
            validate() {
                this.$refs.form.validate()
    
            },
            initViewer() {
                // console.log("init Viewer");
    
                this.getDrawings();
    
            },
            getDrawings() {
                var id = this.$route.params.id;
                axios({
                        url: '/interior/get-drawingbyid',
                        method: 'get',
                        params: {
                            did: id
                        }
                    })
                    .then(resp => {
                        // console.log("Drawing DTA", resp);
                        this.drawing = resp.data.success[0];
                        this.getcoordinates(resp.data.success[0].drawingid);
                    }).catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.required;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            fullImage(url) {
                // console.log(url)
                this.image = url;
                this.fulllImage = true;
                this.full = true;
            },
            fullpanaromaImage(url) {
                //  console.log(url)
                this.fulllImage = true;
                this.image = url;
            },
            fullpanaromaVideo(url) {
                // console.log(url)
                this.fulllImage = true;
                this.image = url;
            },
            uploadDrawing(d) {
    
                console.log("selected coordinates", d);
    
                this.openMedia = d;
                this.galleryOpen = false;
                this.updatedate = d.visitdate;
                this.updatetitle = d.title;
                this.updatex = d.x;
                this.updatey = d.y;
                this.updateworkStatus = d.currentstatus;
                this.updatecategory = d.cateone;
    
                if (d.cateone == '360 Videos Walk-through') {
                    this.activet = "video"
                    this.videoOnly = true
                } else {
                    this.activet = "normal"
                    this.videoOnly = false
                }
    
                this.uploadMedia = true
            },
    
            findCoordinate(event) {
                //  event.offsetParent
                // console.log(event)
                // console.log("offsetX", event.offsetX);
                //  console.log("offsetY", event.offsetY);
                this.xx = event.offsetX;
                this.yy = event.offsetY;
                 
    
                 var msg = this.lang.cordinatConfirm + " X = "+this.xx + " and Y = "+this.yy;
    
                var con = confirm(msg);
    
                if (con) {
    
                    this.cordinates.push({
                        cateone: "normal",
                        catethree: "",
                        catetwo: "",
                        comment_count: 1,
                        coordinateid: event.offsetX,
                        count360: 1,
                        countimages: null,
                        countvideos: null,
                        createat: "2021-02-15 16:16:20",
                        currentstatus: "",
                        drawingid: 77,
                        status: 1,
                        title: "Corridor",
                        updateat: "2021-02-23 17:57:47",
                        visitdate: "02/15/2021",
                        x: event.offsetX,
                        y: event.offsetY
                    });
                    var _self = this;
                    setTimeout(() => {
                        _self.coorddinates();
                        $('.map').maphilight({
                            strokeColor: "000000",
                            strokeWidth: 2,
                            stroke: true,
                            fillOpacity: 0.9,
                            alwaysOn: true
                        });
    
                        _self.coordinateForm = true;
                    }, 100);
                    console.log("this.cordinates", this.cordinates);
                }
    
            },
            submitAddNewCoordinate() {
    
                var data = {
                    status: this.workStatus,
                    did: this.$route.params.id,
                    title: this.title,
                    date: this.date,
                    category: this.category,
                    coox: this.xx,
                    cooy: this.yy
                }
                this.preloader = true;
                axios({
                        url: '/interior/addcoordinates',
                        method: 'get',
                        params: data
                    })
                    .then(resp => {
    
                        // console.log(resp);
                        this.preloader = false;
                        this.coordinateForm = false
                        this.uploadDrawing(resp.data.success[0]);
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
    
            },
            deleteCoordinate(id) {
    
                var conf = confirm(this.lang.removeCoordinate)
    
                if (conf) {
                    this.preloader = true;
    
                    axios({
                            url: '/interior/remove-coordinate',
                            method: 'get',
                            params: {
                                coorid: id
                            }
                        })
                        .then(resp => {
    
                            console.log(resp);
                            this.preloader = false;
                            this.coordinateForm = false
                        })
                        .catch(err => {
                            console.log(err);
                            this.alert = true;
                            this.alertmessage = this.lang.SomethingWentWrong;
                            this.alertType = "error"
                            this.preloader = false
                        })
                }
            },
            updateCoordinate() {
                var data = {
                    status: this.updateworkStatus,
                    did: this.$route.params.id,
                    title: this.updatetitle,
                    x: this.updatex,
                    y: this.updatey,
                    date: this.updatedate,
                    category: this.updatecategory,
                    coorid: this.openMedia.coordinateid,
                }
                this.preloader = true;
                axios({
                        url: '/interior/update-coordinate',
                        method: 'get',
                        params: data
                    })
                    .then(resp => {
    
                        console.log(resp);
                        this.preloader = false;
                        this.coordinateUpdateForm = false;
                        this.uploadMedia = false;
                        this.alert = true;
                        this.alertmessage = this.lang.cordianteUpdate;
                        this.$router.go();
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
    
            sendingEvent(file, xhr, formData) {
    
                 this.userdata = JSON.parse(localStorage.getItem('userdata'));
    
               var username =  this.userdata.firstname+" "+this.userdata.lastname;
               console.log("username", file);
              //  console.log("imagesExtract", this.imagesExtract[this.indd].latitude, this.imagesExtract[this.indd].longitude, this.indd)
                console.log("xhr", xhr);
                  var pp1 = file.name.split(".");
                  var pp2 = pp1[0].split("-");

                  console.log("--",pp2);
                    var tyepe = "normal";
                  if(pp2[3] == "R"){
                    tyepe = "panaroma";
                  }
                // console.log("formData", formData);
                formData.append("uploadedby", username);
                formData.append("proj_dir", "PRJCT"+pp2[1]);
    
                formData.append("project_id", pp2[1]);
    
                formData.append("thumbnail", this.thumbnailimage[this.indd]);
    
                formData.append("coordinateid", pp2[0]);
                formData.append("latitude", '0');
                formData.append("longitude", '0');
                formData.append("zone", '0');
                formData.append("did", pp2[2]);
                formData.append("type", tyepe);
                //formDaa.append("gpsDateStamp", "this.imagesExtract[this.indd].GPSDateStamp");
               
                const date = new Date(file.lastModified);
               //     console.log();
                var mm = date.getMonth()+1;
                formData.append("gpsDateStamp", date.getFullYear() + '/' + mm  + '/' + date.getDate()); //new Date(this.imagesExtract[this.indd].CreateDate).toISOString().slice(0, 10));
              
                //console.log("dfddg",this.imagesExtract[this.indd]);
                this.indd++;
                
                if (this.bulkupload) {
                    this.uploadImages();
                }
            },
        
            thumbnail(file, dataUrl) {
    
                this.thumbnailimage.push(dataUrl);
    
                console.log("this.thumbnailimage g", file.name);
               this.imagesExtractMethos(file);
                var _self = this;
                setTimeout(() => {
                    _self.$refs.myVueDropzone1.processQueue();
                }, 3000);
            },
            uploadImages() {
                var acceptedFiles = []
                acceptedFiles = this.$refs.myVueDropzone1.getAcceptedFiles();
                console.log("fff", acceptedFiles.length);
                if (acceptedFiles.length == this.indd)
                    var _self = this;
                setTimeout(() => {
                   // this.review = true;
                    _self.alert = true;
                    _self.alertmessage = this.lang.reviewImagesandSubmit;
                    _self.alertType = "error"
    
                }, 3000)
    
                //  this.$refs.myVueDropzone.processQueue();
                // this.$refs.myVueDropzone1.processQueue();    
            },
            async imagesExtractMethos(file) {
                var s = await exifr.parse(file);
                this.imagesExtract.push(s);
                console.log(s);
            },
            activetab(d) {
                this.activet = d;
    
                if (this.galleryOpen) {
    
                    if (d == 'normal') {
                        if (this.bulkupload) {
                            console.log("picker date", this.picker);
                            this.reviewImages('normal');
                        } else {
                            this.getNormalImages('normal');
                        }
    
                        this.selected = [];
                        this.showdeletebuttons = false;
                    }
                    if (d == 'panaroma') {
                        if (this.bulkupload) {
                            console.log("picker date", this.picker);
                            this.reviewImages('panaroma');
                        } else {
                            this.getNormalImages('panaroma');
                        }
                        this.selected = [];
                        this.showdeletebuttons = false;
                    }
                    if (d == 'video') {
                        this.getNormalImages('video');
                        this.selected = [];
                        this.showdeletebuttons = false;
                    }
                }
    
            },
            openGallery() {
    
                this.galleryOpen = true;
                // console.log(this.galleryOpen);
                this.imagesExtract = [];
                this.thumbnailimage = [];
    
                this.indd = 0;
    
                if (this.bulkupload) {
                    console.log("picker date", this.picker);
                    this.reviewImages(this.activet);
                } else {
                    this.getNormalImages(this.activet);
                }
    
            },
            getNormalImages(t) {
                // var id = this.$route.params.id;
                this.preloader = true;
                axios({
                        url: '/interior/get-media',
                        method: 'get',
                        params: {
                            coordi: this.openMedia.coordinateid,
                            type: t
                        }
                    })
                    .then(resp => {
                        console.log("Normal Images", resp.data.success);
    
                        if (t == 'normal') {
                            this.normalImages = resp.data.success
                        }
                        if (t == 'panaroma') {
                            this.panaromaImages = resp.data.success
                        }
                        if (t == 'video') {
                            this.videos = resp.data.success
                        }
    
                        this.preloader = false;
    
                        if(resp.data.success.length == 0){
                          this.alert = true;
                         this.alertmessage = this.lang.imagesnotavailble;
                         this.alertType = "error"
                        } 
    
                    }).catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            reviewImages(t) {
                // var id = this.$route.params.id;
                this.preloader = true;
                axios({
                        url: '/interior/review/get-media',
                        method: 'get',
                        params: {
                            date: this.picker,
                            type: t,
                            did: this.$route.params.id
                        }
                    })
                    .then(resp => {
                        console.log("Normal Images", resp.data.success);
    
                        if (t == 'normal') {
                            this.normalImages = resp.data.success
                        }
                        if (t == 'panaroma') {
                            this.panaromaImages = resp.data.success
                        }
                        if (t == 'video') {
                            this.videos = resp.data.success
                        }
    
                        this.preloader = false;
                         if(resp.data.success.length == 0){
                          this.alert = true;
                         this.alertmessage = this.lang.imagesnotavailble;
                         this.alertType = "error"
                        } 
    
                    }).catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            openDropZone() {
                this.galleryOpen = false;
            },
            fullscreen() {
                this.full = true;
    
                //console.log("hfghf", this.full);
            },
            fullscreenexit() {
                this.full = false;
                this.fulllImage = false;
                //console.log("hfghf", this.full);
            },
            getcoordinates(id) {
                axios({
                        url: '/interior/getcoordinates',
                        method: 'get',
                        params: {
                            did: id
                        }
                    })
                    .then(resp => {
                        // console.log("headings", resp);
    
                        this.cordinates = resp.data.filter(function (item) {
    
                            if (item.cateone == "360 Videos Walk-through") {
    
                                item.fillcolor = '{"fillColor":"ff3131"}';
    
                            } else {
                                item.fillcolor = '{"fillColor":"fdc004"}';
                            }
    
                            return 1 == 1;
    
                        });
    
                        console.log(this.cordinates);
    
                        this.coorddinates();
    
                        var _self = this;
                        setTimeout(() => {
                            _self.coorddinates();
                            $('.map').maphilight({
                                strokeColor: "000000",
                                strokeWidth: 2,
                                stroke: true,
                                fillOpacity: 0.9,
                                alwaysOn: true
                            });
                        }, 100);
                        this.preloader = false;
    
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            s3UploadError(errorMessage) {
                console.log("Drop AWS error.", errorMessage);
            },
            s3UploadSuccess(s3ObjectLocation) {
                console.log("Drop AWS response", s3ObjectLocation);
            },
            videosAdded() {
    
                var fileItem = document.getElementById('fileItem');
                var files = fileItem.files;
                var file = files[0];
    
                this.value = 0;
    
                console.log("---", file);
    
                if (file.size > 0) {
    
                    //var url = URL.createObjectURL(file);
                    //console.log("d", url);
                    var _self = this;
                    setTimeout(() => {
                        _self.getUrl(file);
                        _self.value = 20;
                        _self.showprogrss = true;
                    }, 100);
                    // const r = new FileReader();
    
                    // r.onload = function(){
                    //    console.log("Binary data", r.result);
    
                    //    
                    // return r.result;
                    // };
                    // url = URL.createObjectURL(file);
                    // r.readAsArrayBuffer(videoObj);
                }
            },
            getUrl(file) {
                //console.log("File",  file);
                //  console.log("File Type", file.type);
                this.value = 30;
                axios({
                        url: 'interior/create/pre-signed-url',
                        method: 'post',
                        data: {
                            filename: file.name,
                            filetype: file.type,
                            proj_dir: this.prefix,
                            zone: this.zone,
                        }
                    })
                    .then(resp => {
                        this.value = 50;
                        //console.log("URL api data................", resp.data.success);
                        var action = resp.data.success;
                        this.sendVideo(action, file);
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            sendVideo(action, file) {
                this.value = 70;
                var options = {
                    headers: {
                        'Content-Type': file.type
                    },
                    onUploadProgress: progressEvent => {
                        var complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
                        this.uploadPercentage = complete
                    }
                };
                delete axios.defaults.headers.common["Authorization"]
                axios.put(action, file, options).then(resp => {
    
                    console.log("Upload resp", resp);
    
                    this.value = 90;
    
                    const token = localStorage.getItem("token")
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token
    
                    this.saveDataUploadData(action);
    
                }).catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
            },
            saveDataUploadData(videoPath) {
    
                var path = videoPath.split('?');
                 var username =  this.userdata.firstname+" "+this.userdata.lastname;
              
                axios({
                        url: '/interior/upload-video-media',
                        method: 'post',
                        data: {
                            proj_dir: this.prefix,
                            project_id: this.projectid,
                            media: path[0],
                            coordinateid: this.openMedia.coordinateid,
                            zone: this.zoneid,
                            did: this.$route.params.id,
                            uploadedby : username
                        }
                    })
                    .then(resp => {
                        this.value = 100;
                        console.log("URL api data................", resp);
    
                        this.showprogrss = false;
                        this.value = 0;
                        this.vidoesss = '';
                        this.alert = true;
                        this.alertmessage = this.lang.videoupload;
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                        this.showprogrss = false;
                    })
            },
            reloadpage() {
                this.$router.go();
            },
            selecteditem() {
    
                if (this.selected.length > 0) {
                    this.showdeletebuttons = true;
                } else {
                    this.showdeletebuttons = false;
    
                }
            },
            removeselectedMedias() {
                var conf = confirm(this.lang.deleteConfirm);
    
                if (conf) {
                    this.preloader = true;
                    axios({
                            url: '/interior/remove-media-files',
                            method: 'post',
                            data: {
                                ids: this.selected
                            }
                        })
                        .then(resp => {
    
                            console.log("URL api data................", resp);
    
                            this.preloader = false;
    
                            this.alert = true;
                            this.alertmessage = this.lang.selecteddelte;
                            this.selected = [];
                            this.showdeletebuttons = false;
                            this.getNormalImages(this.activet);
                        })
                        .catch(err => {
                            console.log(err);
                            this.alert = true;
                            this.alertmessage =  this.lang.SomethingWentWrong;
                            this.alertType = "error"
                            this.preloader = false
                            this.showprogrss = false;
                        })
                }
            },
            openBulkUpload() {
                this.imagesExtract = [];
                this.thumbnailimage = [];
                 this.updateItems = [];
                this.indd = 0;
                this.bulkupload = true;
                this.uploadMedia = true;
                this.openMedia.coordinateid = "";
                this.galleryOpen = false;
                 console.log("updateItems",this.updateItems);
    
            },
            closeUpload() {
                this.bulkupload = false;
                this.uploadMedia = false;
                
                this.updateItems = [];
    
                 if (this.activet == "normal") {
                     this.$refs.myVueDropzone.removeAllFiles();
                } else if (this.activet == "panaroma") {
                    this.$refs.myVueDropzone1.removeAllFiles();
                }
              
               // this.this.review = false;
               console.log("updateItems",this.updateItems);
            },
            coordinatechange(d,nid) {
                this.updateItems.push(nid);
                console.log("click value", d);
               // console.log("Normal Images", this.normalImages);
            },
            updateImageCoordinate(n, nid) {
    
                console.log(nid)
    
                this.preloader = true;
                axios({
                        url: '/interior/update-media-files',
                        method: 'post',
                        data: {
                            mediaid: n.mediaid,
                            coordinateid: n.coordinateid
    
                        }
                    })
                    .then(resp => {
    
                        console.log("update................", resp);
                         //    console.log("update................", nid);
    
    
                        this.preloader = false;
    
                        this.alert = true;
                        this.alertmessage = this.lang.cordianteUpdate;
                         console.log(this.updateItems);
                        this.updateItems = this.updateItems.filter(item => item !== nid);
    
                        //   console.log("after",this.updateItems)
                           
    
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
    
                    })
            },
            coorddinates() {
                var has_VML, has_canvas, create_canvas_for, add_shape_to, clear_canvas, shape_from_area,
                    canvas_style, hex_to_decimal, css3color, is_image_loaded, options_from_area;
    
                has_canvas = !!document.createElement('canvas').getContext;
    
                // VML: more complex
                has_VML = (function () {
                    var a = document.createElement('div');
                    a.innerHTML = '<v:shape id="vml_flag1" adj="1" />';
                    var b = a.firstChild;
                    b.style.behavior = "url(#default#VML)";
                    return b ? typeof b.adj == "object" : true;
                })();
    
                if (!(has_canvas || has_VML)) {
                    $.fn.maphilight = function () {
                        return this;
                    };
                    return;
                }
    
                if (has_canvas) {
                    hex_to_decimal = function (hex) {
                        return Math.max(0, Math.min(parseInt(hex, 16), 255));
                    };
                    css3color = function (color, opacity) {
                        return 'rgba(' + hex_to_decimal(color.substr(0, 2)) + ',' + hex_to_decimal(color.substr(2, 2)) + ',' + hex_to_decimal(color.substr(4, 2)) + ',' + opacity + ')';
                    };
                    create_canvas_for = function (img) {
                        var c = $('<canvas style="width:' + $(img).width() + 'px;height:' + $(img).height() + 'px;"></canvas>').get(0);
                        c.getContext("2d").clearRect(0, 0, $(img).width(), $(img).height());
                        return c;
                    };
                    var draw_shape = function (context, shape, coords, x_shift, y_shift) {
                        x_shift = x_shift || 0;
                        y_shift = y_shift || 0;
    
                        context.beginPath();
                        if (shape == 'rect') {
                            // x, y, width, height
                            context.rect(coords[0] + x_shift, coords[1] + y_shift, coords[2] - coords[0], coords[3] - coords[1]);
                        } else if (shape == 'poly') {
                            context.moveTo(coords[0] + x_shift, coords[1] + y_shift);
                            for (var i = 2; i < coords.length; i += 2) {
                                context.lineTo(coords[i] + x_shift, coords[i + 1] + y_shift);
                            }
                        } else if (shape == 'circ') {
                            // x, y, radius, startAngle, endAngle, anticlockwise
                            context.arc(coords[0] + x_shift, coords[1] + y_shift, coords[2], 0, Math.PI * 2, false);
                        }
                        context.closePath();
                    };
                    add_shape_to = function (canvas, shape, coords, options, name) {
                        var i, context = canvas.getContext('2d');
    
                        console.log(name, i);
    
                        // Because I don't want to worry about setting things back to a base state
    
                        // Shadow has to happen first, since it's on the bottom, and it does some clip /
                        // fill operations which would interfere with what comes next.
                        if (options.shadow) {
                            context.save();
                            if (options.shadowPosition == "inside") {
                                // Cause the following stroke to only apply to the inside of the path
                                draw_shape(context, shape, coords);
                                context.clip();
                            }
    
                            // Redraw the shape shifted off the canvas massively so we can cast a shadow
                            // onto the canvas without having to worry about the stroke or fill (which
                            // cannot have 0 opacity or width, since they're what cast the shadow).
                            var x_shift = canvas.width * 100;
                            var y_shift = canvas.height * 100;
                            draw_shape(context, shape, coords, x_shift, y_shift);
    
                            context.shadowOffsetX = options.shadowX - x_shift;
                            context.shadowOffsetY = options.shadowY - y_shift;
                            context.shadowBlur = options.shadowRadius;
                            context.shadowColor = css3color(options.shadowColor, options.shadowOpacity);
    
                            // Now, work out where to cast the shadow from! It looks better if it's cast
                            // from a fill when it's an outside shadow or a stroke when it's an interior
                            // shadow. Allow the user to override this if they need to.
                            var shadowFrom = options.shadowFrom;
                            if (!shadowFrom) {
                                if (options.shadowPosition == 'outside') {
                                    shadowFrom = 'fill';
                                } else {
                                    shadowFrom = 'stroke';
                                }
                            }
                            if (shadowFrom == 'stroke') {
                                context.strokeStyle = "rgba(0,0,0,1)";
                                context.stroke();
                            } else if (shadowFrom == 'fill') {
                                context.fillStyle = "rgba(0,0,0,1)";
                                context.fill();
                            }
                            context.restore();
    
                            // and now we clean up
                            if (options.shadowPosition == "outside") {
                                context.save();
                                // Clear out the center
                                draw_shape(context, shape, coords);
                                context.globalCompositeOperation = "destination-out";
                                context.fillStyle = "rgba(0,0,0,1);";
                                context.fill();
                                context.restore();
                            }
                        }
    
                        context.save();
    
                        draw_shape(context, shape, coords);
    
                        // fill has to come after shadow, otherwise the shadow will be drawn over the fill,
                        // which mostly looks weird when the shadow has a high opacity
                        if (options.fill) {
                            context.fillStyle = css3color(options.fillColor, options.fillOpacity);
                            context.fill();
                        }
                        // Likewise, stroke has to come at the very end, or it'll wind up under bits of the
                        // shadow or the shadow-background if it's present.
                        if (options.stroke) {
                            context.strokeStyle = css3color(options.strokeColor, options.strokeOpacity);
                            context.lineWidth = options.strokeWidth;
                            context.stroke();
                        }
    
                        context.restore();
    
                        if (options.fade) {
                            $(canvas).css('opacity', 0).animate({
                                opacity: 1
                            }, 100);
                        }
                    };
                    clear_canvas = function (canvas) {
                        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
                    };
                } else { // ie executes this code
                    create_canvas_for = function (img) {
                        return $('<var style="zoom:1;overflow:hidden;display:block;width:' + img.width + 'px;height:' + img.height + 'px;"></var>').get(0);
                    };
                    add_shape_to = function (canvas, shape, coords, options, name) {
                        var fill, stroke, opacity, e;
                        for (var i in coords) {
                            coords[i] = parseInt(coords[i], 10);
                        }
                        fill = '<v:fill color="#' + options.fillColor + '" opacity="' + (options.fill ? options.fillOpacity : 0) + '" />';
                        stroke = (options.stroke ? 'strokeweight="' + options.strokeWidth + '" stroked="t" strokecolor="#' + options.strokeColor + '"' : 'stroked="f"');
                        opacity = '<v:stroke opacity="' + options.strokeOpacity + '"/>';
                        if (shape == 'rect') {
                            e = $('<v:rect name="' + name + '" filled="t" ' + stroke + ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' + coords[0] + 'px;top:' + coords[1] + 'px;width:' + (coords[2] - coords[0]) + 'px;height:' + (coords[3] - coords[1]) + 'px;"></v:rect>');
                        } else if (shape == 'poly') {
                            e = $('<v:shape name="' + name + '" filled="t" ' + stroke + ' coordorigin="0,0" coordsize="' + canvas.width + ',' + canvas.height + '" path="m ' + coords[0] + ',' + coords[1] + ' l ' + coords.join(',') + ' x e" style="zoom:1;margin:0;padding:0;display:block;position:absolute;top:0px;left:0px;width:' + canvas.width + 'px;height:' + canvas.height + 'px;"></v:shape>');
                        } else if (shape == 'circ') {
                            e = $('<v:oval name="' + name + '" filled="t" ' + stroke + ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' + (coords[0] - coords[2]) + 'px;top:' + (coords[1] - coords[2]) + 'px;width:' + (coords[2] * 2) + 'px;height:' + (coords[2] * 2) + 'px;"></v:oval>');
                        }
                        e.get(0).innerHTML = fill + opacity;
                        $(canvas).append(e);
                    };
                    clear_canvas = function (canvas) {
                        // jquery1.8 + ie7
                        var $html = $("<div>" + canvas.innerHTML + "</div>");
                        $html.children('[name=highlighted]').remove();
                        $(canvas).html($html.html());
                    };
                }
    
                shape_from_area = function (area) {
                    var i, coords,
                        shape = (area.getAttribute('shape') || 'rect').toLowerCase().substr(0, 4);
                    if (shape === 'defa') {
                        // 'default' doesn't really apply to what we're doing; it's the background state
                        return;
                    }
                    coords = (area.getAttribute('coords') || '').split(',');
                    for (i = 0; i < coords.length; i++) {
                        coords[i] = parseFloat(coords[i]);
                    }
                    return [shape, coords];
                };
    
                options_from_area = function (area, options) {
                    var $area = $(area);
                    return $.extend({}, options, $.metadata ? $area.metadata() : false, $area.data('maphilight'));
                };
    
                is_image_loaded = function (img) {
                    if (!img.complete) {
                        return false;
                    } // IE
                    if (typeof img.naturalWidth != "undefined" && img.naturalWidth === 0) {
                        return false;
                    } // Others
                    return true;
                };
    
                canvas_style = {
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    padding: 0,
                    border: 0
                };
    
                var ie_hax_done = false;
                $.fn.maphilight = function (opts) {
                    opts = $.extend({}, $.fn.maphilight.defaults, opts);
    
                    if (!has_canvas && !ie_hax_done) {
                        $(window).ready(function () {
                            document.namespaces.add("v", "urn:schemas-microsoft-com:vml");
                            var style = document.createStyleSheet();
                            var shapes = ['shape', 'rect', 'oval', 'circ', 'fill', 'stroke', 'imagedata', 'group', 'textbox'];
                            $.each(shapes,
                                function () {
                                    style.addRule('v\\:' + this, "behavior: url(#default#VML); antialias:true");
                                }
                            );
                        });
                        ie_hax_done = true;
                    }
    
                    return this.each(function () {
                        var img, wrap, options, map, canvas, canvas_always, usemap;
                        img = $(this);
    
                        //  console.log(highlighted_shape,imgSrc);
    
                        if (!is_image_loaded(this)) {
                            // If the image isn't fully loaded, this won't work right.  Try again later.
                            return window.setTimeout(function () {
                                img.maphilight(opts);
                            }, 200);
                        }
    
                        options = $.extend({}, opts, $.metadata ? img.metadata() : false, img.data('maphilight'));
    
                        // jQuery bug with Opera, results in full-url#usemap being returned from jQuery's attr.
                        // So use raw getAttribute instead.
                        usemap = img.get(0).getAttribute('usemap');
    
                        if (!usemap) {
                            return;
                        }
    
                        map = $('map[name="' + usemap.substr(1) + '"]');
    
                        if (!(img.is('img,input[type="image"]') && usemap && map.length > 0)) {
                            return;
                        }
    
                        if (img.hasClass('maphilighted')) {
                            // We're redrawing an old map, probably to pick up changes to the options.
                            // Just clear out all the old stuff.
                            var wrapper = img.parent();
                            img.insertBefore(wrapper);
                            wrapper.remove();
                            $(map).unbind('.maphilight');
                        }
    
                        //Formating the image source. IE > 9 has issue with new line characters
                        var imgSrc = this.src.replace(/[\n\r]/g, '');
                        console.log(imgSrc);
                        wrap = $('<div></div>').css({
                            display: 'block',
                            background: 'url("' + this.src + '")',
                            "background-size": 'contain',
                            "background-repeat": 'no-repeat',
                            position: 'relative',
                            padding: 0,
                            width: this.width,
                            height: this.height
                        });
                        if (options.wrapClass) {
                            if (options.wrapClass === true) {
                                wrap.addClass($(this).attr('class'));
                            } else {
                                wrap.addClass(options.wrapClass);
                            }
                        }
                        // Firefox has a bug that prevents tabbing into the image map if
                        // we set opacity of the image to 0, but very nearly 0 works!
                        img.before(wrap).css('opacity', 0.0000000001).css(canvas_style).remove();
                        if (has_VML) {
                            img.css('filter', 'Alpha(opacity=0)');
                        }
                        wrap.append(img);
    
                        canvas = create_canvas_for(this);
                        $(canvas).css(canvas_style);
                        canvas.height = this.height;
                        canvas.width = this.width;
    
                        $(map).bind('alwaysOn.maphilight', function () {
                                // Check for areas with alwaysOn set. These are added to a *second* canvas,
                                // which will get around flickering during fading.
                                if (canvas_always) {
                                    clear_canvas(canvas_always);
                                }
                                if (!has_canvas) {
                                    $(canvas).empty();
                                }
                                $(map).find('area[coords]').each(function () {
                                    var shape, area_options;
                                    area_options = options_from_area(this, options);
                                    if (area_options.alwaysOn) {
                                        if (!canvas_always && has_canvas) {
                                            canvas_always = create_canvas_for(img[0]);
                                            $(canvas_always).css(canvas_style);
                                            canvas_always.width = img[0].width;
                                            canvas_always.height = img[0].height;
                                            img.before(canvas_always);
                                        }
                                        area_options.fade = area_options.alwaysOnFade; // alwaysOn shouldn't fade in initially
                                        shape = shape_from_area(this);
                                        if (!shape) {
                                            return;
                                        }
                                        if (has_canvas) {
                                            add_shape_to(canvas_always, shape[0], shape[1], area_options, "");
                                        } else {
                                            add_shape_to(canvas, shape[0], shape[1], area_options, "");
                                        }
                                    }
                                });
                            }).trigger('alwaysOn.maphilight')
                            .bind('mouseover.maphilight focusin.maphilight', function (e) {
                                console.log(e);
                                var shape, area_options, area = e.target;
                                area_options = options_from_area(area, options);
                                if (!area_options.neverOn && !area_options.alwaysOn) {
                                    shape = shape_from_area(area);
                                    if (!shape) {
                                        return;
                                    }
                                    add_shape_to(canvas, shape[0], shape[1], area_options, "highlighted");
                                    if (area_options.groupBy) {
                                        if (typeof area_options.groupBy == 'string') {
                                            area_options.groupBy = [area_options.groupBy];
                                        }
                                        var el = $(this);
                                        $.each(area_options.groupBy, function (index, groupitem) {
                                            var areas;
                                            // two ways groupBy might work; attribute and selector
                                            if (/^[a-zA-Z][a-zA-Z]+$/.test(groupitem)) {
                                                areas = map.find('area[' + groupitem + '="' + el.attr(groupitem) + '"]');
                                            } else {
                                                areas = map.find(groupitem);
                                            }
                                            var first = this;
                                            areas.each(function () {
                                                if (this != first) {
                                                    var subarea_options = options_from_area(this, options);
                                                    if (!subarea_options.neverOn && !subarea_options.alwaysOn) {
                                                        var shape = shape_from_area(this);
                                                        add_shape_to(canvas, shape[0], shape[1], subarea_options, "highlighted");
                                                    }
                                                }
                                            });
                                        });
                                    }
                                    // workaround for IE7, IE8 not rendering the final rectangle in a group
                                    if (!has_canvas) {
                                        $(canvas).append('<v:rect></v:rect>');
                                    }
                                }
                            }).bind('mouseout.maphilight focusout.maphilight', function () {
                               // console.log(e);
                                clear_canvas(canvas);
                            });
    
                        img.before(canvas); // if we put this after, the mouseover events wouldn't fire.
    
                        img.addClass('maphilighted');
                    });
                };
                $.fn.maphilight.defaults = {
                    fill: true,
                    fillColor: 'fdc004',
                    fillOpacity: 0.2,
                    stroke: true,
                    strokeColor: '000000',
                    strokeOpacity: 1,
                    strokeWidth: 1,
                    fade: true,
                    alwaysOn: true,
                    neverOn: false,
                    groupBy: false,
                    wrapClass: true,
                    // plenty of shadow:
                    shadow: true,
                    shadowX: 0,
                    shadowY: 0,
                    shadowRadius: 6,
                    shadowColor: '000000',
                    shadowOpacity: 0.8,
                    shadowPosition: 'outside',
                    shadowFrom: false
                }
            }
        }
    
    };
    </script>
    
    <style>
    /* .stepper_outer {
        display: flex;
        width: 100%;
        flex-: center;
        justify-content: space-between;
        margin-bottom: 33px;
    }
    
    .steper_item {
        display: inline-flex;
        flex-direction: column;
        text-align: justify;
        z-index: 6;
      width: 95%;
      cursor: pointer;
    }
    
    .itcons {
        width: 95%;
        height: 50px;
    
        border-radius:0;
        background: #292929;
        color: #fff;
        text-align: center;
        line-height: 47px;
        margin: auto;
        margin-bottom: 11px;
        border: 1px solid #ffc400;
        transition: 0.5s;
    }
    
    .itcons i {
        color: #ffc400 !important;
    }
    
    .active .itcons {
    
        height: 50px;
        background: #ffc400;
        border: 2px solid #000;
        box-shadow: 0 0 15px 5px #0000005c;
        transition: 0.5s;
        line-height: 47px;
    }
    
    .active .itcons i {
        color: #000 !important;
    }
    
    .active .steperItem {
        color: #000000 !important;
        text-shadow: 0px 1px 20px #000;
        font-weight: 500;
    }
    
    .stepper_outer {
        padding: 0 19px;
        position: relative;
    }
    
    .stepper_outer:after {
        content: "";
        display: block;
        height: 2px;
        background: #000;
        width: 88%;
        position: absolute;
        left: 58px;
        top: 26px;
        z-index: 1;
    }
    
    .centerform {
        text-align: center;
        padding: 51px;
    } */
    
    .formcard {
        min-height: 92vh;
    }
    
    .listbox {
        max-height: 100vh;
        overflow: auto;
    }
    
    .vci-container img {
        border: 1px solid #1f1f1f45;
    }
    
    span.palandate {
        width: 40%;
        text-align: center;
    
        float: left;
        color: #595858
    }
    
    span.palandate.right {
        float: right;
        width: 40%;
        text-align: center;
    }
    
    .plann_buttons {
        padding: 13px 12px;
        background: #fac003;
        color: #000 !important;
        font-weight: bold;
        text-decoration: none;
        width: 20%;
        cursor: pointer;
        font-size: 12px;
        text-align: center;
        box-shadow: 1px 1px 20px 1px #7b7b7b;
        border-radius: 5px;
        float: left;
    }
    
    .dbuttons {
        padding: 13px 12px;
        background: #ffffff;
        color: #000 !important;
        font-weight: bold;
        text-decoration: none;
        width: 20%;
        cursor: pointer;
        font-size: 12px;
        text-align: center;
        box-shadow: 1px 1px 20px 1px #7b7b7b;
        border-radius: 5px;
    }
    
    span.actualdate {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        float: left;
        color: #595858
    }
    
    .navi {
        color: #f8bb03 !important;
        background: #000;
        cursor: pointer !important;
        margin: 0 3px;
    
    }
    
    .houseselecrt {
        width: 75px;
        padding: 0 !important;
        position: relative;
        right: 38px;
        top: -9px;
        float: right;
    }
    
    .navibar {
        text-align: center;
        position: relative;
        padding-top: 50px;
    }
    
    .listitemicon {
        margin: 8px 0;
    }
    
    .map {
        border: 1px solid #ccc;
    
    }
    
    .coordinate_image {
        text-align: center;
        overflow: auto;
    }
    
    .map {
        margin: auto;
    }
    
    span.fixheader {
        position: fixed;
        top: 11px;
        z-index: 9999999999;
        color: #fff;
        margin: auto;
        left: 0;
        right: 0;
        text-align: center;
        display: block;
        width: 275px;
    }
    
    .bx {
        overflow: auto;
    }
    
    .uploadMedia {
        display: block;
        width: 100%;
        height: 100%;
        padding: 3% 0;
        background: #000000ab;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
    }
    
    .fullviewbox {
        display: block;
        width: 100%;
        height: 100%;
        background: #000000ab;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
    }
    
    .uploadBox {
        display: block;
        position: relative;
        background: #fff;
        min-height: calc(100vh - 5%);
        padding: 15px 30px;
    }
    
    span.uploadHeader {
        display: block;
        padding: 14px 3px;
        background: #f8bb03;
        text-align: center;
    }
    
    span.uploadHeader strong {
        font-size: 19px;
    }
    
    #drop1,
    #drop2,
    #drop3 {
        height: 430px;
        padding: 40px;
        background: #efeeea;
    }
    
    #drop1 .dz-preview,
    #drop2 .dz-preview,
    #drop3 .dz-preview {
        width: 160px;
    }
    
    .uploadHeader a {
        border: 1px solid #000;
        padding: 2px 9px;
        border-radius: 3px;
        margin-right: 10px;
    }
    
    .filterb {
        margin: auto;
    }
    
    .centeritem {
        text-align: center;
    }
    
    .closeMedia {
        float: right;
        position: relative;
        top: -2px;
        right: 0px;
    }
    
    
    .normalGallery {
        overflow: auto;
        height: 500px;
    }
    
    .videothumbnails,
    .v-pano {
        height: 150px;
        width: 100%;
    }
    
    .exitbuttons {
        z-index: 999;
        float: right;
        top: 26px;
        right: 52px;
        position: fixed !important;
        background: #ffc107;
        border-radius: 50%;
    }
    
    .v-progress-circular {
        margin: 1rem;
    }
    
    .progress {
        position: fixed;
        margin: 0;
        display: flex;
        text-align: center;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #0000000d;
        z-index: 99;
    }
    
    .datepint {
        margin-bottom: 16px;
        position: absolute;
        bottom: 0;
        margin: 0;
        width: 100%;
        color: #000;
        background: #0c0c0c1c;
        padding: 6px 0;
    }
    
    .fullpanaroma {
        height: 100%;
    }
    
    .gallery {
        overflow: auto;
        height: 475px;
        display: block;
        width: 100%;
        overflow-x: hidden;
    }
    
    .gitemcheck {
        position: absolute;
        top: 21px;
        left: 23px;
        z-index: 9;
    }
    
    .sellect {
        position: absolute;
        top: 10px;
        left: 50px;
        z-index: 9;
        max-width: 200px;
    }
    .sellect .v-text-field__details{
       display:none;
    }
    .sellect .v-input__slot {
        margin-bottom: 0px;
    }
    
    .updatecheck {
        position: absolute;
        top: 20px;
        right: 3px;
        z-index: 9;
        background: #fff;
        border-radius: 74%;
        margin: 0px;
        cursor: pointer;
        padding: 0px;
    }
    
    .gitem {
        position: relative;
    }
    
    .mymap area {
        cursor: pointer;
    }
    
    .bulkupload {
        position: absolute;
        right: 10px;
        top: 5px;
        z-index: 1;
    }
    
    .datte {
        width: 209px;
        display: inline-block;
        position: relative;
        top: 8px;
    }
    .reviewline{
       position: relative;
        top: -80px;
    }
    .stripp {
        width: calc(100% - 25px);
        position: absolute;
        top: 0;
        /* left: 0; */
         background: #ffffffa6;
        height: 54px;
        z-index: 9;
    }
    /* .map{
        height:600px;
    } */
    </style>
    