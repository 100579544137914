import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "@/views/Home.vue";
import openAccessDashboard from "@/views/openAccessDashboard.vue";
import Archive from "@/views/DashboardPages/Archive";
import Compare from "@/views/DashboardPages/Compare";
import Compare_bim from "@/views/DashboardPages/bimCompare";
import Compare_overlay_bim from "@/views/DashboardPages/bimoverlayCompare";
import Timelapse from "@/views/DashboardPages/Timelaps";
import Splitscreen from "@/views/DashboardPages/Splitscreen";
import Annotation from "@/views/DashboardPages/annotation";
import Cctv from "@/views/DashboardPages/Cctv";

import Adduser from "@/views/DashboardPages/SettingsPages/AddUser";
import profile from "@/views/DashboardPages/SettingsPages/profile";

import Camerainfo from "@/views/DashboardPages/SettingsPages/Camerainfo";
import CameraNotifications from "@/views/DashboardPages/SettingsPages/CameraNotifications";
import ChangePassword from "@/views/DashboardPages/SettingsPages/ChangePassword";
import Bim_rendering from "@/views/DashboardPages/SettingsPages/bim_rendering";
import CustomizeTimelapse from "@/views/DashboardPages/SettingsPages/CustomizeTimelaps";
import ManagaUser from "@/views/DashboardPages/SettingsPages/ManageUser";
import ScheduleEmail from "@/views/DashboardPages/SettingsPages/ScheduleEmail";
import globalDashboard from "@/views/DashboardPages/intractiveDashboard";
import Invoices from "@/views/DashboardPages/SettingsPages/zoho_invoce";
import Procoreconnection from "@/views/DashboardPages/SettingsPages/procoreConnection";
import ProcoreUpdate from "@/views/DashboardPages/SettingsPages/ProcoreUpdate";
import Login from "@/views/Login.vue";
import ProjectSetup from "@/views/projectSetup.vue";
import ResetPassword from "@/views/ResetPassword";
import Mainlayout from "@/views/Layout/MainLayout.vue";

import CreateTimelapse from "@/views/DashboardPages/SettingsPages/CreateTimelapse";
//import newEcamsetup from "@/views/DashboardPages/SettingsPages/neweEcam-project-setup";
import onlyEcamsetup from "@/views/DashboardPages/SettingsPages/new-ecam";
import ArchiveTimelapse from "@/views/DashboardPages/ArchiveTimelapse";
import CustomTimelapse from "@/views/DashboardPages/CustomTimelapse";
import AdvanceTimelapse from "@/views/DashboardPages/AdvanceTimelapse";
import ProjectTimelapse from "@/views/DashboardPages/ProjectTimelapse";
import CreateAdvance from "@/views/DashboardPages/SettingsPages/CreateAdvance";
import CreateProjectTimelapse from "@/views/DashboardPages/SettingsPages/CreateProjectTimelapse";
import MediaUpload from "@/views/DashboardPages/mediaUpload";
import UploadeTask from "@/views/DashboardPages/ProjectPlanning/UploadeTask";
import EditTask from "@/views/DashboardPages/ProjectPlanning/EditTask";
import CompareEvent from "@/views/DashboardPages/ProjectPlanning/compareEvent";
import eventChart from "@/views/DashboardPages/ProjectPlanning/eventChart";
import EventTimelapse from "@/views/DashboardPages/ProjectPlanning/EventTimelapse";
import integration from "@/views/DashboardPages/SettingsPages/integration";
import bim360 from "@/views/DashboardPages/SettingsPages/bim360";
import CamConfiguration from "@/views/DashboardPages/SettingsPages/cam_configuration";
import ScheduleReport from "@/views/DashboardPages/ScheduleReport";
import InstantReport from "@/views/DashboardPages/InstantReport";
import ReportDemo from "@/views/DashboardPages/ReportDemo";
import ProjectPlaningDemo from "@/views/DashboardPages/ProjectPlanning/projectPlaningDemo";
import FavouriteList from "@/views/DashboardPages/FavouriteList";
import EventTimeline from "@/views/DashboardPages/EventTimeline";
import CamNameTag from "@/views/DashboardPages/SettingsPages/cam_name_and_tag";
import TwoFactorAuth from "@/views/DashboardPages/SettingsPages/Two_factor_auth";
import InteriorProjectStatus from "@/views/DashboardPages/SettingsPages/interiorProjectsStatus";
import OTP from "@/views/two_factor_auth";
import AiData from "@/views/DashboardPages/AiData";

import AiDetaction from "@/views/DashboardPages/AiPpeDetection";  // new ppe detaction api s

import Ai from "@/views/DashboardPages/Ai";
import Aidatareport from "@/views/DashboardPages/AiDataReports";

import Aippereport from "@/views/DashboardPages/SettingsPages/ai_ppe_report_schedule";

import Aiadminsedit from "@/views/DashboardPages/Aiadminsedit";

import Aiadminseditppe from "@/views/DashboardPages/Aiadminseditppe";

import AiadminseditppeJson from "@/views/DashboardPages/AiadminseditppeJson"; 

import AqiData from "@/views/DashboardPages/Aqidata";
import PpeSheduleRepost from "@/views/DashboardPages/SettingsPages/ppescheduleReports";
import CamInstallInfo from "@/views/DashboardPages/SettingsPages/Check_cam_install";
import projectcatinfo from "@/views/DashboardPages/SettingsPages/project_category_info";

import UserLogs from "@/views/DashboardPages/SettingsPages/UserLogs";
import InteriorUserLogs from "@/views/DashboardPages/SettingsPages/InteriorUserLogs";
import DroneUserLogs from "@/views/DashboardPages/SettingsPages/DroneUserLogs";
import MaterialUserLogs from "@/views/DashboardPages/SettingsPages/MaterialUserLogs";

import SingleUserLogs from "@/views/DashboardPages/SettingsPages/SingleUserLogs";
import CartReport from "@/views/DashboardPages/CartReport";
import dashboardAutodesk from "@/views/DashboardPages/Autodesk";

import PeopleCount from "@/views/DashboardPages/PeopleCount";

import TataLanding from "@/views/Tata-pages/Landing";
// import TataLogin from "@/views/Tata-pages/Login";
import TataDashboard from "@/views/Tata-pages/Dashboard";
import Tatamodules from "@/views/Tata-pages/projectmodules";
// ####################### 360 dash Routes ################//
import Home_360 from "@/views/360Home.vue";
// import VueItUp from "../views/VueIyUp.vue"

// ####################### Material tracking module Routes ################//

import Inventories from "@/views/MaterialTrackingPages/inventories";
import InventoryImages from "@/views/MaterialTrackingPages/inventoryImages";
import Draft from "@/views/MaterialTrackingPages/Draft";
import DraftInventories from "@/views/MaterialTrackingPages/DraftInventories";
import Addentry from "@/views/MaterialTrackingPages/addMaterial";
import Supplires from "@/views/MaterialTrackingPages/suppliers";

// ####################### Interior Monitoring module Routes ################//

import interiorViewEdit from "@/views/InteriorMonitoring/edit_drawingcordinaters";
import edit_drawings from "@/views/InteriorMonitoring/edit_drawings";
import user_interior from "@/views/InteriorMonitoring/user_interiorView";
import user_interior_test from "@/views/InteriorMonitoring/tt";
import user_interior_open_access from "@/views/InteriorMonitoring/user_interiorViewOpenAccess";
import autodesk_coordinate from "@/views/InteriorMonitoring/autodesk_coordinate";
import autodeskZone from "@/views/InteriorMonitoring/autodeskzone";
import autodesk from "@/views/InteriorMonitoring/Autodesk";
import bulkuploadFile from "@/views/InteriorMonitoring/bulkuploadfiles";
import walkthrough from "@/views/InteriorMonitoring/walkthrough"; 
import walkthrough_route from "@/views/InteriorMonitoring/walkthroughrouteset"; 
import walkthrough_loss_fix from "@/views/InteriorMonitoring/walkthroughlossfix"; 

import autodeskCompareBim from "@/views/InteriorMonitoring/bimcompare";
// ####################### Drone Monitoring module Routes ################//

import droneViewEdit from "@/views/DronMonitoring/edit_drawingcordinaters";
import dron_edit_drawings from "@/views/DronMonitoring/edit_drawings";
import user_drone from "@/views/DronMonitoring/user_interiorView";

import GPSImageUpload from "@/views/GeoMonitoring/GPSimageUpload";

import leaflatemap from "@/views/GeoMonitoring/leaflatemap";

import DroneRoute from "@/views/GeoMonitoring/droneRoute";

import DroneRouteConverter from "@/views/GeoMonitoring/srtconvrter";

import GPSuserview from "@/views/GeoMonitoring/GPS_user_view";
import DroneVideoCompare from "@/views/GeoMonitoring/droneVideoCompare";
import DroneImageCompare from "@/views/GeoMonitoring/droneImageCompare";
import Drone360ImageCompare from "@/views/GeoMonitoring/drone360ImageCompare";
import DroneScheduleVideoCompare from "@/views/GeoMonitoring/scheduleDroneVideoCompare";
import DroneSlideShow from "@/views/GeoMonitoring/droneSlideShow";
import DroneLatestVisitReport from "@/views/GeoMonitoring/latestVisitReport";
import DroneEditedVideolist from "@/views/GeoMonitoring/droneEditedVideoList";
import open_access_url from "@/views/DashboardPages/SettingsPages/openAccess";
import slide_show from "@/views/DashboardPages/SlideShow";
import CRM from "@/views/DashboardPages/SettingsPages/crm";
import unsubscribe_email_notification from "@/views/DashboardPages/SettingsPages/unsubscribeEmail";
import snag_chart from "@/views/InteriorMonitoring/snagChart";
import DailyReport from "@/views/DashboardPages/SettingsPages/dailyReport";
import VirtualTour from "@/views/VirtualTour/virtualTourLanding";
import Insights from "@/views/InteriorMonitoring/insights";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    props: route => ({ code: route.query.code })
  },
  /*   Tata pages */
  {
    name: "Landing",
    path: "/landing",
    component: TataLanding
  },
  {
    path: "/project-setup",
    name: "project-setup",
    component: ProjectSetup
  },

  {
    path: "/reset-password",
    name: "Reset-password",
    component: ResetPassword
  },
  {
    path: "/two-factor-auth-verification",
    name: "OTP",
    component: OTP,
    meta: {
      requiresAuth: true,
      access: true,
      comman: true
    }
    //props: route => ({ code: route.query.code })
  },
  {
    path: "/openAccessDashboard/:auth",
    name: "openAccessDashboard",
    component: openAccessDashboard
  },
  {
    path: "/",
    component: Mainlayout,
    redirect: "/dashboard",
    name: "home",
    hidden: false,
    meta: {
      requiresAuth: true,
      access: true,
      cont_camera_aceess: true
    },
    children: [
      {
        name: "tpl-dashboard",
        path: "/tpls",
        meta: {
          
          title: "Dashboard",
          icon: "dashboard",
          access: true,
          cont_camera_aceess: true
        },
        component: TataDashboard
      },
      {
        name: "tpl-module",
        path: "/tpls/modules",
        meta: {
          title: "Dashboard",
          icon: "dashboard",
          access: true,
          cont_camera_aceess: true
        },
        component: Tatamodules
      },
      {
        name: "dashboard",
        path: "dashboard",
        meta: {
          title: "Dashboard",
          icon: "dashboard",
          access: true,
          cont_camera_aceess: true
        },
        component: Home
      },
      {
        name: "annolation",
        path: "/dashboard/annotation",
        component: Annotation,
        meta: {
          title: "Annotation",
          access: true,
          cont_camera_aceess: true
        }
      },
      {
        name: "archive",
        path: "/dashboard/archive",
        component: Archive,
        meta: {
          title: "Archive",
          access: true,
          cont_camera_aceess: true
        }
      },
      {
        name: "compare",
        path: "/dashboard/compare",
        component: Compare,
        meta: {
          title: "Compare Image",
          access: true,
          cont_camera_aceess: true
        }
      },
      {
        name: "compare Bim",
        path: "/dashboard/compare-bim",
        component: Compare_bim,
        meta: {
          title: "Compare BIM",
          access: true,
          cont_camera_aceess: true
        }
      },
      {
        name: "compare Bim overlay",
        path: "/dashboard/compare-bim-overlay",
        component: Compare_overlay_bim,
        meta: {
          title: "Compare BIM Overlay",
          access: true,
          cont_camera_aceess: true
        }
      },
      {
        name: "splitescreen",
        path: "/dashboard/splitscreen",
        component: Splitscreen,
        meta: {
          title: "Split Screen",
          access: true,
          cont_camera_aceess: true
        }
      },
      {
        name: "timelapse",
        path: "/dashboard/timelapse",
        component: Timelapse,
        meta: {
          title: "Timelapse",
          access: true,
          cont_camera_aceess: true
        }
      },
      {
        name: "cctv",
        path: "/dashboard/live",
        component: Cctv,
        meta: {
          title: "Live CCTV",
          access: true,
          cont_camera_aceess: true
        }
      },
      {
        name: "people_count",
        path: "/dashboard/people-detection",
        component: PeopleCount,
        meta: {
          title: "People Count",
          access: true
        }
      },

      {
        name: "adduser",
        path: "/dashboard/settings/adduser",
        component: Adduser,
        meta: {
          title: "Add User",
          access: false,
          comman: true
        }
      },
      {
        name: "profile",
        path: "/dashboard/settings/profile",
        component: profile,
        meta: {
          title: "Profile",
          access: false,
          comman: true
        }
      },
      {
        name: "interior_profile",
        path: "/dashboard/interior/settings/profile",
        component: profile,
        meta: {
          title: "Profile",
          access: false,
          comman: true
        }
      },
      {
        name: "material_profile",
        path: "/dashboard/material/settings/profile",
        component: profile,
        meta: {
          title: "Profile",
          access: false,
          comman: true
        }
      },
      {
        name: "drone_profile",
        path: "/dashboard/drone/settings/profile",
        component: profile,
        meta: {
          title: "Profile",
          access: false,
          comman: true
        }
      },
      {
        name: "geo_profile",
        path: "/dashboard/geo/settings/profile",
        component: profile,
        meta: {
          title: "Profile",
          access: false,
          comman: true
        }
      },
      {
        name: "camerainfo",
        path: "/dashboard/settings/camerainfo",
        component: Camerainfo,
        meta: {
          title: "Camera Info",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "cameranotification",
        path: "/dashboard/settings/camera-notifications",
        component: CameraNotifications,
        meta: {
          title: "Camera Notification",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "changepassword",
        path: "/dashboard/settings/change-password",
        component: ChangePassword,
        meta: {
          title: "Change Password",
          access: true,
          comman: true
        }
      },
      {
        name: "customizetimelapse",
        path: "/dashboard/settings/customize-timelapse",
        component: CustomizeTimelapse,
        meta: {
          title: "Customize Timelapse",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "manageuser",
        path: "/dashboard/settings/manage-user",
        component: ManagaUser,
        meta: {
          title: "Manage User",
          access: true,
          comman: true
        }
      },
      {
        name: "schedulemail",
        path: "/dashboard/settings/schedule-email",
        component: ScheduleEmail,
        meta: {
          title: "Schedule Timelapse",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "bim_rendering",
        path: "/dashboard/settings/bim_rendering",
        component: Bim_rendering,
        meta: {
          title: "BIM Rendering",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "global-dashboard",
        path: "/dashboard/settings/global-dashboard",
        component: globalDashboard,
        meta: {
          title: "Global Dashboard",
          access: false,
          comman: true
        }
      },
      {
        name: "interior_global-dashboard",
        path: "/dashboard/interior/settings/global-dashboard",
        component: globalDashboard,
        meta: {
          title: "Global Dashboard",
          access: false,
          comman: true
        }
      },
      {
        name: "drone_global-dashboard",
        path: "/dashboard/drone/settings/global-dashboard",
        component: globalDashboard,
        meta: {
          title: "Global Dashboard",
          access: false,
          comman: true
        }
      },
      {
        name: "material_global-dashboard",
        path: "/dashboard/material/settings/global-dashboard",
        component: globalDashboard,
        meta: {
          title: "Global Dashboard",
          access: false,
          comman: true
        }
      },
      {
        name: "geo_global-dashboard",
        path: "/dashboard/geo/settings/global-dashboard",
        component: globalDashboard,
        meta: {
          title: "Global Dashboard",
          access: false,
          comman: true
        }
      },
      {
        name: "invoices",
        path: "/dashboard/settings/billing",
        component: Invoices,
        meta: {
          title: "Invoices",
          access: false,
          cont_camera_aceess: true,
          interior_access: true,
          material_access: true,
          drone_access: true
        }
      },

      {
        name: "createtimelapse",
        path: "/dashboard/settings/create-timelapse",
        component: CreateTimelapse,
        meta: {
          title: "Create Timelapse",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "new-Ecamera",
        path: "/dashboard/settings/only-ecam-setup",
        component: onlyEcamsetup,
        meta: {
          title: "Only Ecam Setup",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "interior_project-status",
        path: "/dashboard/interior/settings/project-status",
        component: InteriorProjectStatus,
        meta: {
          title: "Project",
          access: false,
          interior_access: true
        }
      },
      {
        name: "archivetimelapse",
        path: "/dashboard/timelapse/archive-timelapse",
        component: ArchiveTimelapse,
        meta: {
          title: "Archive Timelapse",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "customtimelapse",
        path: "/dashboard/timelapse/custom-timelapse",
        component: CustomTimelapse,
        meta: {
          title: "Custom Timelapse",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "advancetimelapse",
        path: "/dashboard/timelapse/advance-timelapse",
        component: AdvanceTimelapse,
        meta: {
          title: "Advance Timelapse",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "projecttimelapse",
        path: "/dashboard/timelapse/project-timelapse",
        component: ProjectTimelapse,
        meta: {
          title: "Project Timelapse",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "createadvance",
        path: "/dashboard/settings/create-advance-timelapse",
        component: CreateAdvance,
        meta: {
          title: "Create Advance Timelapse",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "createproject_timelapse",
        path: "/dashboard/settings/create-project-timelapse",
        component: CreateProjectTimelapse,
        meta: {
          title: "Create Project Timelapse",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "mediaupload",
        path: "/dashboard/media-upload",
        component: MediaUpload,
        meta: {
          title: "Media Upload",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "uploadetask",
        path: "/dashboard/planning/uploade-task",
        component: UploadeTask,
        meta: {
          title: "TasK Upload",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "edittask",
        path: "/dashboard/planning/edit-task",
        component: EditTask,
        meta: {
          title: "Task Edit",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "comapreEvents",
        path: "/dashboard/planning/compare-events",
        component: CompareEvent,
        meta: {
          title: "Compare Events",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "eventChart",
        path: "/dashboard/planning/event-chart",
        component: eventChart,
        meta: {
          title: "Event Chart",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "eventTimelapse",
        path: "/dashboard/planning/event-timelapse",
        component: EventTimelapse,
        meta: {
          title: "Event Timelapse",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "planningdemo",
        path: "/dashboard/planning/demo",
        component: ProjectPlaningDemo,
        meta: {
          title: "Planning  Demo",
          access: false,
          cont_camera_aceess: true
        }
      },

      {
        name: "integration",
        path: "/dashboard/settings/integration",
        component: integration,
        meta: {
          title: "Integration",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "dashbord_autodesk",
        path: "/dashboard/settings/auto-desk-forge-view",
        component: dashboardAutodesk,
        meta: {
          title: "Autodesk Forge View",
          access: false,
          cont_camera_aceess: true
        }
      },
      
      {
        name: "procore_connection",
        path: "/dashboard/settings/integration/procore_connection",
        component: Procoreconnection,
        meta: {
          title: "Procore Connection",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "procore_update",
        path: "/dashboard/settings/integration/procore_update",
        component: ProcoreUpdate,
        meta: {
          title: "Procore Update",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "bim360",
        path: "/dashboard/settings/integration/bim360",
        component: bim360,
        meta: {
          title: "BIM 360",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "cam_configuration",
        path: "/dashboard/settings/camera/configuration",
        component: CamConfiguration,
        meta: {
          title: "Camera Configuration",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "instant_report",
        path: "/dashboard/reports/instant-report",
        component: InstantReport,
        meta: {
          title: "Instant Report",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "schedule_report",
        path: "/dashboard/reports/schedule-report",
        component: ScheduleReport,
        meta: {
          title: "Schedule Report",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "report_demo",
        path: "/dashboard/reports/report-demo",
        component: ReportDemo,
        meta: {
          title: "Report Demo",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "favourite_list",
        path: "/dashboard/more/favorite-list",
        component: FavouriteList,
        meta: {
          title: "Favorite List",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "event_timeline",
        path: "/dashboard/more/event-timeline",
        component: EventTimeline,
        meta: {
          title: "Schedule Timelapse",
          access: false,
          cont_camera_aceess: true
        }
      },
      {
        name: "Cam_Name_Tag",
        path: "/dashboard/settings/camera/Cam_Name_and_Tag",
        component: CamNameTag,
        meta: {
          title: "Camera Tag Name",
          access: false
        }
      },
      {
        name: "two_factor_auth",
        path: "/dashboard/settings/security/two-factor-auth-setup",
        component: TwoFactorAuth,
        meta: {
          title: "Two Factor Auth",
          access: true,
          comman: true
        }
      },
      {
        name: "interior_two_factor_auth",
        path: "/dashboard/interior/settings/security/two-factor-auth-setup",
        component: TwoFactorAuth,
        meta: {
          title: "Two Factor Auth",
          access: false,
          comman: true
        }
      },
      {
        name: "drone_two_factor_auth",
        path: "/dashboard/drone/settings/security/two-factor-auth-setup",
        component: TwoFactorAuth,
        meta: {
          title: "Two Factor Auth",
          access: true,
          comman: true
        }
      },
      {
        name: "material_two_factor_auth",
        path: "/dashboard/material/settings/security/two-factor-auth-setup",
        component: TwoFactorAuth,
        meta: {
          title: "Two Factor Auth",
          access: true,
          comman: true
        }
      },
      {
        name: "geo_two_factor_auth",
        path: "/dashboard/geo/settings/security/two-factor-auth-setup",
        component: TwoFactorAuth,
        meta: {
          title: "Two Factor Auth",
          access: true,
          comman: true
        }
      },
      {
        name: "AI Annotation",
        path: "/dashboard/ai-annotation-admin",
        component: Aiadminsedit,
        meta: {
          title: "AI Annotation",
          access: false
        }
      },
      {
        name: "AI Annotation ppe",
        path: "/dashboard/ai-annotation-admin-ppe",
        component: Aiadminseditppe,
        meta: {
          title: "AI Annotation ppe",
          access: false
        }
      },
      {
        name: "AI Annotation ppe",
        path: "/dashboard/ai-annotation-admin-ppe-json",
        component: AiadminseditppeJson,
        meta: {
          title: "AI Annotation ppe",
          access: false
        }
      },
      {
        name: "Aidata",
        path: "/dashboard/ai-data",
        component: AiData,
        meta: {
          title: "AI Data",
          access: false
        }
      },
      {
        name: "Ai",
        path: "/dashboard/ai",
        component: Ai,
        meta: {
          title: "AI",
          access: false
        }
      },
      {
        name: "ppe Detection",
        path: "/dashboard/ppe-detection",
        component: AiDetaction,
        meta: {
          title: "PPE Detaction",
          access: false
        }
      },
      {
        name: "Ai Reports",
        path: "/dashboard/ai-reports",
        component: Aidatareport,
        meta: {
          title: "AI Reports",
          access: false
        }
      },
      {
        name: "Ai Ppe Reports",
        path: "/dashboard/ai-ppe-reports",
        component: Aippereport,
        meta: {
          title: "AI Ppe Reports",
          access: false
        }
      },
      {
        name: "Aqidata",
        path: "/dashboard/aqi-data",
        component: AqiData,
        meta: {
          title: "AQI Data",
          access: false
        }
      },
      {
        name: "AidataReports",
        path: "/dashboard/settings/ai-data-schedule-report",
        component: PpeSheduleRepost,
        meta: {
          title: "AI Data Reports",
          access: true
        }
      },
      {
        name: "CamInstallInfo",
        path: "/dashboard/settings/check_camera/installation",
        component: CamInstallInfo,
        meta: {
          title: "Camera Install Info",
          access: true
        }
      },
      {
        name: "ProjectCatInfo",
        path: "/dashboard/settings/project-category",
        component: projectcatinfo,
        meta: {
          title: "Project Cat Info",
          access: true
        }
      },
      
      {
        name: "userLogs",
        path: "/dashboard/settings/user-logs",
        component: UserLogs,
        meta: {
          title: "Users Log",
          access: true,
          comman: true
        }
      },
      {
        name: "interior_invoices",
        path: "/dashboard/interior/settings/billing",
        component: Invoices,
        meta: {
          title: "Invoices",
          access: false,
          cont_camera_aceess: true,
          interior_access: true,
          material_access: true,
          drone_access: true
        }
      },
      {
        name: "interior_User_Logs",
        path: "/dashboard/interior/settings/user-logs",
        component: InteriorUserLogs,
        meta: {
          title: "Users Log",
          access: true,
          comman: true
        }
      },
      {
        name: "drone_UserLogs",
        path: "/dashboard/drone/settings/user-logs",
        component: DroneUserLogs,
        meta: {
          title: "Users Log",
          access: true,
          comman: true
        }
      },
      {
        name: "material_User_Logs",
        path: "/dashboard/material/settings/user-logs",
        component: MaterialUserLogs,
        meta: {
          title: "Users Log",
          access: true,
          comman: true
        }
      },
      {
        name: "single_user_logs",
        path: "/dashboard/settings/user-log",
        component: SingleUserLogs,
        meta: {
          title: "Single User Logs",
          access: true,
          comman: true
        }
      },
      {
        name: "material_single_user_logs",
        path: "/dashboard/material/settings/user-log",
        component: SingleUserLogs,
        meta: {
          title: "Single User Logs",
          access: true,
          comman: true
        }
      },
      {
        name: "drone_single_user_logs",
        path: "/dashboard/drone/settings/user-log",
        component: SingleUserLogs,
        meta: {
          title: "Single User Logs",
          access: true,
          comman: true
        }
      },
      {
        name: "interior_single_user_logs",
        path: "/dashboard/interior/settings/user-log",
        component: SingleUserLogs,
        meta: {
          title: "Single User Logs",
          access: true,
          comman: true
        }
      },
      

      //############################ 360 dashboard View ####################//
      {
        name: "dashboard-360",
        path: "dashboard-360",
        meta: {
          title: "Dashboard-360",
          icon: "dashboard",
          access: true,
          cont_camera_aceess: true
        },
        component: Home_360
      },
      //############################ Inventories Start ####################//
      //  Important Note for Developer
      /*  Please add Prefix "material" to all routes name in material  module 
       without it materila header is not load. */
      //############################ Inventories Start ####################//
      {
        name: "material_inventory",
        path: "/dashboard/material/inventories",
        component: Inventories,
        meta: {
          title: "Inventories",
          access: false,
          material_access: true
        }
      },
      {
        name: "material_inventory_mobile",
        path: "/dashboard/material/inventories/:projectid/:auth",
        component: Inventories,
        meta: {
          title: "Inventories",
          access: false,
          material_access: true
        }
      },
      {
        name: "material_inventoryImages",
        path: "/dashboard/material/inventory-images/:id",
        component: InventoryImages,
        meta: {
          title: "Inventory Images",
          access: false,
          material_access: true
        }
      },
      {
        name: "material_draft",
        path: "/dashboard/material/draft",
        component: Draft,
        meta: {
          title: "Drafts",
          access: false,
          material_access: true
        }
      },
      {
        name: "material_draftinventory",
        path: "/dashboard/material/draft/inventory/:id",
        component: DraftInventories,
        meta: {
          title: "Draft Inventory",
          access: false,
          material_access: true
        }
      },
      {
        name: "material_addMaterial",
        path: "/dashboard/materials",
        component: Addentry,
        meta: {
          title: "Add Material",
          access: false,
          material_access: true
        }
      },
      {
        name: "material_supplires",
        path: "/dashboard/supplires",
        component: Supplires,
        meta: {
          title: "Suppliers",
          access: false,
          material_access: true
        }
      },

      {
        name: "material_adduser",
        path: "/dashboard/material/settings/adduser",
        component: Adduser,
        meta: {
          title: "Add User",
          access: false,
          comman: true
        }
      },
      {
        name: "material_manageuser",
        path: "/dashboard/material/settings/manage-user",
        component: ManagaUser,
        meta: {
          title: "Manage User",
          access: true,
          comman: true
        }
      },
      {
        name: "material_changepassword",
        path: "/dashboard/material/settings/change-password",
        component: ChangePassword,
        meta: {
          title: "Change Password",
          access: true,
          comman: true
        }
      },

      // ###################################
      // ##  Interior Monitoring Page
      // ##
      // ##
      // ###################################

      {
        name: "interior_viewedit",
        path: "/dashboard/interior-view/:id",
        component: interiorViewEdit,
        meta: {
          title: "Interior View",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_editdrawings",
        path: "/dashboard/edit-drawings",
        component: edit_drawings,
        meta: {
          title: "Edit Drawing",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_userinteriorview",
        path: "/dashboard/user-interior-view",
        component: user_interior,
        meta: {
          title: "User Interior View",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_userinteriorviewtest",
        path: "/dashboard/user-interior-view-test",
        component: user_interior_test,
        meta: {
          title: "User Interior View t",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_userinteriorviewopenaccess",
        path: "/dashboard/user-interior-view-open-access",
        component: user_interior_open_access,
        meta: {
          title: "User Interior View Open Access",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_userinteriorview_mobile",
        path: "/dashboard/user-interior-view/:projectid/:auth",
        component: user_interior,
        meta: {
          title: "User Interior View",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_biminteriorview",
        path: "/dashboard/interior/user-bim-interior-view",
        component: autodesk_coordinate,
        meta: {
          title: "Bim Interior View",
          access: false,
          interior_access: true
        }
      }, 
      {
        name: "interior_autodeskZone",
        path: "/dashboard/interior/settings/zone-auto-desk-forge-view",
        component: autodeskZone,
        meta: {
          title: "Autodesk Zone",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_autodeskcompare",
        path: "/dashboard/interior/auto-desk-forge-view-compare",
        component: autodeskCompareBim,
        meta: {
          title: "Autodesk Compare",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_bulkupload",
        path: "/dashboard/interior/buklupload",
        component: bulkuploadFile,
        meta: {
          title: "bulkupload",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_walkthrough",
        path: "/dashboard/interior/walkthrough",
        component: walkthrough,
        meta: {
          title: "walkthrough",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_walkthrough_route",
        path: "/dashboard/interior/walkthrough-route/:did/:wid",
        component: walkthrough_route,
        meta: {
          title: "walkthrough-route",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_walkthrough_loss_fix",
        path: "/dashboard/interior/walkthrough-fix/:did/:wid",
        component: walkthrough_loss_fix,
        meta: {
          title: "walkthrough-lossfix",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_adduser",
        path: "/dashboard/interior/settings/adduser",
        component: Adduser,
        meta: {
          title: "Add User",
          access: false,
          comman: true
        }
      },
      {
        name: "interior_manageuser",
        path: "/dashboard/interior/settings/manage-user",
        component: ManagaUser,
        meta: {
          title: "Manage User",
          access: true,
          comman: true
        }
      },
      {
        name: "interior_changepassword",
        path: "/dashboard/interior/settings/change-password",
        component: ChangePassword,
        meta: {
          title: "Change Password",
          access: true,
          comman: true
        }
      },
      {
        name: "interior_autodesk",
        path: "/dashboard/interior/settings/auto-desk-forge-view",
        component: autodesk,
        meta: {
          title: "Autodesk",
          access: false,
          interior_access: true
        }
      },
      {
        name: "interior_snag_chart",
        path: "/dashboard/interior/snag-chart",
        component: snag_chart,
        meta: {
          title: "Snag Chart",
          access: true,
          interior_access: true
        }
      },
      {
        name: "interior_insights",
        path: "/dashboard/interior/insights",
        component: Insights,
        meta: {
          title: "Insights",
          access: true,
          interior_access: true
        }
      },

      // ###################################
      // ##  Dron Monitoring Page
      // ##
      // ##
      // ###################################

      {
        name: "drone_viewedit",
        path: "/dashboard/drone-view/:id",
        component: droneViewEdit,
        meta: {
          title: "Drone View",
          access: false,
          drone_access: true
        }
      },
      {
        name: "drone_editdrawings",
        path: "/dashboard/drone-edit-drawings",
        component: dron_edit_drawings,
        meta: {
          title: "Drone Edit Drawing",
          access: false,
          drone_access: true
        }
      },
      {
        name: "drone_userdronview",
        path: "/dashboard/user-drone-view",
        component: user_drone,
        meta: {
          title: "User Drone View",
          access: false,
          drone_access: true
        }
      },
      {
        name: "drone_adduser",
        path: "/dashboard/drone/settings/adduser",
        component: Adduser,
        meta: {
          title: "Add User",
          access: false,
          comman: true
        }
      },
      {
        name: "drone_manageuser",
        path: "/dashboard/drone/settings/manage-user",
        component: ManagaUser,
        meta: {
          title: "Manage User",
          access: true,
          comman: true
        }
      },
      {
        name: "drone_changepassword",
        path: "/dashboard/drone/settings/change-password",
        component: ChangePassword,
        meta: {
          title: "Change Password",
          access: true,
          comman: true
        }
      },

      // ###################################
      // ##  GPS Image Monitoring Page
      // ##
      // ##
      // ###################################

      {
        name: "geo_upload",
        path: "/dashboard/image-upload",
        component: GPSImageUpload,
        meta: {
          title: "GPS Image-upload ",
          access: false
        }
      },
      {
        name: "geo_tiles_map",
        path: "/dashboard/drone-map",
        component: leaflatemap,
        meta: {
          title: "Drone map",
          access: false
        }
      },
      {
        name: "geo_user_view",
        path: "/dashboard/gps-map-view",
        component: GPSuserview,
        meta: {
          title: "GPS Map View",
          access: false
        }
      },
      {
        name: "geo_routeeditview",
        path: "/dashboard/route-update",
        component: DroneRoute,
        meta: {
          title: "GPS Map Route",
          access: false
        }
      },
      {
        name: "geo_srtconverter",
        path: "/dashboard/route-converter",
        component: DroneRouteConverter,
        meta: {
          title: "GPS Map Route converter",
          access: false
        }
      },
      {
        name: "geo_drone_video_compare",
        path: "/dashboard/drone-video-compare",
        component: DroneVideoCompare,
        meta: {
          title: "Drone Video Comapre",
          access: false
        }
      },
      {
        name: "geo_drone_image_compare",
        path: "/dashboard/drone-image-compare",
        component: DroneImageCompare,
        meta: {
          title: "Drone Image Comapre",
          access: false
        }
      },
      {
        name: "geo_drone_schedule_video_compare",
        path: "/dashboard/drone-schedule-video-compare",
        component: DroneScheduleVideoCompare,
        meta: {
          title: "Drone Schedule Video Comapre",
          access: false
        }
      },
      {
        name: "geo_drone_slide_show",
        path: "/dashboard/drone-slide-show",
        component: DroneSlideShow,
        meta: {
          title: "Drone Slide Show",
          access: false
        }
      },
      {
        name: "geo_drone_latest_visit_report",
        path: "/dashboard/drone-latest-visit-report",
        component: DroneLatestVisitReport,
        meta: {
          title: "Drone Latest Visit Report",
          access: false
        }
      },
      {
        name: "geo_drone_edited_video_list",
        path: "/dashboard/drone-edited-video-list",
        component: DroneEditedVideolist,
        meta: {
          title: "Drone Edited Video List",
          access: false
        }
      },
      {
        name: "geo_360_image_compare",
        path: "/dashboard/drone-360-image-compare",
        component: Drone360ImageCompare,
        meta: {
          title: "Drone 360 Image Comapre",
          access: false
        }
      },
      {
        name: "geo_adduser",
        path: "/dashboard/geo/settings/adduser",
        component: Adduser,
        meta: {
          title: "Add User",
          access: false,
          comman: true
        }
      },
      {
        name: "geo_manageuser",
        path: "/dashboard/geo/settings/manage-user",
        component: ManagaUser,
        meta: {
          title: "Manage User",
          access: true,
          comman: true
        }
      },
      {
        name: "geo_changepassword",
        path: "/dashboard/geo/settings/change-password",
        component: ChangePassword,
        meta: {
          title: "Change Password",
          access: true,
          comman: true
        }
      },
      {
        name: "geo_single_user_logs",
        path: "/dashboard/geo/settings/user-log",
        component: SingleUserLogs,
        meta: {
          title: "Single User Logs",
          access: true,
          comman: true
        }
      },
      {
        name: "geo_User_Logs",
        path: "/dashboard/geo/settings/user-logs",
        component: DroneUserLogs,
        meta: {
          title: "Users Log",
          access: true,
          comman: true
        }
      },
      {
        name: "cart_report",
        path: "/dashboard/reports/cart-report",
        component: CartReport,
        meta: {
          title: "Users Log",
          access: true
        }
      },
      {
        name: "open_access_url",
        path: "/dashboard/settings/open-access-url",
        component: open_access_url,
        meta: {
          title: "Create Open Access Url",
          access: true
        }
      },
      {
        name: "slide_show",
        path: "/dashboard/slide-show",
        component: slide_show,
        meta: {
          title: "Slide Show",
          access: true
        }
      },
      {
        name: "crm",
        path: "/dashboard/settings/crm",
        component: CRM,
        meta: {
          title: "CRM",
          access: true
        }
      },
      {
        name: "unsubscribe_email_notification",
        path: "/dashboard/settings/unsubscribe-email-notification",
        component: unsubscribe_email_notification,
        meta: {
          title: "Unsubscribe Email Notification",
          access: true
        }
      },
      {
        name: "daily_report",
        path: "/dashboard/settings/daily-report",
        component: DailyReport,
        meta: {
          title: "Daily Report",
          access: true
        }
      },
      {
        name: "vtour_virtual_tour",
        path: "/dashboard/virtual-tour",
        component: VirtualTour,
        meta: {
          title: "Virtual Tour",
          access: true,
          virtual_tour: true
        }
      },
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // console.log(to);
  var userdata = {};
  //var currentProject;
  //  console.log('to',to.hash);
  //  const params = to.hash.split('&').map(part => part.replace(/#/, ''));
  //  const parsedParams = {};
  //  params.forEach(param => {
  //   const parts = param.split('=');
  //   parsedParams[parts[0]] = parts[1];
  // });
  // console.log(parsedParams)
  // let pro_access_token = parsedParams['access_token'];
  //  console.log(pro_access_token);
  //var currentmodule = to.name.split("_");

  // console.log("module", currentmodule);

  if (
    to.name == "Login" ||
    to.name == "dashboard" ||
    to.name == "Reset-password" ||
    to.name == "OTP" ||
    to.name == "project-setup" ||
    to.name == "openAccessDashboard" ||
    to.name == "tpl-dashboard" 
  ) {
    userdata = {
      user_code: 1
    };
  } else {
    userdata = JSON.parse(localStorage.getItem("userdata"));
    //currentProject = JSON.parse(localStorage.getItem('currentProject'));
    //console.log("userdata", userdata);
  }
  if (userdata.user_code == 2 || userdata.user_code == 0) {
    // if(from.name == 'Login'){
    //   localStorage.setItem('ppopup', true);
    //  }

    if (to.matched.some(record => record.meta.requiresAuth)) {
      
      if (store.getters.isLoggedIn) {  
        // console.log("check point 1");
        if (to.matched.some(record => record.meta.comman)) {
          //console.log("commane");
          next();
          return;
        } else if (
          userdata.cont_camera_aceess == 1 &&
          to.matched.some(record => record.meta.cont_camera_aceess)
        ) {
          //  console.log("check point 1 cont_camera_aceess");
          next();
          return;
        } else if (
          userdata.drone_access == 1 &&
          to.matched.some(record => record.meta.drone_access)
        ) {
          //  console.log("check point 1 drone_access");
          next();
          return;
        } else if (
          userdata.interior_access == 1 &&
          to.matched.some(record => record.meta.interior_access)
        ) {
          //  console.log("check point 1 interior_access");
          next();
          return;
        } else if (
          userdata.material_access == 1 &&
          to.matched.some(record => record.meta.material_access)
        ) {
          // console.log("check point 1 material_access");
          next();
          return;
        }else if(userdata.linear_monitoring_access == 1){
          next();
          return;
        }else if(userdata.virtual_tour_access == 1 &&
          to.matched.some(record => record.meta.virtual_tour)){
          next();
          return;
        }
      }
      next("/login");
    } else {
      next();
    }
  } else {
    console.log("checking", store.getters.isLoggedIn);
    //  console.log("from.name == 'Login'", from.name);
    if (from.name == "Login") {
      localStorage.setItem("ppopup", true);
    }
    if (
      to.matched.some(record => record.meta.requiresAuth) &&
      to.matched.some(record => record.meta.access)
    ) {
      if (store.getters.isLoggedIn) {
        userdata = JSON.parse(localStorage.getItem("userdata"));
        console.log("check point 2");

        //  console.log("check cont_camera_aceess ", userdata.cont_camera_aceess , to.matched.some(record => record.meta.cont_camera_aceess));

        //  console.log("check drone_access ", userdata.drone_access , to.matched.some(record => record.meta.drone_access));

        //  console.log("check interior_access ", userdata.interior_access , to.matched.some(record => record.meta.interior_access));

        // console.log("check material_access ",userdata.material_access , to.matched.some(record => record.meta.material_access));

        if (to.matched.some(record => record.meta.comman)) {
          // console.log("commane");
          next();
          return;
        } else if (
          userdata.cont_camera_aceess == 1 &&
          to.matched.some(record => record.meta.cont_camera_aceess)
        ) {
          next();
          return;
        } else if (
          userdata.drone_access == 1 &&
          to.matched.some(record => record.meta.drone_access)
        ) {
          next();
          return;
        } else if (
          userdata.interior_access == 1 &&
          to.matched.some(record => record.meta.interior_access)
        ) {
          next();
          return;
        } else if (
          userdata.material_access == 1 &&
          to.matched.some(record => record.meta.material_access)
        ) {
          next();
          return;
        }else if(userdata.linear_monitoring_access == 1){

           console.log("userdata.linear_monitoring_access", userdata.linear_monitoring_access);
          next();
          return;
        }else if(userdata.virtual_tour_access == 1 &&
          to.matched.some(record => record.meta.virtual_tour)){
          next();
          return;
        }
      }
      next("/login");
    } else {
      next();
    }
  }
});

export default router;
