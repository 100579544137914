<template>
  <div>
    <Preloader v-if="preloader" />
    <v-row>
      <v-col cols="12">
        <v-card class="pa-4">
          <v-row>
            <!-- Radio Buttons for Selection -->
            <v-col cols="3" md="3">
              <v-radio-group v-model="selectedMode" row>
                <v-radio label="Project Wise" value="project"></v-radio>
                <v-radio label="Layout Wise" value="layout"></v-radio>
              </v-radio-group>
            </v-col>

            <!-- Date Range Picker (Always Visible) -->
            <v-col cols="2" md="2">
              <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="startDate" label="Start Date" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="startDate" @input="menuStartDate = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="2" md="2">
              <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="endDate" label="End Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="endDate" @input="menuEndDate = false"></v-date-picker>
              </v-menu>
            </v-col>

            <!-- Layout Dropdown (Only in Layout-Wise Mode) -->
            <v-col cols="3" md="3" v-if="selectedMode === 'layout'">
              <v-select v-model="selectedLayout" :items="layouts" item-text="name" item-value="drawingid"
                label="Select Layout" dense outlined :menu-props="{ auto: true }"></v-select>
            </v-col>
            <v-col cols="2" md="2" class="text-center">
              <v-btn color="primary" @click="fetchData">
                Fetch Data
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="topChartsMargin">
      <v-col cols="12" md="6">
        <v-card>
          <apexchart type="donut" height="380" :options="snagStatusChartOptions" :series="snagStatusSeries"></apexchart>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <apexchart type="donut" :options="chartSummaryOptions" :series="chartSummarySeries" height="380"></apexchart>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="bottomChartsMargin">
      <v-col cols="12" md="6">
        <v-card>
          <apexchart type="line" :options="chartProgressOverTimeOptions" :series="chartProgressOverTimeSeries"
            height="335">
          </apexchart>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <apexchart type="donut" :options="chart360normalImagesOptions" :series="chart360normalImagesSeries"
            height="380">
          </apexchart>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <apexchart type="heatmap" :options="chartDataDensityOptions" :series="chartDataDensitySeries" height="380">
          </apexchart>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" md="6">
        <v-card>
          <apexchart type="radialBar" :options="chartRadialDataOptions" :series="chartRadialDataSeries" height="380">
          </apexchart>
        </v-card>
      </v-col> -->
    </v-row>
  </div>
</template>
<script>

import axios from "axios";
import Preloader from "../../components/PreLoader";
import { locals } from "../../utils/locals";
import VueApexCharts from 'vue-apexcharts';
import image1 from '../../assets/e.png';
// import $ from 'jquery';
export default {
  components: {
    Preloader,
    apexchart: VueApexCharts,
  },
  name: "UserLogs",
  data() {
    return {
      preloader: false,
      items: {},
      currentProject: "",
      currentcam: "",
      logsdesserts: [],
      logitem: {},
      Local: locals,
      lang: {},
      snagStatusSeries: [],
      completedSnagStatusSeries: [],
      snagStatusChartOptions: {
        chart: {
          width: 380,
          type: 'donut',
        },
        labels: ['Completed Snag', 'Pending Snags'],
        dataLabels: {
          enabled: true,
          formatter: (val, opts) => {
            return opts.w.config.series[opts.seriesIndex]; // Show actual count inside the chart
          },
        },
        title: {
          text: 'Snag Status Distribution',
          // offsetX: -30,
          align: 'center',
          style: {
            color: '#444'
          }
        },
        tooltip: {
          enabled: true,
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function (w) {
                    return w.config.series.reduce((a, b) => a + b, 0); // Show total count in center
                  },
                },
              },
            },
          },
        },
      },
      chartSummarySeries: [],
      chartSummaryOptions: {
        chart: {
          type: "donut",
        },
        labels: ["Comments", "Images", "Videos"], // Label names
        colors: ["#008FFB", "#00E396", "#FEB019"], // Set colors (optional)
        dataLabels: {
          enabled: true,
          formatter: (val, opts) => {
            return opts.w.config.series[opts.seriesIndex]; // Show actual count inside the chart
          },
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        title: {
          text: 'Captured Data Summary',
          // offsetX: -60,
          align: 'center',
          style: {
            color: '#444'
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function (w) {
                    const series = w.config.series || [];
                    if (series.length === 0) {
                      return "No Data"; // Show this when no data is available
                    }
                    return series.reduce((a, b) => a + b, 0); // Correct total sum calculation
                  },
                },
              },
            },
          },
        },
      },
      chart360normalImagesSeries: [],
      chart360normalImagesOptions: {
        chart: {
          type: "donut",
        },
        title: {
          text: '360° vs Normal Images Distribution',
          // offsetX: -50,
          align: 'center',
          style: {
            color: '#444'
          }
        },
        labels: ["360 Images", "Normal Images"], // Labels for the two datasets
        colors: ["#008FFB", "#FEB019"], // Set colors (optional)
        dataLabels: {
          enabled: true,
          formatter: (val, opts) => {
            return opts.w.config.series[opts.seriesIndex]; // Show actual count inside the chart
          },
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function (w) {
                    return w.config.series.reduce((a, b) => a + b, 0); // Show total count in center
                  },
                },
              },
            },
          },
        },
      },
      chartProgressOverTimeSeries: [],
      chartProgressOverTimeOptions: {
        chart: {
          type: "line",
          zoom: { enabled: true },
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        xaxis: {
          type: "datetime",
          categories: [], // Dynamically filled with visitdate
        },
        yaxis: {
          title: { text: "Captured Media" },
        },
        title: {
          text: 'Media Progress Over Time',
          // offsetX: -50,
          align: 'center',
          style: {
            color: '#444'
          }
        },
        colors: ["#008FFB", "#00E396", "#FF4560", "#775DD0"], // Color options
        legend: { position: "bottom" },
        tooltip: {
          x: { format: "yyyy-MM-dd" },
        },
      },
      chartDataDensitySeries: [], // Heatmap series
      chartDataDensityOptions: {
        chart: {
          type: "heatmap",
          // toolbar: { show: true },
          toolbar: {
            show: true,
            tools: {
              // download: true,
              selection: true,
              zoom: true, // Enable zoom button
              zoomin: true,
              zoomout: true,
              pan: true, // Enable pan
              reset: true, // Reset zoom
            },
          },
        },
        title: {
          text: 'Data Density by Strategic Points',
          // offsetX: -50,
          align: 'center',
          style: {
            color: '#444'
          }
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560"], // Color scale for intensity
        xaxis: {
          categories: [], // Dynamic categories for X-Axis
          title: { text: "Strategic Points" },
        },
        yaxis: {
          title: { text: "Coordinate ID" },
        },
        tooltip: {
          y: { title: { formatter: (val) => `Captured Data: ${val}` } },
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            colorScale: {
              ranges: [
                { from: 0, to: 10, color: "#008FFB" },
                { from: 11, to: 50, color: "#00E396" },
                { from: 51, to: 100, color: "#FEB019" },
                { from: 101, to: 500, color: "#FF4560" },
              ],
            },
          },
        },
      },
      chartRadialDataSeries: [], // RadialBar chart series
      chartRadialDataOptions: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: "97%",
              margin: 5,
            },
            dataLabels: {
              name: { show: false },
              value: {
                fontSize: "22px",
                show: true,
                formatter: function (val) {
                  return val + "%"; // Show percentage
                },
              },
            },
          },
        }
      },
      colors: ["#00E396"],
      labels: ["Completion"],
      progressValue: 0, // Dynamic progress value
      completedSnagListCount: "",
      pendingSnagListCount: "",
      showcharts: false,
      getdrawingid: '',
      currentDrawingName: 'Select Drawing',
      drawingToggle: false,
      activeDrawing: {},
      userdata: {},
      taskbox: false,
      search: '',
      tasks: [],
      tasktype: {},
      zoom: 1,
      image1: image1,
      drawing: {
        name: 'Select Drawing',
        drawingpath: image1
      },
      reportemails: '',
      screenH: 768,
      ImgHeight: 600,
      imaglossp: 50,
      reportdates: {},
      projectid: 0,
      alertmessage: '',
      alert: false,
      hshow: false,
      allcordinatedata: [],
      coordsh: [],
      allavarage: 0,
      cordinatesOriginal: [],
      noti30: 30,
      noti45: 45,
      noti60: 60,
      notemessage60: "",
      sixtydaysnotificationxx: false,
      sendmail60: "",
      sendmail45: "",
      sendmail30: "",
      thirtydayNotification: false,
      foutyfivednotification: false,
      width: 0,
      timer: true,
      drawings: [],
      zoneid: 0,
      picker: new Date().toISOString().substr(0, 10),
      drawingLoadedData: [],
      snagList: [],
      headers: [
        {
          text: 'S.No',
          value: 'serialNumber',
          align: "center",
          width: "1px",
        },
        {
          text: "Package",
          value: "discipline",
          align: "center",
          width: "1px",
        },
        {
          text: "Raised",
          value: "total_snaglist_count",
          align: "center",
          width: "1px",
        },
        {
          text: "Closed",
          value: "completed_snaglist_count",
          align: "center",
          width: "1px",
        },
        {
          text: "Open",
          value: "pending_snaglist_count",
          align: "center",
          width: "1px",
        }
      ],
      pending_snaglist_count: [],
      completed_snaglist_count: [],
      snagListByDate: [],
      pending_snaglist_count_by_date: [],
      completed_snaglist_count_by_date: [],
      closedHighSnagListCount: "",
      openHighSnagListCount: "",
      closedModerateSnagListCount: "",
      openModerateSnagListCount: "",
      closedLowSnagListCount: "",
      openLowSnagListCount: "",
      startDate: this.getFormattedDate(new Date(new Date().setDate(new Date().getDate() - 7))), // 7 days back
      endDate: this.getFormattedDate(new Date()), // Current date
      chartdate: {},
      selectedMode: "project", // Default to Project Wise
      menuStartDate: false,
      menuEndDate: false,
      layouts: [], // Layouts from API
      selectedLayout: null, // Selected Layout

    };
  },
  mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];

    let currentProject = JSON.parse(localStorage.getItem('currentProject'));
    this.projectid = currentProject.id

    this.userdata = JSON.parse(localStorage.getItem("userdata"));


    if (this.userdata.open_access == 1) {
      this.$router.push("/dashboard");
    }

    this.getProjectCoordinates();
    this.getLayoutList();

  },
  watch: {
    layouts: {
      handler(newLayouts) {
        if (newLayouts.length > 0 && !this.selectedLayout) {
          this.selectedLayout = newLayouts[0].drawingid; // Auto-select first layout
        }
      },
      immediate: true, // Run immediately when component loads
    }
  },
  methods: {
    getFormattedDate(date) {
      return date.toISOString().split('T')[0]; // Formats as YYYY-MM-DD
    },
    fetchData() {
      if (this.selectedMode === "project") {
        this.getProjectCoordinates();
      } else {
        this.getLayoutCoordinates();
      }
    },
    getLayoutList() {
      this.preloader = true;
      axios({
        url: '/interior/getProjectLayouts/' + this.projectid,
        method: 'get',
      })
        .then(resp => {
          this.layouts = resp.data.success;
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error"
          this.preloader = false
        });
    },
    getLayoutCoordinates() {
      this.cordinates = [];
      this.hshow = false;
      this.preloader = true;
      axios({
        url: `/interior/getLayoutData/${this.projectid}/${this.selectedLayout}`,
        method: "get",
        params: { startDate: this.startDate, endDate: this.endDate },
      })
        .then((resp) => {

          this.allcordinatedata = resp.data.success[0]; // Assuming only one drawing_id is returned

          /****Snag Status Count (Completed & Pending)** **/
          let completedSnags = this.allcordinatedata.comments.filter(comment => comment.comment_status === 1).length || 0;
          let pendingSnags = this.allcordinatedata.comments.filter(comment => comment.comment_status === 0).length || 0;

          this.snagStatusSeries = [completedSnags, pendingSnags];

          /****Summary Data: Total Comments, 360 Images & Videos** **/
          let totalComments = this.allcordinatedata.comments.length || 0;
          let totalCount360Images = this.allcordinatedata.media.filter(media => media.imagetype === "panaroma").length || 0;
          let totalCountAllImages = this.allcordinatedata.media.filter(media => media.imagetype === "normal").length || 0;
          let totalCountAllImage = totalCount360Images + totalCountAllImages;
          let totalCountVideos = this.allcordinatedata.media.filter(media => media.imagetype === "video").length || 0;

          this.chartSummarySeries = [totalComments, totalCountAllImage, totalCountVideos];

          /****Data for 360 images vs. Normal images** **/
          let totalCount360 = this.allcordinatedata.media.filter(media => media.imagetype === "panaroma").length || 0;
          let totalCountImages = this.allcordinatedata.media.filter(media => media.imagetype === "normal").length || 0;

          this.chart360normalImagesSeries = [totalCount360, totalCountImages];

          /** **Layout-wise Progress Over Time (Line Chart)** **/
          let layoutProgressData = {};
          let uniqueDates = new Set();

          // Process comments
          this.allcordinatedata.comments.forEach((comment) => {
            const date = comment.commentsCrateAt.split(" ")[0]; // Extract date only
            uniqueDates.add(date);
            layoutProgressData[date] = (layoutProgressData[date] || 0) + 1;
          });

          // Process media
          this.allcordinatedata.media.forEach((media) => {
            const date = media.media_createAt.split(" ")[0]; // Extract date only
            uniqueDates.add(date);
            layoutProgressData[date] = (layoutProgressData[date] || 0) + 1;
          });

          let series1 = [{
            name: "Progress Over Time",
            data: Array.from(uniqueDates)
              .sort()
              .map(date => ({
                x: date,
                y: layoutProgressData[date] || 0,
              })),
          }];

          this.chartProgressOverTimeSeries = series1;
          this.chartProgressOverTimeOptions.xaxis.categories = Array.from(uniqueDates).sort();

          /** **Layout-wise Data Density (Heatmap)** **/
          let heatmapData = {};
          let uniqueCoordsHeatMap = new Set();

          this.allcordinatedata.coordinates.forEach((coord) => {
            const coordKey = coord.coordinate_title; // Unique coordinate title
            uniqueCoordsHeatMap.add(coordKey);

            // Count total media linked to this coordinate
            let mediaCount = this.allcordinatedata.media.filter(media => media.media_coordinateid === coord.coordinateid).length || 0;

            // Count total comments linked to this coordinate
            let commentCount = this.allcordinatedata.comments.filter(comment => comment.coordinateid === coord.coordinateid).length || 0;

            // Calculate total data captured for this coordinate
            let totalData = mediaCount + commentCount;

            heatmapData[coordKey] = (heatmapData[coordKey] || 0) + totalData;
          });

          let series2 = [{
            name: "Data Density",
            data: Array.from(uniqueCoordsHeatMap)
              .sort()
              .map(coord => ({
                x: coord,
                y: heatmapData[coord] || 0,
              })),
          }];

          this.chartDataDensitySeries = series2;
          this.chartDataDensityOptions.xaxis.categories = Array.from(uniqueCoordsHeatMap).sort();
          this.preloader = false;
        })
        .catch((err) => {
          console.error("API Error:", err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.preloader = false;
        });
    },
    getProjectCoordinates() {
      this.cordinates = [];
      this.hshow = false;
      this.preloader = true;
      axios({
        url: '/interior/getProjectCoordinates/' + this.projectid,
        method: 'get',
        params: { startDate: this.startDate, endDate: this.endDate }
      })
        .then(resp => {

          this.allcordinatedata = resp.data.success;

          //Compute Snag Status Counts (Completed & Pending)
          this.completedSnagListCount = this.allcordinatedata.reduce((total, obj) => {
            return total + obj.comments.filter(comment => comment.comment_status === 1).length;
          }, 0);

          this.pendingSnagListCount = this.allcordinatedata.reduce((total, obj) => {
            return total + obj.comments.filter(comment => comment.comment_status === 0).length;
          }, 0);

          // Update Series Data
          this.snagStatusSeries.length = 0;
          this.snagStatusSeries.push(this.completedSnagListCount);
          this.snagStatusSeries.push(this.pendingSnagListCount);

          // Initialize totals for layout-wise data
          let totalComments = 0;
          let totalCount360Images = 0;
          let totalCountNormalImages = 0;
          let totalCountVideos = 0;
          let totalCountAllImages = 0;

          this.allcordinatedata.forEach((drawing) => {
            totalComments += drawing.comments.length;
            totalCount360Images += drawing.media.filter(media => media.imagetype === "panaroma").length;
            totalCountNormalImages += drawing.media.filter(media => media.imagetype === "normal").length;
            totalCountAllImages = totalCount360Images + totalCountNormalImages;
            totalCountVideos += drawing.media.filter(media => media.imagetype === "video").length;
          });

          //Set layout-wise chart data
          this.chartSummarySeries = [totalComments, totalCountAllImages, totalCountVideos];

          //Data for 360 images vs. normal images
          let totalCount360 = 0;
          let totalCountImages = 0;

          this.allcordinatedata.forEach((drawing) => {
            totalCount360 += drawing.media.filter(media => media.imagetype === "panaroma").length;
            totalCountImages += drawing.media.filter(media => media.imagetype == "normal").length;
          });

          this.chart360normalImagesSeries = [totalCount360, totalCountImages];

          //**Layout-wise Progress Over Time (Line Chart)**
          let layoutProgressData = {};
          let uniqueDates = new Set();

          this.allcordinatedata.forEach((drawing) => {
            drawing.coordinates.forEach((coord) => {
              const date = coord.coordinateCreateAt.split(" ")[0]; // Extract Date from Timestamp
              const capturedData = 1; // Each coordinate is a captured data point
              uniqueDates.add(date);

              layoutProgressData[date] = (layoutProgressData[date] || 0) + capturedData;
            });

            drawing.media.forEach((media) => {
              const date = media.media_createAt.split(" ")[0];
              uniqueDates.add(date);
              layoutProgressData[date] = (layoutProgressData[date] || 0) + 1;
            });
          });

          let series1 = [{
            name: "Progress Over Time",
            data: Array.from(uniqueDates)
              .sort()
              .map(date => ({
                x: date,
                y: layoutProgressData[date] || 0,
              })),
          }];

          this.chartProgressOverTimeSeries = series1;
          this.chartProgressOverTimeOptions.xaxis.categories = Array.from(uniqueDates).sort();

          //**Layout-wise Data Density (Heatmap)**
          let heatmapData = {};
          let uniqueCoordsHeatMap = new Set();

          this.allcordinatedata.forEach((drawing) => {
            drawing.coordinates.forEach((coord) => {
              const coordKey = coord.coordinate_title; // Unique coordinate point
              uniqueCoordsHeatMap.add(coordKey);

              // Count total media files linked to this coordinate
              let mediaCount = drawing.media.filter(media => media.media_coordinateid === coord.coordinateid).length;

              // Count total comments linked to this coordinate
              let commentCount = drawing.comments.length;

              // Calculate total data captured for this coordinate
              let totalData = mediaCount + commentCount;

              heatmapData[coordKey] = (heatmapData[coordKey] || 0) + totalData;
            });
          });

          let series2 = [{
            name: "Data Density",
            data: Array.from(uniqueCoordsHeatMap)
              .sort()
              .map(coord => ({
                x: coord,
                y: heatmapData[coord] || 0,
              })),
          }];

          this.chartDataDensitySeries = series2;
          this.chartDataDensityOptions.xaxis.categories = Array.from(uniqueCoordsHeatMap).sort();
          this.preloader = false;


        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error"
          this.preloader = false
        });
    },

  }
};
</script>
<style>
.topChartsMargin {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.bottomChartsMargin {
  margin-right: 10px;
  margin-left: 10px;
}
</style>