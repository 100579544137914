export default {
       welcome: 'Bienvenu',
        SelectCamera :'Sélectionnez la caméra',
        Projects : 'Projects',
        AIData : 'PPE',
        AIDataAnalysis : 'Tableaux PPE',
        PpeReports : 'Rapports EPI',
        Aqi :'Aqi',
        PeopleDetection :'Détection de personnes',
        Archive : 'Archiver',
        Live : 'Habitent',
        Timelapse :'Laps de temps',
        WatchTimelapse : 'Regarder l`accéléré',
        CreateTimelapse :'Créer un timelapse',
        Compare :'Comparer',
        MediaUpload  :'téléchargement de médias',
        Planning :'Planification',
        EditData :'Modifier les données',
        CompareEvents :'Comparer les événements',
        EventChart : 'Tableau des événements',
        EventTimelapse :"Accéléré de l'événement",
        Report :'Reportage',
        FavoriteList : 'Liste des favoris',
        More : 'Suite',
        EventTimeline : "Chronologie de l'événement",
        SplitScreen : 'Écran divisé',
        Back :'Retour',
        Menu : 'Menu',
        Options : 'Choix',  
        CreateDetailedReport : 'Créer un rapport détaillé',
        InstantReport : 'Rapport instantané',
        CreateNow : 'Créer maintenant',
        Scheduleit : 'Programmez-le',
        PleaseReloadPage : 'Veuillez recharger la page',
        ImageLoadError : 'Veuillez recharger la page',
        imagenotFound : 'Images introuvables à la date sélectionnée',
        ImageShareSuccessful :"Partage d'image réussi",
        SomethingWentWrong  :'Something went wrong please try again.',
        ImageShareProcoreSuccessful :"Partage d'image avec Procore réussi.",
        LayoutSuccessfullyUploaded :'La mise en page a été téléchargée avec succès',
        LayoutSuccessfullyDeleted :'La mise en page a été supprimée avec succès',
        Logout : 'Se déconnecter',
        SuperAdmin : 'Super administrateur',
        User : 'Utilisateur',
        Admin : 'Administrateur',
        SelectLanguage :'Choisir la langue',
        UpdatePassword : 'Mettre à jour le mot de passe',
        AddUser : 'Ajouter un utilisateur',
        Users : 'Gérer les utilisateurs',
        UserLogs :'Journaux des utilisateurs',
        EmailTrigger : "Déclencheur d'e-mail",
        CameraOffalert : 'Alerte caméra désactivée',
        CameraStatus : 'État de la caméra',
        CameraNameAndTag : 'Nom et balise de la caméra',
        CreateCustomTimelapse : 'Créer un timelapse personnalisé',
        Billing : 'Facturation',
        ManageBimRenderings :'Gérer les rendus BIM',
        Integration : "L'intégration",
        TwoFactorAuthentication :'Two-Factor Authentication',
        DemoVideos :'Vidéos de démonstration',
        Support : 'Soutien',
        CameraConfiguration :'Configuration de la caméra',
        ProjectInfo : 'Informations sur le projet',
        SearchProjects : 'Rechercher des projets',
        ContactSupport :'Contact support',
        SendEmail :'Envoyer un e-mail',
        MapView : 'Vue de la carte',
        ConstructionCamera : 'Caméra de chantier',
        InteriorMonitoring : 'Surveillance des intérieurs',
        MaterialTracking : 'Suivi du matériel',
        DroneMonitoring : 'Surveillance des drones',
        LinearConstructionMonitoring : 'Surveillance de la construction linéaire',
        InteractiveDashboard : 'Tableau de bord interactif',
        To:'Pour',
        OtherEmails:'Ajouter plus de-mails ici',
        Subject : 'Sujet',
        Message : 'Message',
        SelectImage : 'Sélectionner une image',
        EmailRequired : "L'e-mail est requis",
        EmailValid : "L'email doit être valide",
         required: 'Ce champ est requis',
        Send : 'Envoyer',
        Cancel : 'Annuler',
        Close :'Fermer',
        Done : 'Fait',
        AddNew : 'Ajouter nouveau',
        Ok :"D'accord",
        Save : 'Sauvegarder',
        LiveImage : 'Image en direct',
        ImageMarkup : 'Image en direct',
        ShareImage : "Partager l'image",
        AddEventTimeline :"Ajouter à la chronologie de l'événement",
        RemoveFavorite : 'Supprimer le favori',
        AddToFavorite :  'Ajouter aux Favoris',
        Weather : 'La météo',
        DownloadImage : "Télécharger l'image",
        FullScreen : 'Plein écran',
        Pleasestandby : 'Veuillez patienter',
        UpdateCameraTag : 'Mettre à jour la balise de la caméra',
        BIM : 'BIM',
        FullTimeline : 'Chronologie complète',
        ShareToProcore :'Partager sur Procore',
        WeatherInfo : 'Infos météo',
        CreateNewBIM: 'Créer un nouveau rendu BIM',
        OpticVyuUpdates : 'Mises à jour OpticVyu',
        InstallationChecklist : "Liste de contrôle d'installation",
        InstallationDetails: "Détails d'installation",
        SimCardAdded : 'Carte SIM ajoutée',
        title :'Titre',
        ItemRequired : "L'article est requis",
        Remoteit :'À distance',
        InstallationSnap :"Snap d'installation",
        CamConfigSnap :'Snap de configuration de la caméra',
        SpeedTest :'Test de rapidité',
        ActivateReport : 'Activer le rapport',
        SiteLayout : 'Disposition du site',
        CamNameTag :  'étiquette de nom de caméra',
        DeliveryChallan :'Livraison Challan',
        LocationWhatapps : 'Whatapps de localisation',
        ImageDeletion : "Suppression d'images",
        NolayoutimageAvailable : 'Aucune image de mise en page disponible',
        Deletelayout : 'Supprimer la mise en page',
        GoToBilling : 'Aller à la facturation',
        NotifyTeam:  "Avertir l'équipe",
        billingNote : 'Remarque: Payez toutes les factures en attente pour supprimer cette notification.',
        mediaUploadTitle : 'Cliquez pour télécharger une mise en page',
        DragDrop : 'Glisser-déposer',
        EmailFaild : "Échec de la livraison de l'e-mail",
        Liveimagenotfound : 'Image en direct introuvable',
        removedFavorites :'Image supprimée des favoris',
        addedFavourites : 'Image ajoutée aux favoris',
        WhomtoNotify : 'Qui avertir (Email)?',
        ShareByEmail :'Partager par email',
        Structure : 'Structure',
        RenederingName : 'Nom de rendu',
        RenderingInfo : 'Informations de rendu',
        DiscribeTheEvent : "Décrivez l'événement",
        Imagenotfound : 'Image non trouvée',
        MainView : 'Vue principale',
        EditView :'Modifier vue',
        SurveyDate : "Date de l'enquête",
        SelectZone : 'Sélectionnez Zone',
        Inventories : 'Inventaires',

        Draft : 'Brouillon',
        AddMaterial :'Ajouter du matériel',
        Suppliers :'Fournisseurs',
        EditTask : 'Modifier la tâche',
        selectEvent: "SÉLECTIONNER UN ÉVÉNEMENT", 
        PlannedStartDate  : 'Date de début prévue',
        seeActualStartDate:"cliquez ici pour voir les images des dates réelles de début et de fin",
        seeActualEndDate : 'cliquez ici pour voir les images des dates de début et de fin prévues',
        SeePlannedDateImages : 'Voir les images de date planifiée',
        plannedEndDate:'Date de fin prévue',
        ActualStartDate :'Date de début réelle',
        SaveActualStartDate:'Enregistrer la date de début réelle',
        SaveActualEndDate : 'Enregistrer la date de fin réelle',
        StartdateSave : 'Date de début réelle enregistrée avec succès',
        EnddateSave : 'Date de fin réelle enregistrée avec succès',
        Search : 'Chercher',
        Import :'Importer',
        Export : 'Exportation',
        TaskName : 'Nom de la tâche',
        startDateTime:"Date-heure de début",
        endDatetime:'Date-heure de fin',
        SelectDatetime : 'Sélectionnez Date-heure',
        AddNewTask : 'Ajouter une nouvelle tâche',
        ImportExcelFile : 'Importer un fichier Excel',
        Steps : 'Pas',
        stepOne:'1. Exportez le fichier Excel (.xls) du projet Primavera / Microsoft, etc.',
        stepTwo: '2. Copiez les données dans sample.xls sans modifier son format ni les étiquettes de colonne',
        stepThree : '3. Téléchargez le fichier sample.xls.',
        Downloadfile:'Télécharger le fichier Sample.xls',


        RecordUpdatesuccessfully:'Enregistrer la mise à jour avec succès',
        NewEventsuccess:"Nouvelle tâche d'événement soumise avec succès",
        taskUplaod : 'Votre tâche a été téléchargée avec succès.',
        submit : 'Soumettre',
        StartDate : 'Date de début',
        EndDate : 'Date de fin',
        ActualEndDate :'Date de fin réelle',
        Actions :'Actions',
        pleaseSelectanyOne :'Veuillez en sélectionner un',
        eventtimenote:"Time-lapse de l'événement: de jour uniquement (de 8h00 à 17h00)",
        eventtime24 : 'Event time-lapse: 24 Hrs ',
        videoConfirm : 'Confirmer la suppression de ces vidéos?',
      removealert:'Vidéo accélérée supprimée avec succès',
      videonotfound : 'La vidéo accélérée est introuvable.',
      AddNewUser : 'Ajouter un nouvel utilisateur',
      Checkinfo : 'Check info',
      ManageUser :"Gérer l'utilisateur",
      FirstName : 'Prénom',
      LastName : 'Nom de famille',
      Email : 'E-mail',
      ContactNumber :'Numéro de contact',
      selectproject: "Sélectionnez des projets pour l'utilisateur",
      selectProjectOpenAccess: 'Sélectionnez des projets',
      newUserOpenAccess: 'Nouvel utilisateur avec accès libre créé avec succès',
      updateUserErrorOpenAccess: 'Impossible de mettre à jour le statut de l\'utilisateur',
      createOpenAccessHeading: 'Créer un lien d\'accès libre',
      createOpenAccessLinkButton: 'Créer un lien',
      createOpenAccessLinkNavButton:'enlace de acceso abierto',
      MakeNormalUser : 'Faire un utilisateur normal',
      Privileges : 'Privilèges',
      SeeExistingUsers : 'See Existing Users',
      MakeAdmin : 'Faire administrateur',
      ChangePassword : 'Change Password',
      EmailScheduler : "Planificateur d'e-mails",
      CameraTag : 'Balise de caméra',
      CameraInfo : 'Informations sur la caméra',
      CameraNotification : 'Notification de caméra',
      CustomizeTimelapseVideo : 'Personnaliser la vidéo accélérée',
      Userinfo : 'Informations utilisateur',
      Name : 'Nom',
      CreatedDate :'date de création',
      Contact : 'Contact',
      Status : 'Statut',
      ProjectName :'nom du projet',
      Company :'Société',
      City :'Ville',
      AddMorePorject :'Ajouter plus de projet',
      usermsgone : "Accès donné pour caméra de construction. Pour gérer l'accès à la surveillance intérieure et au suivi des matériaux, accédez à gérer les utilisateurs",
      usermsgtwo: "Accès donné pour caméra de construction. Pour gérer l'accès à la surveillance intérieure et au suivi des matériaux, accédez à gérer les utilisateurs",
      usermsgthree :"Utilisateur ajouté avec succès. Les instructions de connexion ont été envoyées par e-mail au nouvel utilisateur.",
      usererrorp1 :"L'utilisateur existe déjà. Demandez à votre super-administrateur d'accéder à Options--> Utilisateurs et accordez l'accès à",
      usererrorp2 : 'pour le projet en cours',
      Default : 'Défaut',
      CreatenewBIMRenedering :'Créer un nouveau rendu BIM',
      createprocore:'Connectez-vous avec Procore',
      ConnectWithBim360 : 'Connectez-vous avec Bim 360 ',
      requestURL:"Demander l'URL de l'application intégrée",
      biminstruction : "Pour utiliser l'URL de l'application intégrée sur le tableau de bord BIM360 Insight, suivez les instructions ci-dessous",
      instOne: '1. Accédez au tableau de bord BIM 360 Insight',
      instTwo: '2. Allez dans Personnaliser',
      instThree : '3. Cliquez sur Bibliothèque de cartes',
      instfour :'4. Recherchez une carte nommée "OpticVyu" ou "Carte partenaire" et ajoutez-la.',
      instFive :'5. Configurez la carte avec le titre approprié et OpticVyu "Embedded App URL"',
      instSix:"6. Les flux de la caméra doivent s'afficher automatiquement.",
      bimQue :"Quels projets souhaitez-vous afficher sur l'application intégré",
      bimsuccessmsg:"Demande enregistrée ! Vous recevrez l'URL de l'application intégrée dans les 2 jours par e-mail.",
      Sim :'Simulateur',
      Notes: 'Remarques',
      CameraReboot :'Redémarrage de la caméra',
      ImageInterval :"Intervalle d'image",
      CamType :'Type de came',
      BackImageTime : "Temps d'image arrière",
      ViewAddNote:'Afficher/Ajouter une note',
      SimManage:'Sim Gérer',
      AddCameraNote :'Ajouter une note de caméra',
      AddNote :'Ajouter une note',
      AssignSimcard : 'Assign Sim card',
      Addsimno :'Ajoutez le numéro de sim.',
      simprovider : 'fournisseur de carte SIM',
      Simusagestatus :"Statut d'utilisation de la Sim",
      ifsimfree : 'si libre, avec qui ?',
      UpdatedBy : 'Mis à jour par',
      camsetting: 'Réglage de la caméra mis à jour avec succès',
      camfialdsetting :'Échec de la mise à jour des paramètres de la caméra. Veuillez réessayer!',
      EditCameraNameandTag:"Modifier le nom et l'étiquette de la caméra",
      CameraName : 'Nom de la caméra',
      Reset :'Réinitialiser',
      cameranotification:'Notification marche/arrêt de la caméra',
      NotificationActive : 'Notification active',
      camranotiStatus:"L'état de notification de la caméra est inactif, pour l'activer, veuillez cliquer sur la case à cocher",
      cameraActivemsg :'Notification caméra activée',
      Resetsuccesful:'Réinitialisation réussie.',

      ChangeYourPassword :'changez votre mot de passe',
      OldPassword :'ancien mot de passe',
      NewPassword : 'nouveau mot de passe',
      ConfirmPassword:'Confirmez le mot de passe',
      passwordvalid:'Veuillez saisir au moins un caractère majuscule, minuscule, numérique et spatial',
      passwordsame: 'Le mot de passe doit être le même',
      UploadSpeed : 'Vitesse de téléchargement',
      OpticVyuPresentation : 'Présentation OpticVyu',
      DateofInstallation :"Date d'installation",
      Designation : 'La désignation',
      Person1 : 'Personne 1',
      Person2 : 'Personne 2',
      Person3 : 'Personne 3',
      Person4 : 'Persona 4',
      AccountPerson :'Personne de compte',
      InstallationNote:"Remarque sur l'installation",
      OVEnginner :'Ingénieur AV',
      OneTimeTimelapse : 'Timelapse unique',
      AdvanceTimelapse : 'Accéléré avancé',
      RecurringTimelapse : 'Timelapse récurrent',
      ProjectTimelapse : 'Accéléré du projet',
      Refresh : 'Rafraîchir',
      StartTime :'Heure de début',
      Endtime : 'Heure de fin',
      TimelapseSpeed:'Vitesse accélérée',
      TimelapseQuality :'Qualité accélérée',
      TimelapseAdvancesettings : "Paramètres avancés de l'accéléré",
      Selectframeblending : 'Sélectionnez la fusion des images',
      SelectVideoSmoothing :'Sélectionnez le lissage vidéo ',
      SelectDeflicker : 'Sélectionnez Anti-scintillement',
      EnterOverlayText:'Entrez le texte de superposition (facultatif)',
      timelapsenote1 : "1. Le time-lapse avancé peut prendre jusqu'à 8 heures. Vous serez averti dès qu'il sera prêt.",
      timelapsenote2 : "2. Pour une personnalisation rapide, veuillez utiliser l'option timelapse personnalisée unique.",
      projectnote1 : "1. Le time-lapse du projet peut prendre jusqu'à 12 heures. Vous serez averti dès qu'il sera prêt.",
      selectop1 :"N'utilisez les options de fusion avancées que lorsque l'appareil photo est stable. Lorsqu'elle est installée sur une grue à tour ou sur tout support instable, l'option de mélange détériorera la qualité de sortie.",
      selectop2 :'Si une caméra est installée sur un support instable comme une grue à tour ou un poteau mince, etc., utilisez cette option pour stabiliser la vidéo accélérée tremblante.',
      Adalertmessage : "La date-heure de début et de fin ne doit pas être supérieure à la date-heure d'aujourd'hui",
      adrequired :'La date-heure de début et la date-heure de fin sont des champs obligatoires.',
      adrequired2 : 'La date-heure de début doit être antérieure à la date-heure de fin',
      adrequired3 : 'Veuillez sélectionner la vitesse et la qualité,',
      adrequired4 : "Veuillez sélectionner l'option de personnalisation avancée et ses sous-options respectées.",
      adsuccess : "Le time-lapse avancé est en cours de traitement. Vous serez averti par e-mail une fois qu'il sera prêt",
      addnotschedule :'Time-lapse avancé non programmé, merci de réessayer !',
      upto8 : "1. Le time-lapse avancé peut prendre jusqu'à 8 heures. Vous serez informé dès qu'il sera prêt.<br>2. Pour une personnalisation rapide, veuillez utiliser l'option timelapse personnalisée unique.",
      adarror : "Aucun timelapse personnalisé existant n'a été trouvé pour cette caméra.",
      resetsetting: 'Votre paramètre a été réinitialisé.',
      adnotcreate :"Le time-lapse avancé n'est pas réinitialisé, veuillez réessayer !",
      info1 : 'Pour afficher des vidéos accélérées de plusieurs caméras dans une seule vidéo, utilisez cette option.',
      info2 :'Un minimum de 2 et un maximum de 4 caméras peuvent être sélectionnées pour créer une vidéo time-lapse de projet.',
      ptnotifiy :"Le time-lapse du projet peut prendre jusqu'à 12 heures pour être traité. Vous serez averti dès qu'il sera prêt.",
      proprocess :"Le time-lapse du projet est en cours de traitement. Vous serez averti par e-mail une fois qu'il sera prêt",
      projectTiError :"Time-lapse du projet non planifié, veuillez réessayer !",
      alertmessageminmax :"Choisissez minimum 2 et maximum 4 caméras",
      projectready :"Le time-lapse du projet peut prendre jusqu'à 12 heures pour être traité. Vous serez averti dès qu'il sera prêt",
       reseterror:"Une fois le time-lapse personnalisé n'a pas été réinitialisé, veuillez réessayer !",
       Daily :'du quotidien',
       Weekly : 'Hebdomadaire',
       Monthly :'Mensuel',
       info_rec : 'Le système créera une vidéo accélérée quotidiennement selon les paramètres soumis. .',
       Infovidoss :'Créez une vidéo accélérée pour une date de début/fin personnalisée, une heure de début/fin, la vitesse et la qualité de la vidéo requises.',
      createTimelapseInfo : 'Projection en accéléré : pour afficher des vidéos en accéléré de plusieurs caméras dans une seule vidéo, utilisez cette option. <br />Un minimum de 2 et un maximum de 4 caméras peuvent être sélectionnées pour créer une vidéo time-lapse de projet.',
      reccuringtimelapsecheck :'Veuillez vérifier le type de timelapse récurrent',
       alerrecurring : 'Veuillez vérifier le type de timelapse récurrent',
       recurringsave :'Paramètre de time-lapse récurrent enregistré avec succès',
       timelapsenotsave:'Timelapse non soumis, veuillez réessayer !',
       timelapsebingpressed :"Un time-lapse personnalisé est en cours de traitement. Vous serez averti par e-mail une fois qu'il sera prêt",
       timelapsenotexit:"Aucun timelapse personnalisé existant n'a été trouvé pour cette caméra",
       custometimelapseresrt :'Time-lapse personnalisé non réinitialisé, veuillez réessayer',
       recurringsettingsave :'Les paramètres de Timelapse récurrents ont été enregistrés avec succès',
       onetimesetting:'Les paramètres Timelapse uniques ont été enregistrés avec succès',
       ConnectwithProcore : 'Connectez-vous avec Procore',
       UserName : "Nom d'utilisateur",
       Module : 'Module',
       Page : 'Page',
       Date :'Date',
       UserType :"Type d'utilisateur",
       selectRange:'Sélectionnez une gamme',
       ManageUsers : 'gérer les utilisateurs',
       UserProfile : "Profil de l'utilisateur",
       emailhint : "L'e-mail ne peut pas être mis à jour",
        selectuserHint:"Sélectionnez des projets pour l'utilisateur",
        Active : 'Actif',
        conscamaccess:'Accès à la caméra de construction',
        MaterialAccess : 'Accès matériel',
        InteriorviewAccess : 'Vue intérieure Accès',
        InteriorEditAccess:'Accès aux modifications intérieures',
        DroneEditAccess : 'Accès à la modification des drones',
        LinearEditAccess : 'Accès aux modifications linéaires',
        UserAccess : 'Accès utilisateur',
        PPEAIDataScheduleReport: "Rapport sur le calendrier des données d'IA de l'EPI",
        Reporttype : 'type de rapport',
        DaiDay:'Le rapport quotidien sera envoyé tous les jours',
        DaiDay1:'Le rapport hebdomadaire sera envoyé tous les lundis.',
        Dailyreport: 'Rapport journalier',
        Weeklyreport : 'Rapport hebdomadaire',
        ppeReport : "Le statut du rapport EPI est inactif, pour l'activer, veuillez cliquer sur la case à cocher",
         ppeActive : 'Repot EPI activé',
         procoreconnection : "Vous pouvez connecter votre compte Procore à OpticVyu. Cela vous permettra d'inviter facilement des utilisateurs à rejoindre Opticvyu et à partager des images dans Procore",
         opticvyuProject:'Projets OpticVyu',
         linkproject : 'Projets Procore à lier',
         selectprocoreproject : 'Sélectionnez le projet Procore',
         configprocore :'Configurer des projets avec Procore',
         procoreConnectionSuccess : 'Projet Connecté à Procore réussi.',
         requestembadedcode : "Demander le code d'application intégré",
         SendImagery : 'Envoyer des images',
         procoreissue : "Problème de connexion Procore ?",
         procorealreadyconnected  : "Ce projet de processus est déjà connecté au projet OpticVyu, veuillez accéder à votre projet Procore et renommer ou supprimer les albums actuels, puis réessayer.",
          whichpro : "Quels projets souhaitez-vous afficher sur l'application intégrée ?",
          procoreconfirm  : 'Êtes-vous sûr de connecter ce projet avec Opticvyu ?',
          procoreDisconnect: "Le projet a été déconnecté avec succès pour l'imagerie sedingin",
          procorediconfigure : 'Vous souhaitez déconfigurer tous les projets connectés avec Procore ?',
          deconfigure : 'Déconfigurer avec succès',
          embadedRequest : "La demande de code d'application intégrée a été enregistrée avec succès. Vous recevrez le code par e-mail dans les 2 jours ouvrables.",
          procoremsg :"Vous avez autorisé Opticvyu à communiquer avec Procore. Vous pouvez annuler cette opération et annuler l'autorisation d'OpticVyu de Procore en cliquant sur",
          here : "ici",
          proopticvy : "Pour plus d'informations sur OpticVyu - Intégration Procore",
          EmailSchedule:'Calendrier des e-mails',
          Selectdays:'Sélectionnez des jours',
          Selecttime:"Sélectionnez l'heure (IST)*",
          resetsuccessfull : 'Réinitialiser avec succès',
          twofactoreAuthentication : "Un code d'authentification sera envoyé à votre adresse e-mail pendant que vous essayez de vous connecter",
          ClicktoInActive :'Cliquez pour inactif',
          ClicktoActive :'Cliquez pour inactif',
          Device : 'Appareil',
          Emailstatement : 'Relevé par e-mail',
          Reload : 'Recharger',
          Statementsentsuccessfully : 'Relevé envoyé avec succès',
          InvoiceDate : 'Date de facturation',
          Invoice : "Facture d'achat",
          WorkOrder : 'Demande de service',
          Amount : 'Montant',
          BalanceDue : 'Balance Due',
          DueDate : "Date d'échéance",
          OverdueBy : 'En retard de', 
          DefaultTimelapse : 'En retard de',
          CustomTimelapse :'Accéléré personnalisé',
          ArchiveTimelapse : 'Accéléré des archives',
         
          Advance : 'Avance',
          Project : 'Projet',
          Type : 'Taper',
          Advanceediting :'Édition avancée',
          Deflicker : 'Anti-scintillement',
          Copyurl :'Copier le lien',
          MoveTimelapseToArchive:"Déplacer le time-lapse vers l'archive",
          Addcaptionforarchivetimelapse:"Ajouter une légende pour le time-lapse de l'archive",

          Graphs :'Graphiques',
          max7 : 'Max. 7 jours peuvent être sélectionnés',
          last7days:'Remarque : Aucune donnée disponible au cours des 7 derniers jours. Sélectionnez une plage de dates personnalisée pour voir les données passées.',
          
          clicktogetrelated : 'Remarque : Aucune donnée disponible au cours des 7 derniers jours. Sélectionnez une plage de dates personnalisée pour voir les données passées.',
          PersonsWithRequiredEquipment :"Personnes ayant l'équipement requis",
          PersonsWithoutRequiredEquipment : 'Personnes sans équipement requis',
          PersonUnrecognizable :'Personne méconnaissable',
          PersonsIdentified : 'Personnes identifiées',
          Remark : 'Remark',
          PossibleHealthImpacts :'Impacts possibles sur la santé',
          ColorCode : 'Code couleur',
          poor :"Pauvre",
          VeryPoor :"Très pauvre",
          Good : 'Bon',
          Satisfactory : 'Satisfaisant',
          Moderate : 'Modérer',
          Severe  :'sévère',

          Minimalimpact :'Impact minimal',
          Minorbreathing:"Inconfort respiratoire pour les personnes souffrant de poumons, d'asthme et de maladies cardiaques",
          breathing1:"Inconfort respiratoire pour les personnes souffrant de poumons, d'asthme et de maladies cardiaques",
          breathing2 : 'Gêne respiratoire pour la plupart des personnes exposées à long terme',
          breathing3 : "Maladie respiratoire lors d'une exposition prolongée",
          breathing4 :'Affecte les personnes en bonne santé et affecte gravement les personnes atteintes de maladies existantes',
          AddedOn : 'Ajouté le',
          AdvanceCustomization : 'Personnalisation avancée',
          Speed : 'Vitesse',
          FrameBlending : 'Fusion de trames',
          TimelapseSmoothing : 'Lissage accéléré',
          notification :"Aucun time-lapse d'archive trouvé. Créez un time-lapse personnalisé et ajoutez des vidéos importantes à archiver pour référence future",
          notificationtitle : 'Notification',
          archiveDelete: 'Archive Timelapse supprimée avec succès.',
          archiveError : 'Archive-Timelapse non supprimé. Veuillez réessayer.',
          copymsg : 'Copier le lien! Lien valable 7 jours seulement',
          timelapsesharesuccess:'Timelapse shared successfully. Please check your mail',
          Quality : 'Qualité',
          Delete : 'Supprimer',
          Share : 'Partager',
          Editor :'Éditeur',
          SavetoCart : 'Enregistrer au panier',
          CartImages : 'Images du panier',
          Deleteallcartimages : 'Supprimer toutes les images du panier',
          selectImagescard :"Sélectionnez une image de l'éditeur à ajouter au panier",
          Info :"Info",
          GenerateReport : 'Générer un rapport',
          PreviousReports :'Rapports précédents',
          CartImageInfo : "Informations sur l'image du panier",
          Addedby :'Ajouté par',
          Addedat : 'Ajouté à',
          SwitchMode : 'Changer de mode',
          CheckDifference : 'Vérifier la différence',
          OnetimeRecurring :'Ponctuel/Récurrent',
          ShowAll : 'Afficher tout',
          Favourites : 'Favoris',
          ImageCapturedTime: "Heure de capture de l'image",
          ImageAddedTime : "Temps d'ajout de l'image",
          favouriteremove : "L'image a bien été supprimée des favoris.",
          favoriterror :"Obtenir une erreur lors de la suppression de l'image du favori",   
          ReportDemo : 'Signaler une démo',
          ScheduleReport : 'Rapport de planification',
          GeneratePdfReport : 'Générer un rapport PDF',
          MailPdfReport : 'Envoyer le rapport PDF',

          emailsuccessMsg : 'Rapport partagé avec succès. Veuillez vérifier votre e-mail',
          emailFailsMSG: 'Échec du partage du rapport. Veuillez recharger et réessayer !',
          SelectDate :'Sélectionner une date',
          Allprojects :'Tous les projets',
          Runningprojects : 'Projets en cours',
          Completedprojects : 'Projets achevés',
          Albums : 'Albums',
          ImagesVideos :'Images/Vidéos',
         mobilessupload:"Téléchargez l'application OpticVyu depuis Playstore et téléchargez les photos du projet capturées via mobile",
         nodirectry : "Téléchargez l'application OpticVyu depuis Playstore et téléchargez les photos du projet capturées via mobile",
         noImages : 'Aucune image ou vidéo téléchargée sous ce répertoire',
         nodaavailble :'Remarque : Aucune donnée disponible à cette date, veuillez consulter les données antérieures',
         Smoothing : 'Lissage',
         archivetimelapsedone:"Timelapse déplacé avec succès vers l'archive timelapse",

         biweekly : 'Des rapports bihebdomadaires seront envoyés les 1er et 16e jours du mois',
         monthly : "Le rapport mensuel sera envoyé le 1er jour du mois",
         Weeklyprogressreport :"Rapport d'avancement hebdomadaire",
         Biweeklyprogressreport :"Rapport d'avancement bihebdomadaire",
         Monthlyprogressreport : "Rapport d'avancement mensuel",
          nosechedulereport: "Aucun rapport de planification active n'a été trouvé pour ce projet.",
          checkreport :'Veuillez vérifier le type de rapport',
          EnterEmail: "Veuillez saisir l'identifiant de l'e-mail",
          selectCamera : 'Please select camera',

          settingsave :'Le paramètre Schedule-Report a été enregistré avec succès',
           schedulenotsubmit: 'Horaire-Rapport non soumis, veuillez réessayer',
           reportReset : 'Planification-Rapport réinitialisé avec succès',
         reportnotseset : 'Horaire-Rapport non réinitialisé, veuillez réessayer',
        copysuccess : 'Copie réussie pour archiver le timelapse',

        //  Interior 

        AddCoordinate : 'Ajouter une coordonnée',
        WorkStatus : "Statut de travail",
        VisitingDate : 'Date de visite',
        Category : 'Catégorie',
        Gallery : 'Galerie',
        UploadFiles:'Télécharger des fichiers',
        NormalImage : 'Image normale',
        Image : 'Image',
        Video : 'Vidéo',
        RemoveSelected : 'Remove Selected',
        click360video : 'Cliquez pour télécharger des vidéos 360',
        UpdateCoordinate : 'Mettre à jour les coordonnées',
        cordinatConfirm : "Êtes-vous sûr d'ajouter ces coordonnées.",
        removeCoordinate: 'Êtes-vous sûr de vouloir supprimer cette coordonnée ? si oui, toutes vos images et vidéos téléchargées seront définitivement supprimées de cette coordonnée.',
        cordianteUpdate :'Coordonner la mise à jour avec succès.',
        videoupload : 'Vidéo téléchargée avec succès',
        deleteConfirm : 'Confirmer la suppression de ce(s) fichier(s)',
        deletefilesuccess : 'Fichiers sélectionnés supprimés avec succès',
        Edit : 'Edit',
        UploadDrawing : 'Télécharger le dessin',
        AddZone : 'Ajouter une zone',
        DrawingName : 'Nom du dessin',
        Floor : 'Sol',
        Area: 'Région',
        zoneName : 'Nom de zone',
        NewZone : 'Nouvelle zone',
        drawingupload : 'Téléchargement du dessin réussi',
        drawingconfirm : 'Confirmer la suppression de ce dessin ?',
        drawingremove : 'Dessin supprimé avec succès',
        addezone : 'Nouvelle zone ajoutée avec succès',
        Images360 : 'Images à 360°', 
        NormalImages : 'Images normales',
         videos360 : '360 Vidéos',
         Comments : 'commentaires',
         Task : 'Tâche',
         AddComment : 'Add Comment',
         Lock : 'Fermer à clé',
         ImportTasks : 'Importer des tâches',
         StandardTaskLists:'Listes de tâches standard',
         FilterByDate : 'Filtrer par date',
         AddNewComment : 'Ajouter un nouveau commentaire',
         Comment : 'Commenter',
         Progress : 'Le progrès %',
         Tag : 'Étiqueter',
         UpdateTask : 'Mettre à jour la tâche',
         DownloadExcel : 'Télécharger Excel',
         OR : 'OU',
         DownloadPDF : ' Télécharger le PDF',
         start : 'Démarrer',
         end : 'Finir',
         addzone :'Nouvelle zone ajoutée avec succès.',
         addcommnetssucess :'Commentaire ajouté avec succès',
         listconfirmation : "Êtes-vous sûr d'importer la liste des tâches ?",
         standdrlistloaded: "Liste de tâches standard chargée",
         newmsg :'Nouvelle tâche ajoutée avec succès.',
         tasksuccess : 'Mise à jour de la tâche réussie',
         sureremove : 'Êtes-vous sûr de supprimer cette tâche ?',
         taskDelete : 'Tâche supprimée avec succès',
         removetask :"Êtes-vous sûr de supprimer les tâches sélectionnées ?",
         PlannedEnd : 'Fin prévue',
         PlannedStart : 'Début prévu',
         DroneImages : 'Images de drones',
         DroneVideo : 'Drone Video',
         UploadMedia : 'Télécharger le média',
         VisitDate : 'Date de visite',
         AddNewVisitingDate : 'Ajouter une nouvelle date de visite',
         Upload : 'Télécharger',
         OpenGallery : 'Ouvrir la galerie',
         srtfile:'Télécharger le fichier srt',
         AddVideoInfo : 'Ajouter des informations sur la vidéo',
         MetaList : 'Méta liste',
         latlongnotavailble :"Latitude et longitude de l'image non disponibles",
         imagesnotavailble : "Images non disponibles",
         recordadded : 'Enregistrement ajouté avec succès',
         visitdateadded :'Date de visite ajoutée avec succès',
         selecteddelte :'Fichiers sélectionnés supprimés avec succès',
         taskconfirmation : ' Êtes-vous sûr de supprimer cette information?',
          recordDeleted : 'Enregistrement supprimé avec succès',
          BulkUpload : 'Transfert groupé',
          DroneVideos : 'Vidéos de drones',
          ReviewDate :'Date de révision',
          ReviewImages : 'Examiner les images',
           reviewImagesandSubmit : 'Images téléchargées. Veuillez cliquer sur "Revoir les images" pour soumettre',
           Delayed : 'Retardé',
           Critical : 'Critique',
           Complete : 'Compléter',
           Running : 'Fonctionnement',
           LayoutInsights : 'Informations sur la mise en page',
           SelectDrawing : 'Sélectionnez le dessin',
           AddNewMaterialHeading : 'Ajouter un nouveau titre de matériel',
           AddNewSubHeading : 'Ajouter un nouveau sous-titre',
           AddNewMaterial: 'Ajouter un nouveau matériau',
           MaterialName : 'Nom du matériau',
           Unit : 'Unité',
           AssetType : "Type d'actif",
           Estimateqty : 'Estimer la quantité',
           MaterialHeading :'En-tête du matériel',
           Enable : 'Activer',
           MaterialSubHeading : 'Sous-titre matériel',
           integetrequre :"Ce champ n'autorise que les entiers",
           HeadingUpdateSuccessfully:'Mise à jour du cap réussie',
           confirmdelteheading:'Confirmer Supprimer cette rubrique ?',
           HeadingdeletedSuccessfully: 'Titre supprimé avec succès',
           HeadingAddedSuccessfully  :'Titre ajouté avec succès', 
           SubHeadingUpdateSuccessfully: 'Sous-titre mis à jour avec succès',
           SubheadingdeleteSuccessfully:'Sous-titre supprimé avec succès',
           SubheadingAddedSuccessfully:'Sous-titre ajouté avec succès',
           MaterialUpdateSuccessfully : 'Mise à jour matérielle réussie',
           MaterialDeleteSuccessfully: 'Matériel supprimé avec succès',
           MaterialAddedSuccessfully : 'Matériel ajouté avec succès',
           Yourtaskuploadedsuccesfully: 'Votre tâche a été téléchargée avec succès',
           DraftsInventories : "Projets d'inventaires",
           Confirmremovethisitem:'Confirmer la suppression de cet élément?',
           EnteryDeletedSuccessfully : 'Entrée supprimée avec succès',
           goback: 'Retourner', 
           Discription : 'Description',
           GoToInventory : "Aller à l'inventaire",
           UpdateInventory : "Mettre à jour l'inventaire",
           DCNo : 'N° DC / Bordereau de poids',
           PONo : 'Bon de commande Non',
           VehicleNo : 'Vehicle No',
           SupplierName : 'Nom du fournisseur',
           Rate : 'Taux',
           Quantity : 'Quantité',
           Update : 'Mettre à jour',
           NewInventory : 'Nouvel inventaire',
           InventoryUpdateSuccessfully : "Mise à jour de l'inventaire réussie",
           InventoryDeletedSuccessfully : 'Inventaire supprimé avec succès',
           InventoryAddedSuccessfully : 'Inventaire ajouté avec succès',
           mobileapp : "Veuillez télécharger les images depuis l'application mobile pour soumettre les inventaires.",
           InventorySubmitSuccessfull : 'Inventaire soumis avec succès',
           EditRequestNote :'Modifier la note de demande',
           EditRequest : 'Modifier la demande',
           InventoryEditRequest:"Demande de modification d'inventaire",
           Commentsforinventoryupdate:"Commentaires pour la mise à jour de l'inventaire",
           RequestSuperadmin:'Demander un super-administrateur',
         
           OverallReport:'Rapport global',
           CumulativeReport : 'Rapport cumulatif',
           MaterialwiseReport : 'Rapport sur les matériaux',
           Requestsuccessfullysent:'Demande envoyée avec succès',
           Images : 'Images',
           VehicleEntry : 'Entrée du véhicule',
           offtheproject:'hors site du projet',
          
           VehicleNumberPlate : "Plaque d'immatriculation du véhicule",
           deliverychalaimage : 'Livraison Chalan & Bordereau de Pesée',
           vehivleunloading:'Déchargement de véhicule',
           VehicleEmpty : 'Véhicule vide',
           other : 'Autre',
           reflected : "Si les données du tableau ne sont pas reflétées, veuillez essayer de recharger ou d'actualiser la page",
           ImportSuppliers : "Fournisseurs d'importation",
           SupplieraddedSuccessfull : 'Fournisseur ajouté avec succès',
           Suppliersfileimportsuccessfully : 'Importation du fichier fournisseurs réussie',
           deleteconfirm:'Importation du fichier fournisseurs réussie',
            removesucces:'supprimer le succès',
            clear : 'dégager',
            Profile : 'Informations sur le projet',
            SaveandShare : 'Enregistrer et envoyer',
            CRM:'CRC',
            DailyReport:'Rapport journalier',
            EveryMonthprogressreport:'Rapport d\'avancement mensuel',
            BiMonthlyprogressreport:'Rapport d\'avancement bimensuel',
            Quarterlyprogressreport:'rapport d\'étape trimestriel',
            HalfYearlyprogressreport:'rapport d\'étape semestriel',
      }